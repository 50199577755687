
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';
import AuthLayout from '../../components/AuthLayout';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const Resetpassword = () => {
    const history = useNavigate();
    const [form, setForm]: any = useState({ confirmPassword: '', newPassword: '', code: '' })
    const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
    const [formSubmit, setformSubmit] = useState(false)
    const [error, seterror] = useState()

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history('/dashboard')
        }
        let prm = {
            code: methodModel.getPrams('code'),
        }
        setForm({ ...form, ...prm })
    }, [])

    // Validation Schema for resset password form
    const FormSchema = Yup.object({
        password: Yup.string()
            .min(8, 'Password must be at least 8 cahracters *')
            .required('Password  is required *')
            .matches(/[0-9]/, 'Password requires a number *')
            .matches(/[a-z]/, 'Password requires a lowercase letter *')
            .matches(/[A-Z]/, 'Password requires an uppercase letter *')
            .matches(/[^\w]/, 'Password requires a symbol *')
            .max(16, 'Password must be less than 16 characters *'),
        confirmpassword: Yup.string()
            .min(8, 'Password must be at least 8 cahracters *')
            .required('Confirm password  is required *')
            .oneOf([Yup.ref('password'), null || ''], "Confirm password not match with password *")
    })

    const hendleSubmit = (value: any) => {
        let payload = {
            code: form.code,
            newPassword: value.password,
            confirmPassword: value?.confirmpassword
        }
        ApiClient.put('reset/password', payload, '', true).then(res => {
            if (res?.success) {
                setformSubmit(true)
            }
            else {
                seterror(res.error.message)
            }
        })
    }

    return (
        <>
            <AuthLayout>
                <div className="w-full max-w-[400px] px-1 py-5 mx-auto min-h-screen flex items-center">
                    <div className='mx-auto h-full'>
                        <Formik
                            initialValues={{
                                password: '',
                                confirmpassword: ''
                            }}
                            validationSchema={FormSchema}
                            onSubmit={hendleSubmit}
                        >
                            <Form>
                                <div className='img_data mb-3 lg:mb-6'>
                                    <img className='mx-auto' src="/assets/img/front/key-updated.svg" />
                                </div>
                                <div className="text-center mb-6">
                                    <h3 className="font-semi-bold font-semi-bold text-lg lg:text-4xl text-secondary mb-2">{!formSubmit ? 'Set New Password' : 'Password reset'}</h3>
                                    <p className='text-base text-gray-light'>Your new password must be different to previously used passwords.</p>
                                </div>
                                {!formSubmit ?
                                    <>
                                        <div className="mb-4">
                                            <label className='text-sm text-secondary font-medium mb-1 lg:mb-2 block'>Password</label>
                                            <div className="inputWrapper relative">
                                                <Field
                                                    type={eyes.password ? 'text' : 'password'}
                                                    name='password'
                                                    className="relative shadow-sm  border-gray-border bg-white  h-10 flex items-center gap-2 overflow-hidden px-3.5 w-full focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none !border-0 rounded-xl appearance-none"
                                                    placeholder="Password"
                                                />
                                                {eyes.password ? (
                                                    <FaEye className="size-[18px] top-3 right-3.5 absolute text-gray-light cursor-pointer" onClick={() => setEyes({ ...eyes, password: !eyes.password })} />
                                                ) : (
                                                    <FaEyeSlash className="size-[18px] top-3 right-3.5 absolute text-gray-light cursor-pointer" onClick={() => setEyes({ ...eyes, password: !eyes.password })} />
                                                )}
                                            </div>
                                            <div className="invalid-feedback text-red-500 mt-0.5"><ErrorMessage name='password' /></div>
                                        </div>
                                        <div className="inputWrapper">
                                            <label className='text-sm text-secondary font-medium mb-1 lg:mb-2 block'>Confirm Password</label>
                                            <div className="inputWrapper relative">
                                                <Field
                                                    type={eyes.confirmPassword ? 'text' : 'password'}
                                                    name='confirmpassword'
                                                    className="relative shadow-sm border-gray-border bg-white  h-10 flex items-center gap-2 overflow-hidden px-3.5 w-full focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none !border-0 rounded-xl appearance-none"
                                                    placeholder="Confirm Password"
                                                />
                                                {eyes.confirmPassword ? (
                                                    <FaEye className="size-4 top-3 right-3.5 absolute text-gray-light cursor-pointer" onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })} />
                                                ) : (
                                                    <FaEyeSlash className="size-4 top-3 right-3.5 absolute text-gray-light cursor-pointer" onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })} />
                                                )}
                                            </div>
                                            <div className="invalid-feedback text-red-500 mt-0.5"><ErrorMessage name='confirmpassword' /></div>
                                        </div>
                                        <div className="buttons mt-3 lg:mt-6">
                                            <button type="submit" className="w-full text-base font-medium text-white h-11 flex items-center justify-center rounded-xl py-2 px-4 gap-2 !bg-primary shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-gray-border disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                                                Reset Password
                                            </button>
                                        </div>
                                        <div className="invalid-feedback  break-all mt-4 text-red-500 mt-0.5"><span>{error}</span></div>
                                    </>
                                    :
                                    <>
                                        <div className='text center'>
                                            <button onClick={e => { history('/login') }} className='!px-4 w-full text-base font-medium text-white h-11 flex items-center justify-center rounded-xl py-2 gap-2 !bg-primary shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-gray-border disabled:bg-[#D0CAF6] disabled:cursor-not-allowed'>Continue</button>
                                            <p className='mt-4 text-center text-secondary text-base font-medium'>
                                            </p>
                                        </div>
                                    </>
                                }
                            </Form>
                        </Formik>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
};

export default Resetpassword;
