const initialState = {
    loggedIn: false
};

const User = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return {
                ...state, ...{ loggedIn: true }, ...action.payload,
            };
            break;
        case "LOGOUT_SUCCESS":
            return initialState;
            break;
        default:
            return state;
    }
};

export default User;
