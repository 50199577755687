import { Link } from "react-router-dom";
import methodModel from '../../../methods/methods';
import { Menu, Transition, Dialog } from '@headlessui/react'
import { FaPhoneAlt } from "react-icons/fa";
import MegaMenuDropdown from "./MegaMenuDropdown";
import { Fragment, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { SlCalender } from "react-icons/sl";
import { GoGift } from "react-icons/go";
import { GoMail } from "react-icons/go";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoLogOutOutline, IoSettingsOutline } from "react-icons/io5";
import CryptoJS from 'crypto-js';
import environment from "../../../environment";
import { LuPhone } from "react-icons/lu";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Html = ({ user, Logout, siteSettings, isOpen, setIsOpen, eyes, setEyes, FormSchema, handleSubmit }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const token = localStorage.getItem('token')
  const API_KEY = environment.AU_SPACESHIPS_API_KEY
  const SHARED_SECRET = environment.AU_SPACESHIPS_SHARED_SECRET
  const API_URL = `https://apis.rentalcarmanager.com/agent/booking/v3.2?apikey=${API_KEY}`;

  const [data, setData] = useState(null);
  // console.log(data, '==Data is here');

  // // Just to check the RCM live api's working or not
  // useEffect(() => {
  //   const requestBody = JSON.stringify({ "method": "step1" })
  //   const signature = CryptoJS.HmacSHA256(requestBody, SHARED_SECRET).toString(CryptoJS.enc.Hex)

  //   let formData = new FormData()
  //   formData.append('method', 'step1')

  //   const fetchData = async () => {
  //     const response = await fetch(API_URL, {
  //       method: 'POST',
  //       mode: "cors",
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'signature': signature,
  //       },
  //       body: requestBody
  //     })
  //     const data = await response.json()
  //     setData(data)
  //   }

  //   fetchData()
  // }, []);

  return (
    <header className='desktop-header  bg-[#F6F7FB] py-3  lg:py-5 flex items-center'>
      <nav className="max-w-[1504px] w-full mx-auto px-4 lg:px-8">
        <div className="flex gap-x-3 xl:gap-x-3 2xl:gap-x-4 justify-between items-center mx-auto">
          <Link to="/" className="flex items-center">
            <img src={siteSettings?.logo ? `${methodModel.campervanImage(siteSettings?.logo)}` : "/assets/img/logo.png"} className="mr-3 h-8 lg:h-10 xl:h-12 2xl:h-[50px]" alt={siteSettings?.logo_name_alt || "Campervan Logo"} />
          </Link>
          
          <div className='flex items-center max-lg:flex-row-reverse'>
            <div className="flex items-center lg:order-2 gap-x-6">
              {token ?
                <>
                  <Menu as="div" className="relative ml-auto">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md py-1 text-sm font-semibold text-gray-900">
                        <div className="flex items-center">
                          <div className="flex items-center">
                            <img src={methodModel.userImg(user.image)} alt="user" className="min-h-10 min-w-10 max-w-12 max-h-12 rounded-full object-cover" />
                          </div>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute -right-16 sm:right-0 z-20 mt-2 w-[280px] origin-top-right bg-white rounded-2xl sm:w-[398px] sm:h-[384px]">
                        <div className="py-1 px-3 sm:px-6 bg-white rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <div className="flex gap-3 items-center border-b border-gray-border pb-[27px] pt-2">
                                <img src={methodModel.userImg(user.image)} alt="user" className={classNames(active ? 'bg-[#F6F7FB] text-primary roun' : 'text-gray-light', 'h-12 w-12 rounded-full object-cover block text-sm')} />
                                <p className="text-[2B2B2B] text-xl sm:text-2xl font-semibold"> {user?.fullName || user?.firstName}
                                  <p className="text-sm sm:text-base text-gray-light"> {user?.email || '--'}</p>
                                </p>
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/profile" className={classNames(active ? 'bg-[#F6F7FB] text-primary  rounded-xl' : 'text-gray-light', 'block py-4 text-lg')}>
                                <HiOutlineUserCircle className="size-6 mr-2 inline" /> Profile
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/bookings" className={classNames(active ? 'bg-[#F6F7FB] text-primary  rounded-xl' : 'text-gray-light', 'block py-4 text-lg')}>
                                <SlCalender className="size-6 mr-2 inline " />My Bookings
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/rewards" className={classNames(active ? 'bg-[#F6F7FB] text-primary  rounded-xl' : 'text-gray-light', 'block py-4 text-lg')}>
                                <GoGift className="size-6 mr-2 inline " /> My Rewards
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/my-message" className={classNames(active ? 'bg-[#F6F7FB] text-primary  rounded-xl' : 'text-gray-light', 'block py-4 text-lg')}>
                                <GoMail className="size-6 mr-2 inline " /> My Message
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/my-wishlist" className={classNames(active ? 'bg-[#F6F7FB] !text-primary  rounded-xl' : 'text-gray-light', 'block py-4 text-lg')}>
                                <IoMdHeartEmpty className="size-6 mr-2 inline " /> My Wishlist
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/profile/change-password" className={classNames(active ? 'bg-[#F6F7FB] text-primary  rounded-xl' : 'text-gray-light', 'block py-4 text-lg')}>
                                <IoSettingsOutline className="size-6 mr-2 inline" /> Change Password
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item className="divide-y-1 divide-gray-800 pt-1 mt-2">
                            <p className="border-t border-gray-border"></p>
                          </Menu.Item>
                          <Menu.Item className="">
                            {({ active }) => (
                              <a type="submit" onClick={() => Logout()} className={classNames(active ? 'bg-[#F6F7FB] text-primary  rounded-xl' : 'text-gray-light', 'cursor-pointer block w-full py-4 text-left text-lg ancortag')}>
                                <IoLogOutOutline className="size-6 mr-2 inline" />  Logout
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </>
                : null}
              <button onClick={toggleMenu} type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-expanded={isMenuOpen} >
                <span className="sr-only">Open main menu</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </button>
            </div>
            <div className={`${isMenuOpen ? 'block' : 'hidden'} justify-between items-center w-full md:flex md:w-auto md:order-1`} >
              <div class="pr-3 xl:px-3 2xl:px-4">
                <ul className="max-md:shadow-xl max-md:p-3 rounded-2xl w-full max-md:absolute left-0 top-[64px] z-20 max-md:bg-white flex flex-col  font-medium md:flex-row gap-3 md:gap-4 lg:gap-6 xl:gap-7 2xl:gap-8 md:mt-0 transition-all duration-500 items-center">
                <li>
                    <MegaMenuDropdown />
                  </li>
                  <li>
                    <Link to={`/itineraries`} className="block py-2 hover:text-primary hover:underline underline-offset-8 text-sm xl:text-base text-gray-light font-normal">Itineraries</Link>
                  </li>
                  <li>
                    <Link to={`/about-us`} className="block py-2 hover:text-primary hover:underline underline-offset-8 text-sm xl:text-base text-gray-light font-normal">About</Link>
                  </li>
                  <li>
                    <Link to={`/blog`} className="block py-2 hover:text-primary hover:underline underline-offset-8 text-sm xl:text-base text-gray-light font-normal">Blog</Link>
                  </li>
                  <li className="">
                    <Link to={`tel:${siteSettings?.contactNumber}`} className=" flex items-center py-2 text-sm xl:text-base lg:gap-x-2 xl:gap-x-3 2xl:gap-x-4 text-primary"><LuPhone /> +{siteSettings?.contactNumber}</Link>
                  </li>
                  {!token && (
                    <li className="max-md:my-2 border border-gray-border rounded-xl  hover:bg-primary hover:border-primary group">
                      <Link to="/login" className=" group-hover:text-white block h-10 xl:h-12 2xl:h-[54px] w-[70px] md:w-[80px] xl:w-[100px] 2xl:w-[120px] flex justify-center items-center hover:text-text-primary text-sm  xl:text-base 2xl:text-lg text-gray-light font-medium">Sign in</Link>
                    </li>
                  )}
                  <li className="max-md:my-2">
                    <Link to="/" className="bg-primary hover:opacity-80 rounded-xl flex items-center justify-center h-10 xl:h-12 2xl:h-[54px] w-[110px] xl:w-[140px] 2xl:w-[163px] text-sm xl:text-base 2xl:text-lg text-[#F6F7FB]">List Your RV</Link>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-wrap ">
            <ul className="max-md:shadow-xl max-md:p-3 rounded-2xl w-full max-md:absolute left-0 top-[64px] z-20 max-md:bg-white flex flex-col items-center  font-medium md:flex-row md:gap-6 lg:gap-8 xl:gap-10 2xl:gap-12 md:mt-0 transition-all duration-500">
            <li>
                    <Link to={`tel:${siteSettings?.contactNumber}`} className="flex items-center py-2 md:text-sm xl:text-base gap-x-4 lg:gap-x-2 xl:gap-x-3 2xl:gap-x-4 text-primary md:mr-[24px] lg:mr-[32px] xl:mr-[40px] 2xl:mr-[40px]"><LuPhone /> +{siteSettings?.contactNumber}</Link>
                  </li>
            {!token && (
                    <li className="  border-[1px] border-[#E3E5ED] rounded-xl  hover:bg-primary hover:border-primary group">
                      <Link to="/login" className=" group-hover:text-white block py-4 px-8 hover:text-text-primary  md:text-sm xl:text-base text-gray-light font-medium">Sign in</Link>
                    </li>
                  )}
                  
            </ul>
          </div> */}
        </div>
      </nav>
      <Transition show={isOpen} as={Fragment}>
        <div onClick={e => setIsOpen(true)}>
          <Dialog as="div" className="relative z-10" onClose={e => console.log(e)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white lg:p-6 p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      <div className='main-title mb-8'>
                        <h3 className='text-2xl font-semibold text-[#111827] mb-6'> Create Password</h3>
                      </div>
                    </Dialog.Title>
                    <div className='wrapper_section'>
                      <div className='input_form px-2 shadow-box overflow-hidden rounded-lg bg-white'>
                        <Formik
                          initialValues={{
                            password: "",
                            confirmpassword: ""
                          }}
                          validationSchema={FormSchema}
                          onSubmit={handleSubmit}
                        >
                          <Form>
                            <div className='items-start'>
                              <div className=''>
                                <div className="flex flex-col items-start gap-2 mb-6">
                                  <label className='w-full lg:w-36'>
                                    Password <span className='text-red-600'>*</span>
                                  </label>
                                  <div className='w-full'>
                                    <div className="relative ">
                                      <Field type={eyes.password ? 'text' : 'password'} onBlur={e => e.preventDefault()} name="password" className="shadow-box text-lg placeholder:text-gray-500 p-4 relative bg-white w-full rounded-lg h-10 focus:ring-offset-[1.2px] ring-1 ring-[#787878] focus:ring-primary outline-none appearance-none" />

                                      <div className="absolute -translate-y-1/2 right-6 top-1/2 bottom-1 cursor-pointer">
                                        {eyes.password ? (
                                          <FaEye
                                            className="absolute text-[#84818A] cursor-pointer"
                                            onClick={() =>
                                              setEyes({ ...eyes, password: !eyes.password })
                                            }
                                          />
                                        ) : (
                                          <FaEyeSlash
                                            className="absolute text-[#84818A] cursor-pointer"
                                            onClick={() =>
                                              setEyes({ ...eyes, password: !eyes.password })
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="invalid-feedback d-block text-red-600"><ErrorMessage name='password' /></div>
                                  </div>
                                </div>
                                <div className="flex flex-col items-start gap-2 mb-6">
                                  <label className='w-full lg:w-36'>
                                    Confirm Password <span className='text-red-600'>*</span>
                                  </label>
                                  <div className='w-full'>
                                    <div className="relative">
                                      <Field type={eyes.confirmPassword ? 'text' : 'password'} name="confirmpassword" className="shadow-box text-lg placeholder:text-gray-500 p-4 relative bg-white w-full rounded-lg h-10 focus:ring-offset-[1.2px] ring-1 ring-[#787878] focus:ring-primary outline-none appearance-none" />
                                      <div className="absolute -translate-y-1/2 right-6 top-1/2 bottom-1 cursor-pointer">
                                        {eyes.confirmPassword ? (
                                          <FaEye
                                            className="absolute text-[#84818A] cursor-pointer"
                                            onClick={() =>
                                              setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })
                                            }
                                          />
                                        ) : (
                                          <FaEyeSlash
                                            className="absolute text-[#84818A] cursor-pointer"
                                            onClick={() =>
                                              setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="invalid-feedback d-block text-red-600"><ErrorMessage name='confirmpassword' /></div>
                                  </div>
                                </div>
                                <div className="flex items-end justify-end">
                                  <button type="button" onClick={e => setIsOpen(false)} className="text-black-800 bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-4 py-2.5 text-center me-2 mb-2 cursor-pointer">
                                    Skip
                                  </button>
                                  <button type="submit" className="text-white bg-primary hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-4 py-2.5 text-center me-2 mb-2 cursor-pointer">
                                    Create
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </Formik>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </div>
      </Transition>
    </header>
  );
}

export default Html