import { FiChevronRightt, FiChevronLeftt } from "./ArrowComponents";

export const campervanImageSlider = {
  dots: true,
  infinite: false,
  arrows: true,
  speed: 500,
  // swipe: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
  nextArrow: <FiChevronRightt
    size={18}
    child="!right-5 !-translate-y-1/2 !w-8 !h-8 hover:opacity-80 !bg-white !text-secondary"
  />
  ,
  prevArrow:
    <FiChevronLeftt
      size={18}
      child="!left-5 !-translate-y-1/2 !w-8 !h-8 hover:opacity-80 !bg-white !text-secondary"
    />
  ,
  appendDots: (dots: React.ReactNode) => (
    <div id="imageSlider">
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
};

export const campervanCardSlider = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  initialSlide: 0,
  swipe: false,
  draggable: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <FiChevronRightt
    size={24}
    child="!right-2 sm:!right-3.5 !top-[-27px] sm:!top-[-36px] md:!top-[-30px] lg:!top-[-52px] !size-8 sm:!w-[52px] sm:!h-[52px]  !rounded-xl !bg-white !text-gray-light cbtn"
  />
  ,
  prevArrow:
    <FiChevronLeftt
      size={24}
      child="!right-[48px] sm:!right-[90px] sm:!right-24 !top-[-27px] sm:!top-[-36px] lg:!top-[-52px] md:!top-[-30px] !size-8 sm:!w-[52px] sm:!h-[52px] !rounded-xl !bg-white !text-gray-light cbtn"
    />
  ,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,  
      }
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      }
    }
  ]
}
export const trendingDestinationsSlider = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  swipe: false,
  draggable: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: <FiChevronRightt
    size={24}
    child="!right-3.5 !top-[-27px] sm:!top-[-37px] lg:!top-[-50px] !size-8 sm:!w-[52px] sm:!h-[52px] !rounded-xl !bg-white !text-gray-light cbtn"
  />
  ,
  prevArrow:
    <FiChevronLeftt
      size={24}
      child="!right-[58px] sm:!right-[90px] !top-[-27px] sm:!top-[-37px] lg:!top-[-50px] !size-8 sm:!w-[52px] sm:!h-[52px] !rounded-xl !bg-white !text-gray-light cbtn"
    />
  ,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}