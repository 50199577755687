import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ApiClient from "../../methods/api/apiClient";
import InputSelect from "../../components/common/InputSelect";

interface DescriptionRuleProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}

const DescriptionRule: React.FC<DescriptionRuleProps> = ({ settab }) => {
  const [form, setForm] = useState({ email: "" });
  const [emailSent, setemailSent] = useState(false);

  const FormSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = (value: any) => {
    let payload: any = {
      email: value.email,
    };
    ApiClient.post("", payload).then((res) => {
      if (res.success) {
        setForm(value);
        setemailSent(true);
      } else {
        setemailSent(false);
      }
    });
  };

  const license = [
    { name: "License 1" },
    { name: "License 2" },
    { name: "License 3" },
  ];
  const [selectedLicense, setSelectedLicense] = useState(license[0]);

  return (
    <div className={`max-w-[1504px] w-full mx-auto px-4 sm:px-8 mt-8 sm:mt-12`}>
      <Formik
        initialValues={form}
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
      >
        <Form className="">
          <div className="w-full mb-3 max-w-[707px]">
            <label className="text-sm/5 text-secondary font-medium block mb-1 sm:mb-2">
              Listing Name
            </label>
            <Field
              type="text"
              name="text"
              placeholder="Listing name..."
              className="focus:ring-offset-[1.2px] placeholder:text-gray-light text-sm/5 font-normal ring-1 ring-gray-border focus:ring-primary outline-none  !border-0  rounded-xl w-full h-11 px-4 py-3 block mb-6  appearance-none"
            />
            <label className="text-sm/5 text-secondary font-medium block mb-1 sm:mb-2">
              Vehicle Description
            </label>
            <textarea
              name=""
              id=""
              placeholder="Write a compelling description about your RV here!"
              className="resize-none placeholder:text-gray-light text-sm/5 font-normal  text-secondary w-full h-[96px] focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl  !border-0  px-4 py-3  block appearance-none"
            ></textarea>
          </div>
          <h6 className="text-sm font-medium text-secondary mb-2">Indie tip</h6>
          <p className="text-sm font-normal text-gray-light max-w-[881px] mb-8">
            Use this space to write a few sentences about your vehicle, about what
            the experiences of traveling in it is like anad how it can be the best
            booking option for the traveler. if you ned any support, don’t hesitate
            and contact us.
          </p>
          <h3 className="text-lg sm:text-xl 2xl:text-2xl/8 font-semibold text-secondary mb-6">Listing Rules</h3>
          <div className="grid sm:grid-cols-2 gap-6">
            <div className="">
              <label
                htmlFor=""
                className="text-sm font-medium text-secondary block mb-2">
                Minimum age of driver
              </label>
              <div className="flex">
                <Field
                  type="text"
                  name="text"
                  placeholder="23"
                  className="resize-none focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0  rounded-l-xl  w-full h-11 px-4 py-3 block appearance-none"
                />
                {/* <span className="px-6 py-[11px] rounded-r-xl bg-[#E6E6E6] text-sm font-normal text-secondary -my-[.9px]">
                  Years
                </span> */}
              </div>
            </div>
            <div className="">
              <label
                htmlFor=""
                className="text-sm font-medium text-secondary block mb-2"
              >
                Required driving licence
              </label>
              <InputSelect
                classes={"w-full cursor-pointer focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none rounded-xl !border-0 appearance-none "}
                labelBg={"bg-white"}
                options={license}
                value={selectedLicense}
                onChange={setSelectedLicense}
              />
            </div>
          </div>
        </Form>
      </Formik>
      <div className="flex flex-col sm:flex-row items-center gap-3 mt-8 sm:gap-5 mb-12">
        <button
          type="button"
          onClick={() => settab(6)}
          className="block text-sm lg:text-base 2xl:text-lg/5 font-medium h-10 xl:h-12 2xl:h-[54px] w-full sm:max-w-[199px] px-5 xl:px-[30px] py-2 lg:py-[9px] text-white bg-primary rounded-xl hover:opacity-80"
        >
          Save & continue
        </button>
        <Link
          to=""
          onClick={() => settab(6)}
          className="text-sm lg:text-base 2xl:text-lg/5 font-medium text-gray-light flex items-center justify-center h-10 xl:h-12 2xl:h-[54px] w-full sm:max-w-[152px] rounded-xl border border-gray-border hover:bg-primary hover:text-[#F6F7FB] transition-all"
        >
          Skip Setup
        </Link>
      </div>
    </div>
  );
};

export default DescriptionRule;
