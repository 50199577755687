import { useEffect, useState, Fragment } from "react";
import Layout from "../../components/global/layout";
import { useNavigate } from "react-router-dom";
import { FiEdit2, FiPlus } from "react-icons/fi";
import { PiEyeLight } from "react-icons/pi";
import { MdOutlineDelete } from "react-icons/md";
import { Tooltip } from "antd";
import Pagination from "react-js-pagination";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import { GoArrowSwitch } from "react-icons/go";
import { AiOutlineDelete } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import loader from "../../methods/loader";
import Select from 'react-select';

const Customer = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.User);
  const [filter, setfilter] = useState({ page: 1, count: 10, status: '' });
  const [total, settotal] = useState(0);
  const [data, setdata] = useState([]);
  const [action, setaction] = useState({ type: "status", id: "", status: "" });
  let [isOpen, setIsOpen] = useState(false);

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'deactive', label: 'Inactive' },
  ]

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = (p = {}) => {
    let filters = { status: filter?.status?.value, ...p, role: "customer", count: filter?.count };
    if (user?.role == 'sub_agent') {
      filters = { ...filters, sub_agent_id: user?._id || user?.id, agent_id: user?.agent_id }
    } else {
      filters = { ...filters, agent_id: user?._id || user?.id }
    }
    loader(true)
    ApiClient.get(`users/list`, filters).then((res) => {
      if (res.success) {
        setdata(
          res?.data?.map((item) => {
            return {
              firstName: item?.firstName || "--",
              lastName: item?.lastName || "--",
              email: item?.email,
              commission: item?.commission,
              status: item.status,
              action: item?.id || item?._id,
            };
          })
        );
        settotal(res?.data?.total);
      }
      loader(false)
    });
  };

  const handlePagination = (e) => {
    setfilter({ ...filter, page: e });
    getData({ page: e });
  };

  const handleActions = (type, item) => {
    if (type == "edit") {
      navigate(`/customer/edit/${item}`);
    } else if (type == "view") {
      navigate(`/customerdetail/${item}`);
    } else if (type == "status") {
      setIsOpen(true);
      setaction({ type: "status", id: item?.action, status: item?.status });
    } else {
      setIsOpen(true);
      setaction({ type: "delete", id: item });
    }
  };

  const onActionDialogOk = () => {
    if (action?.type == "status") {
      ApiClient.put(
        `change/status?model=users&status=${action?.status == "active" ? "deactive" : "active"
        }&id=${action?.id || action?._id}`,
        {}
      ).then((res) => {
        if (res.success) {
          setIsOpen(false);
          getData();
        } else {
          toast.error(res?.error?.message);
        }
      });
    } else {
      ApiClient.delete(
        `admin/delete-user?id=${action?.id || action?._id}`
      ).then((res) => {
        if (res.success) {
          setIsOpen(false);
          getData();
        }
      });
    }
  };

  const handleFilters = (e, key) => {
    if (e?.value) {
      setfilter(prev => ({ ...prev, [key]: e }))
      getData({ [key]: e?.value })
    } else {
      setfilter(prev => ({ ...prev, [key]: '' }))
      getData({ [key]: '' })
    }
  }

  return (
    <>
      <Layout>
        <div className="flex justify-between align-center">
          <h2 className="text-2xl font-bold">Customers</h2>
          <div className="flex">
            <Select className="rounded-lg" options={statusOptions} value={filter?.status} onChange={e => handleFilters(e, 'status')} isClearable placeholder='Select Status' />
            {!(user?.role == "customer") ? <button
              onClick={(e) => {
                navigate("/customer/add");
              }}
              className="bg-[#2849B2] rounded-lg p-2 flex items-center gap-1 text-white ml-4"
            >
              <FiPlus />Add Customer
            </button> : null}
          </div>
        </div>
        <div className="mt-4">
          <div className="scrollbar !overflow-x-auto border border-[#B3B3B3] rounded-2xl px-4">
            <table className=" w-full text-left">
              <thead>
                <tr>
                  <th className="">First Name</th>
                  <th className="">Last Name</th>
                  <th className="">Email</th>
                  <th className="">Status</th>
                  <th className="">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data?.length == 0 ?
                  <tr>
                    <td className="text-center" colSpan={5}>No Records Found</td>
                  </tr>
                  :
                  <>
                    {data && data.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td className="text-base font-normal text-[#787878] capitalize">
                            <span className="fs-5 capitalize">{item?.firstName || "--"}</span>
                          </td>
                          <td className="text-base font-normal text-[#787878] capitalize">{item?.lastName || "--"}</td>
                          <td className="text-base font-normal text-[#787878] ">{item?.email}</td>
                          <td className="text-base font-normal text-[#787878] capitalize">
                            <span
                              className={!(user?.role === "customer") ? `cursor-pointer inline-flex items-center px-4 py-1 text-sm  text-cente ${item.status == "active"
                                ? "text-white rounded-full bg-green-500"
                                : "text-white rounded-full bg-red-500"
                                } flex items-center` : `${item.status == "active"
                                  ? "text-green-500"
                                  : "text-red-500"} flex items-center`}
                              onClick={!(user?.role === "customer") ? () => handleActions("status", item) : null}
                            >
                              <span
                                className={`${item.status == "active"
                                  ? "tag-affix tag-prefix bg-green-500 flex"
                                  : "tag-affix tag-prefix bg-red-500 flex"
                                  }`}
                              ></span>{" "}
                              {item.status == "active" ? "Active" : "Inactive"}
                            </span>
                          </td>
                          <td className="text-base font-normal text-[#787878]">
                            <div className="flex text-gray-500 items-center">
                              {!(user?.role == "customer") ? <Tooltip title="Edit" className="cursor-pointer">
                                <div className="border border-gray-200 p-2 mr-3 shadow hover:shadow-lg">
                                  <FiEdit2
                                    className=""
                                    onClick={(e) => handleActions("edit", item?.action)}
                                  />
                                </div>
                              </Tooltip> : null}
                              <Tooltip title="View" className="cursor-pointer">
                                <div className="border border-gray-200 p-2 mr-3 shadow hover:shadow-lg">
                                  <PiEyeLight
                                    className=""
                                    onClick={(e) => handleActions("view", item?.action)}
                                  />
                                </div>
                              </Tooltip>
                              {!(user?.role == "customer") ? <Tooltip title="Delete" className="cursor-pointer">
                                <div className="border border-gray-200 p-2 mr-3 shadow hover:shadow-lg">
                                  <MdOutlineDelete
                                    className=""
                                    onClick={(e) =>
                                      handleActions("delete", item?.action)
                                    }
                                  />
                                </div>
                              </Tooltip> : null}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                }
              </tbody>
            </table>
          </div>
          {filter?.count < total ? (
            <div className="flex justify-center mt-4">
              <Pagination
                activePage={filter?.page}
                itemsCountPerPage={filter?.count}
                totalItemsCount={total}
                pageRangeDisplayed={1}
                onChange={handlePagination}
                hideFirstLastPages={true}
              />
            </div>
          ) : null}
        </div>

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <img src="/assets/img/img-2.png" className="mx-auto" />
                    {action?.type == "status" ? (
                      <div className="mt-2">
                        <p className="my-6 text-center font-semibold">
                          Do you really want to change <br></br> the status of
                          this customer?
                        </p>
                      </div>
                    ) : (
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 text-center">
                          Do you really want to delete this customer?
                        </p>
                      </div>
                    )}
                    <div className="text-center mt-6">
                      <button
                        variant={action?.type == "status" ? "solid" : "warning"}
                        onClick={(e) => onActionDialogOk()}
                      >
                        {action?.type == "status" ? (
                          <div className="">
                            <span className="flex items-center gap-2 button bg-blue-800 hover:bg-blue-700 active:bg-blue-900 text-white rounded-md h-11 px-8 py-2">
                              {" "}
                              <GoArrowSwitch /> Change
                            </span>
                          </div>
                        ) : (
                          <div className="">
                            <span className="flex items-center gap-2 button bg-red-600 md:px-5 lg:px-8 border hover:bg-red-400 transition duration-150 ease-out hover:ease-in text-white  border-red-300  rounded-lg h-11 px-8 py-2">
                              <AiOutlineDelete /> Delete
                            </span>
                          </div>
                        )}
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </Layout>
    </>
  );
};

export default Customer;
