import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../header';
import Footer from '../footer';


const PageLayout = ({ children }) => {
  const urlPath = window.location.pathname
  return (
    <>
      <div className='' component="page-layout">
        {urlPath != "/campervanlist" ?
          <Header />
          : null}
        <div className='mobile-header'>
          <div className=''></div>
        </div>
        <main className='pageContent'>
          {children}
        </main>
        {urlPath != "/campervanlist" ?
          <Footer />
          : null}
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};
export default PageLayout;