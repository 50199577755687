import React from "react";

const Invoice: React.FC = () => {
  return (
	<div className="max-lg:p-3">
    <div className="w-full max-w-[1072px]  mx-auto bg-white p-6 border border-gray-border rounded-2xl mt-4 lg:mt-8 mb-6 lg:mb-12">
      <div className="flex flex-wrap justify-between  mb-3 lg:mb-6 ">
        <p className="text-secondary text-lg lg:text-2xl font-semibold">
          {" "}
          Invoice
        </p>
        <button className="flex justify-center items-center gap-2 w-[119px] h-10 border border-primary rounded-xl text-primary">
          {" "}
          <img src="/assets/img/front/downloadicon.svg" alt="CalenderIcon" width="16" height="16"/>
          Download
          
        </button>
      </div>
      <div className="border border-gray-border rounded-2xl ">
        <div className="border-b border-gray-border flex flex-wrap max-md:gap-3 justify-between pb-5 px-3 lg:px-6 pt-3 lg:pt-6">
          <div className="w-[130px] h-10">
            <img
              src="https://api.rvrentercenter.com/images/users/537f37f6-ae82-485f-a996-f52eaba7d26e1714470089622.png"
              alt="rvcenter-invoice"
            />
          </div>
          <div>
            <h1 className="text-base font-medium text-gray-light text-left">
              Invoice Number : <span className="text-secondary"> 301 </span>{" "}
            </h1>
            <h1 className="text-base font-medium text-gray-light text-left">
              Date : <span className="text-secondary"> 02/02/2024 </span>
            </h1>
          </div>
        </div>

        <div className="mt-3 lg:mt-6 mb-5 sm:flex justify-between border-b border-gray-border pb-3 lg:pb-6 px-3 lg:px-6">
          <div className="space-y-2">
            <h1 className="text-lg font-normal text-secondary">Bill From :</h1>
            <h1 className="text-base font-normal text-gray-light">
              RV Renter Center
            </h1>
            <h1 className="text-base font-normal text-gray-light">
              108 Wickham St,Brisbane, QLD 4001
            </h1>
            <h1 className="text-base font-normal text-gray-light">
              Phone Number : +18152558815
            </h1>
          </div>
          <div className="space-y-1 ">
            <h1 className="text-lg font-normal text-secondary">Bill To :</h1>
            <h1 className="text-base font-normal text-gray-light">
              Md. Shahid Hasan Sifat
            </h1>
            <h1 className="text-base font-normal text-gray-light">
              Dhaka, Bangladesh
            </h1>
            <h1 className="text-base font-normal text-gray-light">
              Phone Number : +10153358815
            </h1>
          </div>
        </div>

        <div className="px-3 lg:px-6">
          <h1 className="text-xl font-semibold text-secondary">
            Booking Details :
          </h1>
          <div className="flex flex-wrap justify-between mt-5">
            <div>
              <h1 className="text-lg font-normal text-secondary">Pick Up</h1>
              <h1 className="text-base font-normal text-gray-light mt-3">
                Alice Spring - 20 Mar 2024
              </h1>
              <h1 className="text-base font-normal text-gray-light mt-2">
                29 Stokes St, Alice Springs NT, 0870
              </h1>
            </div>
            <div className=" max-w-[288px]  w-full">
              <h1 className="text-lg font-normal text-secondary">Drop Off</h1>
              <h1 className="text-base font-normal text-gray-light mt-3">
                Alice Spring - 26 Mar 2024
              </h1>
              <h1 className="text-base font-normal text-gray-light mt-2">
                29 Stokes St, Alice Springs NT, 0870
              </h1>
            </div>
          </div>

          <div className="flex flex-wrap justify-between mt-3 lg:mt-6 ">
            <div>
              <h1 className="text-lg font-normal text-secondary ">Pick Time</h1>
              <h1 className="text-base font-normal text-gray-light mt-3">
                Sun, 03 Mar 2024
              </h1>
            </div>
            <div className=" max-w-[288px]  w-full">
              <h1 className="text-lg font-normal text-secondary  ">
                Drop Off Time
              </h1>
              <h1 className="text-base font-normal text-gray-light mt-3">
                Sun, 03 Mar 2024
              </h1>
            </div>
          </div>
        </div>

        <div className="mt-6 overflow-x-auto">
          <table className="w-full ">
            <thead className="bg-[#F6F7FB]">
              <tr className="">
                <th className="text-left text-lg font-normal text-secondary px-6 py-4 w-full max-w-[70%]">
                  Description
                </th>
                <th className="text-right text-lg font-normal text-secondary px-6 py-4 min-w-[30%] ">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-fit">
                <td className="text-xl font-medium text-secondary text-left px-6 py-4 ">
                  Rental 6x Days
                </td>
                <td className="text-xl font-medium text-secondary text-right px-6 py-4  ">
                  $800.00
                </td>
              </tr>
              <tr className="h-fit ">
                <td className="text-xl font-medium text-secondary px-6 py-4 ">
                  Insurance
                  <ul className="text-base flex flex-col font-normal text-gray-light gap-5 mt-4">
                    <li>24/7 Customer Support</li>
                    <li>Minimum Liability Limits</li>
                    <li>Physical Damage (300K)</li>
                    <li>Roadside Assistance</li>
                    <li>Windshield Coverage</li>
                  </ul>
                </td>
                <td className="text-xl font-medium text-secondary text-right px-6 py-4 ">
                  $450.00
                </td>
              </tr>
              <tr className="h-fit">
                <td className="text-xl font-medium text-secondary px-6 py-4 ">
                  Add Ons
                  <ul className="text-base flex flex-col font-normal text-gray-light gap-5 mt-4">
                    <li>Camping Chair</li>
                    <li>Child Seat</li>
                    <li>Extra Bed</li>
                  </ul>
                </td>
                <td className="text-xl font-medium text-secondary text-right px-6 py-4">
                  $150.00
                </td>
              </tr>
              <tr className="h-fit ">
                <td className="text-xl font-medium text-secondary px-6 py-4">
                  Taxes and fees
                  <ul className="text-base flex flex-col font-normal text-gray-light gap-5 mt-4">
                    <li>RV Renter Center Fee</li>
                    <li>Cleaning Fee</li>
                    <li>Tax</li>
                  </ul>
                </td>
                <td className="text-xl font-medium text-secondary text-right px-6 py-4">
                  $150.00
                </td>
              </tr>
              <tr className="h-fit ">
                <td className="text-xl font-medium text-secondary px-6 pt-4">
                  Total Cost
                </td>
                <td className="text-xl font-medium text-secondary text-right px-6 py-4">
                  $150.00
                </td>
              </tr>
              <tr className="h-fit border-b-0 mb-0.5">
                <td className="text-xl font-medium text-secondary px-6 py-6">
                  Security Deposit (refundable)
                </td>
                <td className="text-xl font-medium text-secondary text-right px-6 py-4">
                  $150.00
                </td>
              </tr>
              <tr className="h-fit pt-8">
                <td className="text-xl font-medium text-secondary px-6 pb-8">
                  Bond Details
                </td>
                <td className="text-xl font-medium text-secondary text-right px-6 py-4">
                  $150.00
                </td>
              </tr>
              <tr className="border-t border-gray-border">
                <td>
                  <h6 className="text-base lg:text-xl xl:text-2xl font-semibold text-secondary whitespace-nowrap pt-8 pb-6 px-6 ">
                    Grand Total
                  </h6>
                </td>
                <td>
                  {" "}
                  <h6 className="text-lg lg:text-[32px] font-semibold text-secondary text-right whitespace-nowrap pt-8 pb-6 px-6">
                    $2 ,750.00
                  </h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
	</div>
  );
};

export default Invoice;
