import React, { useState } from "react";
import { Link } from "react-router-dom";

interface PhotoUploadProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}

interface ImageCardProps {
  idx: number;
  selectedImage: File | null;
  handleImageChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => void;
}

const cardText = [
  { label: "Font View" },
  { label: "Dining area" },
  { label: "Bedroom/Toilet" },
  { label: "Side View" },
  { label: "Rear View" },
  { label: "Berth" },
];

const PhotoUpload: React.FC<PhotoUploadProps> = ({ settab }) => {
  const [selectedImages, setSelectedImages] = useState<(File | null)[]>(
    Array(6).fill(null)
  );

  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const updatedImages = [...selectedImages];
      updatedImages[idx] = file;
      setSelectedImages(updatedImages);
    }
  };

  const ImageCard: React.FC<ImageCardProps> = ({
    idx,
    selectedImage,
    handleImageChange,
  }) => {
    return (
      <label
        htmlFor={`image-input-${idx}`}
        className="flex justify-center items-center cursor-pointer border bg-[#F1F0EF] rounded-2xl h-[331px] relative"
      >
        {selectedImage === null ? (
          <label
            htmlFor={`image-input-${idx}`}
            className="rounded-xl bg-white cursor-pointer py-2 px-4 text-sm lg:text-base font-normal text-secondary hover:bg-primary hover:text-white"
          >
            Add Images
          </label>
        ) : (
          <div className="h-full w-full">
            <img
              className="h-full w-full rounded-xl"
              src={URL.createObjectURL(selectedImage)}
              alt="Selected"
            />
          </div>
        )}
        <input
          id={`image-input-${idx}`}
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, idx)}
          style={{ display: "none" }}
        />
      </label>
    );
  };
  return (
    <div className={`max-w-[1504px] w-full mx-auto px-4 sm:px-8 mt-8 sm:mt-12`}>
      <div className="max-w-[1440px] w-full">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
          {cardText.map((itm, idx) => (
            <div key={idx} className="relative">
              <div className="absolute top-3 sm:top-5 left-3 sm:left-5 z-[999] w-[110px] sm:w-[138px] h-10 sm:h-12 xl:h-[54px] flex items-center justify-center rounded-xl bg-[#F6F7FB66] bg-opacity-40 border border-secondary/20 ">
                <p className="text-sm sm:text-base font-medium text-secondary">
                  {itm.label}
                </p>
              </div>
              <ImageCard
                key={idx}
                idx={idx}
                selectedImage={selectedImages[idx]}
                handleImageChange={handleImageChange}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col sm:flex-row items-center gap-3 mt-8 sm:gap-5 mb-12">
        <button
          type="button"
          onClick={() => settab(4)}
          className="block text-sm lg:text-base 2xl:text-lg/5 font-medium h-10 xl:h-12 2xl:h-[54px] w-full sm:max-w-[199px] px-5 xl:px-[30px] py-2 lg:py-[9px] text-white bg-primary rounded-xl hover:opacity-80"
        >
          Save & continue
        </button>
        <Link
          to=""
          onClick={() => settab(4)}
          className="text-sm lg:text-base 2xl:text-lg/5 font-medium text-gray-light flex items-center justify-center h-10 xl:h-12 2xl:h-[54px] w-full sm:max-w-[152px] rounded-xl border border-gray-border hover:bg-primary hover:text-[#F6F7FB] transition-all"
        >
          Skip Setup
        </Link>
      </div>
    </div>
  );
};

export default PhotoUpload;
