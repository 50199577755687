import { ToastContainer } from "react-toastify"
import Header from "../global/header"

const AuthLayout = ({ children }: any) => {
  return <>
    <Header />
    <div className='w-full max-w-[1504px] px-4 lg:px-8 mx-auto py-8 h-full'>
      <div className='flex justify-center items-center w-full min-h-[calc(100vh_-_164px)]'>
            {children}
      </div>
    </div>
    <ToastContainer position="top-right" />
  </>
}

export default AuthLayout