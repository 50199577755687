// import { GoCheckCircleFill } from "react-icons/go"
import { useSelector } from "react-redux"
import { FaRegCircle } from "react-icons/fa";

const WhyBookWithUs = ({classes = " "}) => {
    const siteSettings = useSelector((state) => state.Reducer.siteSettings)

    return (
        <>
            <div className={`${classes} border border-primary rounded-2xl p-3 lg:p-6 lg:pb-7 xl:mt-5`}>
                <h4 className="text-base xl:text-lg font-semibold text-secondary mb-4">Why book with us?</h4>
                <ul className="space-y-3 lg:space-y-5">
                    {siteSettings?.whyBookWithUs?.map((itm, idx) => (
                        <li className="flex gap-2 items-center" key={idx}>
                            <FaRegCircle className="min-w-3 min-h-3 text-primary " />
                            <span className="text-base font-normal text-gray-light">{itm}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default WhyBookWithUs