import React, { useState } from "react"
import Slider from "react-slick";

import { campervanImageSlider } from "../../../utils/slick-settings";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SEARCH_STATE } from "../../../Redux/Action/Action";

interface ImageSliderProps {
  images: string[]
  imageCounter?: boolean
  imageHeight?: string
  id: string
  data: any
}

const ImageSliderDirect: React.FC<ImageSliderProps> = ({ images, imageHeight = 'h-[274px]', imageCounter = false, id = "", data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const totalImages = images?.length;
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const search = useSelector((state: any) => state.Reducer.search)

  const sliderSettings = {
    ...campervanImageSlider,
    afterChange: (index: number) => setActiveIndex(index),
  };

  const handleViewPage = (id: string, type: string) => {
    if(data?.vehiclecategoryid){
      dispatch(SEARCH_STATE({ ...search, vehiclecategoryid: data?.vehiclecategoryid }))
    }
    if (id) navigate(`/campervan/detail/${id}/${type}`)
    else return
  }

  return (
    <>
      {imageCounter &&
        <span className="h-9 flex gap-2 items-center rounded-lg px-[18px] py-2 bg-white absolute z-10 top-5 left-5 text-base/5 font-medium text-secondary">
          <img
            alt="ImageIcon"
            src="assets/img/front/image-icon.svg"
            width={20}
            height={20}
            className="shrink-0 shadow-xl"
          />
          {activeIndex + 1}/{totalImages}
        </span>
      }
      <Slider {...sliderSettings}>
        {images?.map((image, idx) => (
          <div className={`${id && "cursor-pointer"}`} key={idx}>
            <img
              alt="campVan1"
              onClick={e => handleViewPage(id, data?.campervan_type)}
              src={image}
              className={`w-full ${imageHeight} object-cover rounded-lg`} />
          </div>
        ))}
      </Slider>
    </>
  )
}

export default ImageSliderDirect