import PageLayout from "../../components/global/PageLayout";
import { Formik, Form, Field, ErrorMessage, resetForm } from "formik";
import { Link } from "react-router-dom";
import { MdPhone } from "react-icons/md";
import { LuPhone } from "react-icons/lu";
import { HiChatAlt } from "react-icons/hi";
import { RiWhatsappFill } from "react-icons/ri";
import { BiLogoMessenger } from "react-icons/bi";
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaInstagram, FaTiktok } from "react-icons/fa";
import { PiWhatsappLogoLight } from "react-icons/pi";
import * as Yup from "yup";
import ApiClient from "../../methods/api/apiClient";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/common/Breadcrumb";

const Contactus = () => {

  const FormSchema=Yup.object({
    fullName: Yup.string()
    .required('Name is required'),
    email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
    message: Yup.string()
    .required('Message is required')
  })

  const handleContactUs=(values, { resetForm })=>{
    ApiClient.post(`contactus`,values).then((res)=>{
      if(res.success){
        resetForm({ values: { fullName: "", email: "", message: "" } });
        window.scrollTo({ top: 0 });
      }else{
        toast.error(res?.error?.message)
      }
    })
  }

  const details = {
    campervan_name: "Contact Us",
  };

  return (
    <PageLayout>
      <div className={`max-w-[1504px] w-full mx-auto px-4 lg:px-8`}>
      <div className="mt-3 lg:mt-6 ">
          <Breadcrumb
            links={[
              { link: "/", name: "Home" },
            ]}
            currentPage={details?.campervan_name}
          />
          </div>
        <div className="border-b border-gray-border pb-3 lg:pb-6">
        <h2 className="text-base/5 md:text-xl lg:text-3xl 2xl:text-4xl font-semibold text-secondary mt-4 lg:mt-8 mb-4">
          Contact Us
        </h2>
        <p className="text-base font-normal text-gray-light w-full max-w-[1008px]">
          RV Renter Center world-class customer service team is here to help.
          No question is too small — or too hard — so if you need any
          assistance whatsoever, please get in touch. Our team can help with
          pre-booking questions along with any queries you might have while
          you’re on the road, or about the ins and outs of returning your
          vehicle at the end of your rental period.
        </p>
        </div>
        <div className="flex lg:justify-between gap-5 flex-wrap lg:flex-nowrap mt-3 lg:mt-6 mb-6 lg:mb-12">
          <Formik
            initialValues={{ fullName: '', email: '', message: '' }}
            validationSchema={FormSchema}
            onSubmit={handleContactUs}
          >
            <Form className="lg:max-w-[706px] w-full">
              <h2 className="text-base lg:text-xl xl:text-2xl font-semibold text-secondary mb-4">
                How can we help?
              </h2>
              <p className="text-base font-normal text-gray-light mb-3 lg:mb-6">
                If you have any questions about the subscription or you are
                not sure which plan is right for you, please contact our team.
                If you have any questions about the subscription or you are
                not sure.
              </p>
              <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 mb-2.5 lg:mb-5">
              <div className="w-full">
                <label className="text-sm font-semibold text-secondary mb-1 sm:mb-2 block">Name</label>
                <Field
                  type="text"
                  name="fullName"
                  placeholder="Your Name"
                  className="text-sm font-normal text-secondary w-full rounded-xl py-2.5 px-4 block !border-0 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary   outline-none appearance-none" />
                <div className="invalid-feedback d-block"><ErrorMessage name='fullName' className="!text-red-500" /></div>
              </div>
              <div className="w-full">
                <label className="text-sm font-semibold text-secondary mb-1 sm:mb-2 block">Email</label>
                <Field
                  type="email"
                  name="email"
                  placeholder="Email address"
                  className="text-sm font-normal text-secondary w-full rounded-xl py-2.5 px-4 block !border-0 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary   outline-none appearance-none" />
                <div className="invalid-feedback d-block"><ErrorMessage name='email' /></div>
              </div>
              </div>
              <div className="mb-2.5 lg:mb-5">
                <label className="text-sm font-semibold text-secondary mb-1 sm:mb-2 block">Your Message</label>
                <Field
                  as="textarea"
                  name="message"
                  placeholder="How we can help?"
                  className="resize-none text-sm font-normal h-[80px] text-secondary w-full  rounded-xl p-4 !border-0  focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary   outline-none appearance-none" />
                <div className="invalid-feedback d-block"><ErrorMessage name='message' /></div>
              </div>
              <button type="submit" className=" w-[123px] h-10 xl:h-12 2xl:h-[54px] shrink-0 py-4 px-10 rounded-xl bg-primary text-lg font-medium text-[#F6F7FB] hover:opacity-80 transition-all flex justify-center items-center">
                Submit
              </button>
            </Form>
          </Formik>
          <div className="lg:max-w-[619px] w-full">
            <h2 className="text-base lg:text-xl xl:text-2xl font-semibold text-secondary mb-4">
              How to get in touch with us
            </h2>
            <p className="text-base font-normal text-gray-light mb-6">
              Get in touch with our maui booking hosts and let us know how we can help.
              Contact us by phone 24/7, start a chat, or fill out the enquiry form below
              and we’ll come back to you
            </p>
            <div className="flex items-center flex-wrap gap-3 md:gap-4 lg:gap-5 xl:gap-6">
              <Link to="#" className="flex items-center gap-2 rounded-xl border border-primary p-2.5 text-lg text-primary font-medium whitespace-nowrap text-center hover:bg-primary hover:text-[#F6F7FB] transition-all">
                <LuPhone className="size-5" />
                Phone
              </Link>
              <Link to="#" className="flex items-center gap-2 rounded-xl border border-primary p-2.5 text-lg text-primary font-medium whitespace-nowrap text-center hover:bg-primary hover:text-[#F6F7FB] transition-all">
                <HiChatAlt className="size-5" />
                Live Chat
              </Link>
              <Link to="#" className="flex items-center gap-2 rounded-xl border border-primary p-2.5 text-lg text-primary font-medium whitespace-nowrap text-center hover:bg-primary hover:text-[#F6F7FB] transition-all">
                <PiWhatsappLogoLight className="size-5" />
                WhatsApp
              </Link>
              <Link to="#" className="flex items-center gap-2 rounded-xl border border-primary p-2.5 text-lg text-primary font-medium whitespace-nowrap text-center hover:bg-primary hover:text-[#F6F7FB] transition-all">
                <BiLogoMessenger className="size-5" />
                FB Messenger
              </Link>

            </div>
            <h4 className="text-xl font-semibold text-secondary mt-8 mb-4">Social Media</h4>
            <div className="flex items-center justify-between max-sm:gap-3 w-full max-w-[432px]">
            <Link to="#" className="h-14 w-14 flex items-center justify-center rounded-full border border-gray-border hover:bg-primary  group hover:border-transparent">
                <FaInstagram className="size-5 text-gray-light group-hover:text-[#F6F7FB]" />
              </Link>
              <Link to="#" className="h-14 w-14 flex items-center justify-center rounded-full border border-gray-border hover:bg-primary group hover:border-transparent">
                <FaFacebookF className="size-5 text-gray-light group-hover:text-[#F6F7FB]" />
              </Link>
              <Link to="#" className="h-14 w-14 flex items-center justify-center rounded-full border border-gray-border hover:bg-primary group hover:border-transparent">
                <FaTwitter className="size-5 text-gray-light group-hover:text-[#F6F7FB]" />
              </Link>
              <Link to="#" className="h-14 w-14 flex items-center justify-center rounded-full border border-gray-border hover:bg-primary group hover:border-transparent">
                <FaLinkedinIn className="size-5 text-gray-light group-hover:text-[#F6F7FB]" />
              </Link>
              
              
              {/* <Link to="#" className="h-10 w-10 flex items-center justify-center rounded-full border border-gray-border hover:bg-primary hover:text-white">
                <FaTiktok className="size-5 text-white rounded-full p-1 bg-[#171717]" />
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Contactus;
