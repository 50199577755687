import moment from "moment";
import { useState } from "react";
import { GoArrowRight } from "react-icons/go";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { SEARCH_STATE } from "../../Redux/Action/Action";
import { useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip"
import { FiEdit } from "react-icons/fi";
import { Tooltip } from "antd";

const Pricing = ({ tab, form, campervanDetails, totalCampervanPrice, findTotalAddOns, selectedInsurance, couponCode, setCouponCode, allCoupons, discountApplied, error }) => {
    const { id } = useParams()
    const [couponErr, setCouponErr] = useState(false)
    const search = useSelector((state) => state.Reducer.search)
    const dispatch = useDispatch()

    const campervanPrice = (item) => {
        let price = Number(campervanDetails?.defaultRate || 0)
        if (item === 'Perday') {
            let days = calculateDays(new Date(form?.pickUpDate?.startDate), new Date(form?.dropOffDate?.startDate))
            price /= days
        }
        return parseFloat(price || 0).toFixed(2)
    }
    // It calculate the days with the help of start and end date and return the total number of days
    const calculateDays = (startDate, endDate) => {
        if (endDate < startDate) {
            return 0
        }
        const diffInMilliseconds = Math.abs(endDate - startDate)
        const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24)
        return Math.ceil(diffInDays)
    }

    const addCouponCode = () => {
        if (!couponCode?.value) {
            dispatch(SEARCH_STATE({ ...search, couponCode: null }))
            setCouponCode({ value: '', allow: false })
        }
        if (allCoupons?.length != 0) {
            allCoupons && allCoupons?.filter((item, index) => {
                if (item?.code == couponCode?.value && item?.campervan_details?.some((itm) => itm?.id == id)) {
                    dispatch(SEARCH_STATE({ ...search, couponCode: { ...item, value: couponCode?.value, allow: false } }))
                    setCouponCode((prev) => ({ ...prev, ...item, allow: false }))
                }
                else setCouponErr('Invalid Discount Code')
            })
        } else setCouponErr('Invalid Discount Code')
    }

    return (
        <>
            <div className=" sticky top-4">
                <div className="border border-primary rounded-2xl py-3 lg:py-6">
                    <h6 className="text-gray-light text-sm   px-3 lg:px-6">
                        <span className="text-secondary text-lg font-semibold">
                            {campervanDetails?.DailyRate?.CurrencyCode || 'AUD'}${campervanDetails?.DailyRate?.Value || campervanPrice('Perday')}/
                        </span>
                        Per Day
                    </h6>
                    <div className="flex items-center gap-5 justify-between mt-3 lg:mt-4 border-b border-gray-border  pb-3 lg:pb-4 px-3 lg:px-6 ">
                        <div className="flex items-center gap-x-2 ">
                            <p className="whitespace-nowrap text-sm  text-gray-light">
                                {form?.pickUpDate?.startDate ? moment(form?.pickUpDate?.startDate).format('MMM DD, YYYY') : '--'}
                            </p>
                            <p>
                                <GoArrowRight size={12} className="text-gray-light" />
                            </p>
                            <p className="whitespace-nowrap text-sm  text-gray-light">
                                {form?.dropOffDate?.startDate ? moment(form?.dropOffDate?.startDate).format('MMM DD, YYYY') : '--'}
                            </p>
                        </div>
                        {/* {tab != 2 ?
                            <a href={'#PickUp&DropOff'}>
                                <div className="flex items-center gap-x-2 group">
                                    <FiEdit className="text-secondary group-hover:text-primary" size={20} />
                                    <p className="text-base/5 text-secondary group-hover:text-primary transition-all">Edit</p>
                                </div>
                            </a>
                            : null} */}
                    </div>
                    <div className="border-b border-gray-border pb-3 lg:pb-4 px-3 lg:px-6">
                        <div className="flex items-center justify-between mt-3">
                            <p className="text-sm text-gray-light">
                                Rental {campervanDetails?.RentalDays || calculateDays(new Date(form?.pickUpDate?.startDate), new Date(form?.dropOffDate?.startDate))}x Days
                            </p>
                            <p className="text-base text-secondary font-medium">
                                ${campervanDetails?.Total?.Value || totalCampervanPrice('campervanOnly')}
                            </p>
                        </div>
                        <div className="flex items-center justify-between mt-3">
                            <p className="text-sm text-gray-light">
                                Taxes and fees
                            </p>
                            <p className="text-base text-secondary font-medium">
                                ${parseFloat(campervanDetails?.serviceFees || 0).toFixed(2)}
                            </p>
                        </div>
                    </div>
                    <div className="border-b border-gray-border flex items-center flex-wrap justify-between pb-3 lg:pb-4 mt-3 px-3 lg:px-6">
                        <div className="flex items-center gap-x-6">
                            <p className="text-sm text-gray-light">
                                Insurance
                            </p>
                            {selectedInsurance?.ExcessType ?
                                <>
                                    {tab != 2 ?
                                        <a href={'#InsuranceCoverage'}>
                                            <div className="flex items-center gap-x-2 group">
                                                {/* <img src="/assets/img/front/edit.svg" alt="edit" width={20} height={20} className="size-5 shrink-0" /> */}
                                                <FiEdit className="text-gray-light group-hover:text-primary" size={20} />
                                                <p className="text-sm text-gray-light group-hover:text-primary transition-all">Edit</p>
                                            </div>
                                        </a>
                                        : null}
                                    {selectedInsurance?.ExcessType ?
                                        <a href={"#InsuranceCoverage"}>
                                            <p className="bg-primary text-white rounded text-sm py-1 px-4" >
                                                {selectedInsurance?.ExcessType}
                                            </p>
                                        </a>
                                        : null}
                                </>
                                :
                                <>
                                    <a href="#InsuranceCoverage">
                                        <div className="text-gray-light text-sm font-normal">Select Insurance</div>
                                    </a>
                                    {/* <div className="text-gray-light text-sm font-normal"> Standard</div> */}
                                </>
                            }
                        </div>
                        <p className="text-base text-secondary font-medium ml-auto">
                            ${selectedInsurance?.Total?.Value || 0}
                        </p>
                    </div>
                    <div className="border-b border-gray-border mt-3 flex items-center justify-between pb-3 lg:pb-4 px-3 lg:px-6">
                        <div className="flex items-center gap-x-6">
                            <p className="text-base text-gray-light">
                                Add Ons
                            </p>
                            {tab != 2 ?
                                <a href={"#ExtraItems"}>
                                    <div className="flex items-center gap-x-2 group">
                                        {/* <img src="/assets/img/front/edit.svg" alt="edit" width={20} height={20} className="size-5 shrink-0" /> */}
                                        <FiEdit className="text-gray-light group-hover:text-primary" size={20} />
                                        <p className="text-base/5 text-gray-light  group-hover:text-primary transition-all">Edit</p>
                                    </div>
                                </a>
                                : null}
                        </div>
                        <p className="text-base text-secondary font-medium">
                            ${findTotalAddOns()}
                        </p>
                    </div>
                    {search?.couponCode ?
                        <div className="flex items-center justify-between flex-wrap mt-4">
                            <div className="flex items-center gap-x-6">
                                <p className="text-base text-primary">Coupon code applied</p>
                                <Link to="#">
                                    <div className="flex items-center gap-x-2" onClick={e => (setCouponCode((prev) => ({ ...prev, allow: true })), setCouponErr(false))}>
                                        <img src="/assets/img/front/edit.svg" alt="edit" width={20} height={20} className="size-5 shrink-0" />
                                        <p className="text-base/5 text-secondary hover:text-primary transition-all">Edit</p>
                                    </div>
                                </Link>
                            </div>
                            <p className="text-base text-primary ml-auto">{discountApplied()}</p>
                        </div>
                        : null}
                        <div className="px-3 lg:px-6">   
                    <div className="text_lines py-4 lg:py-5">
                        {search?.couponCode ? null :
                            <button type="button" onClick={() => (setCouponCode((prev) => ({ ...prev, allow: !couponCode?.allow })), setCouponErr(false))} className="text-primary text-base font-medium hover:underline underline-offset-2 transition-all">coupon code applied</button>
                        }
                        {couponCode?.allow &&
                            <div className="mt-3">
                                <h6 className="text-lg font-medium text-secondary mb-2">Discount Code</h6>
                                <p className="text-sm text-[#808080]">Enter a discount code and save more!</p>
                                <div className="mt-4 flex gap-4">
                                    <input type="text" value={couponCode?.value} onChange={e => setCouponCode((prev) => ({ ...prev, value: e.target.value }))} className="w-full border border-gray-border outline-none px-3 py-2 h-11 focus:border-primary focus:border-[1.2px] appearance-none rounded-xl" placeholder="Enter a discount code" />
                                    <button type="button" onClick={e => addCouponCode()} className="h-11 bg-primary px-6 outline-none text-[#F6F7FB] rounded-xl hover:opacity-80 transition-all">Apply</button>
                                </div>
                                {couponErr ? <span className="text-red-600 mt-4">{couponErr}</span> : null}
                            </div>
                        }
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-base lg:text-xl font-semibold text-gray-light">
                            Total
                        </p>
                        <p className="text-lg lg:text-2xl font-semibold text-secondary">
                            ${totalCampervanPrice()}{" "}
                        </p>
                    </div>
                    {tab != 2 ?
                        <>
                            <div className="flex mt-5 items-center mb-3 ">
                                <input type="radio" name="" id="" checked className="size-6 shrink-0 accent-primary mr-2" />
                                <h5 className="text-lg font-medium text-secondary">Booking Deposit</h5>
                                <p className="text-base  font-medium text-[#2B2B2B] ml-auto">
                                    ${totalCampervanPrice()}
                                </p>
                            </div>
                            <p className="text-xs text-gray-light">The balance is due 30 days before the travel date.</p>
                        </>
                        : null}
                    <div className="flex items-center justify-between mt-4 lg:mt-6 mb-3">
                        <div className="flex items-center gap-x-1">
                            <p className="text-sm whitespace-nowrap sm:text-base text-secondary">
                                Security deposit (refundable)
                            </p>
                            <div className="App">
                            <Tooltip placement="top" title="The security deposit or bond is refundable.However, please note we will not be liable for any fluctuations in exchange rates." trigger="hover" className="!opacity-100">
                                <h2
                                //  data-tooltip-id="my-tooltip-3"
                                  className="cursor-pointer">
                                    <img src="/assets/img/front/imark.svg" alt="" />
                                </h2>
                                </Tooltip>
                            </div>
                            
                        </div>
                        <div className="price-text ">
                            <p className="text-base text-secondary font-medium">
                                ${campervanDetails?.security_deposite || 0}
                            </p>
                        </div>
                    </div>
                    {tab != 2 ?
                        <p className="text-xs font-medium text-primary">If you pay the full amount today, you can get 2% discount</p>
                        : null}
                    {error ? <span className="text-red-600 mt-4 text-lg">{error}</span> : null}

                    </div>

                </div>
                <div className="mt-5">
                    <div className="flex items-center mb-4 gap-3">
                        <img src="/assets/img/front/file.svg" alt="file" className="size-8 shrink-0" />
                        <h4 className="font-semibold text-base lg:text-xl xl:text-2xl  text-secondary">
                            Bond Details
                        </h4>
                    </div>
                    <div className="bg-[#EEFBF4] p-3 lg:p-6 rounded-2xl">
                        <p className="text-sm text-gray-light font-normal">
                            A Bond (security deposit) of {selectedInsurance?.Excess?.Value?`${selectedInsurance?.Excess?.CurrencyCode} $${selectedInsurance?.Excess?.Value}`:`between AUD $0.00 and AUD
                            $3500.00`} is required on arrival by the vehicle supplier.
                            This is a preaut horisation which means the bond amount is
                            frozen (or reserved) on your credit card, but not deducted.{" "}
                        </p>
                    </div>
                </div>
            </div>
            {/* <ReactTooltip
                id="my-tooltip-3"
                classNameArrow="border-b border-r"
                className="!z-[999] !p-4 !bg-white !text-secondary rounded-xl shadow-md !border !w-[300px]"
                place="top"
                variant="info"
                content={`The security deposit or bond is refundable.However, please note we will not be liable for any fluctuations in exchange rates.`}
            /> */}
        </>
    )
}

export default Pricing