export const bestCampervan = [
  {
    inWishList: false,
    bookingType: "On Request",
    driveType: "Automatic",
    images: [
      "/assets/img/front/camper1.svg",
      "/assets/img/front/camper2.svg",
      "/assets/img/front/camper1.svg",
    ]
  },
  {
    inWishList: true,
    bookingType: "Instant Confirmation",
    driveType: "Automatic",
    images: [
      "/assets/img/front/camper1.svg",
      "/assets/img/front/camper2.svg",
      "/assets/img/front/camper1.svg",
    ]
  },
  {
    inWishList: false,
    bookingType: "On Request",
    driveType: "Automatic",
    images: [
      "/assets/img/front/camper1.svg",
      "/assets/img/front/camper2.svg",
      "/assets/img/front/camper1.svg",
    ]
  },
  {
    inWishList: true,
    bookingType: "Instant Confirmation",
    driveType: "Automatic",
    images: [
      "/assets/img/front/camper1.svg",
      "/assets/img/front/camper2.svg",
      "/assets/img/front/camper1.svg",
    ]
  },
]

export const ReviewData = [
  {
    image: "/assets/img/front/p1.svg",
    name: "Linda M.",
    date: "11:06 AM July 4, 2023",
    description: "We started a listing for our little 16ft Coachmen with RV in the middle of last summer as a way to make extra money. We had booking requests within a few days. The response and renters were great. The financial results for us were so good that we have purchased a second unit this year. The response and renters were great. The financial results The response and renters were great. The financial results"
  },
  {
    image: "/assets/img/front/p2.svg",
    name: "Keny L.",
    date: "11:06 AM July 4, 2023",
    description: "It was our first time renting an RV. I was little bit unsure but the renting process was extremely."
  },
  {
    image: "/assets/img/front/p3.svg",
    name: "Benx M.",
    date: "11:06 AM July 4, 2023",
    description: "Both the RV owner and the  team were fantastic. I will be coming back to rent again for our next family vacation."
  },
  {
    image: "/assets/img/front/p4.svg",
    name: "John D.",
    date: "11:06 AM July 4, 2023",
    description: "We started a listing for our little 18ft Coachmen with RV in the middle of last summer as a way to make extra money. We had booking requests within a few days. The response and renters were great. The financial results for us were so good that we have purchased a second unit this year. The response and renters were great. The financial results The response and renters were great. The financial results"
  },
]

export const TrendingDestinationData = [
  {
    image: "/assets/img/front/t1.png",
    title: "Alberta"
  },
  {
    image: "/assets/img/front/t2.png",
    title: "British Columbia"
  },
  {
    image: "/assets/img/front/t3.png",
    title: "Alberta"
  },
  {
    image: "/assets/img/front/t4.png",
    title: "Quebec"
  },
  {
    image: "/assets/img/front/t3.png",
    title: "Alberta"
  },
  {
    image: "/assets/img/front/t4.png",
    title: "Quebec"
  },
]

export const vanSleepLimit = [
  {
    label: "Show all",
    icon: "/assets/img/front/van-1.svg"
  },
  {
    label: "Sleeps 2",
    icon: "/assets/img/front/van-2.svg"
  },
  {
    label: "Sleeps 3",
    icon: "/assets/img/front/van-1.svg"
  },
  {
    label: "Sleeps 4",
    icon: "/assets/img/front/van-3.svg"
  },
  {
    label: "Sleeps 5",
    icon: "/assets/img/front/van-4.svg"
  },
  {
    label: "Sleeps 6+",
    icon: "/assets/img/front/van-5.svg"
  },
]

export const ourServices = [
  {
    title: "Flexible cancelation",
    description: "Campervans and RVs with the indie fleet flexible cancelation policy can be canceled free of charge up to 31 days before pick-up.",
    image: "/assets/img/front/sec1.svg"
  },
  {
    title: "One-way trips are available",
    description: "Pick up and drop off your campervan at +70 locations across North America, Europe and Oceania.",
    image: "/assets/img/front/sec2.svg"
  },
  // {
  //   title: "For your next road trip",
  //   description: "Choose between a fully equipped Indie Campers van, a privately owned campervan in your neighborhood or a van from a professional campervan renter.",
  //   image: "/assets/img/front/sec3.svg"
  // },
  {
    title: "Book now, pay later!",
    description: "flexible cancelation policy, you only pay the full amount of your booking 30 days before the pick-up date.",
    image: "/assets/img/front/sec4.svg"
  },
]

export const amenities = [
  {
    label: "Shower",
    icon: "/assets/img/front/am1.svg"
  },
  {
    label: "Toilet",
    icon: "/assets/img/front/am2.svg"

  },
  {
    label: "Kitchen",
    icon: "/assets/img/front/am3.svg"
  },
  {
    label: "Bed",
    icon: "/assets/img/front/am4.svg"
  },
]

export const bookWithUs = [
  { label: "Flexible cancelation" },
  { label: "One-way trips are available" },
  { label: "For your next road trip" },
  { label: "Book now, pay later!" },
]

export const kitchenItems = [
  { label: "Kitchen" },
  { label: "Dining table" },
  { label: "Refrigerator" },
  { label: "Gas Cooker" },
  { label: "Cooler" },
  { label: "Stove" },
  { label: "Microwave" },
  { label: "Oven" },
  { label: "Kitchen" },
];
export const bathroomItems = [
  { label: "Integrated bathroom with shower" },
  { label: "Toilet" },
  { label: "Inside shower" },
  { label: "Outside shower" },

];
export const drivingItems = [
  { label: "Tow hitch" },
  { label: "backup sensors" },
  { label: "Levelling jacks" },
  { label: "Backup camera" },
  { label: "Triangle and vests" },
  { label: "Inverter" },
];
export const roadItems = [
  { label: "Air conditioner" },
  { label: "GPS" },
  { label: "Awning" },
  { label: "Hose" },
  { label: "TV" },
  { label: "Solar panels" },
  { label: "Heater" },
  { label: "Child seat" },
  { label: "Bike rack" },

];
export const featureItems = [
  { label: "Bedding Kit" },
  { label: "Generator" },

];
// export const headerData = [
//   {
//     title: "Countries",
//     items: [
//       { name: "Canada", link: "#" },
//       { name: "Australia", link: "#" },
//       { name: "Italy", link: "#" },
//       { name: "Spain", link: "#" },
//       { name: "Portugal", link: "#" },
//       { name: "New Zealand", link: "#" },
//       { name: "Iceland", link: "#" },
//       { name: "France", link: "#" },
//       { name: "United Kingdom", link: "#" }
//     ]
//   },
//   {
//     title: "North America",
//     items: [
//       { name: "Anchorage", link: "#" },
//       { name: "Chicago", link: "#" },
//       { name: "Las Vegas", link: "#" },
//       { name: "Los Angeles", link: "#" },
//       { name: "Miami", link: "#" },
//       { name: "Orlando", link: "#" },
//       { name: "Phoenix", link: "#" },
//       { name: "Salt Lake City", link: "#" },
//       { name: "San Francisco", link: "#" },
//       { name: "Seattle", link: "#" },
//       { name: "Calgary", link: "#" },
//       { name: "Vancouver", link: "#" },
//       { name: "Toronto", link: "#" }
//     ]
//   },
//   {
//     title: "Europe",
//     items: [
//       { name: "Lisbon", link: "#" },
//       { name: "Paris", link: "#" },
//       { name: "Amsterdam", link: "#" },
//       { name: "Madrid", link: "#" },
//       { name: "Dublin", link: "#" },
//       { name: "Milan", link: "#" },
//       { name: "Reykjavik", link: "#" },
//       { name: "Malaga", link: "#" },
//       { name: "Berlin", link: "#" },
//       { name: "London", link: "#" }
//     ]
//   },
//   {
//     title: "Oceania",
//     items: [
//       { name: "Sydney", link: "#" },
//       { name: "Melbourne", link: "#" },
//       { name: "Brisbane", link: "#" },
//       { name: "Auckland", link: "#" },
//       { name: "Christchurch", link: "#" },
//       { name: "Cairns", link: "#" },
//       { name: "Darwin", link: "#" },
//       { name: "Perth", link: "#" },
//       { name: "Queenstown", link: "#" }
//     ]
//   }
// ];
