import React from "react";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
// import { IoIosArrowUp } from "react-icons/io";
import { placeholderCSS } from "react-select/dist/declarations/src/components/Placeholder";
import { FiChevronUp } from "react-icons/fi";

interface Option {
  name: string;
  isPlaceholder?: boolean;
}

interface InputSelectDropdown {
  options: Option[];
  classes: string;
  labelBg: string;
  label?: string;
  value: Option;
  textLabel?: string;
  placeholder?: string;
  onChange: (option: Option) => void;
  btnClasses?: string
}

const InputSelect: React.FC<InputSelectDropdown> = ({
  options,
  classes,
  value,
  textLabel,
  onChange,
  btnClasses = "",
  placeholder
}) => {

  return (
    <div className="flex">
      <Listbox value={value} onChange={onChange}>
        {({ open }) => (
          <div className={`relative ${classes} `}>
            <Listbox.Button
              className={`${classes}  border-0 relative cursor-pointer text-left outline-none bg-white h-10 sm:h-11 px-5  ${textLabel ? "rounded-l-lg" : "rounded-lg"
                } ${btnClasses}`}>
              <span className="block text-sm/5 font-normal text-gray-light truncate  pr-3">
                {value?.name === "" ? placeholder : value.name}
              </span>
              <span className="pointer-events-none absolute inset-y-0 left-4 flex items-center"></span>
              <span className="pointer-events-none absolute inset-y-0 right-3 flex items-center">
              <FiChevronUp 
              className={`${open ? "" : "rotate-180 transform"
            } size-[18px] text-secondary`} />
               
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={` ${classes} z-[9999] absolute mt-1 max-h-60 scrollbar w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm`}
              >
                {options.map((option, idx) => (
                  <Listbox.Option
                  key={idx}
                  className={({ active , selected }) =>
                    `relative select-none py-3.5 px-4 cursor-pointer ${selected ? "bg-primary text-white transition-all" : active ? "bg-primary text-white transition-all" : "text-gray-900"}`
                  }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? "font-medium" : "font-normal"
                            }`}
                        >
                          {option.name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>

            </Transition>
          </div>
        )}
      </Listbox>
      {textLabel && (
        <span className="bg-[#E6E6E6] text-base flex items-center justify-center font-normal text-center px-6 h-[46px] -my-[.5px] whitespace-nowrap text-secondary rounded-r-lg">
          {textLabel}
        </span>
      )}
    </div>
  );
};

export default InputSelect;
