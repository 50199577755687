import React from 'react'
import { FaStar } from "react-icons/fa6";

interface ReviewCardIF {
  image: string
  name: string
  description: string
  date: string
}

interface ReviewCardProps {
  itm: ReviewCardIF,
}
const ReviewCard: React.FC<ReviewCardProps> = ({ itm }) => {
  return (
    <div className="p-4 lg:p-8 rounded-[20px] bg-[#F6F7FB] flex flex-col h-full">
      <div className="flex gap-3 items-center">
        <img
          alt={itm.name}
          src={itm.image}
          width={48}
          height={48}
          className="rounded-full" />
        <div>
          {/* font-family */}
          <h5 className="text-sm lg:text-[18px] font-semibold text-secondary">{itm.name}</h5>
          <div className="flex gap-1 items-center mt-1.5">
            <FaStar size={15} className="text-[#FFC529]" />
            <FaStar size={15} className="text-[#FFC529]" />
            <FaStar size={15} className="text-[#FFC529]" />
            <FaStar size={15} className="text-[#FFC529]" />
            <FaStar size={15} className="text-[#FFC529]" />
          </div>
        </div>
      </div>
      <p className="text-sm lg:text-base text-gray-light mt-2 lg:mt-4">{itm.description}</p>
      {/* font-family */}
      <p className="text-xs  font-normal text-gray-light pt-1 mt-auto">{itm.date}</p>
    </div>
  )
}

export default ReviewCard