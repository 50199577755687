import { useState, useEffect } from 'react';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
// import './style.scss';
import Html from './Html';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LOGIN_SUCCESS } from '../../../Redux/Action/Action';

const EditProfile = () => {
  const user: any = useSelector((state: any) => state.User)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const history = useNavigate()
  const [cities, setCities] = useState([])
  const [form, setForm]: any = useState({
    image: user?.image || "",
    fullName: user?.fullName || `${user?.firstName} ${user?.lastName}`,
    email: user?.email || "",
    mobileNo: user?.mobileNo || "",
    city: user?.city ? { value: user?.city, label: user?.city } : null,
    dateOfBirth: user?.dateOfBirth || "",
  });

  useEffect(() => {
    setForm({ ...form, id: user?.id });
    getCities()
  }, [])

  const getCities = () => {
    ApiClient.get(`cities?status=active`).then((res: any) => {
      if (res.success) {
        setCities(res?.data?.map((item: any) => {
          return ({ value: item?.city_name, label: item?.city_name })
        }))
      }
    })
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let value = { ...form, id: user?.id, city: form?.city?.label || '' }
    loader(true)
    ApiClient.put(`admin/edit-user`, value).then((res: any) => {
      if (res.success) {
        dispatch(LOGIN_SUCCESS(res?.data))
        history("/profile")
      }
      loader(false)
    })
  };

  const ImageUpload = (e: any) => {
    let files = e.target.files
    let file = files.item(0)
    loader(true)
    ApiClient.postFormData('upload/image?modelName=users', { file: file, modelName: 'users' }).then(res => {
      if (res.success) {
        setForm({ ...form, image: res?.data?.fullpath })
      }
      loader(false)
    })
  }

  const nameValidation = (e: any, key: any) => {
    let values = e.target.value
    let regex = /^[A-Za-z' ]+$/
    let isvalid = regex.test(values)
    if (isvalid) {
      setForm({ ...form, [key]: values })
    }
    else if (!values) {
      setForm({ ...form, [key]: '' })
    }
  }

  return (
    <>
      <Html
        handleSubmit={handleSubmit}
        setForm={setForm}
        form={form}
        ImageUpload={ImageUpload}
        navigate={navigate}
        nameValidation={nameValidation}
        cities={cities}
      />
    </>
  );
};

export default EditProfile;
