import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import methodModel from "../../../methods/methods";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { LOGOUT_SUCCESS } from "../../../Redux/Action/Action";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";

const ChangePassword = (p) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const FormSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password must be at least 8 cahracters")
      .required("Password  is required")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol")
      .max(16, "Password must be less than 16 characters"),
    confirmpassword: Yup.string()
      .min(8, "Password must be at least 8 cahracters")
      .required("Confirm password  is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password not match with password"
      ),
  });

  const handleSubmit = (value) => {
    // alert("hii")
    // value.preventDefault()
    // setSubmitted(true)
    loader(true);
    let payload = {
      newPassword: value.password,
      confirmPassword: value.confirmpassword,
    };
    ApiClient.put("change/password", payload).then((res) => {
      if (res.success) {
        dispatch(LOGOUT_SUCCESS());
        localStorage.removeItem("token");
        localStorage.removeItem("persist:admin-app");
        history("/login");
      }
      loader(false);
    });
  };
  return (
    <>
      <div className="wrapper_section">
        <div className="input_form border border-gray-border p-3 lg:p-6  rounded-2xl">
          <h3 className="text-lg lg:text-xl font-semibold text-secondary mb-5">
            Change Password
          </h3>
          <Formik
            initialValues={{
              password: "",
              confirmpassword: "",
            }}
            validationSchema={FormSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="w-full mb-5">
                <label className="text-sm font-medium block text-secondary mb-2">
                  Password 
                  {/* <span className="text-red-600">*</span> */}
                </label>
                <div className="relative w-full">
                  <Field
                    type={eyes.password ? "text" : "password"}
                    name="password"
                    placeholder="Enter your password"
                    className="shadow-box text-lg placeholder:text-gray-500  p-4 relative  bg-white w-full rounded-xl h-10  focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none"
                  />
                  <div className="absolute right-[34px] top-2.5 cursor-pointer">
                    {eyes.password ? (
                      <IoEyeOutline
                        className="size-4 absolute text-secondary cursor-pointer"
                        onClick={() =>
                          setEyes({ ...eyes, password: !eyes.password })
                        }
                      />
                    ) : (
                      <IoEyeOffOutline
                        className="size-4 absolute text-secondary cursor-pointer"
                        onClick={() =>
                          setEyes({ ...eyes, password: !eyes.password })
                        }
                      />
                    )}
                  </div>
                  <div className="invalid-feedback mt-1 text-red-600">
                    <ErrorMessage name="password" />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <label className="text-sm font-medium block text-secondary mb-2">
                  Confirm Password 
                  {/* <span className="text-red-600">*</span> */}
                </label>
                <div className="relative w-full">
                  <Field
                    type={eyes.confirmPassword ? "text" : "password"}
                    name="confirmpassword"
                    placeholder="Enter your confirm password"
                    className=" text-lg placeholder:text-gray-500  border-0 p-4 relative shadow-box bg-white w-full rounded-xl h-10  focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none"
                  />
                  <div className="absolute right-[34px] top-2.5 cursor-pointer">
                
                    {eyes.confirmPassword ? (
                      <IoEyeOutline
                        className="size-4 absolute text-secondary cursor-pointer"
                        onClick={() =>
                          setEyes({
                            ...eyes,
                            confirmPassword: !eyes.confirmPassword,
                          })
                        }
                      />
                    ) : (
                      <IoEyeOffOutline
                        className="size-4 absolute text-secondary cursor-pointer"
                        onClick={() =>
                          setEyes({
                            ...eyes,
                            confirmPassword: !eyes.confirmPassword,
                          })
                        }
                      />
                    )}
                  </div>
                  <div className="invalid-feedback mt-1 text-red-600">
                    <ErrorMessage name="confirmpassword" />
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
              <div className="flex justify-end mt-5 gap-4">
                <button
                  type="submit"
                  className="text-gray-light border border-gray-border rounded-xl shrink-0 text-base/5 lg:text-lg/5 font-medium px-8 py-4 w-fit text-center cursor-pointer hover:bg-primary hover:text-white transition-all">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-[#F6F7FB] bg-primary hover:opacity-80 rounded-xl shrink-0 text-base/5 lg:text-lg/5 font-normal px-8 py-4 w-fit text-center cursor-pointer transition-all">
                  Save Changes
                </button>
              </div>
      </div>
    </>
  );
};

export default ChangePassword;
