import ApiClient from "../../methods/api/apiClient"
import methodModel from "../../methods/methods"
import moment from "moment"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Card from "antd/es/card/Card"
import { RiArrowGoBackFill } from "react-icons/ri"
import { HiOutlineMail, HiOutlineUserAdd } from "react-icons/hi"
import { CgCalendarDates } from "react-icons/cg"
import { LiaUsersCogSolid } from "react-icons/lia";
import Layout from "../../components/global/layout";
import { Button } from "antd"

const CustomerDetail = () => {
    const { id } = useParams()
    const [detail, setdetail] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        ApiClient.get(`user/detail?id=${id}`).then((res) => {
            if (res.success) {
                setdetail(res?.data)
            }
        })
    }

    return (
        <Layout>
            <>
                <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-bold">Customer Details</h2>
                    <Button variant="solid" className="flex items-center gap-2" onClick={e => { navigate(`/customer`) }}><RiArrowGoBackFill /> Back</Button>
                </div>
                <Card clickable className="mt-6">
                    <div className="grid grid-cols-12 gap-2 items-center">
                        <div className="col-span-12 md:col-span-4 lg:col-span-3 border-r border-gray-300 border-dashed">
                            <div className="img-users text-center">
                                <img src={methodModel.userImg(detail?.image)} className="h-40 w-40 mx-auto object-contain" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-8 lg:col-span-9 ">

                            <div className="lg:ml-8 grid grid-cols-2">
                                <div className="mb-4">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <HiOutlineUserAdd className="text-2xl" />

                                        </div>
                                        <div>
                                            <span>Full Name</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold">{detail?.firstName} <span className="">{detail?.lastName}</span></p>
                                        </div>
                                    </div>

                                </div>


                                <div className="mb-6">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <HiOutlineMail className="text-2xl" />


                                        </div>
                                        <div>
                                            <span>Email</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold">{detail?.email}</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="mb-6">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <LiaUsersCogSolid className="text-2xl" />

                                        </div>
                                        <div>
                                            <span>Role</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold capitalize">{detail?.role.replaceAll('_', ' ')}</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <CgCalendarDates className="text-2xl" />


                                        </div>
                                        <div>
                                            <span>Date</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold">{moment(detail?.createdAt).format('DD MMM, YYYY')}</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                </Card>
            </>
        </Layout>

    )
}

export default CustomerDetail