import { useEffect, useState } from "react";
import PageLayout from "../../components/global/PageLayout";
import AboutUs from "../../components/common/AboutUs";
import NewsLetter from "../../components/common/NewsLetter";
import Breadcrumb from "../../components/common/Breadcrumb";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import OurServices from "../../components/common/OurServices";

const HowItWorks = () => {
  const [detail, setDetail] = useState()

  useEffect(()=>{
    getDetails()
  },[])

  const getDetails=()=>{
    loader(true)
    ApiClient.get(`content?title=how_it_works`).then(res=>{
      if(res.success){
        setDetail(res?.data)
      }
      loader(false)
    })
  }

  const details = {
    campervan_name: "How It Works",
  }

  return (
    <PageLayout>
      <div className={``}>
        <div className="mt-3 lg:mt-6 mb-4 lg:mb-8 max-w-[1504px] w-full mx-auto px-4 lg:px-8">
          <Breadcrumb
            links={[
              { link: "/", name: "Home" },
            ]}
            currentPage={details?.campervan_name}
          />
          </div>
        <div className="flex items-center justify-center lg:justify-between gap-4 flex-wrap lg:flex-nowrap mt-4 lg:mt-8 mb-6 lg:mb-12 max-w-[1504px] w-full mx-auto px-4 lg:px-8">
          <div className="flex flex-col lg:max-w-[727px] w-full">
            <div className="[&>h1]:text-center [&>h1]:lg:text-left [&>h1]:md:text-xl  [&>h1]:lg:text-3xl [&>h1]:2xl:text-4xl [&>h1]:text-secondary [&>h1]:font-semibold [&>h1]:mb-2 [&>h1]:lg:mb-4  [&>p]:text-base [&>p]:font-normal [&>p]:text-gray-light [&>p]:mb-2 [&>p]:lg:mb-4 [&>h2]:font-normal [&>h2]:text-gray-light [&>h2]:text-base  " dangerouslySetInnerHTML={{ __html: detail?.description }}></div>
            {/* <h1 className="text-2xl sm:text-5xl font-medium text-secondary mb-6">
              How it works
            </h1>
            <p className="text-base font-medium text-secondary/90 mb-4 sm:mb-8">
              RV Renter Center connects local RV, motorhome and campervan owners
              with people and families wanting to rent them. Making memorable
              outdoor experiences accessible to everyone.
              <span className="font-normal mt-6 block">
                Our RVs, travel trailers, and campervans have been driven over
                160 million miles and booked over 2,000,000 travel days.  RV is
                built on trust. We provide the highest level of safety so you
                can focus on making memories of a lifetime. When you book with
                us, you rent worry-free because we offer:
              </span>
            </p> */}
          </div>
          <div className="lg:max-w-[615px] w-full ">
            <img
              alt="campVan1"
              src={methodModel.noImg(detail?.image)}
              className="w-full object-cover lg:object-fill rounded-2xl h-[434px]"
            />
          </div>
        </div>
        <div className="bg-[#F6F7FB] max-w-[1504px] w-full mx-auto px-4 lg:px-8">
        <div className="py-6 lg:py-12">
        <h6 className="text-base font-medium text-primary mb-3 uppercase text-center w-full py-2 max-w-[146px] mx-auto bg-[linear-gradient(90deg,_rgba(23,68,210,0.2)_0%,_rgba(12,35,108,0)_100%)] rounded">{details?.campervan_name}</h6>
        <h1 className="text-base/5 md:text-xl lg:text-3xl 2xl:text-4xl font-semibold text-secondary mb-5 lg:mb-10 w-full max-w-[506px] mx-auto text-center" dangerouslySetInnerHTML={{ __html: detail?.heading }}></h1>
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-2 md:gap-4">
          {detail?.favorite.map((item, index) => (
            <div key={index} className="flex">
              <div className="p-2">
                <div className="h-10 w-10 bg-[#D2DDFF] mb-4 flex items-center justify-center rounded-full text-base mx-auto font-semiboldbold text-secondary">{index+1}</div>
                {/* <h6 className="text-xl font-bold text-secondary mb-3">{_itm.heading}</h6> */}
                <div className="[&>h2]:text-base [&>h2]:lg:text-xl [&>h2]:xl:text-2xl [&>h2]:text-secondary [&>h2]:font-semibold [&>h2]:text-center [&>h2]:mb-4 [&>p]:text-gray-light [&>p]:text-sm [&>p]:text-center" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
              </div>
              <div className={`ml-6 lg:h-[85px] my-auto ${index !== detail?.favorite?.length - 1 ? 'lg:border-r border-gray-border' : ''}`} />
            </div>
          ))}
        </div>
        </div>
        </div>
        <div className="mt-6 lg:mt-12 max-w-[1504px] w-full mx-auto px-4 lg:px-8">
          <h1 className="text-base/5 md:text-xl lg:text-3xl 2xl:text-4xl font-semibold text-secondary mb-5 lg:mb-10 w-full max-w-[680px] mx-auto text-center" dangerouslySetInnerHTML={{ __html: detail?.heading1 }}></h1>
          <OurServices detail={detail?.trip_provider} />
        </div>
        <div className="mb-6 lg:my-12 max-w-[1504px] w-full mx-auto px-4 lg:px-8">
          <AboutUs />
        </div>

        {/* <NewsLetter /> */}
      </div>
    </PageLayout>
  );
};

export default HowItWorks;
