import { MdKeyboardArrowLeft } from "react-icons/md";
import { FiChevronRight } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";


interface ArrowProps {
  className?: string;
  child?: string;
  onClick?: () => void;
  size?: number;
}

export function FiChevronRightt(props: ArrowProps) {
  const { className, child, onClick, size } = props;
  return (
    <div
      className={`${className} ${child} cursor-pointer rounded-full !flex !items-center !justify-center before:!content-['']`}
      onClick={onClick}>
      <FiChevronRight className="text-inherit" size={size} />
    </div>
  );
}
export function FiChevronLeftt(props: ArrowProps) {
  const { child, className, onClick, size } = props;
  return (
    <div
      className={`${className} ${child} z-10 cursor-pointer rounded-full !flex !items-center !justify-center before:!content-['']`}
      onClick={onClick}>
      <FiChevronLeft className="text-inherit" size={size} />
    </div>
  );
}