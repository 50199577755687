const initialState = {
  search: "",
  tab: "",
  siteSettings: "",
  jucyImages: [],
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_TAB":
      return {
        ...state,
        tab: action.payload,
      };
      break;
    case "SITE_SETTINGS":
      return {
        ...state,
        siteSettings: action?.payload
      }
      break
    case "JUCY_IMAGES":
      return {
        ...state,
        jucyImages: action?.payload
      }
      break
    case "SEARCH_STATE":
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
