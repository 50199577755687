import "slick-carousel/slick/slick.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageSlider from "../../components/common/ImageSlider";
import PageLayout from "../../components/global/PageLayout";
import ApiClient from '../../methods/api/apiClient'
import Pagination from "react-js-pagination";
import methodModel from "../../methods/methods";
import ImageSliderDirect from "../../components/common/ImageSliderDirect";
import loader from "../../methods/loader";
import SearchBanner from "../../components/common/SearchBanner";
import { CiHeart } from "react-icons/ci";
import StarRatings from 'react-star-ratings';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoMdHeart } from "react-icons/io";
import { RxLightningBolt } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SEARCH_STATE } from "../../Redux/Action/Action";
import environment from "../../environment";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumb from "../../components/common/Breadcrumb";
import moment from "moment";
import CryptoJS from 'crypto-js';

const sortOptions = [
  { value: "", name: "Recommended" },
  { value: "defaultRate asc", name: "Lowest price" },
  { value: "defaultRate desc", name: "Highest Price" },
  { value: "age asc", name: "Vehicle Age" },
];

const GeoPage = () => {
  const search = useSelector((state) => state.Reducer.search)
  const user = useSelector((state) => state.User)
  const { name } = useParams()
  const dispatch = useDispatch()
  const [searchFilter, setSearchFilter] = useState({
    peopleCount: 0,
    startDate: new Date().toISOString().substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10)
  })
  const [active, setActive] = useState(0)
  const navigate = useNavigate()
  const [filter, setfilter] = useState({ page: 1, count: 10, sortBy: sortOptions[0] })
  const [campervans, setcampervans] = useState([])
  const [totalCampervans, settotalCampervans] = useState(0)
  const [filters, setFilters] = useState({
    transmission: search?.transmission || [],
    fuelType: search?.fuelType || [],
    vehicleAge: search?.vehicleAge || [],
    campervan_type: search?.campervan_type || [],
    amenitieis: search?.amenitieis || [],
    priceStart: search?.priceStart || '',
    priceEnd: search?.priceEnd || '',
  })
  const [jucyCampervans, setJucyCampervans] = useState([])
  const [detail, setDetail] = useState()
  const pickUpRef = useRef()
  const API_KEY = environment.AU_SPACESHIPS_API_KEY
  const SHARED_SECRET = environment.AU_SPACESHIPS_SHARED_SECRET || ''

  useEffect(() => {
    getDetail()
  }, [name])

  useEffect(() => {
    setfilter((prev) => ({ ...prev, ...search }))
    getcampervan(search)
  }, [search])

  useEffect(() => {
    dispatch(SEARCH_STATE({
      ...search, ...filters,
      transmission: String(filters?.transmission),
      fuelType: String(filters?.fuelType),
      campervan_type: String(filters?.campervan_type),
      vehicleAge: String(filters?.vehicleAge),
      amenitieis: String(filters?.amenitieis),
    }))
  }, [filters])

  // Return the country or city detail to show the heading, images, and description
  const getDetail = () => {
    const capitalizeName = name.replace("-", " ").replace(/\b\w/g, l => l.toUpperCase()) // To convert the name into capitalize
    ApiClient.get(`city?city_name=${capitalizeName}`, {}, '', true).then(res => {
      if (res.sucess) {
        setDetail(res?.city)
      } else {
        // When city api give error then it check the country api
        ApiClient.get(`country?country_name=${capitalizeName.replace("-", " ")}`).then(res => {
          if (res.success) {
            setDetail(res?.data)
          }
        })
      }
    })
  }

  // Return the campervan list
  const getcampervan = (p = {}) => {
    let payload = { ...filter, sortBy: filter?.sortBy?.value, ...p }
    delete payload?.isReturn //No Need for this key in payload

    // It check the pickUpLocation and dropOffLocation location siteCode is valid or not
    if (payload?.pickUpLocation?.label === payload?.pickUpLocation?.value) delete payload?.pickUpLocation?.value
    if (payload?.dropOffLocation?.label === payload?.dropOffLocation?.value) delete payload?.dropOffLocation?.value

    //Location and Date filters
    const pickUpLocationSiteCode = payload?.pickUpLocation?.value || ''
    const dropOffLocationSiteCode = payload?.dropOffLocation?.value ? payload?.dropOffLocation?.value : payload?.pickUpLocation?.value ? payload?.pickUpLocation?.value : ''
    const pickUpDate = payload?.start_date || ''
    const dropOfDate = payload?.end_date || ''

    // Spaceship api payload, api key and signature
    const spaceShipPayload = {
      method: "step2",
      vehiclecategorytypeid: "0",
      pickuplocationid: payload?.pickUpLocation?.spaceship_id,
      pickupdate: moment(payload?.start_date).format('DD/MM/YYYY'), // Change the date format
      pickuptime: "10:00",
      dropofflocationid: payload?.dropOffLocation?.spaceship_id || payload?.pickUpLocation?.spaceship_id,
      dropoffdate: moment(payload?.end_date).format('DD/MM/YYYY'), // Change the date format
      dropofftime: "10:00",
      ageid: 9
    }
    const signature = CryptoJS.HmacSHA256(JSON.stringify(spaceShipPayload), SHARED_SECRET).toString(CryptoJS.enc.Hex)

    if (pickUpLocationSiteCode || dropOffLocationSiteCode || pickUpDate || dropOfDate) {
      loader(true)
      ApiClient.post(`availabilityAndPrice?page=${payload?.page}&count=${payload?.count}&typeId=${'7d3dece7-ab86-e611-80e8-c4346bc5977c'}&pickUpLocation=${pickUpLocationSiteCode}&dropOffLocation=${dropOffLocationSiteCode}&pickUpDateTime=${pickUpDate}&dropOffDateTime=${dropOfDate}&accountKey=${environment?.agentAccountKey}&driverAge=${23}&mergeSurchargesFees=${false}&transmission=${payload?.transmission || ''}&fuelType=${payload?.fuelType || ''}&vehicleAge=${payload?.vehicleAge || ''}&amenitieis=${payload?.amenitieis || ''}&sortBy=${payload?.sortBy}&status=${'active'}&apikey=${API_KEY}`, spaceShipPayload).then(res => {
        if (res?.data?.ResponseType == 'Success') {
          const campervanIds = []
          const response = res?.data?.Data?.[1]?.Categories?.filter((item, index) => item?.campervan_type == 'spaceship' && item?.numberofdays != 0 || item?.Availability != 'StopSell' && item?.campervan_type == 'jucy')
          setcampervans(response)
          settotalCampervans(0)
          res?.data?.Data?.[1]?.Categories?.map((item) => {
            if (item?.Availability != 'StopSell' && item?.campervan_type == 'jucy') {
              campervanIds.push(item?.Id)
            }
          })
          ApiClient.get(`all/Campervan`, { jucy_campervan_id: String(campervanIds) }).then(res => {
            if (res.success) {
              setJucyCampervans(res?.data)
            }
          })
        } else {
          setcampervans([])
        }
        loader(false)
      })
    } else {
      loader(true)
      ApiClient.get(`all/Campervan`, { ...payload, status: 'active' }).then((res) => {
        if (res?.success) {
          setcampervans(res?.data)
          settotalCampervans(res?.total)
        }
        loader(false)
      })
    }
  }

  const images1 = [
    "assets/img/front/camper1.svg",
    "assets/img/front/camper2.svg",
  ];

  // This is the comman function to handle the actions with the help of type and key
  const handleAction = (item, type, key) => {
    if (key == 'view details') {
      if (item) navigate(`/campervan/detail/${item}/${type}`)
      else return
    }
  }

  const handlePagination = (e) => {
    setfilter(prev => ({ ...prev, page: e }))
    getcampervan({ page: e })
  }
  // This function return the Jucy images
  const showJucyImages = (item, index) => {
    let sliderImages = item
    sliderImages = item?.filter((itm) => {
      if (itm?.type == "Image") return itm?.original
    })
    sliderImages = sliderImages?.slice(0, 5)?.map((value) => value?.original)
    return sliderImages
  }
  // This function is use for the filters to search campervans
  const handleSearchCampervans = (f) => {
    setfilter({ ...filter, ...f })
    delete f?.sortBy
    dispatch(SEARCH_STATE({ ...search, ...f }))
    getcampervan(f)
  }
  // This function is use to show the jucy campervan details those detail are not in the availability api
  const showJucyCampervanDetail = (item, index, key) => {
    const data = jucyCampervans?.find((itm) => itm?.jucy_campervan_id == item?.Id)
    if (key == 'gallery') {
      return showJucyImages(data?.gallery, index)
    } else {
      return data?.[key]
    }
  }
  // It use to make the campervan favorite and unfavorite
  const handleFavoriteUnFavorite = (id) => {
    let token = localStorage.getItem('token')
    if (!token) navigate('/login')
    ApiClient.post(`favourite`, { campervan_id: id }).then(res => {
      if (res.success) {
        getcampervan()
      }
    })
  }
  // This function is use to find the campervan is favorite or not
  const findIsFavourite = (favoriteDetails) => {
    let value = []
    value = favoriteDetails?.filter(item => {
      if (item?.fav_user_id == user?.id) {
        return item
      } else {
        return false
      }
    })
    return value?.[0]?.isFavourite
  }

  return (
    <>
      <PageLayout>
        <div className="max-w-[1368px] w-full mx-auto px-3 md:px-6">
          <div className="my-6">
            <Breadcrumb
              links={[
                { link: "/", name: "Home" },
              ]}
              currentPage={name.replace("-", " ").replace(/\b\w/g, l => l.toUpperCase())} // To convert the name into capitalize
            />
          </div>
          <div className="mt-3 lg:mt-6">
            <h1 className="font-semibold text-secondary text-2xl/6 lg:text-[58px] mb-6 lg:mb-12">
              {detail?.banner_head || "Browse and book your RV escape"}
            </h1>
            <SearchBanner
              bgImage={detail?.banner ? methodModel.campervanImage(detail?.banner) : "assets/img/front/herobanner.png"}
              listingPage
              searchFilter={searchFilter}
              filter={filter}
              setSearchFilter={setSearchFilter}
              classes="lg:py-[104px]"
              handleSearchCampervans={handleSearchCampervans}
              activeIdx={active}
              setActiveIdx={setActive}
              pickUpRef={pickUpRef}
            />
          </div>
        </div>
        <div className="max-w-[1368px] w-full mx-auto px-3 md:px-6">
          <div className="grid grid-cols-12 gap-x-[10px] lg:gap-x-[20px] 2xl:gap-x-[40px]">
            {detail?.banner_body ?
              <div className="col-span-12 lg:col-span-3 mt-10">
                <div className="bg-white rounded-2xl border border-black/15 p-3 md:p-6">
                  <div>
                    <h6 className="font-semibold text-xl text-neutral-800">{detail?.banner_head || "Browse and book your RV escape"}</h6>
                    <img className="w-full h-52 rounded-md" src={detail?.banner ? methodModel.campervanImage(detail?.banner) : "assets/img/front/herobanner.png"} />
                    <p className="mt-3 text-lg font-regular text-neutral-500" dangerouslySetInnerHTML={{ __html: detail?.banner_body }}></p>
                  </div>
                </div>
              </div>
              : null}
            <div className={`col-span-12 lg:col-span-${detail?.banner_body ? "9" : "12"} mt-10`}>
              {campervans?.length == 0 ?
                <div className="text-center mt-10">No Records Found</div>
                :
                <ul className="space-y-6 mb-6 lg:mb-20">
                  {campervans && campervans?.map((item, idx) => {
                    return <>
                      <li key={idx} className="bg-white rounded-2xl border border-black/15 p-3 md:p-6 flex flex-col lg:flex-row gap-3 sm:gap-6">
                        <div className="w-full lg:max-w-[386px]  relative">
                          {item?.campervan_type === 'jucy' || item?.Id ?
                            <ImageSliderDirect images={item?.gallery ? showJucyImages(item?.gallery, idx) : showJucyCampervanDetail(item, idx, 'gallery')} imageHeight={'md:h-[327px] rounded-[15px]'} />
                            :
                            item?.campervan_type == 'spaceship'?
                            <ImageSliderDirect images={item?.images} imageHeight={'md:h-[327px] rounded-[15px]'} />
                            :
                            <ImageSlider
                              images={item?.images?.length === 0 ? images1 : item?.images}
                              imageCounter
                              imgHeight={327}
                            />
                          }
                          <span className="size-9 rounded-full bg-white flex items-center justify-center absolute right-5 top-5" role="button" aria-label="add-to-wishlist" onClick={e => handleFavoriteUnFavorite(item?._id || showJucyCampervanDetail(item, idx, '_id'))}>
                            {findIsFavourite(item?.favourite_details || showJucyCampervanDetail(item, idx, 'favourite_details')) ?
                              <IoMdHeart size={18} className="text-base text-red-600" />
                              :
                              <CiHeart className="size-[18px] font-medium text-base text-secondary" />
                            }
                          </span>
                        </div>
                        <div className="flex-1 w-full flex flex-col md:flex-row gap-6 items-center">
                          <div className="w-full flex-1 2xl:flex-none lg:max-w-[300px]">
                            <h4 className="text-2xl/7 font-semibold text-secondary mb-3 capitalize"> {item?.campervan_name || item?.Name}</h4>
                            <p className="text-ellipsis text-sm/4 text-secondary/80 mb-3 line-clamp-3" dangerouslySetInnerHTML={{ __html: item?.description || item?.Description }}></p>
                            <div className={`${item?.campervan_type == 'jucy' && !item?.Total?.Value ? 'h-auto' : 'h-[104px]'} bg-[#E9EDFF96] rounded-2xl py-6 px-3.5 relative`}>
                              <div className="flex  gap-2 mb-2 mt-2 ">
                                <h5 className=" flex text-xl/5 font-bold text-secondary whitespace-nowrap">Total Price: <span className="text-primary">{item?.Total?.CurrencyCode || 'AUD'}${item?.defaultRate || item?.Total?.Value || 0}</span></h5>
                                <div className="App">
                                  <h2 data-tooltip-id="my-tooltip-1" className="cursor-pointer">
                                    <AiOutlineInfoCircle className="min-w-5 min-h-5 text-secondary" />
                                  </h2>
                                </div>
                                <ReactTooltip
                                  id="my-tooltip-1"
                                  classNameArrow="border-b border-r "
                                  className="!z-[999] !bg-white !text-secondary !border"
                                  place="top"
                                  variant="info"
                                  content="I'm a info tooltip"
                                />
                              </div>
                              {item?.campervan_type == 'jucy' && !item?.Total?.Value ?
                                <p className="text-sm/5 text-secondary">To check the price please select the pickup & dropoff location and date</p>
                                : null}
                            </div>
                            <div className="flex mt-4 gap-2 items-end flex-wrap">
                              <span className="text-base/6 font-medium text-[#707070]">Ratings -</span>
                              <StarRatings
                                rating={4.5}
                                starSpacing="3px"
                                starRatedColor="#FEB602"
                                starHoverColor="#FEB602"
                                starDimension="24"
                                numberOfStars={5}
                                name='rating'
                              />
                              <span className="text-base/6 font-medium text-[#707070] leading-5 block">(4.8)</span>
                            </div>
                            <button
                              type="button"
                              onClick={e => handleAction(item?._id || showJucyCampervanDetail(item, idx, '_id'), item?.campervan_type || showJucyCampervanDetail(item, idx, 'campervan_type'), 'view details')}
                              className="w-full bg-primary hover:bg-primary/80 rounded-full text-lg/5 text-white hidden md:flex items-center justify-center h-[54px] mt-3 py-4 text-center">
                              View Details
                            </button>
                          </div>
                          <div className="hidden md:flex h-[156px] border-r border-black/20" />
                          <div className="flex-1 flex flex-col h-full w-full gap-5">
                            {item?.Availability ?
                              <span className="shrink-0 h-10 w-fit self-end px-3 2xl:px-[22px] py-1.5 2xl:py-2.5 rounded-full flex items-center gap-1.5 bg-[#CFFFBB] text-xs 2xl:text-sm">
                                <RxLightningBolt className="text-xs 2xl:text-base text-secondary" />{item?.Availability}
                              </span>
                              : null}
                            {item?.Id ?
                              <div className="grid grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 gap-6 my-auto items-baseline">
                                {showJucyCampervanDetail(item, idx, 'highlightedFeatures')?.slice(0, 6)?.map(itm => {
                                  return <div className="flex flex-col items-center justify-center">
                                    <p className="text-sm text-[#031D24] text-center capitalize">{itm}</p>
                                  </div>
                                })}
                              </div>
                              :
                              <>
                                {item?.highlightedFeatures?.length === 0 || !item?.highlightedFeatures ?
                                  <>
                                    {item?.amenities_name === 0 || !item?.amenities_name ?
                                      <div className="text-center flex items-center justify-center h-full">
                                        <p> No Amenities</p>
                                      </div>
                                      : null
                                    }
                                  </> : null
                                }
                                <div className="grid grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3 gap-6 my-auto items-baseline">
                                  {item?.highlightedFeatures?.length === 0 || !item?.highlightedFeatures ?
                                    <>
                                      {item?.amenities_details?.slice(0, 6)?.map(itm => {
                                        return <div className="flex flex-col items-center justify-center">
                                          <img src={methodModel.campervanImage(itm?.amenities_image)} alt={itm?.image_alt_name} width={30} />
                                          <p className="text-sm text-[#031D24] text-center capitalize">{itm?.amenities_name}</p>
                                        </div>
                                      })}
                                    </>
                                    :
                                    <>
                                      {item?.highlightedFeatures?.slice(0, 6)?.map(itm => {
                                        return <div className="flex flex-col items-center justify-center">
                                          <p className="text-xs lg:text-sm text-[#031D24] text-center capitalize">{itm}</p>
                                        </div>
                                      })}
                                    </>
                                  }
                                </div>
                              </>
                            }
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={e => handleAction(item?._id || showJucyCampervanDetail(item, idx, '_id'), item?.campervan_type || showJucyCampervanDetail(item, idx, 'campervan_type'), 'view details')}
                          className="w-full bg-primary rounded-full text-lg/5 text-white md:hidden flex items-center justify-center h-[54px] py-4 text-center">
                          View Details
                        </button>
                      </li>
                    </>
                  })}
                </ul>
              }
              <div>
                <Pagination
                  prevPageText={<FiChevronLeft className="text-2xl font-extralight" />}
                  nextPageText={<FiChevronRight className="text-2xl font-extralight" />}
                  activePage={filter?.page}
                  itemsCountPerPage={filter?.count}
                  totalItemsCount={totalCampervans}
                  pageRangeDisplayed={7}
                  onChange={handlePagination}
                  hideFirstLastPages={true}
                />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  )
}

export default GeoPage