import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import ImageSlider from "../../components/common/ImageSlider"
import { AiOutlineClose } from "react-icons/ai"
import environment from "../../environment"
import ImageSliderDirect from '../../components/common/ImageSliderDirect'

const CampervanImages = ({ images, brand, threeSixtyImage = "" }) => {
  let [isOpen, setIsOpen] = useState(false)

  const bannerImages = (index) => {
    let image = ''
    if (brand === 'jucy') image = images?.[index]
    else if (brand == 'spaceship') {
      if (images?.[index]) image = images?.[index]
      else image = ""
    }
    else {
      if (images?.[0]) image = environment.api + 'images/users/' + images?.[0]
      else image = `/assets/img/front/d${index + 1}.png`
    }
    return image
  }

  return (
    <>
      <div className="flex flex-col xl:flex-row gap-3 xl:gap-6">
        <div className="xl:max-w-[636px] min-[1400px]:max-w-[762px] w-full h-full">
          <div className="relative h-ful cursor-pointer" onClick={e => setIsOpen(true)}>
            <img src={bannerImages(0)} alt={bannerImages(0)} className="w-full h-[300px] md:h-[450px] xl:h-[513px] object-cover object-center rounded-3xl" />
            <div className="absolute top-0 w-full p-4 h-full bg-black/[6%] rounded-2xl flex items-end justify-end">
              {images?.length > 3 ?
                <p className="bg-secondary rounded-lg px-3 py-2 text-sm text-white cursor-pointer"><span>{images?.length - 3}+</span> Photos</p>
                : null}
            </div>
          </div>
        </div>
        {/* <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-1 md:gap-4">
            <div className="">
              {threeSixtyImage ?
                <iframe src={threeSixtyImage} className="h-[147px] w-full object-cover object-center rounded-3xl"></iframe>
                :
                <img src={bannerImages(1)} alt={bannerImages(1)} className="h-[147px] w-full object-cover object-center rounded-3xl" />
              }
            </div>
              <img src={bannerImages(2)} alt={bannerImages(2)} className="h-[147px] w-full object-cover object-center rounded-3xl" />
          </div> */}
        <div className="flex-1 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-1 gap-3 xl:gap-6">
          <div>
            {threeSixtyImage ? (
              <iframe
                src={threeSixtyImage}
                className="h-full w-full max-h-[150px] object-cover object-center rounded-xl"
              ></iframe>
            ) : (
              <img
                src={bannerImages(1)||bannerImages(0)}
                alt="Image 1"
                onClick={e => setIsOpen(true)}
                className="h-full max-h-[150px] w-full object-cover object-center rounded-xl cursor-pointer"
              />
            )}
          </div>
          {[2, 3 ].map((num) => (
            bannerImages(num) &&
            <img
              key={num}
              src={bannerImages(num)}
              alt={`Image ${num}`}
              onClick={e => setIsOpen(true)}
              className="h-full max-h-[150px] w-full object-cover object-center rounded-xl cursor-pointer"
            />
          ))}
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={e => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/80" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="px-3 sm:px-10 lg:px-0 flex min-h-full max-w-full items-center justify-center text-center relative">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden relative rounded-xl  text-left align-middle shadow-xl transition-all">

                  <div className='relative'>
                    {brand === 'jucy' ?
                      <ImageSliderDirect images={images} imageHeight='h-[300px] lg:h-[550px]' />
                      :
                      <ImageSlider images={images} imgHeight={550} />
                    }
                    <div className="absolute bg-white rouded-full  z-10 p-2 shadow-lg right-2 top-2 rounded-xl">
                      <AiOutlineClose onClick={e => setIsOpen(false)} size={20} className="cursor-pointer hover:text-red-500" />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default CampervanImages