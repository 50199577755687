import { useDispatch } from "react-redux";
import Layout from "../../components/global/layout";
import CampervanListing from "../Campervan/CampervanListing";
import { useEffect, useState } from "react";
import { SEARCH_STATE } from "../../Redux/Action/Action";
import loader from "../../methods/loader";

const CampervanList = () => {
  const dispatch = useDispatch()
  const [loaders, setLoaders] = useState(true)

  useEffect(() => {
    // To clear the search persist for the first time component call
    loader(true)
    let clearSearchFilters = { dropOffLocation: '', end_date: '', isDriverAge: false, pickUpLocation: '', start_date: '', type: '', isReturn: true, region: '', transmission: '', fuelType: '', vehicleAge: '', amenitieis: [], priceStart: '', priceEnd: '', vehiclecategoryid: "", vehiclecategorytypeid: "" }
    dispatch(SEARCH_STATE(clearSearchFilters))
    setTimeout(() => {
      setLoaders(false)
      loader(false)
    }, 1000);
  }, [])

  return (
    <>
      <Layout>
        {!loaders && <CampervanListing />}
      </Layout>
    </>
  );
};

export default CampervanList;