import PageLayout from "../../components/global/PageLayout";
import { Dialog, Transition } from '@headlessui/react'
import { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import CampervanImages from "./CampervanImages";
import FeaturesFacilities from "./FeaturesFacilities";
import { AiOutlineClose } from "react-icons/ai";
import PickupLocation from "./PickupLocation";
import Reviews from "./Reviews";
import Pricing from "./Pricing";
import methodModel from "../../methods/methods";
import Breadcrumb from "../../components/common/Breadcrumb";
import { IoHeartOutline, IoShareOutline } from "react-icons/io5";
import CampervanSlider from "../../components/common/CampervanSlider";
import StarRatings from 'react-star-ratings';
import environment from "../../environment";
import { useSelector } from "react-redux";
import { IoMdHeart } from "react-icons/io";
import WhyBookWithUs from "../../components/common/WhyBookWithUs";
import { BsArrowUpRight } from "react-icons/bs";
import moment from "moment";
import { useDispatch } from "react-redux";
import { SEARCH_STATE } from "../../Redux/Action/Action";
import { LiaShareAltSolid } from "react-icons/lia";
import { LuSaveAll } from "react-icons/lu";
import { TbBookmarks } from "react-icons/tb";

const CampervanDetail = () => {
  const { id, type } = useParams()
  const [detail, setdetail] = useState()
  const user = useSelector((state) => state.User)
  const search = useSelector((state) => state.Reducer.search)
  const jucyAmenitiesImages = useSelector((state) => state.Reducer.jucyImages)
  const dispatch = useDispatch()
  const [showFullDescription, setshowFullDescription] = useState(false)
  const [err, setErr] = useState(false)
  let [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (type == "jucy") {
      dispatch(SEARCH_STATE({ ...search, vehiclecategoryid: "" }))
    }
    if (id) getCampervanDetail()
  }, [])

  useEffect(() => {
    getCampervanDetail()
  }, [search])

  const getCampervanDetail = () => {
    loader(true)
    const pickUpLocationSiteCode = search?.pickUpLocation?.value || ''
    const dropOffLocationSiteCode = search?.dropOffLocation?.value || search?.pickUpLocation?.value || ''
    const pickUpDate = search?.start_date || ''
    const dropOfDate = search?.end_date || ''
    const step3Payload = {
      method: "step3",
      vehiclecategorytypeid: search?.vehiclecategorytypeid || "",
      pickuplocationid: search?.pickUpLocation?.spaceship_id || "",
      pickupdate: search?.start_date ? moment(search?.start_date).format("DD/MM/YYYY") : "", // Change date format to RCM date format
      pickuptime: "10:00", // Pass static time because user choose only date as dinesh sir said
      dropofflocationid: search?.dropOffLocation?.spaceship_id || search?.pickUpLocation?.spaceship_id || "",
      dropoffdate: search?.start_date ? moment(search?.end_date).format("DD/MM/YYYY") : "", // Change date format to RCM date format
      dropofftime: "10:00", // Pass static time because user choose only date as dinesh sir said
      ageid: search?.ageid?.value || "",
      vehiclecategoryid: search?.vehiclecategoryid || ""
    }
    if (pickUpLocationSiteCode && pickUpDate && dropOfDate) {
      ApiClient.post(`availabilityandprice/campervan?user_id=${user?.id || ''}&campervan_type=${type == 'admin' ? 'admin' : ''}&accountKey=${environment.agentAccountKey}&typeId=${'7d3dece7-ab86-e611-80e8-c4346bc5977c'}&pickUpLocation=${pickUpLocationSiteCode}&dropOffLocation=${dropOffLocationSiteCode}&pickUpDateTime=${pickUpDate}&dropOffDateTime=${dropOfDate}&driverAge=${23}&mergeSurchargesFees=${false}&id=${id}&type=${type == "spaceship" ? "rcm" : ""}`, step3Payload, '', true).then(res => {
        if (res.success) {
          if (type == "spaceship") {
            // Add user select dates in RCM response because RCM not provide the campervan start & end date
            setdetail({ ...res?.data, start_date: search?.start_date, end_date: search?.end_date, extraItems: res?.data?.extraItems_details?.map((itm) => itm?.name) })
            if (res?.data?.defaultRate === 0 || !res?.data?.defaultRate) {
              setErr("Campervan is not available for this locations and dates")
              setTimeout(() => {
                setErr(false)
              }, 10000);
            }else{
              setErr(false)
            }
          } else {
            let response = res?.data?.campervan_details
            response = {
              ...response,
              Total: res?.data?.Total,
              DiscountPercentageApplied: res?.data?.DiscountPercentageApplied,
              DailyRate: res?.data?.DailyRate,
              CategoryCode: res?.data?.CategoryCode,
              Availability: res?.data?.Availability,
              RentalDays: res?.data?.RentalDays,
              TypeSlug: res?.data?.TypeSlug
            }
            let threeSixtyImage = ""
            let jucyImage = response?.gallery?.filter((item => {
              if (item?.type == "Image") return item
              else threeSixtyImage = item?.original
            }))
            let jucyAmenitiesDetails = response?.keyFeatures?.map((item) => {
              return ({ image: "", name: item?.title })
            })
            setdetail({
              ...response, images: jucyImage?.map((item) => item?.original),
              amenities_details: jucyAmenitiesDetails, terms: response?.specificationsDescription, start_date: search?.start_date || response?.start_date,
              end_date: search?.end_date || response?.end_date, threeSixtyImage: threeSixtyImage
            })
            setErr(false)
          }
          loader(false)
        } else {
          setErr(res?.error?.message)
          ApiClient.get(`Campervan?id=${id}&user_id=${user?.id || ''}`).then(res => {
            if (res.success) {
              if (res?.data?.campervan_type == 'jucy') {
                let threeSixtyImage = ""
                let jucyImage = res?.data?.gallery?.filter((item => {
                  if (item?.type == "Image") return item
                  else threeSixtyImage = item?.original
                }))
                let jucyAmenitiesDetails = res?.data?.keyFeatures?.map((item) => {
                  return ({ image: "", name: item?.title })
                })
                setdetail({
                  ...res?.data, images: jucyImage?.map((item) => item?.original),
                  amenities_details: jucyAmenitiesDetails, terms: res?.data?.specificationsDescription, start_date: search?.start_date || res?.data?.start_date,
                  end_date: search?.end_date || res?.data?.end_date, threeSixtyImage: threeSixtyImage
                })
              } else {
                setdetail({ ...res?.data, start_date: search?.start_date || res?.data?.start_date, end_date: search?.end_date || res?.data?.end_date })
              }
            }
            loader(false)
          })
          setTimeout(() => {
            setErr(false)
          }, 10000);
        }
      })
    } else {
      ApiClient.get(`Campervan?id=${id}&user_id=${user?.id || ''}`).then(res => {
        if (res.success) {
          if (res?.data?.campervan_type == 'jucy') {
            let threeSixtyImage = ""
            let jucyImage = res?.data?.gallery?.filter((item => {
              if (item?.type == "Image") return item
              else threeSixtyImage = item?.original
            }))
            let jucyAmenitiesDetails = res?.data?.keyFeatures?.map((item) => {
              return ({ image: "", name: item?.title })
            })
            setdetail({
              ...res?.data, images: jucyImage?.map((item) => item?.original), beds: res?.data?.sleepDescription,
              amenities_details: jucyAmenitiesDetails, terms: res?.data?.specificationsDescription, threeSixtyImage: threeSixtyImage
            })
          } else {
            setdetail(res?.data)
          }
        }
        loader(false)
      })
    }
  }

  const handleFavoriteUnFavorite = () => {
    let token = localStorage.getItem('token')
    if (!token) navigate('/login')
    ApiClient.post(`favourite`, { campervan_id: id }).then(res => {
      if (res.success) {
        getCampervanDetail()
      }
    })
  }

  // Check the jucy image based on the name if exists return the image
  const checkJucyAmenitiesImage = (name) => {
    let data = jucyAmenitiesImages && jucyAmenitiesImages?.find((item, index) => {
      if (item?.name == name) {
        return item
      }
    })
    return data?.image
  }

  return (
    <>
      <PageLayout>
        <div className="max-w-[1504px] w-full mx-auto px-4 lg:px-8">
          <div className="my-4 xl:mt-10 lg:mb-6">
            <Breadcrumb
              links={[
                { link: "/", name: "Home" },
                { link: "/campervans", name: "Campervans" },
              ]}
              currentPage={detail?.campervan_name}
            />
          </div>
          <div className="flex max-sm:flex-wrap gap-3 sm:gap-2 items-center justify-between mb-4 xl:mb-8">
            <div className="checkings_data ">
              <div className="flex gap-3 lg:gap-8 items-center flex-wrap">
                <h2 className="font-medium text-secondary text-lg lg:text-3xl 2xl:text-4xl capitalize">{detail?.campervan_name}</h2>
                <div class="flex items-center">
                  <span className="text-sm font-normal text-gray-light mr-1 block">Ratings:</span>
                  <StarRatings
                    rating={4.5}
                    starSpacing="4px"
                    starRatedColor="#FEB602"
                    starHoverColor="#FEB602"
                    starDimension="16"
                    numberOfStars={5}
                    name='rating'
                  />
                  <span className="ml-5 block text-sm font-normal text-secondary">(4.8)</span>
                </div>
              </div>
            </div>
            <ul className="flex items-center gap-3 ml-auto">
              <li className="bg-[#F6F7FB] p-2.5 rounded-lg cursor-pointer group hover:text-primary" onClick={e => handleFavoriteUnFavorite()}>
                {/* Save */}
                {detail?.isFavourite ?
                  <IoMdHeart size={20} className="text-primary" />
                  :
                  <IoHeartOutline size={20} className="text-secondary  group-hover:text-primary" />
                }
              </li>
              <li className="bg-[#F6F7FB] px-5 py-2.5 rounded-lg flex items-center gap-2 text-sm font-normal text-secondary cursor-pointer group hover:text-primary">
                <LiaShareAltSolid size={20} className="text-secondary group-hover:text-primary" />
                Share
              </li>
              <li className="bg-[#F6F7FB] p-2.5 rounded-lg cursor-pointer group hover:text-primary">
                <TbBookmarks size={20}  className=" font-thin text-secondary group-hover:text-primary" />
              </li>
            </ul>
          </div>

          <div className="flex flex-col xl:flex-row gap-4 min-[1400px]:gap-12">
            <div className="xl:max-w-[900px] min-[1400px]:max-w-[1012px] w-full" >
              <CampervanImages images={detail?.images} brand={detail?.campervan_type} threeSixtyImage={detail?.threeSixtyImage} />

              <div className="flex flex-col mt-5 w-full">
                <div className="xl:max-w-[776px] w-full">
                  <div className="flex items-end justify-between max-xl:flex-wrap gap-4 mb-3 lg:mb-5">
                    {detail?.amenities_details?.slice(0, 6)?.map((item, idx) => (
                      <div className="flex flex-col max-lg:items-center max-w-[120px] w-full gap-1 items-center" key={idx}>
                        {detail?.campervan_type != "admin" ?
                          <>
                            {checkJucyAmenitiesImage(item?.name) && <img src={methodModel.campervanImage(checkJucyAmenitiesImage(item?.name))} className="size-8" />}
                          </>
                          :
                          <>
                            {item?.image ? <img className="h-10 w-10 object-contain" src={methodModel.campervanImage(item?.image)} alt={item?.image_alt_name} /> : null}
                          </>
                        }
                        <p className="2xl:whitespace-nowrap text-xs text-gray-light text-center capitalize line-clamp-2">{item?.name}</p>
                      </div>
                    ))}
                  </div>
                  <div className="erorr_msg rounded-lg bg-[#EEFBF4] mb-3 lg:mb-6 flex items-center gap-2 px-5 py-2.5 ">
                    <img src="/assets/img/front/shield-check.svg" alt="shield-check.svg" width={20} height={20} className="size-5" />
                    <h6 className="text-[#00DD80] text-sm sm:text-base font-medium">All vehicles are fitted with GPS tracker for your safety.</h6>
                  </div>
                </div>
                <div className="flex flex-col mb-4 xl:mb-12">
                  <h4 className="font-semibold text-secondary text-lg lg:text-xl xl:text-2xl mb-3">Description</h4>
                    <p className={`text-gray-light text-base font-normal   ${showFullDescription ? '' : 'line-clamp-4'}`} dangerouslySetInnerHTML={{ __html: detail?.description }}></p>
                    <button type="button" className="w-fit underline underline-offset-4 inline-block text-primary text-base cursor-pointer font-medium"><span className="cursor-pointer" onClick={e => setshowFullDescription(!showFullDescription)}>Show {showFullDescription ? 'Less' : 'More'}</span></button>
                </div>
                <FeaturesFacilities detail={detail} />
                {detail?.terms ?
                  <div className="icons_ilustion mb-4 xl:mb-12">
                    <div className="flex items-center gap-6">
                      <h2 className="font-semibold text-secondary text-lg lg:text-xl xl:text-2xl mb-3">Terms & Cancellation Policy</h2>
                      <div className="">
                      </div>
                    </div>
                    <div className="flex item-center gap-0.5">
                      <p className="font-normal text-gray-light text-base line-clamp-4 inline-block" dangerouslySetInnerHTML={{ __html: detail?.terms }}></p>
                      <button onClick={e => setIsOpen(true)} className="outline-none underline text-primary text-base font-medium flex items-center gap-x-2.5 rounded-full hover:underline transition-all">
                        Read Full Terms
                        {/* <BsArrowUpRight size={16} /> */}
                      </button>
                    </div>
                  </div>
                  : null}
                <Reviews />
              </div>
            </div>
            <div className="flex-1 w-full" >
              <div class="grid sm:grid-cols-2 xl:grid-cols-1 gap-2.5 lg:gap-5">
                <Pricing detail={detail} err={err} />
                <WhyBookWithUs classes="!mt-0 lg:mt-0" />
                <PickupLocation detail={detail} />
              </div>
            </div>
          </div>
        </div>
          <div className="bg-[#F6F7FB] py-7 lg:py-14 border-b border-gray-border">
            <div className="max-w-[1504px] w-full mx-auto px-4 lg:px-8">
             <h2 className="font-medium text-secondary text-lg lg:text-xl xl:text-3xl 2xl:text-4xl mb-4 lg:mb-9">Discover your best Campervan</h2>
              <CampervanSlider />
            </div>
          </div>
      </PageLayout>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={e => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full max-w-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between">
                    <p className="text-lg lg:text-xl xl:text-2xl font-bold text-secondary">Terms & Cancellation Policy</p>
                    <AiOutlineClose onClick={e => setIsOpen(false)} size={20} className="cursor-pointer" />
                  </div>
                  <div className="mt-4" dangerouslySetInnerHTML={{ __html: detail?.terms }}></div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default CampervanDetail