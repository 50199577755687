let host = document.location.host;
let apiUrl = process.env.REACT_APP_API_URL

const environment = {
  api: apiUrl,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
  siteKey: process.env.REACT_APP_SITE_KEY,
  siteSecret: process.env.REACT_APP_SITE_SECRET,
  agentAccountKey: process.env.REACT_APP_AGENT_ACCOUNT_KEY,
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
  AU_SPACESHIPS_API_KEY: process.env.REACT_APP_AU_SPACESHIPS_API_KEY,
  AU_SPACESHIPS_SHARED_SECRET: process.env.REACT_APP_AU_SPACESHIPS_SHARED_SECRET,
};

export default environment;
