import { useSelector } from "react-redux"
import Layout from "../../components/global/layout"
import { Chart } from "react-google-charts";
import { Card } from "antd";
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Dashboard = () => {
    const user = useSelector((state) => state.User)
    const navigate = useNavigate()
    const [cardData, setCardData] = useState()
    const [bookings, setBookings] = useState([])
    const [subAgentsCustomer, setSubAgentsCustomer] = useState([])

    useEffect(() => {
        getCardData()
        getBookings()
        getSubAgentsCustomers()
    }, [])

    // It check the month and return in alphabets
    const findMonth = (month) => {
        if (month === 1) return "Jan"
        if (month === 2) return "Feb"
        if (month === 3) return "Mar"
        if (month === 4) return "Apr"
        if (month === 5) return "May"
        if (month === 6) return "Jun"
        if (month === 7) return "Jul"
        if (month === 8) return "Aug"
        if (month === 9) return "Sep"
        if (month === 10) return "Oct"
        if (month === 11) return "Nov"
        if (month === 12) return "Dec"
    }

    // Get the cards data and store in setCardData state
    const getCardData = () => {
        ApiClient.get(`front/dashboard/count?agent_id=${user?.id || user?._id}`).then(res => {
            if (res.success) {
                setCardData(res?.data)
            }
        })
    }

    // Get the all bookings detail and set in bookings state
    const getBookings = () => {
        ApiClient.get(`agent/graph/bookings?type=daily&agent_id=${user?.id || user?._id}`).then(res => {
            if (res?.TotalBookings?.length > 0) setBookings(res?.TotalBookings)
            else setBookings([{ condition: { day: moment(new Date()).format("DD"), date_month: Number(moment(new Date()).format("M")), year: moment(new Date()).format("YYYY") }, completed: 0, pending: 0, rejected: 0 }])
        })
    }

    // Get the all sub-agent and customer detail and set in subAgentsCustomer state
    const getSubAgentsCustomers = () => {
        ApiClient.get(`graph/customer/subagent?type=daily&agent_id=${user?.id || user?._id}`).then(res => {
            if (res?.data?.length > 0) setSubAgentsCustomer(res?.data)
            else setSubAgentsCustomer([{ id: { day: moment(new Date()).format("DD"), date_month: Number(moment(new Date()).format("M")), year: moment(new Date()).format("YYYY") }, subagent: 0, customer: 0 }])
        })
    }

    // Line Chart x is the limit, success is the complete bookings, pending is the booking those payment is pending and last one is cancel bookings
    const lineChartData = [
        ["x", "Success", "Pending", "Failed"],
        ...bookings && bookings?.map((item, index) => {
            return ([`${item?.condition?.day} ${findMonth(item?.condition?.date_month)} ${item?.condition?.year}`, item?.completed, item?.pending, item?.rejected])
        })
    ]
    const lineChartOptions = {
        series: {
            0: { curveType: "function" },
            1: { curveType: "function" },
        },
        legend: { position: "bottom" },
    }

    // Column chart sub-agent and customer data
    const columnChartData = [
        ["Name", "Sub Agent", "Customer"],
        ...subAgentsCustomer && subAgentsCustomer?.map((item, index) => {
            return ([`${item?.id?.day} ${findMonth(item?.id?.date_month)} ${item?.id?.year}`, item?.subagent, item?.customer])
        })
    ]
    const columnChartOptions = {
        title: "",
        legend: { position: "top" },
        animation: {
            startup: true,
            easing: "linear",
            duration: 1500
        }
    }

    // Pie Chart Data is the label and Count is the total number
    const pieChartData = [
        ["Data", "Counts"],
        ["Sub Agents", cardData?.getAllSubAgents || 0],
        ["Customers", cardData?.getAllCustomer || 0],
        ["Bookings", cardData?.getAllBookings || 0],
    ]
    const pieChartOptions = {
        title: "",
        is3D: true,
        legend: 'bottom',
    }

    return <>
        < Layout >
            <h2 className="text-xl font-bold tracking-tight text-gray-900 !capitalize mb-6"><span className="text-3xl text-blue-500 mr-1">Hi</span> {user?.fullName || user?.firstName}</h2>
            {/* Cards Section */}
            <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4">
                <div className="">
                    <Card className="hover:shadow-lg transition duration-150 ease-in-out card-border border-[#B3B3B3] cursor-pointer user-select-none" role="presentation" onClick={e => { navigate('/bookings') }}>
                        <div className="card-body">
                            <div className="flex justify-between items-center">
                                <div className="">
                                    <h5 className="text-sm text-gray-600">Total Bookings</h5>
                                    <p className=" text-2xl font-bold text-black">{cardData?.getAllBookings || 0}</p>
                                </div>
                                <div className="bg-gray-100 p-2 rounded-lg shadow">
                                    <img className="w-10 " src="assets/img/camper/make.png" />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="">
                    <Card className="hover:shadow-lg transition duration-150 ease-in-out card-border border-[#B3B3B3] cursor-pointer user-select-none" role="presentation" onClick={e => { navigate('/bookings') }}>
                        <div className="card-body">
                            <div className="flex justify-between items-center">
                                <div className="">
                                    <h5 className="text-sm text-gray-600">Completed Bookings</h5>
                                    <p className=" text-2xl font-bold text-black">{cardData?.successfulBooking || 0}</p>
                                </div>
                                <div className="bg-gray-100 p-2 rounded-lg shadow">
                                    <img className="w-10 " src="assets/img/camper/make.png" />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="">
                    <Card className="hover:shadow-lg transition duration-150 ease-in-out card-border border-[#B3B3B3] cursor-pointer user-select-none" role="presentation" onClick={e => { navigate('/bookings') }}>
                        <div className="card-body">
                            <div className="flex justify-between items-center">
                                <div className="">
                                    <h5 className="text-sm text-gray-600">Pending Bookings</h5>
                                    <p className=" text-2xl font-bold text-black">{cardData?.pendingBooking || 0}</p>
                                </div>
                                <div className="bg-gray-100 p-2 rounded-lg shadow">
                                    <img className="w-10 " src="assets/img/camper/make.png" />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="">
                    <Card className="hover:shadow-lg transition duration-150 ease-in-out card-border border-[#B3B3B3] cursor-pointer user-select-none" role="presentation" onClick={e => { navigate('/customer') }}>
                        <div className="card-body">
                            <div className="flex justify-between items-center">
                                <div className="">
                                    <h5 className="text-sm text-gray-600">Total Customer</h5>
                                    <p className=" text-2xl font-bold text-black">{cardData?.getAllCustomer || 0}</p>
                                </div>
                                <div className="bg-gray-100 p-2 rounded-lg shadow">
                                    <img className="w-10 " src="assets/img/camper/userss.png " />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="">
                    <Card className="hover:shadow-lg transition duration-150 ease-in-out card-border border-[#B3B3B3] cursor-pointer user-select-none" role="presentation" onClick={e => { navigate('/subagent') }}>
                        <div className="card-body">
                            <div className="flex justify-between items-center">
                                <div className="">
                                    <h5 className="text-sm text-gray-600">Total Sub Agents</h5>
                                    <p className=" text-2xl font-bold text-black">{cardData?.getAllSubAgents || 0}</p>
                                </div>
                                <div className="bg-gray-100 p-2 rounded-lg shadow">
                                    <img className="w-10 " src="assets/img/camper/agents.png " />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="">
                    <Card className="hover:shadow-lg transition duration-150 ease-in-out card-border border-[#B3B3B3] cursor-pointer user-select-none" role="presentation" onClick={e => { navigate('/campervanlist') }}>
                        <div className="card-body">
                            <div className="flex justify-between items-center">
                                <div className="">
                                    <h5 className="text-sm text-gray-600">Total Campervans</h5>
                                    <p className=" text-2xl font-bold text-black">{cardData?.getAllCampervan || 0}</p>
                                </div>
                                <div className="bg-gray-100 p-2 rounded-lg shadow">
                                    <img className="w-10 " src="assets/img/camper/van.png " />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            {/* Charts Section */}
            {/* Bookings Line Chart */}
            <div className="border border-gray-200 shadow rounded-md mt-8">
                <h2 className="text-lg p-2 bg-gray-200 font-semibold">Bookings</h2>
                <div className="">
                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={lineChartData}
                        options={lineChartOptions}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 mt-6">
                {/* Sub-agent & Customers Line Chart */}
                <div className="border border-gray-200 shadow rounded-md">
                    <h2 className="text-lg p-2 bg-gray-200 font-semibold">Sub Agents & Customers</h2>
                    <div className="">
                        <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="400px"
                            data={columnChartData}
                            options={columnChartOptions}
                        />
                    </div>
                </div>
                {/* Pie Chart */}
                <div className="border border-gray-200 shadow rounded-md">
                    <h2 className="text-lg p-2 bg-gray-200 font-semibold">Sub Agents, Customers & Bookings</h2>
                    <div className="">
                        <Chart
                            chartType="PieChart"
                            data={pieChartData}
                            options={pieChartOptions}
                            width={"100%"}
                            height="400px"
                        />
                    </div>
                </div>
            </div>
        </Layout >
    </>
}

export default Dashboard