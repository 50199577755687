import React, { Dispatch, SetStateAction } from 'react'

import { searchFilterIF } from '.';

interface PeopleCouterProps {
  peopleCount: number;
  setSearchFilter: Dispatch<SetStateAction<searchFilterIF>>;
}
const PeopleCounter: React.FC<PeopleCouterProps> = ({ peopleCount, setSearchFilter }) => {

  const handleIncrement = () => {
    setSearchFilter((prev) => ({
      ...prev,
      peopleCount: prev.peopleCount + 1,
    }));
  };
  const handleDecrement = () => {
    setSearchFilter((prev) => ({
      ...prev,
      peopleCount: prev.peopleCount > 0 ? prev.peopleCount - 1 : 0,
    }));
  }
  return (
    <div className="mb-6">
      <label className="text-sm/4 font-medium text-secondary inline-block mb-3">Number Of People</label>
      <div className="flex items-center gap-6 border border-[#8D8D8D] rounded-full py-2 px-3.5 w-fit">
        <button className="size-6 flex items-center justify-center outline-none" onClick={handleIncrement} aria-label="add-btn">
          <img src="assets/img/front/add-circle.svg" alt="" className="size-6" width={24} height={24} />
        </button>
        <span className="text-sm/4 text-[#8D8D8D]">{peopleCount}</span>
        <button className="size-6 flex items-center justify-center outline-none" onClick={handleDecrement} aria-label="minus-btn">
          <img src="assets/img/front/minus-circle.svg" alt="" className="size-5" width={20} height={20} />
        </button>
      </div>
    </div>
  )
}

export default PeopleCounter