import { useEffect, useState } from "react"
import PageLayout from "../../components/global/PageLayout"
import ApiClient from "../../methods/api/apiClient"

const AgentTermsAndConditions = () => {
    const [data, setdata] = useState()

    useEffect(()=>{
        getData()
    },[])

    const getData=()=>{
        ApiClient.get(`content?title=agent_term_condition`).then(res=>{
            if(res.success){
                setdata(res?.data?.description)
            }
        })
    }

    return (
        <>
            <PageLayout>
                <div className="mx-16 mt-12" dangerouslySetInnerHTML={{ __html: data }}></div>
            </PageLayout>
        </>
    )
}

export default AgentTermsAndConditions