import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PhoneInput from 'react-phone-input-2'
import moment from "moment";
import InputSelect from "../../components/common/InputSelect";
import Select from "react-select";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { SEARCH_STATE } from "../../Redux/Action/Action";
import Pricing from "./Pricing";
import ApiClient from "../../methods/api/apiClient";
import { useNavigate, useParams } from "react-router-dom";
import loader from "../../methods/loader";
import environment from "../../environment";
import { IoIosArrowRoundForward } from "react-icons/io";
import methodModel from "../../methods/methods";

const CartPage = ({ tab, settab, user, form, setForm, quoteDetails, setQuoteDetails, termsConditions, setTermsConditions, error, setError, couponCode, setCouponCode, allCoupons, discountApplied, campervanDetails, getCampervanDetails, insurance, extraItems, setExtraItems, selectedInsurance, setSelectedInsurance, findTotalAddOns, totalCampervanPrice, findAgentorCustomer }) => {
  const { id, bookingId } = useParams()
  const search = useSelector((state) => state.Reducer.search)
  const dispatch = useDispatch()
  const [showAllExtraItems, setShowAllExtraItems] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(1)
  const [customers, setCustomers] = useState([])
  const navigate = useNavigate()

  const ageArray = [
    { key: 18, name: '18' },
    { key: 19, name: '19' },
    { key: 20, name: '20' },
    { key: 21, name: '21' },
    { key: 22, name: '22' },
    { key: 23, name: '23' },
    { key: 24, name: '24' },
    { key: 25, name: '25' }
  ]
  const licenseIssueCountryArray = [
    { key: 'Australia', name: 'Australia' },
    { key: 'Canada', name: 'Canada' },
    { key: 'USA', name: 'USA' }
  ]
  const quantityOptions = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
  ]

  const timeOption = [
    { value: "12:00 AM", label: "12:00 AM" },
    { value: "12:15 AM", label: "12:15 AM" },
    { value: "12:30 AM", label: "12:30 AM" },
    { value: "12:45 AM", label: "12:45 AM" },
    { value: "01:00 AM", label: "01:00 AM" },
    { value: "01:15 AM", label: "01:15 AM" },
    { value: "01:30 AM", label: "01:30 AM" },
    { value: "01:45 AM", label: "01:45 AM" },
    { value: "02:00 AM", label: "02:00 AM" },
    { value: "02:15 AM", label: "02:15 AM" },
    { value: "02:30 AM", label: "02:30 AM" },
    { value: "02:45 AM", label: "02:45 AM" },
    { value: "03:00 AM", label: "03:00 AM" },
    { value: "03:15 AM", label: "03:15 AM" },
    { value: "03:30 AM", label: "03:30 AM" },
    { value: "03:45 AM", label: "03:45 AM" },
    { value: "04:00 AM", label: "04:00 AM" },
    { value: "04:15 AM", label: "04:15 AM" },
    { value: "04:30 AM", label: "04:30 AM" },
    { value: "04:45 AM", label: "04:45 AM" },
    { value: "05:00 AM", label: "05:00 AM" },
    { value: "05:15 AM", label: "05:15 AM" },
    { value: "05:30 AM", label: "05:30 AM" },
    { value: "05:45 AM", label: "05:45 AM" },
    { value: "06:00 AM", label: "06:00 AM" },
    { value: "06:15 AM", label: "06:15 AM" },
    { value: "06:30 AM", label: "06:30 AM" },
    { value: "06:45 AM", label: "06:45 AM" },
    { value: "07:00 AM", label: "07:00 AM" },
    { value: "07:15 AM", label: "07:15 AM" },
    { value: "07:30 AM", label: "07:30 AM" },
    { value: "07:45 AM", label: "07:45 AM" },
    { value: "08:00 AM", label: "08:00 AM" },
    { value: "08:15 AM", label: "08:15 AM" },
    { value: "08:30 AM", label: "08:30 AM" },
    { value: "08:45 AM", label: "08:45 AM" },
    { value: "09:00 AM", label: "09:00 AM" },
    { value: "09:15 AM", label: "09:15 AM" },
    { value: "09:30 AM", label: "09:30 AM" },
    { value: "09:45 AM", label: "09:45 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "10:15 AM", label: "10:15 AM" },
    { value: "10:30 AM", label: "10:30 AM" },
    { value: "10:45 AM", label: "10:45 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "11:15 AM", label: "11:15 AM" },
    { value: "11:30 AM", label: "11:30 AM" },
    { value: "11:45 AM", label: "11:45 AM" },
    { value: "12:00 PM", label: "12:00 PM" },
    { value: "12:15 PM", label: "12:15 PM" },
    { value: "12:30 PM", label: "12:30 PM" },
    { value: "12:45 PM", label: "12:45 PM" },
    { value: "01:00 PM", label: "01:00 PM" },
    { value: "01:15 PM", label: "01:15 PM" },
    { value: "01:30 PM", label: "01:30 PM" },
    { value: "01:45 PM", label: "01:45 PM" },
    { value: "02:00 PM", label: "02:00 PM" },
    { value: "02:15 PM", label: "02:15 PM" },
    { value: "02:30 PM", label: "02:30 PM" },
    { value: "02:45 PM", label: "02:45 PM" },
    { value: "03:00 PM", label: "03:00 PM" },
    { value: "03:15 PM", label: "03:15 PM" },
    { value: "03:30 PM", label: "03:30 PM" },
    { value: "03:45 PM", label: "03:45 PM" },
    { value: "04:00 PM", label: "04:00 PM" },
    { value: "04:15 PM", label: "04:15 PM" },
    { value: "04:30 PM", label: "04:30 PM" },
    { value: "04:45 PM", label: "04:45 PM" },
    { value: "05:00 PM", label: "05:00 PM" },
    { value: "05:15 PM", label: "05:15 PM" },
    { value: "05:30 PM", label: "05:30 PM" },
    { value: "05:45 PM", label: "05:45 PM" },
    { value: "06:00 PM", label: "06:00 PM" },
    { value: "06:15 PM", label: "06:15 PM" },
    { value: "06:30 PM", label: "06:30 PM" },
    { value: "06:45 PM", label: "06:45 PM" },
    { value: "07:00 PM", label: "07:00 PM" },
    { value: "07:15 PM", label: "07:15 PM" },
    { value: "07:30 PM", label: "07:30 PM" },
    { value: "07:45 PM", label: "07:45 PM" },
    { value: "08:00 PM", label: "08:00 PM" },
    { value: "08:15 PM", label: "08:15 PM" },
    { value: "08:30 PM", label: "08:30 PM" },
    { value: "08:45 PM", label: "08:45 PM" },
    { value: "09:00 PM", label: "09:00 PM" },
    { value: "09:15 PM", label: "09:15 PM" },
    { value: "09:30 PM", label: "09:30 PM" },
    { value: "09:45 PM", label: "09:45 PM" },
    { value: "10:00 PM", label: "10:00 PM" },
    { value: "10:15 PM", label: "10:15 PM" },
    { value: "10:30 PM", label: "10:30 PM" },
    { value: "10:45 PM", label: "10:45 PM" },
    { value: "11:00 PM", label: "11:00 PM" },
    { value: "11:15 PM", label: "11:15 PM" },
    { value: "11:30 PM", label: "11:30 PM" },
    { value: "11:45 PM", label: "11:45 PM" },
  ]

  useEffect(() => {
    if (findAgentorCustomer()) {
      getCustomers()
    }
  }, [])

  const getCustomers = () => {
    ApiClient.get(`users/list`, { status: "active", agent_id: user?.id || user?._id, role: "customer" }).then(res => {
      if (res.success) {
        setCustomers(res?.data?.map((item) => {
          return ({ ...item, value: item?.id || item?._id, label: item?.firstName + "/" + item?.email })
        }))
      }
    })
  }

  // It calculate the days with the help of start and end date and return the total number of days
  const calculateDays = (startDate, endDate) => {
    if (endDate < startDate) {
      return 0
    }
    const diffInMilliseconds = Math.abs(endDate - startDate)
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24)
    return Math.ceil(diffInDays)
  }

  // Handle the booking form details and hit create quote api first
  const handleFirstTab = (customer_Id = "") => {
    loader(true)
    //Create Quote For Jucy
    if (campervanDetails?.campervan_type == 'jucy') {
      // Payload for Create Quote api
      let payload = {
        firstName: form?.firstName,
        lastName: form?.lastName,
        email: form?.email,
        mobileNo: form?.mobileNo,
        driverAge: form?.driverAge,
        licenseIssueCountry: form?.licenseIssueCountry,
        pickUpLocation: JSON.stringify(search?.pickUpLocation),
        dropOffLocation: JSON.stringify(search?.dropOffLocation || search?.pickUpLocation),
        availableVehicle: JSON.stringify({ value: campervanDetails?.CategoryCode, label: campervanDetails?.Name }),
        categoryCode: campervanDetails?.CategoryCode,
        pickUpLocationCode: search?.pickUpLocation?.value,
        dropOffLocationCode: search?.dropOffLocation?.value || search?.pickUpLocation?.value,
        pickUpDateTime: search?.start_date || '',
        dropOffDateTime: search?.end_date || '',
        total_days: campervanDetails?.RentalDays,
        campervan_id: id,
        campervan_type: campervanDetails?.campervan_type,
        campervan_price: totalCampervanPrice(),
        accountKey: environment?.agentAccountKey,
        campervanPrice: campervanDetails?.Total?.Value || totalCampervanPrice('campervanOnly'),
        taxesAndFees: campervanDetails?.serviceFees || 0,
        insurance: JSON.stringify(selectedInsurance),
        addOns: JSON.stringify(extraItems),
        securityDeposit: campervanDetails?.security_deposite || 0,
        bondDetails: selectedInsurance?.Excess?.Value,
        role: user?.role || "customer",
        bookingId: bookingId || ""
      }
      // If user login then it add customer_id in payload
      if (customer_Id || user?.role == "customer" || methodModel.getPrams("customerId")) {
        payload = { ...payload, customer_id: customer_Id || user?.id || user?._id || methodModel.getPrams("customerId") }
      }
      if (methodModel.getPrams("agentId") && methodModel.getPrams("agentName") || user?.role == "agent") {
        payload = { ...payload, agentName: methodModel.getPrams("agentName") || user?.fullName || user?.firstName, agent_id: methodModel.getPrams("agentId") || user?.id || user?._id }
      }
      if (user?.role == "sub_agent") {
        payload = { ...payload, agent_id: user?.agent_id, sub_agent_id: user?._id || user?.id }
      }
      ApiClient.post(`Createquote`, payload, "", true).then(res => {
        if (res?.success) {
          // It store the extra items & insurance code and quantity for secondaryProductsAndInsuranceMap key
          let updateQuoteData = {}
          updateQuoteData = { ...updateQuoteData, [selectedInsurance?.ProductCode]: "1" }
          extraItems && extraItems?.map((itm, i) => {
            itm?.Items?.map((item, index) => {
              if (item?.Qty > 0) {
                updateQuoteData = { ...updateQuoteData, [item?.ProductCode]: String(item?.Qty) }
              }
            })
          })
          // Payload for Update Quote Api
          const secondaryProductsAndInsuranceMap = JSON.stringify(updateQuoteData)
          // At the time of update quote in the response QuoteId come in the jucy_create_qouteId key instead of jucy_create_qoute object so we add check behalf of bookingId
          const quoteId = bookingId ? res?.data?.jucy_create_qouteId :res?.data?.jucy_create_qoute?.QuoteId
          // Update Quote Api for update extra items and insurance
          ApiClient.thirdPartyPost(`https://lanier.test.jucy.cloud/api/Account/${environment?.agentAccountKey}/2.0/UpdateQuote?quoteId=${quoteId}&secondaryProductsAndInsuranceMap=${secondaryProductsAndInsuranceMap}&languageCode=EN`, {}, "", true).then(response => {
            if (response.ResponseType == "Success") {
              setQuoteDetails(response?.Data)
              if (findAgentorCustomer()) {
                navigate("/bookings")
              } else {
                if (paymentMethod == 1) stripeRedirection(res?.data?.id || res?.data?._id, response?.Data)
                else paypalRedirection(res?.data?.id || res?.data?._id, response?.Data)
              }
            } else {
              loader(false)
            }
          })
        } else {
          loader(false)
        }
      })
    } else {
      // For Other Campervans Need To Add The Create Quote Api Also
      if (campervanDetails?.campervan_type == "spaceship") {
        let extraItemsArray = []
        extraItems && extraItems?.map((item) => {
          item?.Items?.map((itm) => {
            if (itm?.Qty > 0) {
              extraItemsArray.push({ id: itm?.id, qty: itm?.Qty })
            }
          })
        })
        const calTotalPayload = {
          method: "calctotal",
          pickuplocationid: search?.pickUpLocation?.spaceship_id || "",
          pickupdate: search?.start_date ? moment(search?.start_date).format("DD/MM/YYYY") : "", // Change date format to RCM date format
          vehiclecategoryid: search?.vehiclecategoryid || "",
          numberofdays: calculateDays(new Date(search?.start_date), new Date(search?.end_date)),
          totalrateafterdiscount: campervanDetails?.totalrateafterdiscount,
          freedaysamount: campervanDetails?.freedaysamount,
          insuranceid: selectedInsurance?.insuranceid || "",
          extrakmsid: 0, // Static for testing
          mandatoryfees: campervanDetails?.mandatoryfees?.map((item) => {
            return ({ id: item?.id, qty: item?.qty })
          }),
          optionalfees: extraItemsArray,
        }
        ApiClient.post(`calTotal`, calTotalPayload).then(res => {
          if (res.success) {
            let bookingPayload = {
              method: "booking",
              vehiclecategorytypeid: search?.vehiclecategorytypeid || "",
              pickuplocationid: search?.pickUpLocation?.spaceship_id,
              pickupdate: moment(form?.pickUpDate?.startDate).format("DD/MM/YYYY"), // Change date format into RCM date format
              pickuptime: "10:00", // Pass static time because user choose only date as dinesh sir said
              dropofflocationid: search?.dropOffLocation?.spaceship_id || search?.pickUpLocation?.spaceship_id,
              dropoffdate: moment(form?.dropOffDate?.endDate).format("DD/MM/YYYY"), // Change date format into RCM date format
              dropofftime: "10:00", // Pass static time because user choose only date as dinesh sir said
              ageid: search?.ageid?.value || "",
              vehiclecategoryid: search?.vehiclecategoryid || "",
              bookingtype: 2, // Booking type (1 = quote, 2 = booking)
              insuranceid: selectedInsurance?.insuranceid || "",
              extrakmsid: 0, // Static for testing
              transmission: 1,// Transmission preference: 0 = no preference, 1 = auto, 2 = manual
              campervan_id: id,
              total_days: calculateDays(new Date(search?.start_date), new Date(search?.end_date)),
              campervan_price: totalCampervanPrice(),
              pickUpLocation: search?.pickUpLocation?.label || "",
              dropOffLocation: search?.dropOffLocation?.label || "",
              insurance: selectedInsurance,
              addOns: extraItems,
              optionalfees: extraItemsArray,
              customer: {
                firstname: form?.firstName,
                lastname: form?.lastName,
                dateofbirth: moment(user?.dateOfBirth).format("DD/MMM/YYYY"), // Change date format into RCM date format
                email: form?.email,
                address: user?.address || "",
                city: user?.city || "",
                state: user?.state || "",
                postcode: user?.pincode || "",
                driverAge: form?.driverAge,
                licenseIssueCountry: form?.licenseIssueCountry,
              }
            }
            // If user login then it add customer_id in payload
            if (customer_Id || user?.role == "customer" || methodModel.getPrams("customerId")) {
              bookingPayload = { ...bookingPayload, customer_id: customer_Id || user?.id || user?._id || methodModel.getPrams("customerId") }
            }
            // If agent login then it add agentName & agent_id in payload
            if (methodModel.getPrams("agentId") && methodModel.getPrams("agentName") || user?.role == "agent") {
              bookingPayload = { ...bookingPayload, agentName: methodModel.getPrams("agentName") || user?.fullName || user?.firstName, agent_id: methodModel.getPrams("agentId") || user?.id || user?._id }
            }
            if (user?.role == "sub_agent") {
              bookingPayload = { ...bookingPayload, agent_id: user?.agent_id, sub_agent_id: user?._id || user?.id }
            }
            ApiClient.post(`booking`, bookingPayload).then(response => {
              if (res.success) {
                if (findAgentorCustomer()) {
                  navigate("/bookings")
                  loader(false)
                } else {
                  if (paymentMethod == 1) stripeRedirection(response?.data?.booking_id, response?.data)
                  else paypalRedirection(response?.data?.booking_id, response?.data)
                }
              }
            })
          }
        })
      } else { // To add functionality for other third party api's and brands in future
        if (findAgentorCustomer()) {
          navigate("/bookings")
        } else {
          if (paymentMethod == 1) stripeRedirection()
          else paypalRedirection()
        }
      }
    }
  }

  //Stripe Payment Api
  const stripeRedirection = (bookingId = '', quoteData = '') => {
    let payload = {
      email: form?.email,
      fullName: form?.firstName + " " + form?.lastName,
      pay_amount: totalCampervanPrice(),
      transaction_status: "pending",
      customer_id: user?.id || user?._id || methodModel.getPrams("customerId") || "",
      customerId: methodModel.getPrams("customerId") || "",
      agentId: methodModel.getPrams("agentId") || "",
      agentName: methodModel.getPrams("agentName") || "",
      campervan_id: id,
      currency: "usd",
      booking_id: bookingId,
    }
    if (bookingId) {
      ApiClient.post(`payNowOnStripe`, payload).then(res => {
        if (res.success) {
          // localStorage.setItem('form', JSON.stringify(form))
          // localStorage.setItem('extraItems', JSON.stringify(extraItems))
          // localStorage.setItem('selectedInsurance', JSON.stringify(selectedInsurance))
          // localStorage.setItem('quoteDetails', JSON.stringify(quoteData))
          window.open(res?.data?.url, '_self')
          // settab(2)
        }
        loader(false)
      })
    }
  }
  // Paypal Payment Api
  const paypalRedirection = (bookingId = '', quoteData = '') => {
    let payload = {
      email: form?.email,
      fullName: form?.firstName + " " + form?.lastName,
      pay_amount: totalCampervanPrice(),
      transaction_status: "pending",
      customer_id: user?.id || user?._id || methodModel.getPrams("customerId") || "",
      customerId: methodModel.getPrams("customerId") || "",
      agentId: methodModel.getPrams("agentId") || "",
      agentName: methodModel.getPrams("agentName") || "",
      campervan_id: id,
      currency: "usd",
      booking_id: bookingId,
    }
    if (bookingId) {
      ApiClient.post(`payNowOnPaypal`, payload).then(res => {
        if (res.success) {
          // localStorage.setItem('form', JSON.stringify(form))
          // localStorage.setItem('extraItems', JSON.stringify(extraItems))
          // localStorage.setItem('selectedInsurance', JSON.stringify(selectedInsurance))
          // localStorage.setItem('quoteDetails', JSON.stringify(quoteData))
          window.open(res?.data, '_self')
          // settab(2)
        }
        loader(false)
      })
    }
  }

  const handleExtraItems = (data, parentIndex, parentKey, childIndex, childKey) => {
    let array = [...extraItems]
    array[parentIndex][parentKey][childIndex][childKey] = data
    if (campervanDetails?.campervan_type == "jucy") {
      array[parentIndex][parentKey][childIndex]['Price']['Value'] = data * Number(array[parentIndex][parentKey][childIndex]['price'])
    }
    array[parentIndex][parentKey][childIndex]['Total']['Value'] = data * Number(array[parentIndex][parentKey][childIndex]['totalPrice'])
    setExtraItems([...array])
  }

  const handleDropOffDate = (e) => {
    setForm((prev) => ({ ...prev, dropOffDate: e }))
    dispatch(SEARCH_STATE({ ...search, start_date: form?.pickUpDate?.startDate, end_date: e?.endDate }))
    if (campervanDetails?.campervan_type == 'jucy') {
      getCampervanDetails(form?.pickUpDate?.startDate, e?.endDate)
    }
  }

  const checkInsuranceFeatures = (insurance, item) => {
    const value = item.toLowerCase()
    if (insurance?.[value]) {
      return true
    } else {
      return false
    }
  }

  // Agent Flow
  const handleCustomers = (e) => {
    const data = customers.find((item) => item?._id == e.value);
    setForm((prev) => ({ ...prev, id: data?._id, firstName: data?.firstName, lastName: data?.lastName, email: data?.email, mobileNo: data?.mobileNo, chooseCustomer: e }));
  }

  const handleSendQuote = (e) => {
    e.preventDefault()
    // To check the Insurance is selected or not if not it redirect to the Insurance Section
    if (!selectedInsurance?.ExcessType) {
      const tag = document.createElement('a')
      tag.href = "#InsuranceCoverage"
      tag.click()
      tag.remove()
      return
    } else {
      if (findAgentorCustomer()) {
        if (bookingId) {
          handleUpdateQuote(form?.id)
          return
        } else {
          const param = {
            firstName: form?.firstName,
            lastName: form?.lastName,
            email: form?.email,
            agent_id: [user?.id || user?._id],
            role: "customer",
            mobileNo: form?.mobileNo,
          }
          ApiClient.post(`admin/add-user`, param, '', true).then((res) => {
            if (res?.success) {
              handleFirstTab(res?.data?.id || res?.data?._id)
            }
            else {
              if (res?.error?.code == 404) {
                let customer_agent = res?.data?.agent_id || []
                customer_agent?.push(user?.id)
                let param = {
                  id: res?.data?.id || res?.data?._id,
                  agent_id: customer_agent
                }
                ApiClient.put(`admin/edit-user`, param, '', true).then((res) => {
                  if (res?.success) {
                    handleFirstTab(res?.data?.id || res?.data?._id)
                  }
                  else {
                    setError(res?.error?.message)
                    setTimeout(() => {
                      setError("")
                    }, 5000)
                  }
                });
              }
              else {
                setError(res?.error?.message)
                setTimeout(() => {
                  setError("")
                }, 5000)
              }
            }
          });
        }
      } else {
        handleFirstTab()
      }
    }
  }

  const handleJucyBooking = (customerId) => {
    const pickUpDateTime = form?.pickup_date
    const dropOffDateTime = form?.drop_off_date
    const categoryCode = campervanDetails?.categoryCode
    const availableVehicle = JSON.stringify(campervanDetails?.categoryCode)
    const pickUpLocation = JSON.stringify(form?.pickUpLocation)
    const dropOffLocation = JSON.stringify(form?.dropOffLocation)
    let agentName = user?.fullName || user?.firstName
    let agentId = user?.id || user?._id
    loader(true)
    ApiClient.post(`Createquote?firstName=${form?.firstName}&lastName=${form?.lastName}&email=${form?.email}&mobileNo=${form?.mobileNo}&pickUpLocation=${pickUpLocation}&dropOffLocation=${dropOffLocation}&availableVehicle=${availableVehicle}&categoryCode=${categoryCode}&pickUpLocationCode=${form?.pickUpLocation?.value}&dropOffLocationCode=${form?.dropOffLocation?.value}&pickUpDateTime=${pickUpDateTime}&dropOffDateTime=${dropOffDateTime}&agentName=${agentName}&total_days=${form?.total_days}&campervan_id=${id}&agent_id=${agentId}&campervan_type=${campervan?.type}&campervan_price=${finalTotalPrice()}&customer_id=${customerId}&accountKey=${form?.accountKey}`, {}, '', true).then(res => {
      if (res?.success) {
        navigate("/bookings");
      } else {
        setError(res?.message?.message)
        setTimeout(() => {
          setError()
        }, 5000)
      }
      loader(false)
    })
  }

  const handleAdminCampervanBookings = (customerId) => {
    delete form?.accountKey //Delete account key beacuse in admin campervans no need for account key
    let payload = {
      ...form,
      campervan_id: id,
      campervan_type: campervanDetails?.campervan_type || campervanDetails?.type,
      discount: Number(campervanDetails?.discount || 0),
      customer_id: customerId,
      campervan_price: totalCampervanPrice(),
      campervanPrice: campervanDetails?.Total?.Value || totalCampervanPrice('campervanOnly'),
      taxesAndFees: campervanDetails?.serviceFees || 0,
      insurance: JSON.stringify(selectedInsurance),
      addOns: JSON.stringify(extraItems),
      securityDeposit: campervanDetails?.security_deposite || 0,
      bondDetails: selectedInsurance?.Excess?.Value,
      pickUpLocation: JSON.stringify(search?.pickUpLocation),
      dropOffLocation: search?.dropOffLocation ? JSON.stringify(search?.dropOffLocation) : "",
      availableVehicle: JSON.stringify({ value: campervanDetails?.CategoryCode, label: campervanDetails?.Name }),
      total_days: campervanDetails?.RentalDays || calculateDays(new Date(form?.pickUpDate?.startDate), new Date(form?.dropOffDate?.startDate))
    }
    if (user?.role == 'agent') {
      payload = { ...payload, agent_id: user?.id || user?._id }
    } else {
      payload = { ...payload, agent_id: user?.agent_id, sub_agent_id: user?._id || user?.id }
    }
    delete payload?.id
    delete payload?.chooseCustomer
    ApiClient.post(`Bookings`, payload, '', true).then((res) => {
      if (res?.success == true) {
        navigate("/bookings");
      }
      else {
        setError(res?.error?.message)
        setTimeout(() => {
          setError()
        }, 5000)
      }
    });
  }

  const handleUpdateQuote = (customerId) => {
    let payload = {
      ...form,
      id: bookingId,
      campervan_id: id,
      campervan_type: campervanDetails?.campervan_type || campervanDetails?.type,
      discount: Number(campervanDetails?.discount || 0),
      customer_id: customerId,
      campervan_price: totalCampervanPrice(),
      campervanPrice: campervanDetails?.Total?.Value || totalCampervanPrice('campervanOnly'),
      taxesAndFees: campervanDetails?.serviceFees || 0,
      insurance: JSON.stringify(selectedInsurance),
      addOns: JSON.stringify(extraItems),
      securityDeposit: campervanDetails?.security_deposite || 0,
      bondDetails: selectedInsurance?.Excess?.Value,
      pickUpLocation: JSON.stringify(search?.pickUpLocation),
      dropOffLocation: search?.dropOffLocation ? JSON.stringify(search?.dropOffLocation) : "",
      availableVehicle: JSON.stringify({ value: campervanDetails?.CategoryCode, label: campervanDetails?.Name }),
      total_days: campervanDetails?.RentalDays || calculateDays(new Date(form?.pickUpDate?.startDate), new Date(form?.dropOffDate?.startDate))
    }
    if (user?.role == 'agent') {
      payload = { ...payload, agent_id: user?.id || user?._id }
    } else {
      payload = { ...payload, agent_id: user?.agent_id, sub_agent_id: user?._id || user?.id }
    }
    delete payload?.chooseCustomer
    ApiClient.put(`Bookings`, payload).then(res => {
      if (res.success) {
        navigate("/bookings");
      }
    })
  }

  return (
    <>
      <div>
        <form onSubmit={handleSendQuote} autoComplete="off">
          <div className="max-w-[1504px] w-full mx-auto px-4 lg:px-8">
            <div className="grid grid-cols-12 md:gap-x-6 lg:gap-x-10 2xl:gap-x-12 mt-6 lg:mt-12 mb-10 lg:mb-[91px]">
              <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-7 xl:col-span-8 2xl:col-span-8 order-last lg:order-first">
                <div className="border border-gray-border rounded-2xl p-4 lg:p-8 ">
                  <h4 className="text-secondary text-base lg:text-xl xl:text-2xl font-semibold mb-3 sm:mb-6">Book Your Campervan</h4>
                  {findAgentorCustomer() && customers?.length > 0 && !bookingId ?
                    <>
                      <label className="text-secondary font-medium	text-sm block mb-2">Select Customer</label>
                      <Select options={customers} value={form?.chooseCustomer} onChange={e => handleCustomers(e)} placeholder="Select Customer" className="w-full cursor-pointer min-w-fit " />
                    </>
                    : null}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 mt-4">
                    <div>
                      <label className="text-secondary font-medium	text-sm block mb-2">First Name</label>
                      <input value={form?.firstName} onChange={e => setForm({ ...form, firstName: e.target.value })} className="rounded-xl h-[47px] w-full px-4 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none" placeholder="Enter First Name" type="text" required />
                    </div>
                    <div>
                      <label className="text-secondary font-medium	text-sm block mb-2">Last Name</label>
                      <input value={form?.lastName} onChange={e => setForm({ ...form, lastName: e.target.value })} className="rounded-xl h-[47px] w-full px-4 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none" placeholder="Enter Last Name" type="text" required />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 mt-4">
                    <div>
                      <label className="text-secondary font-medium	text-sm block mb-2">Email</label>
                      <input value={form?.email} onChange={e => setForm({ ...form, email: e.target.value })} className="rounded-xl h-[47px] w-full px-4 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none" placeholder="Enter Email" type="email" required />
                    </div>
                    <div>
                      <label className="text-secondary font-medium	text-sm block mb-2">Phone Number</label>
                      <div className="mobile_input" id="profileEditNumber">
                        <PhoneInput
                          // country={'us'}
                          value={form?.mobileNo}
                          enableSearch={true}
                          inputClass={"relative shadow-box bg-white !w-full !h-[47px] rounded-xl !border-0 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none"}
                          containerClass={"w-full"}
                          onChange={e => setForm({ ...form, mobileNo: e })}
                          inputProps={{ required: true }}
                          disableSearchIcon={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 mt-4  items-end">
                    <div>
                      <label className="text-secondary font-medium	text-sm block mb-2">Drivers Age</label>
                      <InputSelect
                        classes="w-full"
                        options={ageArray}
                        placeholder="Select Drivers Age"
                        value={{ name: form?.driverAge }}
                        btnClasses="!rounded-xl !border-0 !h-[47px] focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none"
                        onChange={e => { setForm({ ...form, driverAge: e.name }) }} />
                    </div>
                    <div>
                      <label className=" text-secondary font-medium	text-sm block mb-2">License Issue Country</label>
                      <InputSelect
                        classes="w-full"
                        placeholder="Select License Issue Country"
                        options={licenseIssueCountryArray}
                        value={{ name: form?.licenseIssueCountry }}
                        btnClasses="!rounded-xl !border-0 !h-[47px] focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none"
                        onChange={e => setForm({ ...form, licenseIssueCountry: e.name })} />
                    </div>
                  </div>
                </div>
                {/* <div className="mt-6 lg:mt-[100px]">
                  <h4 className="text-secondary text-xl sm:text-[32px] font-medium	mb-3.5" id="PickUp&DropOff">Pick Up & Drop Off</h4>
                  <p className="text-secondary text-sm text-normal mb-6">Please select your pickup & drop off times</p>
                  <div className="w-full border border-[#787878] grid grid-cols-1 md:grid-cols-2 p-5 sm:p-11 rounded-2xl gap-6">
                    <div>
                      <h5 className="text-[#444] text-xl font-semibold mb-3">Pick Up</h5>
                      <p className="xl:text-base text-sm font-normal text-[#444444] mb-2">{search?.pickUpLocation?.label || '--'} - {moment(form?.pickUpDate?.startDate).format('DD MMM YYYY')}</p>
                      {search?.pickUpLocation?.label ?
                        <p className={`xl:text-base text-sm font-normal text-[#444444] cursor-pointer hover:text-blue-400`} onClick={e => { window.open(`https://www.google.com/maps/search/?api=1&query=${search?.pickUpLocation?.label}`, '_blank') }}>Show in google maps</p>
                        : null}
                      <h5 className="text-[#444] xl:text-xl text-lg	font-semibold	mt-4 mb-[23px]">Preferred pickup-time:</h5>
                      <div className="h-[53px] flex items-center mb-3">
                        <Datepicker
                          inputClassName="relative transition-all duration-300 py-2.5 pl-4 pr-14 w-full rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white disabled:opacity-40 disabled:cursor-not-allowed h-[53px] rounded-lg border-0 focus:ring-offset-[1.2px] ring-1 ring-[#787878] focus:ring-primary outline-none appearance-none"
                          toggleClassName="absolute text-[#8D8D8D] right-0 h-full px-4 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                          useRange={false}
                          asSingle={true}
                          minDate={new Date()}
                          value={form?.pickUpDate}
                          onChange={e => setForm((prev) => ({ ...prev, pickUpDate: e, dropOffDate: { startDate: null, endDate: null } }))}
                          required />
                      </div>
                      <Select className="w-full h-[50px]" value={form?.pickUpTime} onChange={(e, time) => setForm({ ...form, pickUpTime: time })} options={timeOption} placeholder="Optional"/>
                    </div>
                    <div>
                      <h5 className="text-[#444] text-xl font-semibold mb-3">Drop Off</h5>
                      <p className="xl:text-base text-sm font-normal text-[#444444] mb-2">{search?.dropOffLocation?.label ? search?.dropOffLocation?.label : search?.pickUpLocation?.label ? search?.pickUpLocation?.label : '--'} - {form?.dropOffDate?.startDate ? moment(form?.dropOffDate?.startDate).format('DD MMM YYYY') : '--'}</p>
                      {search?.dropOffLocation?.label || search?.pickUpLocation?.label ?
                        <p className={`xl:text-base text-sm font-normal text-[#444444] cursor-pointer hover:text-blue-400`} onClick={e => { window.open(`https://www.google.com/maps/search/?api=1&query=${search?.dropOffLocation?.label || search?.pickUpLocation?.label}`, '_blank') }} >Show in google maps</p>
                        : null}
                      <h5 className="text-[#444] xl:text-xl text-lg font-semibold	mt-4 mb-[23px]">Preferred drop off-time:</h5>
                      <div className="h-[53px] flex items-center mb-3">
                        <Datepicker
                          inputClassName="relative transition-all duration-300 py-2.5 pl-4 pr-14 w-full rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white disabled:opacity-40 disabled:cursor-not-allowed h-[53px] rounded-lg border-0 focus:ring-offset-[1.2px] ring-1 ring-[#787878] focus:ring-primary outline-none appearance-none"
                          toggleClassName="absolute text-[#8D8D8D] right-0 h-full px-4 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                          useRange={false}
                          asSingle={true}
                          minDate={form?.pickUpDate?.startDate || new Date()}
                          value={form?.dropOffDate}
                          onChange={e => handleDropOffDate(e)}
                          required />
                      </div>
                      <Select className="w-full h-[50px]" placeholder="Optional" value={form?.dropOffTime} onChange={(e, time) => setForm({ ...form, dropOffTime: time })} options={timeOption} />
                    </div>
                  </div>
                </div> */}
                {/* Insurance Div */}
                {insurance?.length != 0 ?
                  <div className="mt-6">
                    <h4 className="text-secondary text-base lg:text-xl xl:text-2xl  font-semibold mb-6" id="InsuranceCoverage">Choose your insurance coverage</h4>
                    {campervanDetails?.campervan_type == "jucy" ?
                      <div className="w-full overflow-auto scrollbar pb-1">
                        <div className="bg-white border border-gray-border rounded-2xl p-4 sm:p-8 flex gap-6 min-w-[845px]">
                          <div className="max-w-[196px] w-full">
                            <p className="text-lg text-secondary mb-5">Included:</p>
                            <ul className="space-y-3">
                              <li className="flex gap-2 items-center text-base text-gray-light">
                                <span className="size-1.5 bg-gray-light rounded-full" />
                                24/7 Customer Support</li>
                              <li className="flex gap-2 items-center text-base text-gray-light">
                                <span className="size-1.5 bg-gray-light rounded-full" />
                                Minimum Liability Limits
                              </li>
                              <li className="flex gap-2 items-center text-base text-gray-light">
                                <span className="size-1.5 bg-gray-light rounded-full" />
                                Physical Damage (300K)
                              </li>
                              <li className="flex gap-2 items-center text-base text-gray-light">
                                <span className="size-1.5 bg-gray-light rounded-full" />
                                Roadside Assistance
                              </li>
                              <li className="flex gap-2 items-center text-base text-gray-light">
                                <span className="size-1.5 bg-gray-light rounded-full" />
                                Windshield Coverage
                              </li>
                            </ul>
                            <ul className="space-y-3 mt-12 -mr-6">
                              <li className="h-10 pb-3 border-b border-[#E5E3E3] flex items-center gap-1 text-lg  font-normal text-gray-light">Deductible</li>
                              {campervanDetails?.excessReductionFeatures?.map((item, index) => {
                                return <>
                                  {item?.name != "Cost per day" ?
                                    <li style={{ lineHeight: "18px" }} key={index} className="h-10 pb-3 border-b border-[#E5E3E3] flex items-center gap-1 text-lg font-normal text-gray-light  justify-between">{item?.name} <BsFillQuestionCircleFill className="text-[#C0C0C0] h-8 mr-2 cursor-pointer shrink-0" /></li>
                                    : null}
                                </>
                              })}
                            </ul>
                          </div>
                          <div className="flex-1 grid grid-cols-3 gap-3">
                            {insurance && insurance?.map((itm, i) => {
                              return <>
                                {itm?.Items?.map((item, index) => {
                                  return <>
                                    <div key={index} onClick={() => setSelectedInsurance(item)} className={`px-4 pt-4 rounded-xl border overflow-hidden ${selectedInsurance?.ExcessType == item?.ExcessType ? "border-primary border bg-[#F6F7FB]" : "border-gray-border"} flex flex-col cursor-pointer`}>
                                      <div className="flex justify-between items-center">
                                        {selectedInsurance?.ExcessType == item?.ExcessType ? <img src="/assets/img/front/ess-blue.svg" alt="ess-green" className="size-8 shrink-0" /> : <img src="/assets/img/front/ess.svg" alt="ess" className="size-8 shrink-0" />}
                                        {item?.ExcessType == 'Standard' ? <span className="bg-primary text-white text-[10px] px-2 py-1 rounded">RECOMMENDED</span> : null}
                                      </div>
                                      <h5 className="text-base lg:text-lg  xl:text-xl font-medium text-secondary mt-4 mb-3">{item?.ExcessType}</h5>
                                      <p className="text-base text-secondary">${item?.Price?.Value || 0}</p>
                                      <ul className="-ml-4 -mx-7 mt-auto space-y-3">
                                        <li className="h-10 pb-3 border-b border-gray-border text-lg font-medium px-6 text-right">${item?.Total?.Value || 0}</li>
                                        {campervanDetails?.excessReductionFeatures?.map((insurance, index) => {
                                          return <>
                                            {insurance?.name != "Cost per day" ?
                                              <li className="h-10 pb-3 border-b border-gray-border flex items-center justify-end px-6" key={index}>
                                                {insurance?.type == "boolean" ?
                                                  <>
                                                    {checkInsuranceFeatures(insurance, item?.ExcessType) ?
                                                      <span className={`size-3 ${selectedInsurance?.ExcessType == item?.ExcessType ? "bg-primary" : "bg-secondary"} rounded-full block`} />
                                                      : null}
                                                  </>
                                                  :
                                                  <span>{insurance?.[(item?.ExcessType).toLowerCase()]}</span>
                                                }
                                              </li>
                                              : null}
                                          </>
                                        })}
                                      </ul>
                                    </div>
                                  </>
                                })}
                              </>
                            })}
                          </div>
                        </div>
                      </div>
                      :
                      <div className="w-full overflow-auto scrollbar pb-1">
                        <div className="bg-white border border-gray-border rounded-2xl p-4 sm:p-8 flex gap-6 min-w-[845px]">
                          <div className="flex-1 grid grid-cols-3 gap-3">
                            {insurance && insurance?.map((item, index) => {
                              return <div key={index} onClick={() => setSelectedInsurance(item)} className={`px-4 pt-4 rounded-lg border overflow-hidden ${selectedInsurance?.ExcessType == item?.ExcessType ? "border-primary border-2 bg-[#F6F7FB]" : "border-gray-border"} flex flex-col cursor-pointer`}>
                                <div className="flex justify-between items-center">
                                  {selectedInsurance?.ExcessType == item?.ExcessType ? <img src="/assets/img/front/ess-blue.svg" alt="ess-green" className="size-8 shrink-0" /> : <img src="/assets/img/front/ess.svg" alt="ess" className="size-8 shrink-0" />}
                                  {item?.ExcessType == 'Standard' ? <span className="bg-primary text-white text-[10px] px-2 py-1 rounded">RECOMMENDED</span> : null}
                                </div>
                                <h5 className="text-base lg:text-lg  xl:text-xl font-medium text-secondary mt-4 mb-3">{item?.ExcessType}</h5>
                                <p className="text-base text-secondary">${item?.Price?.Value || 0}</p>
                                <ul className="-ml-4 -mx-7 mt-auto space-y-3">
                                  <li className="h-auto pb-3 border-b border-gray-border text-lg font-medium px-6 text-right text-secondary">{item?.description1}</li>
                                  <li className="h-auto pb-3 border-b border-gray-border text-lg font-medium px-6 text-right text-secondary">{item?.description2}</li>
                                  <li className="h-auto pb-3 border-b border-gray-border text-lg font-medium px-6 text-right text-secondary">{item?.description3}</li>
                                </ul>
                              </div>
                            })}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  : null}
                {/* Extra Items Div */}
                <div className="mt-6">
                  {extraItems?.length != 0 ?
                    <>
                      <h4 className="text-secondary text-base lg:text-xl xl:text-2xl font-semibold mb-3	lg:mb-6" id="ExtraItems">
                        Would you like any extras items?
                      </h4>
                      <div className="border rounded-t-lg border-gray-border overflow-auto">
                        <table className="table-auto w-full ">
                          <thead className="bg-[#F6F7FB]">
                            <tr className="h-[60px] border-b border-gray-border">
                              <th className="text-secondary font-medium text-sm text-left  px-5">
                                Add On
                              </th>
                              <th className="text-secondary font-medium text-sm text-left  px-5">
                                Price/Day
                              </th>
                              <th className="text-secondary font-medium text-sm text-left  px-5">
                                Quantity
                              </th>
                              <th className="text-secondary font-medium text-sm text-left  px-5">
                                Total Price
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {extraItems && extraItems?.slice(0, showAllExtraItems ? 1000 : 4)?.map((itm, i) => {
                              return <>
                                {itm?.Items?.map((item, index) => {
                                  return <>
                                    <tr key={index} className="h-[60px] ">
                                      <td className="text-gray-light text-base text-left px-5 font-normal">{item?.Name}</td>
                                      <td className="text-gray-light text-base text-left px-5 font-normal">
                                        {item?.Price?.CurrencyCode || 'AUD'} ${item?.Price?.Value || 0}
                                      </td>
                                      <td className="text-gray-light text-base text-left px-5 font-normal">
                                        <select value={item?.Qty} onChange={e => handleExtraItems(Number(e.target.value), i, 'Items', index, 'Qty')} className="cursor-pointer flex items-center px-2 h-[40px] w-[86px] rounded-md py-[3px] m-auto border- border-gray-border bg-transparent focus:outline-0">
                                          {quantityOptions && quantityOptions?.map((data) => {
                                            return <option value={data?.value}>{data?.label}</option>
                                          })}
                                        </select>
                                      </td>
                                      <td className="text-gray-light text-base text-left px-5 font-normal">
                                        {item?.Total?.CurrencyCode} ${item?.Total?.Value}
                                      </td>
                                    </tr>
                                  </>
                                })}
                              </>
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div onClick={e => setShowAllExtraItems(!showAllExtraItems)} className="flex justify-center cursor-pointer items-center border border-gray-border rounded-b-lg text-base text-secondary border-t-0 px-3 py-3">
                        Show {showAllExtraItems ? 'Less' : 'All'}
                        {/* {showAllExtraItems ?
                          <MdKeyboardArrowUp size={20} className="ml-1" />
                          :
                          <MdKeyboardArrowDown size={20} className="ml-1" />
                        } */}
                      </div>
                    </>
                    : null}

                  {/* Choose Payment Method Div */}
                  {findAgentorCustomer() ?
                    <button type="submit" disabled={error} className="disabled:cursor-not-allowed rounded-xl max-w-[200px] w-full text-[#F6F7FB] text-sm xl:text-base 2xl:text-lg mt-5 font-medium lg:mb-4 bg-primary hover:opacity-80 transition-all flex justify-center items-center gap-2 h-10 xl:h-12 2xl:h-[54px]">{bookingId ? "Update" : "Send"} Quote
                      <IoIosArrowRoundForward className="size-5 text-white" />
                    </button>
                    :
                    <>
                      <h4 className="text-secondary text-base lg:text-xl xl:text-2xl font-semibold my-6">Choose Payment Method</h4>
                      <div className="flex items-center gap-2 max-sm:flex-wrap">
                        <button type="button" onClick={e => setPaymentMethod(1)} className={`w-full  rounded-xl h-[76px] flex items-center outline-none border ${paymentMethod === 1 ? "border-primary" : "border-gray-border"} bg-white py-5 px-4 md:px-8`}>
                          <div className="flex items-center justify-between w-full ">
                            <div className="flex items-center gap-x-2">
                              <input type="radio" className="h-4 w-4 accent-primary shrink-0" checked={paymentMethod === 1} />
                              <span className="text-gray-light text-base md:text-lg font-medium">Stripe</span>
                            </div>
                            <img src="/assets/img/front/stripe.png" alt="wallet" width="80px" />
                          </div>
                        </button>
                        <button type="button" onClick={e => setPaymentMethod(2)} className={`w-full  rounded-xl h-[76px] flex items-center outline-none border ${paymentMethod === 2 ? "border-primary" : "border-gray-border"} bg-white py-5 px-4 md:px-8`}>
                          <div className="flex items-center justify-between w-full ">
                            <div className="flex items-center gap-x-2">
                              <input type="radio" className="h-4 w-4 accent-primary shrink-0" checked={paymentMethod === 2} />
                              <span className="text-gray-light text-base md:text-lg font-medium">PayPal</span>
                            </div>
                            <img src="/assets/img/front/paypal.svg" alt="wallet" />
                          </div>
                        </button>
                      </div>
                      <button type="submit" disabled={error || !termsConditions} className="disabled:cursor-not-allowed rounded-xl max-w-[200px] w-full text-[#F6F7FB] text-sm xl:text-base 2xl:text-lg mt-5 font-medium lg:mb-4 bg-primary hover:opacity-80 transition-all flex justify-center items-center gap-2 h-10 xl:h-12 2xl:h-[54px]">Continue to Payment
                        <IoIosArrowRoundForward className="size-5 text-white" />
                      </button>
                      <div className="flex items-center gap-x-2 cursor-pointer mt-4">
                        <input type="checkbox" onClick={e => setTermsConditions(e.target.checked)} checked={termsConditions} id="conditions" className="shrink-0 text-primary size-4 border border-gray-border rounded cursor-pointer appearance-none" />
                        <label htmlFor="conditions" className="text-gray-light   text-base cursor-pointer">
                          By continuing you agree to our terms and conditions and
                          privacy policy.
                        </label>
                      </div>
                    </>
                  }
                </div>
              </div>
              {/* Pricing Div */}
              <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5 xl:col-span-4 2xl:col-span-4 mb-8 lg:mb-12">
                <Pricing tab={tab} couponCode={couponCode} error={error} discountApplied={discountApplied} setCouponCode={setCouponCode} allCoupons={allCoupons} selectedInsurance={selectedInsurance} findTotalAddOns={findTotalAddOns} totalCampervanPrice={totalCampervanPrice} form={form} campervanDetails={campervanDetails} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CartPage;
