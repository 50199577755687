import { min } from "moment";

export const colourStyles = {
  menuList: (styles) => ({
    ...styles,
    "::-webkit-scrollbar": {
      width: "6px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#ddd",
    },
  }),
  control: (styles, state) => ({
    ...styles,
    cursor: "pointer",
    borderWidth: 0,
    maxHeight: 40,
    minHeight: 40,
    backgroundColor: "white",
    // border: "1.2px solid #8D8D8D !important",
    border:state.isFocused ? "1.2px solid #1744D2":"1px solid #E3E5ED",
    borderRadius: 12,
    textAlign: "left",
    minWidth: 180,
    boxShadow: "none",
    appearance:"none",
    // borderColor: state.isFocused ? "#1744D2" : "",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#1744D2"
        : isFocused
        ? "#1744D2"
        : undefined,
      cursor: "pointer",
      color: isFocused ? "#fff" : isSelected ? "#fff" : isDisabled ? "#fff" :"",
      fontWeight: isSelected ? 500 : 400,
      cursor: isDisabled ? "not-allowed" : "pointer",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#000"
            : "#F7FAFF"
          : undefined,
      },
    };
  },
  input: (styles) => ({
    ...styles,
    fontSize: 14,
    color: "#1D2433",
    fontWeight: 400,
  }),
  placeholder: (styles) => ({ ...styles, overflow: 'hidden', display: "-webkit-box",fontSize:14,
  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "1", }),
  singleValue: (styles, { data }) => ({ ...styles }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};
