import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Pricing from "./Pricing";
import ApiClient from "../../methods/api/apiClient";
import Select from "react-select";
import { colourStyles } from "../../utils/selectStyle";
import { IoIosArrowRoundForward } from "react-icons/io";

const PaymentDetail = ({ tab, settab, form, setForm, campervanDetails, totalCampervanPrice, findTotalAddOns, selectedInsurance, couponCode, setCouponCode, allCoupons, discountApplied, error }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(0);
  const [countries, setCountries] = useState([])
  const [cardTermsConditions, setCardTermsConditions] = useState(false)

  useEffect(() => {
    getCountries()
  }, [])

  const getCountries = () => {
    ApiClient.get(`countries?status=active`).then(res => {
      if (res.success) {
        setCountries(res?.data?.map((item, index) => {
          return ({ value: item?._id, label: item?.country_name })
        }))
      }
    })
  }

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCompleteBooking = async (e) => {
    e.preventDefault()
    settab(3)
  }

  return (
    <>
      <div className="max-w-[1504px] w-full mx-auto px-4 lg:px-8 mt-6 lg:mt-12">
        <div className="flex max-lg:flex-col-reverse gap-4 lg:gap-12 mb-6 lg:mb-12">
          <div className="lg:max-w-[1012px] w-full">
            <form onSubmit={handleCompleteBooking} autoComplete="off">
              <h6 className="text-secondary text-lg lg:text-3xl 2xl:text-4xl font-semibold">Choose your payment option</h6>
              <div className="space-y-3 lg:space-y-6 mt-3 mb-4 lg:mt-6 lg:mb-8">
                <div className={`w-full rounded-2xl border ${openAccordionIndex === 0 ? "border-primary" : "border-gray-border"}  bg-white`}>
                  <button type="button" className="w-full rounded-2xl outline-none p-4 lg:pb-6 lg:pt-8 md:px-8 bg-white" onClick={() => handleAccordionClick(0)}>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-x-2">
                        <input type="radio" className="cursor-pointer h-4 w-4 accent-primary shrink-0" checked={openAccordionIndex === 0} />
                        <span className="text-secondary text-base md:text-lg font-medium text-left">Booking Deposit</span>
                      </div>
                      <img src="/assets/img/front/PaymentDetailIcon.svg" alt="wallet" />
                    </div>
                  </button>
                  {openAccordionIndex === 0 && (
                    <div className="px-4 lg:px-8 pb-4 lg:pb-8">
                      <div className="flex items-center flex-col md:flex-row gap-4">
                        <div className="w-full md:max-w-[430px]">
                          <label className="text-secondary font-medium	text-sm mb-1 sm:mb-2 inline-block">
                            Card Number
                          </label>
                          <input
                            className="rounded-xl  h-10 w-full px-4   !border-0  focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary   outline-none appearance-none"
                            placeholder="1234 1234 1234 1234"
                            value={form?.cardNumber}
                            onChange={e => setForm((prev) => ({ ...prev, cardNumber: e.target.value }))}
                            type="text"
                            maxLength={16}
                            minLength={16}
                            required
                          />
                        </div>
                        <div className="w-full md:max-w-[243px]">
                          <div className="">
                            <label className="text-secondary font-medium	text-sm mb-1 sm:mb-2 inline-block">
                              Expiration
                            </label>
                            <div className="relative">
                              <DatePicker
                                selected={form?.expiry}
                                onChange={(date) => setForm((prev) => ({ ...prev, expiry: date }))}
                                className="rounded-xl h-10 w-full px-4 !border-0  focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary   outline-none appearance-none"
                                showMonthYearPicker
                                dateFormat="MM/YYYY"
                                placeholderText="MM/YYYY"
                                minDate={new Date()}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="w-full md:max-w-[243px]">
                          <label className="text-secondary font-medium	text-sm mb-1 sm:mb-2 inline-block">
                            CVC
                          </label>
                          <div className="relative">
                            <input
                              className="rounded-xl focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary border-0 h-10 w-full px-4 outline-none appearance-none"
                              placeholder="CVC"
                              value={form?.cvc}
                              onChange={e => setForm((prev) => ({ ...prev, cvc: e.target.value }))}
                              minLength={3}
                              maxLength={3}
                              type="text"
                              required
                            />
                            <div className="absolute right-4 top-1/2 -translate-y-1/2">
                            <img src="/assets/img/front/PaymentDetailIcon.svg" alt="wallet" className="size-4 filter brightness-[0] saturate-[100%] invert-[44%] sepia-[7%] saturate-[167%] hue-rotate-[201deg] brightness-[89%] contrast-[84%]" />
                            </div>
                          </div>
                        </div>
                        <div className="w-full md:max-w-[243px]" id="paymentselectcountry">
                          <label className="text-secondary font-medium	text-sm mb-1 sm:mb-2 inline-block">
                            Country
                          </label>
                          <Select
                            styles={colourStyles}
                            value={form?.country}
                            onChange={e => setForm((prev) => ({ ...prev, country: e }))}
                            className="!rounded-x !h-10 capitalize"
                            options={countries}
                            required
                            isClearable
                            classNamePrefix="react"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2  xl:grid-cols-4 gap-3 lg:gap-6">
                <button type="button" className={`w-full rounded-xl h-14 flex items-center outline-none border ${openAccordionIndex === 1 ? "border-primary" : "border-gray-border"} bg-white py-4 px-4 `} onClick={() => handleAccordionClick(1)}>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-x-2">
                      <input type="radio" className="h-4 w-4 accent-primary shrink-0" checked={openAccordionIndex === 1} />
                      <span className="text-gray-light text-sm  font-normal">PayPal</span>
                    </div>
                    <img src="/assets/img/front/paypal.svg" alt="wallet" className="h-6 w-[60px]" />
                  </div>
                </button>
                <button type="button" className={`w-full rounded-xl h-14 flex items-center outline-none border ${openAccordionIndex === 2 ? "border-primary" : "border-gray-border"} bg-white py-4 px-4 `} onClick={() => handleAccordionClick(2)}>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-x-2">
                      <input type="radio" className="h-4 w-4 accent-primary shrink-0" checked={openAccordionIndex === 2} />
                      <span className="text-gray-light text-sm  font-normal">Google Pay</span>
                    </div>
                    <img src="/assets/img/front/gpay.svg" alt="wallet" className="h-6 w-[60px]" />
                  </div>
                </button>
                <button type="button" className={`w-full rounded-xl h-14 flex items-center outline-none border ${openAccordionIndex === 3 ? "border-primary" : "border-gray-border"} bg-white py-4 px-4 `} onClick={() => handleAccordionClick(3)}>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-x-2">
                      <input type="radio" className="h-4 w-4 accent-primary shrink-0" checked={openAccordionIndex === 3} />
                      <span className="text-gray-light text-sm  font-normal">Apple Pay</span>
                    </div>
                    <img src="/assets/img/front/apple.svg" alt="wallet" className="h-6 w-[60px]" />
                  </div>
                </button>
                <button type="button" className={`w-full rounded-xl h-14 flex items-center outline-none border ${openAccordionIndex === 4 ? "border-primary" : "border-gray-border"} bg-white py-4 px-4 `} onClick={() => handleAccordionClick(4)}>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-x-2">
                      <input type="radio" className="h-4 w-4 accent-primary shrink-0" checked={openAccordionIndex === 4} />
                      <span className="text-gray-light text-sm  font-normal">After Pay</span>
                    </div>
                    <img src="/assets/img/front/afterpay.svg" alt="wallet" className="h-6 w-[100px]" />
                  </div>
                </button>
                </div>
              </div>
              <div className="flex items-center gap-2 ">
                <input type="checkbox" onClick={e => setCardTermsConditions(e.target.checked)} checked={cardTermsConditions} id="terms" className="mt-1 shrink-0 text-primary size-6 border border-gray-border rounded appearance-none cursor-pointer" />
                <label htmlFor="terms" className="text-gray-light text-base font-normal cursor-pointer">
                  By providing your card information, you allow RV Renter Center to change your card to future payments in accordance with their terms.
                </label>
              </div>
              <button type="submit" disabled={!cardTermsConditions} className="mt-4 h-10 xl:h-12 2xl:h-[54px] disabled:cursor-not-allowed shrink-0 max-w-[233px] w-full text-white bg-primary rounded-xl outline-none text-lg font-medium h-[54px] flex items-center justify-center gap-2 hover:opacity-80 transition-all max-lg:mb-8">Complete Booking
              <IoIosArrowRoundForward className="size-5 text-white" /></button>
            </form>
          </div>
          {/* Pricing Div */}
          <div className="flex-1 w-full">
            <Pricing tab={tab} couponCode={couponCode} error={error} discountApplied={discountApplied} setCouponCode={setCouponCode} allCoupons={allCoupons} selectedInsurance={selectedInsurance} findTotalAddOns={findTotalAddOns} totalCampervanPrice={totalCampervanPrice} form={form} campervanDetails={campervanDetails} />
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentDetail