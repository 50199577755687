import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import { Link } from 'react-router-dom';
import AuthLayout from '../../components/AuthLayout';
import { useSelector } from 'react-redux';
import AutoLogins from '../../components/common/AutoLogins';
import { useDispatch } from 'react-redux';
import { LOGIN_SUCCESS } from '../../Redux/Action/Action';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const Signup = () => {
  const history = useNavigate();
  const dispatch = useDispatch()
  const [form, setForm] = useState({ email: '', password: '', fullName: '' });
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false, currentPassword: false });
  const user = useSelector((state: any) => state.User)
  const [formSubmit, setformSubmit] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('token') && user?.loggedIn) {
      history('/dashboard')
    }
  }, [])

  const handleRedirect = (data: any) => {
    localStorage.setItem('token', data.access_token)
    dispatch(LOGIN_SUCCESS(data))
    history(`/`);
  }

  const hendleSubmit = (value: any) => {
    const data = {
      firstName: value.fullName,
      email: value.email,
      password: value.password,
      role: 'customer',
    };
    loader(true)
    ApiClient.post('register', data).then(res => {
      loader(false)
      if (res.success) {
        setForm(value)
        setformSubmit(true)
      } else {
        setformSubmit(false)
      }
    })
  }
  const FormSchema = Yup.object({
    fullName: Yup.string()
      .required('Name is required *'),
    email: Yup.string()
      .email('Invalid email address *')
      .required('Email is required *'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 cahracters *')
      .required('Password  is required *')
      .matches(/[0-9]/, 'Password requires a number *')
      .matches(/[a-z]/, 'Password requires a lowercase letter *')
      .matches(/[A-Z]/, 'Password requires an uppercase letter *')
      .matches(/[^\w]/, 'Password requires a symbol *')
      .max(16, 'Password must be less than 16 characters *'),
  })

  const handleOpenEmailApp = () => {
    if (form?.email.includes('gmail.com')) window.open('https://gmail.com/', '_self')
    else if (form?.email.includes('yopmail.com')) window.open('https://yopmail.com/', '_self')
    else window.open('https://outlook.com/', '_self')
  }

  return (
    <>
      <AuthLayout>
        <div className="w-full max-w-[400px] px-1 py-6 md:py-10 mx-auto overflow-hidden">
          <Formik
            initialValues={form}
            validationSchema={FormSchema}
            onSubmit={hendleSubmit}
          >
            <Form>
              <h4 className="text-typo mb-2 text-lg lg:text-3xl 2xl:text-4xl text-secondary font-semi-bold text-center">Sign Up</h4>
              <h4 className="text-typo mb-5 text-base  text-gray-light font-normal text-center">Sign up to access your dashboard, settings and project.</h4>
              {!formSubmit ? <>
                <AutoLogins authFunction={handleRedirect} />
              </> : null}
              {!formSubmit ?
                <div className='w-full'>
                  <label className='text-sm text-secondary font-medium mb-1 lg:mb-2 block'>Name</label>
                  <Field type="text" name='fullName' placeholder="Enter your name"
                    className=" bg-white w-full text-sm placeholder:text-gray-light  h-10 flex items-center gap-2 overflow-hidden px-4 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none !border-0 rounded-xl appearance-none"
                  />
                  <div className="invalid-feedback text-red-500 mt-0.5"><ErrorMessage name='fullName' /></div>
                  <label className='text-sm text-secondary font-medium mb-1 lg:mb-2 block'>Email</label>
                  <Field type="email" name='email' placeholder="Enter your email"
                    className=" bg-white w-full text-sm placeholder:text-gray-light  h-10 flex items-center gap-2 overflow-hidden px-4 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none !border-0 rounded-xl appearance-none"
                  />
                  <div className="invalid-feedback text-red-500 mt-0.5"><ErrorMessage name='email' /></div>
                  <label className='text-sm text-secondary font-medium mb-1 lg:mb-2 block'>Password</label>
                  <div className="relative">
                    <Field type={eyes.password ? "text" : "password"} name='password' placeholder="Create a password"
                      className=" bg-white w-full relative text-sm placeholder:text-gray-light  h-10 flex items-center gap-2 overflow-hidden px-4 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none !border-0 rounded-xl appearance-none"
                    />
                    <div className='absolute right-4 top-[13px] text-gray-light text-sm'>
                      {eyes.password ? (
                        <FaEye className="size-4 top-0 right-0 absolute text-gray-light cursor-pointer" onClick={() => setEyes({ ...eyes, password: !eyes.password })} />
                      ) : (
                        <FaEyeSlash className="size-4 top-0 right-0 absolute text-gray-light cursor-pointer" onClick={() => setEyes({ ...eyes, password: !eyes.password })} />
                      )}
                    </div>
                    <div className="invalid-feedback text-red-500 mt-0.5"><ErrorMessage name='password' /></div>
                  </div>
                  <div className="mt-6">
                    <button type="submit" className="!px-4 w-full text-base font-medium text-white h-11 flex items-center justify-center rounded-xl py-2 gap-2 !bg-primary shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-gray-border disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">Get Started</button>
                  </div>
                  <p className='text-base font-normal mt-[18px] flex items-center gap-2 justify-center'>Already have an account? <Link to="/login" className='!text-primary !text-lg !font-medium hover:!underline underline-offset-2 transition-all'>Sign In</Link></p>
                </div>
                :
                <div>
                  <span className='text-center'>
                    <p className='para_forget mb-1'>Please verify yourself we sent email to<br /> {form?.email}</p>
                    <div className='mb-1'>
                      <button type='button' onClick={e => handleOpenEmailApp()} className='!px-4 w-full text-sm font-normal text-white h-11 flex items-center justify-center rounded-xl py-2 gap-2 !bg-primary shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-gray-border disabled:bg-[#D0CAF6] disabled:cursor-not-allowed'>
                        Open email app
                      </button>
                    </div>
                  </span>
                </div>
              }
            </Form>
          </Formik>
        </div>
      </AuthLayout>
    </>
  );
};

export default Signup;