import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FiMinus } from "react-icons/fi";

interface CampervanCategoryProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}

const InsuranceText = [
  { label: "Number of seats with seatbelt" },
  { label: "Number of people who can sleep in the vehicle" },
  { label: "Number of beds" },
];

const CampervanCategory: React.FC<CampervanCategoryProps> = ({ settab }) => {
  const [activeIdx, setActiveIdx] = useState(0);
  const handleSleepCount = (idx: number) => {
    setActiveIdx(idx);
  };

  const [values, setValues] = useState<number[]>([1, 1, 1]);

  const increment = (index: number) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = newValues[index] + 1;
      return newValues;
    });
  };
  const decrement = (index: number) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = Math.max(newValues[index] - 1, 0);
      return newValues;
    });
  };

  return (
    <div className={`max-w-[1504px] w-full mx-auto px-4 lg:px-8 mt-8 sm:mt-12`}>
      <h3 className="text-lg sm:text-xl 2xl:text-2xl/8 font-semibold text-secondary">
        What type of RV do you have?
      </h3>
      <div className="flex gap-5 flex-wrap mt-6 justify-center sm:justify-start">
        {[...Array(5)].map((_itm, idx) => (
          <div
            onClick={() => handleSleepCount(idx)}
            key={idx}
            className={`w-full sm:max-w-[200px] cursor-pointer h-14 flex items-center px-6 border rounded-xl ${
              activeIdx === idx ? "border-primary" : "border-[#E3E5ED]"
            } `}
          >
            {/* <input
              type="radio"
              className="size-6 mr-3 rounded-full block accent-[#084ead] "
              checked={activeIdx === idx}
            /> */}
            <label className="radio-container">
              <input
                type="radio"
                className="hidden"
                checked={activeIdx === idx}
              />
              <span className="custom-radio"></span>
            </label>
            <span className="text-sm lg:text-base leading-[22.4px] font-medium text-secondary">
              Sleeps {idx + 2}{" "}
            </span>
          </div>
        ))}
      </div>
      <div className="w-full mt-4 lg:mt-8">
        <h3 className="text-lg sm:text-xl 2xl:text-2xl/8 font-semibold text-secondary mb-6">
          Accommodation
        </h3>
        {values.map((value, index) => (
          <div
            key={index}
            className="flex items-center justify-between border border-gray-border mb-5 p-3 lg:pl-8 lg:p-6 w-full rounded-xl"
          >
            <p className="text-sm/5 lg:text-base/5 font-normal text-secondary">
              {InsuranceText[index]?.label}
            </p>
            <div className="flex gap-2 md:gap-4 items-center">
              <button
                type="button"
                onClick={() => decrement(index)}
                className="flex justify-center items-center border border-gray-border size-6 md:size-8 rounded-full"
              >
                <FiMinus className="size-4 text-gray-light" />
              </button>
              <input
                type="text"
                className="text-base/5 font-normal text-secondary w-3 text-center"
                value={value}
                readOnly
              />
              <button
                type="button"
                onClick={() => increment(index)}
                className="flex justify-center items-center border border-gray-border size-6 md:size-8 rounded-full"
              >
                <FaPlus className="size-4 text-secondary" />
              </button>
            </div>
          </div>
        ))}
        <div className="my-4 lg:my-8">
          <h3 className="text-lg sm:text-xl 2xl:text-2xl/8 font-semibold text-secondary mb-6">
            Insurance Information
          </h3>
          <div className="flex gap-3 items-center w-full border border-gray-border p-4 lg:px-[26px] lg:py-5 sm:h-[61px] rounded-xl">
            <input
              type="checkbox"
              id="insurance"
              className="shrink-0 text-primary size-5 border border-gray-400 rounded cursor-pointer appearance-none"
            />
            <label
              htmlFor="insurance"
              className="cursor-pointer block text-sm lg:text-base/5 font-normal text-secondary"
            >
              If you are a campervan or RV renter company, please tick this box.
            </label>
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={() => settab(2)}
        className="block text-sm lg:text-base 2xl:text-lg/5 mb-12 font-medium h-10 xl:h-12 2xl:h-[54px] w-full sm:max-w-[199px] px-5 xl:px-[30px] py-2 lg:py-[9px] text-white bg-primary rounded-xl hover:opacity-80"
      >
        Save & continue
      </button>
    </div>
  );
};

export default CampervanCategory;
