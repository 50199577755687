import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import Select from 'react-select';
import DatePicker from "./DatePicker";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SEARCH_STATE } from "../../../Redux/Action/Action";
import { vanSleepLimit } from "../../../utils/static-data";
import ApiClient from "../../../methods/api/apiClient";
import { colourStyles } from "../../../utils/selectStyle";
import { Tooltip } from "antd";
import { useParams } from "react-router-dom";
import loader from "../../../methods/loader";

export interface searchFilterIF {
  peopleCount: number
  startDate: string
  endDate: string
}

interface SearchBannerProps {
  homedata: any
  bgImage: string
  peopleCounter?: boolean
  listingPage?: boolean
  classes?: string
  searchFilter: searchFilterIF
  filter?: any,
  setSearchFilter: Dispatch<SetStateAction<searchFilterIF>>
  handleSearchCampervans?: (e?: any) => void
  activeIdx?: number
  setActiveIdx?: Dispatch<SetStateAction<number>>
  pickUpRef: any
}

const ListingLocation: React.FC<SearchBannerProps> = ({
  homedata,
  peopleCounter = false,
  handleSearchCampervans,
  searchFilter,
  setSearchFilter,
  activeIdx,
  setActiveIdx,
  filter,
  pickUpRef
}) => {
  const { city } = useParams()
  const search = useSelector((state: any) => state.Reducer.search)
  const dispatch = useDispatch()
  const [filters, setFilters]: any = useState({
    pickUpLocation: '',
    dropOffLocation: '',
    start_date: '',
    end_date: '',
    isReturn: true,
    isDriverAge: false,
    ageid: "",
  })
  const [locations, setLocations] = useState()
  const [driverAges, setDriverAges] = useState([])
  const urlPath = window.location.pathname

  const [hoveredIdx, setHoveredIdx] = useState<null | number>(null);

  const handleSleepCount = (idx: number, label: string) => {
    if (typeof setActiveIdx === 'function') {
      if (label == 'Show all') {
        setFilters({ ...filters, type: '', activeIdx: 0 })
        // dispatch(SEARCH_STATE({ ...search, type: '', activeIdx: 0 }))
      } else {
        setFilters({ ...filters, type: label, activeIdx: idx })
        // dispatch(SEARCH_STATE({ ...search, type: label, activeIdx: idx }))
      }
      setActiveIdx(idx);
    }
  };

  const handleSearch = () => {
    if (typeof handleSearchCampervans === 'function') {
      handleSearchCampervans(filters);
    }
  }

  useEffect(() => {
    getLocations()
    getCategorieAndDriverAge()
  }, [])

  // It return the locations of Jucy and Spaceship
  const getLocations = () => {
    if (urlPath == "/") loader(true)
    ApiClient.get(`locations`).then(res => {
      if (res.success) {
        setLocations(res?.data?.locations?.map((item: any) => {
          return ({ value: item?.SiteCode || item?.location, label: item?.location, spaceship_id: item?.spaceship_id })
        }))
        if (city) {
          const cityName = city.replace("-", " ").replace(/\b\w/g, l => l.toUpperCase()); // To capitalize city name
          res?.data?.locations?.find((item: any) => {
            if (item?.location == cityName) {
              setFilters((prev: any) => ({ ...prev, pickUpLocation: { value: item?.SiteCode || item?.location, label: item?.location, spaceship_id: item?.spaceship_id } }))
            }
          })
        }
      }
      if (urlPath == "/") loader(false)
    })
  }

  // Api to get the spaceship campervans categories and driver age
  const getCategorieAndDriverAge = () => {
    const payload = { "method": "step1" }
    // const API_KEY = environment.AU_SPACESHIPS_API_KEY
    // const SHARED_SECRET = environment.AU_SPACESHIPS_SHARED_SECRET || ''
    // const signature = CryptoJS.HmacSHA256(JSON.stringify(payload), SHARED_SECRET).toString(CryptoJS.enc.Hex)
    ApiClient.post(`step1`, payload).then(res => {
      if (res.success) {
        setDriverAges(res?.data?.results?.driverages?.map((item: any) => {
          if (item?.driverage === 21) {
            setFilters((prev: any) => ({ ...prev, ageid: { value: item?.id, label: item?.driverage } }))
          }
          return ({ value: item?.id, label: item?.driverage })
        }))
        // Pass Spaceship id as vehiclecategorytypeid for spaceship api's as discuss with nishant & dinesh sir if not get then pass the van id 6 getting from testing data in RCM api's
        let vehicalCategoryId = ""
        res?.data?.results?.categorytypes?.filter((item: any) => {
          if (item?.vehiclecategorytype === "Spaceship") vehicalCategoryId = item?.id
        })
        dispatch(SEARCH_STATE({ ...search, vehiclecategorytypeid: vehicalCategoryId || "6" }))
      }
    })
  }

  useEffect(() => {
    setFilters({ ...filters, ...filter })
  }, [filter])

  const clearSearchFields = () => {
    const clearData = {
      pickUpLocation: '',
      dropOffLocation: '',
      start_date: '',
      end_date: '',
      isReturn: true,
      isDriverAge: false,
      ageid: "",
      campervan_type: "",
    }
    setFilters(clearData)
    handleSleepCount(0, "Show all")
    dispatch(SEARCH_STATE({ ...search, ...clearData }))
  }

  return (
    <div className="max-w-[1504px] mx-auto w-full px-4 lg:px-8">
      {/* <div className="justify-center flex gap-4 mb-3 lg:mb-5 z-10 relative flex-wrap w-full">
        {vanSleepLimit.map((itm, idx) => (
          <div
            key={idx}
            role="button"
            onClick={() => handleSleepCount(idx, itm?.label)}
            className={`shrink-0 h-14 w-[55px] ${activeIdx === idx ? "bg-[#E9EDFF] text-primary" : "bg-white text-secondary"}  flex flex-col items-center justify-center rounded-[5px] shadow-[0.63px_1.89px_3.79px_0px_rgba(43,43,43,0.08)] ${hoveredIdx === idx ? "hover:bg-[#E9EDFF]" : ""}`}
            onMouseEnter={() => setHoveredIdx(idx)}
            onMouseLeave={() => setHoveredIdx(null)}>
            <img
              alt={itm.label}
              src={itm.icon}
              width={50}
              height={29}
              className="w-7 lg:w-[29px] h-[15px]"
            />
            <p className="text-[10px] mt-1.5">{itm.label}</p>
          </div>
        ))}
      </div> */}

      <div className="border-gray-border border  rounded-2xl p-4 lg:p-6">
      <div className="w-full flex gap-3 xl:gap-5 max-xl:flex-wrap items-end">
        <div className="flex flex-col sm:flex-row gap-3 xl:gap-5 w-full xl:max-w-[708px]">
          <div className={`w-full`}>
            <label id="pickUpField" className="text-sm/4 font-medium text-secondary inline-block mb-1 lg:mb-2">{homedata?.part1_value1 ? homedata?.part1_value1 : "Pickup Location"}</label>
            <div className="w-full locationSelect">
              <Select
                styles={colourStyles}
                placeholder={homedata?.part1_placeholder1 ? homedata?.part1_placeholder1 : "Enter City, Airport or other location"}
                ref={pickUpRef}
                className="capitalize w-full cursor-pointer"
                value={filters?.pickUpLocation}
                onChange={(e: any) => setFilters({ ...filters, pickUpLocation: e })}
                options={locations}
                isClearable
                classNamePrefix="react zplus"
              />
            </div>
          </div>
          {filters?.isReturn ? <></> : <>
            <div className="w-full">
              <label htmlFor="" className="text-sm/4 font-medium text-secondary inline-block mb-1 lg:mb-2">{homedata?.part1_value2 ? homedata?.part1_value2 : "Dropoff Location"}</label>
              <div className="w-full locationSelect">
                <Select
                  styles={colourStyles}
                  placeholder={homedata?.part1_placeholder2 ? homedata?.part1_placeholder2 : "Enter City, Airport or other location"}
                  className="capitalize w-full cursor-pointer"
                  value={filters?.dropOffLocation}
                  onChange={(e: any) => setFilters({ ...filters, dropOffLocation: e })}
                  options={locations}
                  isClearable
                  classNamePrefix="react zplus"
                />
              </div>
            </div>
          </>}
        </div>
        <div className="max-xl:w-full flex-auto">
          <div className="max-xl:w-full flex flex-col sm:flex-row gap-3 lg:gap-5 sm:items-end">
            <div className="flex-col sm:flex-row w-full flex gap-2 lg:gap-5 xl:max-w-[342px]" id="details_calender">
              <DatePicker
                containerClassName="relative mt-8 w-full max-w-[161px]"
                label={homedata?.part1_value3 ? homedata?.part1_value3 : "Pickup Date"}
                startDate={filters?.start_date}
                endDate={filters?.end_date}
                onChange={(e: any) => {
                  setFilters({ ...filters, start_date: e.startDate, end_date: e.endDate })
                }}
                placeholder={homedata?.part1_placeholder3 ? homedata?.part1_placeholder3 : "MM/DD/YYYY"}
              />
              <DatePicker
                label={homedata?.part1_value4 ? homedata?.part1_value4 : "Return Date"}
                showEndDate={true}
                startDate={filters?.start_date}
                endDate={filters?.end_date}
                onChange={(e: any) => {
                  setFilters({ ...filters, start_date: e.startDate, end_date: e.endDate })
                }}
                placeholder={homedata?.part1_placeholder4 ? homedata?.part1_placeholder4 : "MM/DD/YYYY"}
              />
            </div>
            <div className={`w-full sm:max-w-[161px] xl:min-w-[161px]`} id="lisitngDriveAge">
                {/* Add dropdown of driver age and hide the checkbox to pass the ageid in spaceship api's discuss with nishant sir */}
                <label className="text-sm/4 font-medium text-secondary inline-block mb-1 lg:mb-2">{homedata?.part1_checkbox2 ? homedata?.part1_checkbox2 : "Driver Age"}</label>
                <div className="w-full locationSelect">
                  <Select
                    styles={colourStyles}
                    placeholder={homedata?.part1_checkbox2Placeholder ? homedata?.part1_checkbox2Placeholder : "Select Driver Age"}
                    className="capitalize w-full cursor-pointer sm:min-w-fit"
                    value={filters?.ageid}
                    onChange={(e: any) => setFilters({ ...filters, ageid: e })}
                    options={driverAges}
                    classNamePrefix="react"
                  />
                </div>
                {/* <input type="checkbox"
                checked={filters?.isDriverAge}
                onChange={e => {
                  setFilters({ ...filters, isDriverAge: e.target.checked })
                }}
                name="driverAge" id="driverAge" className="shrink-0 text-primary size-4 border border-gray-400 rounded-sm cursor-pointer appearance-none" />
              <label htmlFor="driverAge" className="text-sm/none text-[#6B6B6B] cursor-pointer">
                Driver Age: Minimum: <span className="underline underline-offset-4 decoration-primary"> 23 years </span>
                Maximum: <span className="underline underline-offset-4 decoration-primary"> 70 years</span>
              </label> */}
              </div>
            <div className="">
              <Tooltip placement="top" title="Search">
                <button type="button" onClick={() => handleSearch()} className="max-sm:w-full h-10 px-6 rounded-xl bg-primary flex gap-1 whitespace-nowrap items-center justify-center text-sm xl:text-base 2xl:text-lg text-[#F6F7FB] hover:bg-primary/80 font-medium">
                  Edit Search
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <div className="inline-flex items-center gap-2 w-full max-w-[250px] mt-3 ">
          <input type="checkbox"
            checked={filters?.isReturn}
            onChange={e => {
              setFilters((prev: any) => ({ ...prev, isReturn: e.target.checked, dropOffLocation: '' }))
            }}
            name="sameLocation" id="sameLocation" className="shrink-0 text-primary size-4 border border-gray-400 rounded-sm cursor-pointer appearance-none" />
          <label htmlFor="sameLocation" className="text-sm/none text-[#6B6B6B] cursor-pointer">{homedata?.part1_checkbox1 ? homedata?.part1_checkbox1 : "Return campervan to same location"}</label>
        </div>

      </div>
      <div className="flex gap-2  flex-col sm:flex-row">
        {/* <div className={`w-full flex-auto flex gap-1.5 items-center`}>
          <input type="checkbox"
            checked={filters?.isDriverAge}
            onChange={e => {
              setFilters({ ...filters, isDriverAge: e.target.checked })
            }}
            name="driverAge" id="driverAge" className="shrink-0 text-primary size-4 border border-gray-400 rounded-sm cursor-pointer appearance-none" />
          <label htmlFor="driverAge" className="text-sm/none text-[#6B6B6B] cursor-pointer">
            Driver Age: Minimum: <span className="underline underline-offset-4 decoration-primary"> 23 years </span>
            Maximum: <span className="underline underline-offset-4 decoration-primary"> 70 years</span>
          </label>
        </div> */}
      </div>
    </div>
  )
}

export default ListingLocation;