import { useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from 'react-google-recaptcha'
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import PageLayout from '../../components/global/PageLayout';
import environment from '../../environment';
import { useSelector } from 'react-redux';
import loader from '../../methods/loader';

const AgentRegister = () => {
    const [location, setlocation] = useState({ address: '', country: '', state: '', city: '', zipCode: '', })
    const [companylocation, setcompanylocation] = useState({ company_address: '', company_country: '', company_state: '', company_city: '', company_zipCode: '' })
    const siteSettings = useSelector((state) => state.Reducer.siteSettings)
    const [form, setform] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        Registration_Number: '',
        Name_of_Company: '',
        Company_Email: '',
        Company_Phone: '',
        mobileNo: '',
        Designation: '',
        Company_Start_Date: '',
    });
    const [passwordErr, setpasswordErr] = useState(false)
    const [confirmpasswordErr, setconfirmpasswordErr] = useState(false)
    const [eye, seteye] = useState({ password: false, confirmPassword: false })
    const [ReCaptcha, setReCaptcha] = useState('')
    const reCaptchaRef = useRef(null)
    const [termsAndConditions, settermsAndConditions] = useState(false)
    const navigate = useNavigate()
    const [register, setregister] = useState(false)
    const [show, setshow] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setshow(true)
        }, 500);
        const formData = JSON.parse(localStorage.getItem('agentRegisterFormData'))
        const locationData = JSON.parse(localStorage.getItem('agentRegisterLocationData'))
        if (formData) {
            setform(formData)
            localStorage.removeItem('agentRegisterFormData')
        }
        if (locationData) {
            setlocation(locationData)
            localStorage.removeItem('agentRegisterLocationData')
        }
    }, [])
    // This function handle the submit form funcitonality
    const hendleSubmit = (e) => {
        e.preventDefault()
        if (form?.password != form?.confirmPassword) {
            setconfirmpasswordErr('Password and Confirm Password is not match.')
            return
        }
        delete form?.confirmPassword
        let payload = {
            ...form,
            ...location,
            ...companylocation,
            role: "agent"
        }
        loader(true)
        ApiClient.post(`register/agent`, payload).then(res => {
            if (res.success) {
                setform({
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    Registration_Number: '',
                    Name_of_Company: '',
                    Company_Email: '',
                    Company_Phone: '+1',
                    mobileNo: '+1',
                    Designation: '',
                    Company_Start_Date: ''
                })
                setlocation({ address: '', country: '', state: '', city: '', zipCode: '' })
                setcompanylocation({ company_address: '', company_country: '', company_state: '', company_city: '', company_zipCode: '' })
                setReCaptcha('')
                settermsAndConditions(false)
                reCaptchaRef.current.reset()
                setregister(true)
                setTimeout(() => {
                    setregister(false)
                }, 10000);
            }
            loader(false)
        })
    }
    // This function return the all details of user address like Country, Region, City, Lat, Lng
    const addressResult = (place) => {
        const addressComponents = place.address_components;
        const address = {};

        for (let i = 0; i < addressComponents.length; i++) {
            const component = addressComponents[i];
            const types = component.types;
            if (types.includes('country')) {
                address.country = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                address.state = component.long_name;
            }
            if (types.includes('locality')) {
                address.city = component.long_name;
            }
            if (types.includes('postal_code')) {
                address.zipCode = component.long_name;
            }
        }

        address.address = place?.formatted_address
        address.lat = place.geometry.location.lat();
        address.lng = place.geometry.location.lng();
        setlocation({ ...address });
    }
    // This function return the all details of user company address like Country, Region, City, Lat, Lng
    const companyaddressResult = (place) => {
        const addressComponents = place?.address_components;
        const address = {};

        for (let i = 0; i < addressComponents.length; i++) {
            const component = addressComponents[i];
            const types = component.types;
            if (types.includes('country')) {
                address.company_country = component.long_name;
            }
            if (types.includes('administrative_area_level_1')) {
                address.company_state = component.long_name;
            }
            if (types.includes('locality')) {
                address.company_city = component.long_name;
            }
            if (types.includes('postal_code')) {
                address.company_zipCode = component.long_name;
            }
        }

        address.company_address = place?.formatted_address
        address.lat = place.geometry.location.lat();
        address.lng = place.geometry.location.lng();
        setcompanylocation({ ...address });
    }
    // This function store the user detail if user wants to read the terms and conditions and redirect user to the terms and conditions page
    const handleData = () => {
        localStorage.setItem('agentRegisterFormData', JSON.stringify(form))
        localStorage.setItem('agentRegisterLocationData', JSON.stringify(location))
        localStorage.setItem('agentRegistercompanyLocationData', JSON.stringify(companylocation))
        navigate('/agent/termsandconditions')
    }
    // Check user to enter the proper valid password e.g one character,one number,one special character & minimum 8 characters
    const passwordvalidation = (e) => {
        let regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/
        if (regex.test(e.target.value)) {
            setform({ ...form, password: e.target.value })
            setpasswordErr(false)
        }
        else {
            setform({ ...form, password: e.target.value })
            setpasswordErr("Password should be at least one character,one number,one special character & minimum 8 characters")
        }
    }
    // Restrict user to enter only space and unwanted symbols in input field
    const nameValidation = (e, key) => {
        let values = e.target.value
        let regex = /^[A-Za-z' ]+$/
        let isvalid = regex.test(values)
        if (isvalid) {
            setform({ ...form, [key]: e.target.value })
        }
        else if (!values) {
            setform({ ...form, [key]: '' })
        }
    }
    // Check user password & confirm password is match or not
    const handleConfirmPassword = (e) => {
        setform({ ...form, confirmPassword: e.target.value })
        if (form?.password == e.target.value) {
            setconfirmpasswordErr(false)
        }
    }

    return (
        <>
            <PageLayout>
                <div className="w-full px-4 py-16  ">
                    <form onSubmit={hendleSubmit} autoComplete='off'>
                        <div className='max-w-[1368px] w-full mx-auto px-3 md:px-6'>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                <div className='checks_profiles'>
                                    <h2 className='bg-gray-100 p-3 rounded-lg '>Company Details</h2>
                                    <div className='grid grid-cols-12 gap-4 mt-6'>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative ">
                                                    <label>Company Name <span className='text-red-600'>*</span></label>
                                                    <input type="text"
                                                        onChange={e => {
                                                            const companyName = e.target.value;
                                                            if (/^[a-zA-Z\s&'"-]*$/.test(companyName)) {
                                                                setform({ ...form, Name_of_Company: companyName });
                                                            }
                                                        }}
                                                        value={form?.Name_of_Company}
                                                        className="shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500"
                                                        placeholder="Company Name"
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <label>Address <span className='text-red-600'>*</span></label>
                                            <div>
                                                <ReactGoogleAutocomplete
                                                   apiKey={siteSettings?.google_map_api_key}
                                                    onPlaceSelected={(place) => { companyaddressResult(place) }}
                                                    onChange={e => setcompanylocation({ ...companylocation, company_address: e.target.value })}
                                                    value={companylocation?.company_address}
                                                    types={['(regions)']}
                                                    key="CompanyAddress"
                                                    required
                                                    className="shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-span-6 md:col-span-6'>
                                            <label>City</label>
                                            <input type='text' value={companylocation?.company_city} onChange={e => setcompanylocation({ ...companylocation, company_city: e.target.value })} placeholder='City'
                                                className='shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500'
                                            />
                                        </div>
                                        <div className='col-span-6 md:col-span-6'>
                                            <label>State</label>
                                            <input type='text' value={companylocation?.company_state} onChange={e => setcompanylocation({ ...companylocation, company_state: e.target.value })} placeholder='State'
                                                className='shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500'
                                            />
                                        </div>

                                        <div className='col-span-6 md:col-span-6'>
                                            <label>Country</label>
                                            <input type='text' value={companylocation?.company_country} onChange={e => setcompanylocation({ ...companylocation, company_country: e.target.value })} placeholder='Country'
                                                className='shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500'
                                            />
                                        </div>

                                        <div className='col-span-6 md:col-span-6'>
                                            <label>Zip Code</label>
                                            <input type='text' value={companylocation?.company_zipCode} onChange={e => setcompanylocation({ ...companylocation, ompany_zipCode: methodModel.isNumber(e) })} maxLength={9} placeholder='Zip Code'
                                                className='shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500'
                                            />
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative ">
                                                    <label>Company Email <span className='text-red-600'>*</span></label>
                                                    <input type="email"
                                                        onChange={e => setform({ ...form, Company_Email: e.target.value })}
                                                        value={form?.Company_Email}
                                                        className="shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500"
                                                        placeholder="Company Email"
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative ">
                                                    <label>Company Phone Number <span className='text-red-600'>*</span></label>
                                                    <PhoneInput country={'us'} inputProps={{ required: true }} value={form?.Company_Phone} onChange={e => setform({ ...form, Company_Phone: e })} enableSearch={true} disableSearchIcon={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative ">
                                                    <label>Registration Number <span className='text-red-600'>*</span></label>
                                                    <input type="text"
                                                        onChange={e => setform({ ...form, Registration_Number: methodModel.isNumber(e) })}
                                                        value={form?.Registration_Number}
                                                        maxLength={20}
                                                        className="shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500"
                                                        placeholder="Registration Number"
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative datepicker">
                                                    <label className='block'>Company Start Date</label>
                                                    <DatePicker selected={form?.Company_Start_Date}
                                                        onChange={date => setform({ ...form, Company_Start_Date: date })}
                                                        showMonthDropdown
                                                        placeholderText='Company Start Date'
                                                        filterDate={(date) => date <= new Date()}
                                                        className='shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='checks_profiles'>
                                    <h2 className='bg-gray-100 p-3 rounded-lg'>Agent Details</h2>
                                    <div className='grid grid-cols-12 gap-4 mt-6'>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative ">
                                                    <label>First Name <span className='text-red-600'>*</span></label>
                                                    <input type="text"
                                                        onChange={e => nameValidation(e, 'firstName')}
                                                        value={form?.firstName}
                                                        className="shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500"
                                                        placeholder="First Name"
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative ">
                                                    <label>Last Name <span className='text-red-600'>*</span></label>
                                                    <input type="text"
                                                        onChange={e => nameValidation(e, 'lastName')}
                                                        value={form?.lastName}
                                                        className="shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500"
                                                        placeholder="Last Name"
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative ">
                                                    <label>Email <span className='text-red-600'>*</span></label>
                                                    <input type="email"
                                                        onChange={e => setform({ ...form, email: e.target.value })}
                                                        value={form?.email}
                                                        className="shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500"
                                                        placeholder="Email"
                                                        required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <label>Address <span className='text-red-600'>*</span></label>
                                            <div>
                                                {show?
                                                <ReactGoogleAutocomplete
                                                    apiKey={siteSettings?.google_map_api_key}
                                                    onPlaceSelected={(place) => { addressResult(place) }}
                                                    onChange={e => setlocation({ ...location, address: e.target.value })}
                                                    value={location?.address}
                                                    types={['(regions)']}
                                                    key="AgentAddress"
                                                    required
                                                    className="shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500"
                                                />
                                                :null}
                                            </div>
                                        </div>
                                        <div className='col-span-6 md:col-span-6'>
                                            <label>City</label>
                                            <input type='text' value={location?.city} onChange={e => setlocation({ ...location, city: e.target.value })} placeholder='City'
                                                className='shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500'
                                            />
                                        </div>
                                        <div className='col-span-6 md:col-span-6'>
                                            <label>State</label>
                                            <input type='text' value={location?.state} onChange={e => setlocation({ ...location, state: e.target.value })} placeholder='State'
                                                className='shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500'
                                            />
                                        </div>
                                        <div className='col-span-6 md:col-span-6'>
                                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                                <div className=''>
                                                    <label>Country</label>
                                                    <input type='text' value={location?.country} onChange={e => setlocation({ ...location, country: e.target.value })} placeholder='Country'
                                                        className='shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500'
                                                    />
                                                </div>
                                                <div className=''>
                                                    <label>Zip Code</label>
                                                    <input type='text' value={location?.zipCode} onChange={e => setlocation({ ...location, zipCode: methodModel.isNumber(e) })} maxLength={9} placeholder='Zip Code'
                                                        className='shadow-box border-1 border-gray-300 relative bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative ">
                                                    <label>Mobile Number</label>
                                                    <PhoneInput country={'us'} value={form?.mobileNo} onChange={e => setform({ ...form, mobileNo: e })} enableSearch={true} disableSearchIcon={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative ">
                                                    <label>Password <span className='text-red-600'>*</span></label>
                                                    <span className='relative block'>
                                                        <input type={eye?.password ? `text` : 'password'}
                                                            value={form?.password} minLength={8} maxLength={16}
                                                            onChange={e => passwordvalidation(e)}
                                                            placeholder="Password" required
                                                            className="shadow-box border-1 border-gray-300  bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500"
                                                        />
                                                        {eye?.password ?
                                                            <FiEye size={15} className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer' onClick={e => seteye({ ...eye, password: false })} />
                                                            :
                                                            <FiEyeOff size={15} className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer' onClick={e => seteye({ ...eye, password: true })} />
                                                        }
                                                    </span>
                                                </div>
                                                <div><span className="invalid-feedback d-block">{passwordErr}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-span-6 md:col-span-6">
                                            <div className="">
                                                <div className="relative">
                                                    <label>Confirm Password <span className='text-red-600'>*</span></label>
                                                    <span className='relative block'>
                                                        <input type={eye?.confirmPassword ? `text` : 'password'} value={form?.confirmPassword} minLength={8} maxLength={16} onChange={e => handleConfirmPassword(e)} placeholder="Confirm Password" required
                                                            className="shadow-box border-1 border-gray-300  bg-gray-100 mb-3 w-full text-sm placeholder:text-gray-500 rounded-lg h-12 flex items-center gap-2 overflow-hidden px-2 hover:ring-blue-500 focus:border-blue-500"
                                                        />
                                                        {eye?.confirmPassword ?
                                                            <FiEye size={15} className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer' onClick={e => seteye({ ...eye, confirmPassword: false })} />
                                                            :
                                                            <FiEyeOff size={15} className='absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer' onClick={e => seteye({ ...eye, confirmPassword: true })} />
                                                        }
                                                    </span>
                                                </div>
                                                <div><span className="invalid-feedback d-block">{confirmpasswordErr}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full text-center'>
                                <div className="flex items-center my-2 mb-4">
                                    <input id="default-checkbox" type="checkbox" checked={termsAndConditions} onChange={e => settermsAndConditions(e.target.checked)} className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="default-checkbox" className="ms-2  text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer">
                                        I agree with the <span onClick={e => handleData()} className='hover:underline text-[#2849B2]'>terms and conditions.</span>
                                    </label>
                                </div>
                                <div className='mt-4 flex items-center justify-start'>
                                    <ReCAPTCHA sitekey={environment?.siteKey} onChange={setReCaptcha} ref={reCaptchaRef} />
                                </div>
                                <div className='flex items-center justify-start'>
                                    <button type='submit' disabled={!ReCaptcha || !termsAndConditions} className='bg-[#2849B2] w-96 disabled:cursor-not-allowed disabled:bg-[#6076bf] rounded-full p-4 text-white mt-4'>Submit</button>
                                </div>
                            </div>
                            {register ? <div className='px-4 py-2 inline-flex bg-green-100 text-green-600 mt-4'>Your request has been sent to the admin and they will respond within 24 hours.</div> : null}
                        </div>
                    </form>
                </div>
            </PageLayout>
        </>
    );
};

export default AgentRegister;