import moment from "moment"
import { useEffect, useState } from "react"
import { GoArrowRight } from "react-icons/go"
import { useSelector } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Tooltip as ReactTooltip } from "react-tooltip";
import Select from 'react-select';
import ApiClient from "../../methods/api/apiClient"
import DatePicker from "../../components/common/SearchBanner/DatePicker"
import { IoIosSearch } from "react-icons/io"
import { useDispatch } from "react-redux"
import { SEARCH_STATE } from "../../Redux/Action/Action";
import { colourStyles } from "../../utils/selectStyle"
import { FiEdit } from "react-icons/fi";
import { Tooltip } from "antd";

const Pricing = ({ detail, err }) => {
  const { id } = useParams()
  const search = useSelector((state) => state.Reducer.search)
  const [couponCode, setCouponCode] = useState({ ...search?.couponCode, value: search?.couponCode?.value || '', allow: false })
  const [allCouponCode, setAllCouponCode] = useState([])
  const [couponCodeErr, setCouponCodeErr] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [filters, setFilters] = useState({
    pickUpLocation: search?.pickUpLocation || '',
    dropOffLocation: search?.dropOffLocation || '',
    start_date: search?.start_date || '',
    end_date: search?.end_date || '',
    isReturn: search?.isReturn || true,
    isDriverAge: search?.isDriverAge || false,
  })
  const [jucyLocations, setJucyLocations] = useState()
  const [editLocationDates, setEditLocatioDates] = useState(false)

  useEffect(() => {
    getJucyLocations()
    getAllCouponCodes()
  }, [])

  useEffect(() => {
    if (detail?.campervan_type == 'jucy' && !detail?.DailyRate?.Value || detail?.campervan_type == 'spaceship' && detail?.defaultRate == 0 ) {
        setEditLocatioDates(true)
    } else {
      if (err) setEditLocatioDates(true)
      else setEditLocatioDates(false)
    }
  }, [detail])

  const getJucyLocations = () => {
    ApiClient.thirdPartyGet(`https://lanier.test.jucy.cloud/api/Sites/1.0/GetSites?country=all`).then(res => {
      if (res.ResponseType == 'Success') {
        setJucyLocations(res?.Data?.map((item) => {
          return ({ value: item?.SiteCode, label: item?.Name })
        }))
      }
    })
  }

  const getAllCouponCodes = () => {
    ApiClient.get(`Coupon/all?status=active`).then(res => {
      if (res.success) {
        setAllCouponCode(res?.data)
      }
    })
  }

  const handleSearchCampervans = () => {
    dispatch(SEARCH_STATE({ ...search, ...filters }))
  }

  const campervanPrice = (item) => {
    let price = Number(detail?.defaultRate || 0) + Number(detail?.serviceFees || 0) + Number(detail?.security_deposite || 0)
    if (item === 'Perday') {
      let days = detail?.campervan_type == "spaceship"? detail?.numberofdays : calculateDays(new Date(detail?.start_date || filters?.start_date || new Date()), new Date(detail?.end_date || filters?.end_date || new Date()))
      price /= days === 0 ? 1 : days
    }
    return parseFloat(price || 0).toFixed(2)
  }
  // It return the total number of days from the start and end date
  const calculateDays = (startDate, endDate) => {
    if (endDate < startDate) {
      return 0;
    }
    const diffInMilliseconds = Math.abs(endDate - startDate);
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
    return Math.ceil(diffInDays);
  };
  // It return the Campervan price
  const totalCampervanPrice = () => {
    let total = 0
    if (detail?.campervan_type == 'jucy') {
      total = Number(detail?.Total?.Value || 0) - Number(discountApplied().replace('$', ''))
    } else {
      total = Number(detail?.defaultRate) + Number(detail?.security_deposite) + Number(detail?.serviceFees)
    }
    return parseFloat(total || 0).toFixed(2)
  }

  const hanldeRedirection = () => {
    // const token = localStorage.getItem('token')
    // if (token) {
      navigate(`/bookingcampervan/${id}`)
    // } else {
    //   localStorage.setItem(`redirectURL`,`/bookingcampervan/${id}`)
    //   navigate('/signup')
    // }
  }

  const addCouponCode = () => {
    if (!couponCode?.value) {
      dispatch(SEARCH_STATE({ ...search, couponCode: null }))
      setCouponCode({ allow: false, value: '' })
    }
    if (allCouponCode?.length != 0) {
      allCouponCode && allCouponCode?.filter((item, index) => {
        if (item?.code == couponCode?.value && item?.campervan_details?.some((itm)=>itm?.id == id)) {
          dispatch(SEARCH_STATE({ ...search, couponCode: { ...item, value: couponCode?.value, allow: false } }))
          setCouponCode((prev) => ({ ...prev, ...item, allow: false }))
        }
        else setCouponCodeErr('Invalid Discount Code')
      })
    } else setCouponCodeErr('Invalid Discount Code')
  }

  const discountApplied = () => {
    if (couponCode?.discount_type == 'Percentage') {
      let amount = detail?.Total?.Value || totalCampervanPrice()
      let calculateAmount = (couponCode?.discount_value * amount) / 100
      return (`$${parseFloat(calculateAmount || 0).toFixed(2)}`)
    } else {
      return `$${couponCode?.discount_value || 0}`
    }
  }

  return (
    <>
      {editLocationDates ?
        <div className="border border-primary rounded-2xl p-3 lg:px-6 lg:py-5">
          <h1 className="text-base xl:text-lg font-semibold text-secondary mb-4">To find the pricing fill the below details.</h1>
          <div>
            <div className="grid grid-cols-12 gap-3 ">
              <div className="col-span-12 lg:col-span-12">
                <div className="flex flex-col gap-3 flex-grow ">
                  <div className="flex-grow ">
                    <label htmlFor="" className="text-sm/4 font-medium text-secondary inline-block mb-2">Pickup Location</label>
                    <div className="w-full locationSelect">
                      <Select
                        styles={colourStyles}
                        placeholder="Enter City, Airport or other location"
                        className="capitalize w-full cursor-pointer sm:min-w-full lg:max-w-[313px]"
                        value={filters?.pickUpLocation}
                        onChange={(e) => setFilters((prev) => ({ ...prev, pickUpLocation: e }))}
                        options={jucyLocations}
                        isClearable
                        classNamePrefix="react zplus"
                      />
                    </div>
                  </div>
                  {filters?.isReturn ? <></> : <>
                    <div className="">
                      <label htmlFor="" className="text-sm/4 font-medium text-secondary inline-block mb-2">Dropoff Location</label>
                      <div className="w-full locationSelect">
                        <Select
                          styles={colourStyles}
                          placeholder="Enter City, Airport or other location"
                          className="capitalize w-full cursor-pointer sm:min-w-full lg:max-w-[313px]"
                          value={filters?.dropOffLocation}
                          onChange={(e) => setFilters((prev) => ({ ...prev, dropOffLocation: e }))}
                          options={jucyLocations}
                          isClearable
                          classNamePrefix="react zplus"
                        />
                      </div>
                    </div>
                  </>}
                </div>
                <div className="mt-3 flex gap-2 items-center">
                  <input type="checkbox"
                    checked={filters?.isReturn}
                    onChange={e => {
                      setFilters((prev) => ({ ...prev, isReturn: e.target.checked, dropOffLocation: '' }))
                    }}
                    name="sameLocation" id="sameLocation" className="shrink-0 text-primary size-4 border border-gray-400 rounded-sm  appearance-none cursor-pointer" />
                  <label htmlFor="sameLocation" className="text-sm/none text-[#6B6B6B] cursor-pointer ">Return campervan to same location</label>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-12">
                <div className="flex gap-2 md:gap-4 flex-col 2xl:flex-row" id="details_calender">
                  <DatePicker
                    label="Pickup Date"
                    startDate={filters?.start_date}
                    endDate={filters?.end_date}
                    onChange={(e) => {
                      setFilters((prev) => ({ ...prev, start_date: e.startDate, end_date: e.endDate }))
                    }}
                    placeholder="MM/DD/YYYY"
                  />
                  <DatePicker
                    label="Return Date"
                    showEndDate={true}
                    startDate={filters?.start_date}
                    endDate={filters?.end_date}
                    onChange={(e) => {
                      setFilters((prev) => ({ ...prev, start_date: e.startDate, end_date: e.endDate }))
                    }}
                    placeholder="MM/DD/YYYY"
                  />
                </div>
              </div>
              <div className="col-span-12">
                <div className="flex justify-center">
                  <button type="button" onClick={() => handleSearchCampervans()} className="py-3 mt-4 px-[30px] w-full rounded-xl bg-primary hover:opacity-80 transition-all flex items-center gap-1 whitespace-nowrap justify-center text-base/4 font-medium text-white">
                    <IoIosSearch className="text-xl text-white" /> Find
                  </button>
                </div>
              </div>
            </div>
          </div>
          {err ? <div className="mt-4 text-red-600 text-base text-center">{err}</div> : null}
        </div>
        :
        <div className="border border-primary rounded-xl py-3 lg:py-6">
          <div className="texting_start">
            <h6 className="text-gray-light text-sm font-normal px-3 lg:px-6 "><span className="text-secondary text-lg font-semibold">{detail?.DailyRate?.CurrencyCode || 'AUD'}${detail?.DailyRate?.Value ? detail?.DailyRate?.Value : campervanPrice('Perday')}/</span>Per Day</h6>
          </div>
          <div className="flex flex-wrap items-center justify-between gap-4 border-b border-gray-border mt-4 pb-2 lg:pb-4 px-3 lg:px-6">
            <div className="flex items-center gap-x-2">
              <p className="whitespace-nowrap text-sm text-gray-light">{moment(detail?.start_date || filters?.start_date || new Date()).format('MMM DD, YYYY')}</p>
              <GoArrowRight size={16} className="text-gray-light" />
              <p className="whitespace-nowrap text-sm text-gray-light">{moment(detail?.end_date || filters?.end_date || new Date()).format('MMM DD, YYYY')}</p>
            </div>
            <button type="button" className="flex items-center gap-x-2 group " onClick={e => setEditLocatioDates(true)}>
              {/* <img src="/assets/img/front/edit.svg" alt="" width={20} className="size-5 shrink-0" /> */}
              <FiEdit className="text-secondary group-hover:text-primary" size={20} />
              <p className="text-sm  text-secondary group-hover:text-primary transition-all ">Edit</p>
            </button>
          </div>
          <div className="border-b border-gray-border pb-2 lg:pb-4">
            <div className="flex items-center justify-between mt-3  px-3 lg:px-6">
              <div className="flex items-center gap-x-2 ">
                <p className="text-sm text-gray-light">Rental {detail?.campervan_type == "spaceship"? detail?.numberofdays: detail?.RentalDays || calculateDays(new Date(detail?.start_date || filters?.start_date), new Date(detail?.end_date || filters?.end_date))}x Days</p>
              </div>
              <div className="price-text">
                <p className="text-base font-medium  text-[#2b2b2b]">${detail?.Total?.Value || totalCampervanPrice('campervanOnly')}</p>
              </div>
            </div>
          </div>
          {search?.couponCode ?
            <div className="flex items-center justify-between flex-wrap mt-4">
              <div className="flex items-center gap-x-6">
                <p className="text-base text-primary">Coupon code applied</p>
                <Link to="#">
                  <div className="flex items-center gap-x-2" onClick={e => (setCouponCode((prev) => ({ ...prev, allow: true })), setCouponCodeErr(false))}>
                    <img src="/assets/img/front/edit.svg" alt="edit" width={20} height={20} className="size-5 shrink-0" />
                    <p className="text-base/5 text-secondary hover:text-primary transition-all">Edit</p>
                  </div>
                </Link>
              </div>
              <p className="text-base text-primary ml-auto">{discountApplied()}</p>
            </div>
            : null}
          <div className="text_lines p-3 lg:py-5 lg:px-6">
            {search?.couponCode ? null :
              <button type="button" onClick={() => (setCouponCode((prev) => ({ ...prev, allow: !couponCode?.allow })),setCouponCodeErr(false))} className="text-primary text-base font-normal hover:underline transition-all">Do you have a coupon code?</button>
            }
            {couponCode?.allow &&
              <div className="mt-3">
                <h6 className="text-base font-medium text-secondary mb-2">Discount Code</h6>
                <p className="text-sm text-gray-light">Enter a discount code and save more!</p>
                <div className="mt-4 flex gap-3 items-center">
                  <input type="text" value={couponCode?.value} onChange={e => setCouponCode((prev) => ({ ...prev, value: e.target.value }))} className="w-full border border-[#BCBCBC] outline-none px-3 py-2 h-11 focus:border-primary focus:border-[1.2px] appearance-none rounded-xl" placeholder="Enter a discount code" required />
                  <button type="button" onClick={e => addCouponCode()} className="h-11 bg-primary hover:bg-primary/80 px-6 outline-none text-[#fff] rounded-xl">Apply</button>
                </div>
                {couponCodeErr ? <span className="text-red-600 mt-4">{couponCodeErr}</span> : null}
              </div>
            }
          </div>
          <div className="pb-2.5 lg:pb-5 px-3 lg:px-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-6">
                <p className="text-base lg:text-xl font-semibold text-gray-light">Total</p>
              </div>
              <div className="price-text">
                <p className="text-base lg:text-2xl font-semibold text-secondary">${totalCampervanPrice()}</p>
              </div>
            </div>
          </div>
          <div className="px-3 lg:px-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-2">
                <p className="text-sm text-gray-light font-normal">Security deposit (refundable)</p>
                <div className="App">
                <Tooltip placement="top" title="The security deposit or bond is refundable.However, please note we will not be liable for any fluctuations in exchange rates." trigger="hover" >
                  <h2 
                  // data-tooltip-id="my-tooltip-2"
                   className="cursor-pointer">
                    <img src="/assets/img/front/imark.svg" alt="" />
                  </h2>
                  </Tooltip>
                </div>
                {/* <ReactTooltip
                  id="my-tooltip-2"
                  classNameArrow="border-b border-r"
                  className="!z-[999] !p-4 !bg-white !opacity-100 !text-secondary !border !w-[300px] rounded-xl shadow-md"
                  place="top"
                  variant="info"
                  content={`The security deposit or bond is refundable.However, please note we will not be liable for any fluctuations in exchange rates.`}
                /> */}
              </div>
              <div className="price-text">
                <p className="text-sm text-secondary">${detail?.security_deposite || 0}</p>
              </div>
            </div>
          </div>
            <div className="px-3 lg:px-6 mt-4 lg:mt-8">
              <button type="button" onClick={e => hanldeRedirection()} className="mb-3 py-3 bg-primary w-full text-white text-lg/5 font-medium rounded-xl hover:bg-primary hover:opacity-80">Book Now</button>
              <p className="text-xs text-gray-light">You can choose Add-Ons and Insurance on the next step</p>
            </div>
           
        </div>
      }
    </>
  )
}

export default Pricing