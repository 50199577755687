import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";

const Breadcrumb = ({ links = [], currentPage = '' }) => {

  return (
    <nav aria-label="breadcrumb">
      <div className="flex items-center leading-5 flex-wrap gap-y-3 gap-2">
        {links.map((itm, idx) => (
          <div className="flex align-middle" key={idx}>
            <Link to={itm.link} className="text-base/5 text-gray-light group hover:text-primary">{itm.name}</Link>
            <FiChevronRight className=" text-gray-light min-w-[18px] mt-[3px] group-hover:text-primary" />

            {/* <MdKeyboardArrowRight size={18} className="text-secondary min-w-[18px]" /> */}
          </div>
        ))}
        <span className="text-base/5 text-secondary" aria-current="page">{currentPage}</span>
      </div>
    </nav>
  )
}

export default Breadcrumb