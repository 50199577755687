import React, { useState } from "react"
import Slider from "react-slick";

import { campervanImageSlider } from "../../../utils/slick-settings";
import methodModel from "../../../methods/methods";
import { useNavigate } from "react-router-dom";

interface ImageSliderProps {
  images: string[]
  imageCounter?: boolean
  imgHeight?: number
  id: string
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images, imageCounter = false, imgHeight = 274, id }) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const totalImages = images?.length;
  const navigate = useNavigate()

  const sliderSettings = {
    ...campervanImageSlider,
    afterChange: (index: number) => setActiveIndex(index),
  };

  const handleViewPage=(id:string)=>{
    if(id) navigate(`/campervan/detail/${id}`)
    else return
  }

  return (
    <>
      {/* {imageCounter &&
        <span className="h-9 flex gap-2 items-center rounded-lg px-[18px] py-2 bg-secondary absolute z-10 top-5 left-5 text-base/5 font-medium text-white">
          <img
            alt="ImageIcon"
            src="/assets/img/front/image-icon.svg"
            width={20}
            height={20}
            className="shrink-0 shadow-xl text-white"
          />
          {activeIndex + 1}/{totalImages}
        </span>
      } */}
      
      <Slider {...sliderSettings}>
        {images?.map((image, idx) => (
          <div className="cursor-pointer" key={idx}>
            <img
              alt="campVan1"
              height={imgHeight}
              src={methodModel.campervanImage(image)}
              onClick={e=>handleViewPage(id)}
              style={{ height: `${imgHeight}px` }}
              className={`w-full object-cover rounded-xl`} />
          </div>
        ))}
      </Slider>
    </>
  )
}

export default ImageSlider