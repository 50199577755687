import React from "react"
import Slider from "react-slick";
import { trendingDestinationsSlider } from "../../utils/slick-settings";
import { TrendingDestinationData } from "../../utils/static-data";


const TrendingDestinationSlider: React.FC = () => {
  return (
    <>
      <Slider {...trendingDestinationsSlider} className="-mx-3 campervan">
        {TrendingDestinationData.map((itm, idx) => (
          <div key={idx} className="px-2 xl:px-4">
            <div className="h-[336px] md:h-[421px] rounded-2xl relative overflow-hidden">
              <img
                alt={itm.title}
                src={itm.image}
                className="rounded-2xl h-full w-full object-cover object-center" />
              <div className="absolute bottom-0 w-full h-24 bg-trending flex items-center justify-center">
                <span className="text-base lg:text-[20px] font-semibold  text-[#F6F7FB]">{itm.title}</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  )
}

export default TrendingDestinationSlider