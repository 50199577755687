import React from "react";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
// import { TiArrowSortedUp } from "react-icons/ti";
import { HiMiniArrowsUpDown } from "react-icons/hi2";
import { FiChevronUp } from "react-icons/fi";
import { IoMdArrowDropup } from "react-icons/io";
import { MdKeyboardArrowUp } from "react-icons/md";


interface Option {
  value: string;
  name: string;
}

interface SortSelectProps {
  options: Option[];
  classes: string;
  labelBg: string;
  label?: string;
  value: Option;
  onChange: (option: Option) => void;
}

const SortSelect: React.FC<SortSelectProps> = ({
  options,
  classes,
  value,
  onChange,
}) => {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div className={`relative ${classes} `}>
          <div className="flex gap-2.5 items-center justify-center">
          <div className="text-sm font-medium text-secondary whitespace-nowrap">Sort by:</div>
          <Listbox.Button
            className={`${classes} relative cursor-pointer text-left outline-none text-sm font-normal text-secondary bg-white h-10 px-3 py-0.5`}>
            <span className="pl-6 lg:pl-6 block truncate border border-gray-border h-10 rounded-xl py-2 text-gray-light  w-[170px] "> {value?.name}</span>
            {/* <span className="pointer-events-none absolute inset-y-0 left-4 flex items-center  ">
              <HiMiniArrowsUpDown className="size-4 text-secondary " />
            </span> */}
            <span className="pointer-events-none absolute inset-y-0 right-6 top-1.5 flex items-center group">
            <MdKeyboardArrowUp
              className={`   ${open ? "" : "rotate-180 transform"
              } size-5 text-secondary`} 
              />
              {/* <TiArrowSortedUp
                className={`${open ? "" : "rotate-180 transform"
                  } size-4 text-secondary`}
              /> */}
            </span>
          </Listbox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options
              className={` ${classes} scrollbar z-[9999] absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm appearance-none`}>
              {options.map((option, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active , selected }) =>
                    `relative select-none py-2 px-4 cursor-pointer ${selected ? "bg-primary text-white transition-all" : active ? "bg-primary text-white transition-all" : "text-gray-900"}`
                  }
                  value={option}>
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? "font-semibold" : "font-normal"
                          }`}>
                        {option.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

export default SortSelect;
