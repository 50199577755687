import PageLayout from "../../components/global/PageLayout";

export const CustomerProfile=()=>{
return(
    <>
        <PageLayout>
            <div className=" w-full mx-auto px-8 md:px-8 lg:px-16 xl:px-24 2xl:px-40">
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-[20px] lg:gap-x-[40px] 2xl:gap-x-[70px]">
                    <div className="leftsidebar w-full md:w-1/3"></div>
                    <div className="leftsidebar w-full md:w-2/3"></div>
                </div>
            </div>
        </PageLayout>
    </>
)
}