import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import { BiArchiveIn } from "react-icons/bi";
import { Link } from 'react-router-dom';

const MyTabs: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [hoveredSection, setHoveredSection] = useState<number | null>(null);


  const handleTabChange = (index: number) => {
    setCurrentTab(index);
  };

  return (
    <Tab.Group selectedIndex={currentTab} onChange={handleTabChange}>
      <Tab.List className="flex space-x-12 items-center border-b border-[#D4E2FE] overflow-auto scrollbar">
        <Tab className={({ selected }) => 
          `whitespace-nowrap text-base/10 font-normal outline-none ${
            selected ? 'border-b border-[#2849B2] text-secondary' : 'text-secondary/70'
          }`
        }>
          All Messages
        </Tab>
        <Tab disabled className={({ selected }) => 
          `whitespace-nowrap text-base/10 font-normal outline-none ${
            selected ? 'border-b border-[#2849B2] text-secondary' : 'text-secondary/70'
          }`
        }>
          Enquiries
        </Tab>
        <Tab disabled className={({ selected }) => 
          `whitespace-nowrap text-base/10 font-normal outline-none ${
            selected ? 'border-b border-[#2849B2] text-secondary' : 'text-secondary/70'
          }`
        }>
          Requests
        </Tab>
        <Tab disabled className={({ selected }) => 
          `whitespace-nowrap text-base/10 font-normal outline-none ${
            selected ? 'border-b border-[#2849B2] text-secondary' : 'text-secondary/70'
          }`
        }>
          Archives
        </Tab>
      </Tab.List>
      <Tab.Panels className="pt-8">
        <Tab.Panel>
        {[...Array(2)].map((_itm, idx) => (
      <div
        key={idx}
        className="flex justify-between items-center gap-4 mb-8 p-3 cursor-pointer hover:bg-[#F4F8FF]"
        onMouseEnter={() => setHoveredSection(idx)} 
        onMouseLeave={() => setHoveredSection(null)}
      >
        <div className="flex gap-3 items-center">
          <div className="relative">
            <img
              src="/assets/img/user-3.png"
              alt="messageImg"
              className="block w-10 h-10 rounded-full"
            />
            <div className="absolute bg-white bottom-0 right-2 size-3 p-1 rounded-full">
              <span className="block bg-[#63C853] "></span>
            </div>
          </div>
          <div>
            <h6 className="text-base sm:text-lg font-medium text-secondary mb-1">
              Rodger Moody
            </h6>
            <p className="text-base font-normal text-secondary/70 mb-2">
              I am ok right now.
            </p>
            <span className="text-base/5 font-normal text-secondary rounded bg-[#CDD5DE] h-7 w-[74px] shrink-0 flex items-center justify-center">
              Expired
            </span>
          </div>
        </div>
        <p className="text-base font-normal text-secondary/70 text-end">
          Mar {idx + 3}
          {hoveredSection === idx && (
            <div className="flex items-center gap-1 mt-[5px]">
              <BiArchiveIn className="size-6 text-secondary" />
              <Link to="" className="text-lg font-medium text-secondary underline">
                Archive
              </Link>
            </div>
          )}
        </p>
      </div>
    ))}
        </Tab.Panel>
        <Tab.Panel className="text-lg"></Tab.Panel>
        <Tab.Panel className="text-lg"></Tab.Panel>
        <Tab.Panel className="text-lg"></Tab.Panel>
      </Tab.Panels>
    </Tab.Group >
  );
};


export default MyTabs;