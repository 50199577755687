import { Menu, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { Link, useNavigate } from "react-router-dom"
import { BsArrowRight } from "react-icons/bs"
import { FiChevronUp } from "react-icons/fi";
import ApiClient from "../../../methods/api/apiClient";
import { useEffect, useState } from "react";


const MegaMenuDropdown = () => {
  const [country, setcountry] = useState([])
  // const [region, setregion] = useState([])
  const [cities, setCities] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    getCountries()
    // getRegions()
    getCities()
  }, [])

  const getCountries = () => {
    ApiClient.get(`countries?status=active&country_type=jucy`).then((res) => {
      if (res.success) {
        setcountry(res?.data?.map((item) => {
          return ({ ...item, value: item?.id, label: item?.country_name })
        }))
      }
    })
  }
  // const getRegions = () => {
  //   ApiClient.get(`regions?status=active&region_type=jucy`).then((res) => {
  //     if (res.success) {
  //       setregion(res?.data?.map((item) => {
  //         return ({ value: item?.id, label: item?.name })
  //       }))
  //     }
  //   })
  // }
  const getCities = () => {
    ApiClient.get(`cities?status=active&city_type=jucy`).then(res => {
      if (res.success) {
        setCities(res?.data?.map((item) => {
          return ({ ...item, value: item?.id || item?._id, label: item?.city_name })
        }))
      }
    })
  }

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <div className="flex gap-1 items-center md:justify-center group">
              <Menu.Button className={`block py-2 md:text-sm xl:text-base hover:text-primary hover:underline underline-offset-8 ${open ? "text-primary underline" : "text-secondary/65"} font-normal`}>
                Destinations
              </Menu.Button>
              <FiChevronUp
                className={` group-hover:text-primary ${open ? "" : "rotate-180 transform"
                  } size-5 text-secondary/65`}
              />
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute z-20 mt-[18px] left-1/2 -translate-x-1/2 w-full transform shadow-xl max-md:px-2">
                <div className="overflow-hidden bg-white py-8 md:pt-[41px] md:pb-[82px]">
                  <div class="max-w-[1368px] w-full mx-auto px-3 md:px-6">
                    <div className="flex flex-wrap gap-5 xl:gap-20 2xl:gap-[200px]">
                      <div className="max-w-[302px] xl:max-w-[702px] flex justify-between w-full">
                        <div>
                          <h6 className="text-base/5 font-semibold text-secondary mb-3">Countries</h6>
                          <ul>
                            {country && country?.map((itm, idx) => (
                              <li key={idx} className="text-base/8 font-normal text-secondary/60 hover:text-primary cursor-pointer capitalize" onClick={e=>navigate(itm?.pageUrl)}>{itm?.label}</li>
                            ))}
                          </ul>
                        </div>
                        {/**<div>
                          <h6 className="text-base/5 font-semibold text-secondary mb-3">Regions</h6>
                          <ul>
                            {region && region?.map((itm, idx) => (
                              <li key={idx} className="text-base/8 font-normal text-secondary/60 hover:text-primary cursor-pointer capitalize">{itm?.label}</li>
                            ))}
                          </ul>
                        </div>**/}
                        <div>
                          <h6 className="text-base/5 font-semibold text-secondary mb-3">Cities</h6>
                          <ul>
                            {cities && cities?.map((itm, idx) => (
                              <li key={idx} className="text-base/8 font-normal text-secondary/60 hover:text-primary cursor-pointer capitalize" onClick={e=>navigate(itm?.pageUrl)}>{itm?.city_name}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="flex-1">
                        <h4 className="text-2xl/5 font-semibold text-secondary">Travel with us !</h4>
                        <Link to="#" className="inline-flex gap-3 items-center text-lg/6 text-primary mb-[22px] mt-2">
                          See all Locations
                          <BsArrowRight size={24} />
                        </Link>
                        <img src="/assets/img/header-campervan.png" alt="" className="rounded-2xl" />
                      </div>
                    </div>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  )
}

export default MegaMenuDropdown