import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Html from './Html';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { JUCY_IMAGES, LOGIN_SUCCESS, LOGOUT_SUCCESS, SEARCH_STATE, SITE_SETTINGS } from '../../../Redux/Action/Action';
import ApiClient from '../../../methods/api/apiClient';
import environment from '../../../environment';
import loader from '../../../methods/loader';
import * as Yup from "yup";

const Header = () => {
  const user = useSelector((state) => state.User)
  const dispatch = useDispatch()
  const history = useNavigate();
  const siteSettings = useSelector((state) => state.Reducer.siteSettings)
  const urlPath = window.location.pathname
  let [isOpen, setIsOpen] = useState(false)
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });

  useEffect(() => {
    if (urlPath.includes('/campervan/detail') || urlPath == '/' || urlPath == '/campervans' || urlPath == "/campervanlist") getAllJucyAmenitiesImages()
    getSiteSettingsDetail()
    //Its scroll the screen at the top
    window.scrollTo({ top: 0 });
    // For Create Password Modal
    if (urlPath == "/dashboard" && user?.role == "agent" || urlPath == "/campervanlist" && user?.role == "customer" || urlPath == "/customer" && user?.role == "sub_agent") {
      if (user?.is_first_login == 'Yes') {
        setIsOpen(true)
      }
    }
    if (urlPath == '/') {
      let clearSearchFilters = { dropOffLocation: '', end_date: '', isDriverAge: false, pickUpLocation: '', start_date: '', type: '', isReturn: true, region: '', transmission: '', fuelType: '', vehicleAge: '', amenitieis: [], priceStart: '', priceEnd: '', vehiclecategoryid: "", vehiclecategorytypeid: "" }
      dispatch(SEARCH_STATE(clearSearchFilters))
    }
  }, [])

  const FormSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Password must be at least 8 cahracters')
      .required('Password  is required')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol')
      .max(16, 'Password must be less than 16 characters'),
    confirmpassword: Yup.string()
      .min(8, 'Password must be at least 8 cahracters')
      .required('Confirm password  is required')
      .oneOf([Yup.ref('password'), null], "Confirm password not match with password")
  })

  const handleSubmit = (value) => {
    loader(true)
    let payload = {
      newPassword: value.password,
      confirmPassword: value.confirmpassword,
    }
    ApiClient.put('change/password', payload).then(res => {
      if (res.success) {
        setIsOpen(false)
        ApiClient.get(`user/detail?id=${user?._id || user?.id}`).then((res) => {
          if (res.success) {
            dispatch(LOGIN_SUCCESS(res?.data))
          }
        })
      }
      loader(false)
    })
  }

  // To get the All Amenities Images
  const getAllJucyAmenitiesImages = () => {
    ApiClient.get(`all/masterAmenities`).then(res => {
      if (res.success) {
        let data = res?.data?.map(item => {
          return ({ name: item?.name, image: item?.image })
        })
        dispatch(JUCY_IMAGES(data))
      }
    })
  }

  // For Site Setting details
  const getSiteSettingsDetail = () => {
    ApiClient.get(`Settings?slug=settings`).then((res) => {
      if (res.success) {
        //Set the site settings data in the redux persist
        dispatch(SITE_SETTINGS(res?.data))
        setSiteSettings(res?.data)
      }
    })
  }

  const setSiteSettings = (data) => {
    // Update the favicon
    const favicon = document.getElementById('favIcon')
    favicon.href = `${environment.api}images/users/${data?.favicon}`

    // Append the scripts in body
    data?.scripts?.map((item, index) => {
      if (item?.isActive) {
        if (item?.script.includes('<script')) {
          const parser = new DOMParser();
          const fragment = parser.parseFromString(item?.script, 'text/html');
          const script = fragment.querySelector('script');
          document.getElementById('mainroot').appendChild(script);
        } else {
          let script = document.createElement('script');
          script.setAttribute('src', item?.script);
          document.body.appendChild(script);
        }
      }
    })
  }

  const Logout = () => {
    dispatch(LOGOUT_SUCCESS())
    localStorage.removeItem("persist:root")
    localStorage.removeItem("token")
    history('/login');
  };

  return (
    <Html
      user={user}
      Logout={Logout}
      siteSettings={siteSettings}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      eyes={eyes}
      setEyes={setEyes}
      FormSchema={FormSchema}
      handleSubmit={handleSubmit}
    />
  );
};

export default Header;
