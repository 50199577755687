import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Agentlogin from './Pages/Agent login/index'
import NotFoundPage from './Pages/NotFoundPage';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import "react-datepicker/dist/react-datepicker.css";
import Profile from './Pages/Profile';
import Settings from './Pages/Settings';
import Forgotpassword from './Pages/Forgotpassword';
import Resetpassword from './Pages/Resetpassword';
import Dashboard from './Pages/Dashboard';
import Signup from './Pages/Signup';
import AgentRegister from './Pages/AgentRegister';
import Customer from './Pages/Customer';
import AddEditCustomer from './Pages/Customer/AddEditCustomer';
import CustomerDetail from './Pages/Customer/CustomerDetail';
import CampervanListing from './Pages/Campervan/CampervanListing';
import CampervanDetail from './Pages/Campervan/CampervanDetail';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermAndCondition from './Pages/TermAndCondition';
import CookiesPolicy from './Pages/CookiesPolicy';
import InsuranceAndProtection from './Pages/InuranceAndProtection';
import BookingCampervan from './Pages/BookingCampervan';
import AgentTermsAndConditions from './Pages/ContentManagement/AgentTermsAndConditions';
import Aboutus from './Pages/SitesPages/AboutUs';
import Contactus from './Pages/SitesPages/ContactUs';
import HowItWorks from './Pages/SitesPages/HowItWorks';
import { CustomerProfile } from './Pages/ProfileCustomer/CustomerProfile'
import Booking from './Pages/Booking';
import BookingListing from './Pages/Booking/BookingListing';
import BookingDetail from './Pages/Booking/BookingDetail';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/Blog/BlogDetail';
import Itineraries from './Pages/Itineraries';
import ListingCampervan from './Pages/ListCampervan';
import MyRewards from './Pages/MyRewards';
import MyMessages from './Pages/MyMessages';
import MessageInbox from './Pages/MyMessages/Inbox';
import MyWishlist from './components/common/Wishlist';
import Invoice from './Pages/Invoice';
import CampervanList from './Pages/CampervanList';
import GeoPage from './Pages/GeoPage';
import SubAgent from './Pages/SubAgent';
import AddEditSubAgent from './Pages/SubAgent/AddEditSubAgent';
import SubAgentDetail from './Pages/SubAgent/SubAgentDetail';

function App() {
  const routes = [
    { url: '', element: <Home /> },
    { url: '/login', element: <Login /> },
    { url: '/agentlogin', element: <Agentlogin /> },
    { url: '/signup', element: <Signup /> },
    { url: '/dashboard', element: <Dashboard /> },
    { url: '/profile', element: <Profile /> },
    { url: '/profile/:tab', element: <Settings /> },
    { url: '/forgotpassword', element: <Forgotpassword /> },
    { url: '/resetpassword', element: <Resetpassword /> },
    { url: '/subagent', element: <SubAgent /> },
    { url: '/subagent/add', element: <AddEditSubAgent /> },
    { url: '/subagent/edit/:id', element: <AddEditSubAgent /> },
    { url: '/subagentdetail/:id', element: <SubAgentDetail /> },
    { url: '/agentregister', element: <AgentRegister /> },
    { url: '/campervans', element: <CampervanListing /> },
    { url: '/campervan/detail/:id', element: <CampervanDetail /> },
    { url: '/campervan/detail/:id/:type', element: <CampervanDetail /> },
    { url: '/privacypolicy', element: <PrivacyPolicy /> },
    { url: '/termandcondition', element: <TermAndCondition /> },
    { url: '/cookiespolicy', element: <CookiesPolicy /> },
    { url: '/insuranceandprotection', element: <InsuranceAndProtection /> },
    { url: '/bookingcampervan/:id', element: <BookingCampervan /> },
    { url: '/bookingcampervan/:id/:bookingId', element: <BookingCampervan /> },
    { url: '/about-us', element: <Aboutus /> },
    { url: '/contact-us', element: <Contactus /> },
    { url: '/how-it-works', element: <HowItWorks /> },
    { url: '/agent/termsandconditions', element: <AgentTermsAndConditions /> },
    { url: '/customer', element: <Customer /> },
    { url: '/customer/add', element: <AddEditCustomer /> },
    { url: '/customer/edit/:id', element: <AddEditCustomer /> },
    { url: '/customerdetail/:id', element: <CustomerDetail /> },
    { url: '/campervanlist', element: <CampervanList /> },
    { url: '/user-profile', element: <CustomerProfile /> },
    // { url: '/booking/:id', element: <Booking /> },
    // { url: '/booking/:id/:bookingId', element: <Booking /> },
    { url: '/bookings', element: <BookingListing /> },
    { url: '/booking/detail/:id', element: <BookingDetail /> },
    { url: '/blog', element: <Blog /> },
    { url: '/blog/detail/:id', element: <BlogDetail /> },
    { url: '/itineraries', element: <Itineraries /> },
    { url: '/itineraries/:id', element: <Itineraries /> },
    { url: '/list-campervan', element: <ListingCampervan /> },
    { url: '/rewards', element: <MyRewards /> },
    { url: '/my-message', element: <MyMessages /> },
    { url: '/message-inbox', element: <MessageInbox /> },
    { url: '/my-wishlist', element: <MyWishlist /> },
    { url: '/invoice', element: <Invoice /> },
    { url: '/:name', element: <GeoPage /> },
    { url: '*', element: <NotFoundPage /> },
  ]
  return (
    <>
      <Router>
        <Routes>
          {routes.map((itm,index) => {
            return <Route path={itm.url} element={itm.element} key={index} />
          })}
        </Routes>
      </Router>
      <div id="loader" className="loaderDiv d-none">
        <div className='bg-black/60 h-full w-full flex flex-col gap-y-3 items-center justify-center relative'>
          <div className='flex gap-3'>
            <h4 className='text-2xl -mt-2 text-white font-semibold'>Loading</h4>
            <div className="">
              <div className="loader rounded-full flex gap-2">
                <div className="w-4 h-4 bg-[#fff] rounded-full animate-bounce"></div>
                <div className="w-4 h-4 bg-[#fff] rounded-full animate-bounce"></div>
                <div className="w-4 h-4 bg-[#fff] rounded-full animate-bounce"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;