import { useEffect, useState } from "react";
import PageLayout from "../../components/global/PageLayout";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import CampervanDetail from "./CampervanDetail";
import CampervanCategory from "./CampervanCategory";
import PhotoUpload from "./PhotoUpload";
// import { IoIosArrowBack } from "react-icons/io";
import Amenities from "./Amenities";
import DescriptionRule from "./DescriptionRule";
import ListingPricing from "./ListingPricing";
import ReviewPreview from "./ReviewPreview";
import { FiChevronLeft } from "react-icons/fi";

const ListingCampervan: React.FC = () => {
  
  const [tab, settab] = useState(1);
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (tab == 1) {
      navigate(-1);
    } else {
      settab(tab - 1);
    }
  };

  const TabsContent = () => {
    switch (tab) {
      case 1:
        return <CampervanCategory tab={tab} settab={settab} />;
      case 2:
        return <CampervanDetail tab={tab} settab={settab} />;
      case 3:
        return <PhotoUpload tab={tab} settab={settab} />;
      case 4:
        return <Amenities tab={tab} settab={settab} />;
      case 5:
        return <DescriptionRule tab={tab} settab={settab} />;
      case 6:
        return <ListingPricing tab={tab} settab={settab} />;
      case 7:
        return <ReviewPreview tab={tab} settab={settab} />;
      default:
        return <CampervanCategory tab={tab} settab={settab} />;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tab]);

  return (
    <PageLayout>
      <div className={`max-w-[1504px] w-full mx-auto px-4 sm:px-8 mt-5`}>
        <div
          className="flex gap-2 items-center cursor-pointer mb-6 lg:mb-10"
          onClick={(e) => handleBackButton()}>
            <FiChevronLeft />
          {/* <IoIosArrowBack /> */}
          <p className="text-secondary font-normal text-base">Back</p>
        </div>
        <div className="w-full">
          <ul className=" flex items-center justify-between w-full">
            <li
              className={`w-[47%] after:content-[''] after:w-full ${tab == 1 ? "after:bg-[#9BAFED]" : "after:bg-primary"
                } after:h-[10px] after:absolute after:-right-[29px] relative after:top-[10px]`}
            >
              <p
                className={`${tab == 1 ? "bg-primary text-white" : "bg-primary"
                  }  size-8 rounded-full flex items-center justify-center text-xs font-semibold z-10	relative`}
              >
                {tab == 1 ? 1 : <FaCheck className="text-white" />}
              </p>
              <h4 className="w-33% -ms-[60px] text-sm font-medium text-secondary mt-4 hidden xl:block ml-1">
                Campervan Category
              </h4>
            </li>
            <li
              className={`hidden sm:block w-[47%] after:content-[''] after:w-full ${tab <= 2 ? "after:bg-[#9BAFED]" : "after:bg-primary"} after:h-[10px] after:absolute after:-right-[31px] relative after:top-[10px]`}
            >
             <p
                className={`${tab >= 3 ? "bg-primary text-white" : "bg-primary"
                  } size-8 rounded-full flex items-center justify-center text-white font-semibold text-xs z-10	relative`}
              >
                {tab <= 2 ? 2 : <FaCheck className="text-white" />}
              </p>
              <h4 className="-ms-[50px] mt-4 text-sm font-medium text-secondary hidden xl:block">
                Campervan Details
              </h4>
            </li>

            <li
              className={`hidden sm:block w-[47%] after:content-[''] after:w-full ${tab <= 3 ? "after:bg-[#9BAFED]" : "after:bg-primary"
                } after:h-[10px] after:absolute after:-right-[31px] relative after:top-[10px]`}
            >
              <p
                className={`${tab >= 4 ? "bg-primary text-white" : "bg-primary"
                  } size-8 rounded-full flex items-center justify-center text-white font-semibold text-xs z-10	relative`}
              >
                {tab <= 3 ? 3 : <FaCheck className="text-white" />}
              </p>
              <h4 className="-ms-[40px] mt-4 text-sm font-medium text-secondary hidden xl:block">
                Photos Upload
              </h4>
            </li>
            <li
              className={`hidden sm:block w-[47%] after:content-[''] after:w-full ${tab <= 4 ? "after:bg-[#9BAFED]" : "after:bg-primary"
                } after:h-[10px] after:absolute after:-right-[31px] relative after:top-[10px]`}
            >
              <p
                className={`${tab >= 5 ? "bg-primary text-white" : "bg-primary"
                  } size-8 rounded-full flex items-center justify-center text-white font-semibold text-xs z-10	relative`}
              >
                {tab <= 4 ? 4 : <FaCheck className="text-white" />}
              </p>
              <h4 className="-ms-[23px] mt-4 text-sm font-medium text-secondary hidden xl:block">
                Amenities
              </h4>
            </li>
            <li
              className={`sm:hidden w-[47%] after:content-[''] after:w-full ${tab <= 6 ? "after:bg-[#9BAFED]" : "after:bg-primary"
                } after:h-[10px] after:absolute after:-right-[31px] relative after:top-[10px]`}
            >
              <p
                className={`${tab >= 7 ? "bg-primary text-white" : "bg-primary"
                  } size-8 rounded-full flex items-center justify-center text-white font-semibold text-xs z-10	relative`}
              >
                {tab <= 6 ? (tab == 1 ? 2 : tab) : <FaCheck className="text-white" />}
              </p>
              <h4 className="-ms-[22px] mt-4 text-sm font-medium text-secondary hidden xl:block">
                Amenities
              </h4>
            </li>
            <li
              className={`hidden sm:block w-[47%] after:content-[''] after:w-full ${tab <= 5 ? "after:bg-[#9BAFED]" : "after:bg-primary"
                } after:h-[10px] after:absolute after:-right-[31px] relative after:top-[10px]`}
            >
              <p
                className={`${tab >= 6 ? "bg-primary text-white" : "bg-primary"
                  } size-8 rounded-full flex items-center justify-center text-white font-semibold text-xs z-10	relative`}
              >
                {tab <= 5 ? 5 : <FaCheck className="text-white" />}
              </p>
              <h4 className="-ms-[50px] mt-4 text-sm font-medium text-secondary hidden xl:block">
                Description & Rules
              </h4>
            </li>
            <li
              className={`hidden sm:block w-[47%] after:content-[''] after:w-full ${tab <= 6 ? "after:bg-[#9BAFED]" : "after:bg-primary"
                } after:h-[10px] after:absolute after:-right-[30px] relative after:top-[10px]`}
            >
              <p
                className={`${tab >= 7 ? "bg-primary text-white" : "bg-primary"
                  } size-8 rounded-full flex items-center justify-center text-white font-semibold text-xs z-10 relative`}
              >
                {tab <= 6 ? 6 : <FaCheck className="text-white" />}
              </p>
              <h4 className="-ms-[33px] mt-4 text-sm font-medium text-secondary hidden xl:block">
                Listing Pricing
              </h4>
            </li>
            <li
              className={`hidden sm:block after:h-[10px] after:absolute after:-right-[37px] relative after:top-[10px]`}
            >
              <p
                className={`${tab >= 8 ? "bg-primary text-white" : "bg-primary"
                  } size-8 rounded-full flex items-center justify-center text-white font-semibold text-xs z-10	relative`}
              >
                {tab <= 7 ? 7 : <FaCheck className="text-white" />}
              </p>
              <h4 className="whitespace-nowrap -ms-[47px] mt-4 text-sm font-medium text-secondary hidden xl:block">
                Review & Preview
              </h4>
            </li>
            <li
              className={`sm:hidden after:h-[10px] after:absolute after:-right-[37px] relative after:top-[10px]`}
            >
              <p
                className={` ${tab >= 8 ? "bg-primary text-white" : "bg-primary"
                  } size-8 rounded-full flex items-center justify-center text-white font-semibold text-xs z-10 relative `}
              >
                {tab <= 7 ? (tab == 1 ? 3 : (tab < 7 ? (tab + 1) : tab)) : <FaCheck className="text-white" />}
              </p>
              <h4 className="whitespace-nowrap -ms-[40px] mt-4 text-sm font-medium text-secondary hidden xl:block">
                Review & Preview
              </h4>
            </li>
          </ul>
        </div>
      </div>
      <div className="mb-6 lg:mb-0">
        {TabsContent()}
      </div>
    </PageLayout>
  );
};

export default ListingCampervan;
