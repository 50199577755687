import PageLayout from "../../components/global/PageLayout";
import SortSelect from "../../components/common/SortSelect";
import { MdAccessTimeFilled, } from "react-icons/md";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import React, { useEffect, useState } from 'react'
import NewsLetter from "../../components/common/NewsLetter";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import methodModel from "../../methods/methods";
import Pagination from "react-js-pagination";
import Select from "react-select";
import Breadcrumb from "../../components/common/Breadcrumb";
import { colourStyles } from "../../utils/selectStyle";
import { IoIosArrowRoundForward } from "react-icons/io";
import CampervanDetail from "../../components/common/CampervanDetail";


interface DetailType {
  campervan_name: string;
}

const Blog: React.FC = () => {
  const [blogs, setBlogs] = useState([])
  const [filter, setFilter] = useState({ page: 1, count: 9, category: '', status: 'active' })
  const [totalBlogs, setTotalBlogs] = useState(0)
  const navigate = useNavigate()
  const [blogCategories, setBlogCategories] = useState([])

  useEffect(() => {
    getBlogs()
    getBlogsCategory()
  }, [])

  const getBlogs = (p = {}) => {
    const filters = { ...filter, ...p }
    loader(true)
    ApiClient.get(`Blog/all`, filters).then((res: any) => {
      if (res.success) {
        setBlogs(res?.data)
        setTotalBlogs(res?.total)
      }
      loader(false)
    })
  }

  const handlePagination = (e: any) => {
    setFilter(prev => ({ ...prev, page: e }))
    getBlogs({ page: e })
  }

  const getBlogsCategory = () => {
    ApiClient.get(`category/all?status=active&category_type=blog`).then(res => {
      if (res.success) {
        setBlogCategories(res?.data?.map((item: any) => {
          return ({ value: item?.id || item?._id, label: item?.name })
        }))
      }
    })
  }

  const campaign = [
    { value: "Campaign 1", name: "Campaign 1" },
    { value: "Campaign 2", name: "Campaign 2" },
    { value: "Campaign 3", name: "Campaign 3" },
  ]
  const [selectedCampaign, setSelectedCampaign] = useState(campaign[0]);

  const handleFilters = (e: any) => {
    setFilter({ ...filter, category: e })
    getBlogs({ category: e?.value })
  }

  const detail: DetailType = {
    campervan_name: "Blog",
  }


  return (
    <PageLayout>
      <div className="max-w-[1504px] w-full mx-auto px-4 lg:px-8">
        <div className="my-4 lg:mb-8 lg:mt-6">
          <Breadcrumb
            links={[
              { link: "/", name: "Home" },
            ]}
            currentPage={detail?.campervan_name}
          />
        </div>
        {blogs && blogs?.map((item: any, index: number) => {
          return (
            <>
              {item?.isFeatured ?
                <div className="flex items-center flex-col lg:flex-row justify-center gap-4 lg:gap-12 max-lg:flex-wrap  mt-5 sm:mt-9">
                  <div className="flex flex-col lg:max-w-[678px] w-full">
                    {item?.category_details?.name ?
                      <span className="mb-5 uppercase w-fit shrink-0 py-2 px-2.5 rounded text-sm md:text-base/5 font-medium text-primary hover:opacity-80 bg-[linear-gradient(90deg,_rgba(23,68,210,0.2)_0%,_rgba(12,35,108,0)_100%)]">
                        {item?.category_details?.name}
                      </span>
                      : null}
                    <h1 className="capitalize text-base/5 md:text-xl lg:text-3xl 2xl:text-4xl font-semibold text-secondary mb-4">{item?.heading}</h1>
                    <p className="text-sm md:text-base font-normal text-gray-light mb-4 lg:mb-8 line-clamp-4" dangerouslySetInnerHTML={{ __html: item?.description2 }}></p>
                    <button
                      type="button"
                      onClick={e => { navigate(`/blog/detail/${item?._id || item?.id}`) }}
                      className="flex items-center gap-2 w-fit shrink-0 h-10 xl:h-12 2xl:h-[54px] p-4 lg:px-8 rounded-xl bg-primary text-sm xl:text-base 2xl:text-lg font-medium text-white hover:opacity-80">
                      Read More
                      <IoIosArrowRoundForward className="size-6 text-white" />
                    </button>
                  </div>
                  <div className="lg:max-w-[714px] w-full">
                    <img
                      alt={item?.image_alt}
                      src={item?.image ? methodModel.campervanImage(item?.image) : "/assets/img/camp-van-3.png"}
                      className="w-full h-[475px] object-cover rounded-[20px]"
                    />
                  </div>
                </div>
                : null}
            </>
          )
        })}
        <div className="flex items-center justify-center flex-col sm:flex-row sm:justify-between gap-4 my-4 lg:mb-8 lg:mt-12">
          <h1 className="text-base lg:text-xl xl:text-2xl font-semibold text-secondary">
            Explore Our Latest Blog
          </h1>
          <div className="flex w-full sm:w-auto gap-4 flex-col sm:flex-row justify-center">
            <div className="blogSelect flex gap-3 items-center">
              <label htmlFor="" className="text-sm font-medium text-secondary whitespace-nowrap">Sort by:</label>
              <Select
                styles={colourStyles}
                placeholder="Select Category"
                className="capitalize w-full cursor-pointer min-w-[170px]"
                value={filter?.category}
                onChange={(e: any) => handleFilters(e)}
                options={blogCategories}
                isClearable
                classNamePrefix="react"
              />
            </div>
            {/* <SortSelect
              classes={"w-full sm:max-w-[223px] cursor-pointer"}
              labelBg={"bg-white"}
              options={campaign}
              value={selectedCampaign}
              onChange={setSelectedCampaign}
            /> */}
          </div>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-6 mb-4 lg:mb-12">
          {blogs && blogs?.map((item: any, index: number) => {
            return <>
              {!item?.isFeatured ?
                <div className="w-full cursor-pointer flex flex-col" onClick={e => { navigate(`/blog/detail/${item?._id || item?.id}`) }}>
                  <div className="w-full relative max-h-[296px] h-full">
                    <img
                      alt={item?.image_alt}
                      src={item?.image ? methodModel.campervanImage(item?.image) : '/assets/img/Rectangle.png'}
                      className="w-full h-[295px] block object-cover rounded-lg"
                    />
                    {item?.category_details?.name ?
                      <span className="rounded-lg capitalize text-center min-w-[100px] flex justify-center items-center whitespace-nowrap p-3 h-8 lg:h-9 xl:h-12 bg-secondary text-sm  leading-none lg:font-medium text-white absolute left-5 top-5">
                        {item?.category_details?.name}
                      </span>
                      : null}
                  </div>
                  <div className="flex-grow">
                    <div className="flex flex-col mt-4">
                      <div className="flex items-center gap-2 mb-2.5">
                        <MdAccessTimeFilled className="size-5 text-gray-light" />
                        <p className="text-sm font-normal text-secondary/85 break-all">{moment(item?.createdAt).format('MMMM DD, YYYY')}</p>
                      </div>
                      <h3 className="text-lg font-semibold text-secondary mb-3 capitalize">{item?.heading || '--'}</h3>
                      <p className="text-ellipsis h-24 text-base font-normal text-secondary/55 break-all line-clamp-4" dangerouslySetInnerHTML={{ __html: item?.description2 }}></p>
                    </div>
                  </div>
                  <button type="button" className="mt-6 block w-[162px] h-11  shrink-0 py-3 rounded-xl bg-transparent border border-primary text-sm xl:text-base/4 2xl:text-lg/4 font-normal text-primary hover:bg-primary hover:text-white transition-all">Read More</button>
                </div>
                : null}
            </>
          })}
        </div>
        <CampervanDetail />
        {/* <div className="mt-8 md:mt-20">
          <Pagination
            prevPageText={<FiChevronLeft className="text-2xl font-extralight" />}
            nextPageText={<FiChevronRight className="text-2xl font-extralight" />}
            activePage={filter?.page}
            itemsCountPerPage={filter?.count}
            totalItemsCount={totalBlogs}
            pageRangeDisplayed={7}
            onChange={handlePagination}
            hideFirstLastPages={true}
          />
        </div> */}
        {/* <div className="mt-6 lg:mt-28">
          <NewsLetter />
        </div> */}
      </div>
    </PageLayout>
  );
};

export default Blog;
