import { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import { useParams, useNavigate } from "react-router-dom";
import methodModel from "../../methods/methods";
import ApiClient from "../../methods/api/apiClient";
import { TiDelete } from "react-icons/ti";
import { FiUpload } from "react-icons/fi";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";

const AddEditSubAgent = () => {
    const user = useSelector((state) => state.User)
    const { id } = useParams()
    const [form, setform] = useState({ firstName: '', lastName: '', email: '', image: '', img_alt: '', commission: '', campervan_id: '' })
    const [loader, setloader] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (id) {
            getDetail()
        }
    }, [])

    const getDetail = () => {
        ApiClient.get(`user/detail?id=${id}`).then((res) => {
            if (res.success) {
                setform({
                    firstName: res?.data?.firstName,
                    lastName: res?.data?.lastName,
                    img_alt: res?.data?.img_alt,
                    email: res?.data?.email,
                    commission: res?.data?.commission,
                    image: res?.data?.image
                });
            }
        });
    };

    const onDialogClose = (e) => {
        navigate("/subagent");
    }

    // Upload image to the server and return the images path
    const uploadImage = (e) => {
        let files = e.target.files;
        let file = files.item(0);
        setloader(true)
        ApiClient.postFormData("upload/image?modelName=users", { file: file, modelName: "users", }).then((res) => {
            if (res.success) {
                setform({ ...form, image: res?.data?.fullpath });
            }
            setloader(false)
        }).catch((err) => {
            setloader(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let payload = {
            ...form,
            brand_Peertopeer: user?.brand_Peertopeer,
            campervan_type: user?.campervan_type.map(itm => itm),
            agent_id: [user?.id || user?._id],
            commission: Number(form?.commission),
        };
        if (id) {
            ApiClient.put(`admin/edit-user`, { ...payload, id: id }).then(
                (res) => {
                    if (res.success) {
                        navigate("/subagent")
                    }
                    else {
                        toast.error(res?.error?.message)
                    }
                }
            );
        } else {
            payload = {
                ...payload,
                commission: Number(form?.commission),
                agent_id: [user?.id || user?._id],
                role: "sub_agent"
            }
            ApiClient.post(`admin/add-user`, payload).then((res) => {
                if (res.success) {
                    navigate("/subagent")
                }
                else {
                    toast.error(res?.error?.message)
                }
            });
        }
    }

    // Restrict user to add commission above 100
    const handlecommission = (e) => {
        if (e <= 100) {
            setform({ ...form, commission: e })
        }
    }
    // Restrict user to add to add unwanted symbols and only space in input field
    const nameValidation = (e, key) => {
        let values = e.target.value
        let regex = /^[A-Za-z' ]+$/
        let isvalid = regex.test(values)
        if (isvalid) {
            setform({ ...form, [key]: e.target.value })
        }
        else if (!values) {
            setform({ ...form, [key]: '' })
        }
    }

    return (
        <>
            <Layout>
                <h2 className="text-2xl font-bold">{!id ? 'Add' : 'Edit'} Sub Agent</h2>
                <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="w-full mt-8 border border-[#B3B3B3] rounded-2xl p-4">
                        <div className="grid grid-cols-12 gap-4 items-center">
                            <div className="col-span-12 md:col-span-3">
                                <div className="flex items-center justify-center">
                                    <div className="relative text-center">
                                        <label className="cursor-pointer  ">
                                            <img className="mb-6 h-32 w-32 rounded-full" src={methodModel.userImg(form?.image)} />
                                            <input
                                                id="bannerImage"
                                                type="file"
                                                className="hidden"
                                                accept="image/*"
                                                value={form.baseImg ? form.baseImg : ""}
                                                onChange={(e) => { uploadImage(e) }}
                                            />
                                            {loader ?
                                                <span className="text-green-600">Uploading...</span>
                                                :
                                                <div className="border flex items-center gap-2 justify-center border-gray-500  hover:border-blue-500 hover:text-blue-500 font-semibold shadow  rounded p-2 ">
                                                    <FiUpload /> {form.image ? "Change" : "Upload"} Image
                                                </div>
                                            }
                                        </label>
                                        <div className="absolute top-1 right-2">
                                            {form.image ? (
                                                <label
                                                    className="text-4xl text-blue-500  hover:text-blue-600 cursor-pointer"
                                                    onClick={(e) => setform({ ...form, image: "" })}
                                                >
                                                    <TiDelete />
                                                </label>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-9">
                                <div className="grid sm:grid-cols-2 gap-4">
                                    <div className="form-item vertical invalid">
                                        <label className="form-label mb-2">First Name <span className="text-red-600">*</span></label>
                                        <div>
                                            <input
                                                type="text"
                                                value={form?.firstName}
                                                onChange={(e) => nameValidation(e, 'firstName')}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 h-11"
                                                placeholder="First Name"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-item vertical invalid">
                                        <label className="form-label mb-2">Last Name <span className="text-red-600">*</span></label>
                                        <div>
                                            <input
                                                type="text"
                                                value={form?.lastName}
                                                onChange={(e) => nameValidation(e, 'lastName')}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 h-11"
                                                placeholder="Last Name"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-item vertical invalid">
                                        <label className="form-label mb-2">Email <span className="text-red-600">*</span></label>
                                        <div>
                                            <input
                                                type="email"
                                                value={form?.email}
                                                onChange={(e) => setform({ ...form, email: e.target.value })}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 h-11"
                                                placeholder="Email"
                                                disabled={id}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="form-item vertical invalid">
                                        <label className="form-label mb-2">Commission <span className="text-red-600">*</span></label>
                                        <div>
                                            <input
                                                type="text"
                                                disabled={id}
                                                value={form?.commission}
                                                onChange={e => handlecommission(methodModel.isNumber(e))} maxLength={10}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3 h-11"
                                                placeholder="Commission"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right mt-6">
                            <button type="button" className="border border-black rounded-full px-5 py-2 mr-4" onClick={onDialogClose}>Back</button>
                            <button type="submit" className="bg-[#2849B2] rounded-full p-2 px-5 text-white">{id ? "Update" : "Save"}</button>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    )
}

export default AddEditSubAgent