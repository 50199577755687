import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login';
import ApiClient from "../../methods/api/apiClient";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook, FaApple } from 'react-icons/fa';
import environment from '../../environment';


const AutoLogins = ({ authFunction }: any) => {
  const googleResponseMessage = (response: any) => {
    axios.get(`https://www.googleapis.com/oauth2/v1/userinfo`, {
      headers: {
        Authorization: `Bearer ${response?.access_token}`,
        Accept: 'application/json'
      }
    }).then((res: any) => {
      let payload = {
        email: res?.data?.email,
        firstName: res?.data?.given_name,
        lastName: res?.data?.family_name,
        image: res?.data?.picture,
        role: 'customer',
      }
      ApiClient.post(`loginSignupSocialMedia`, payload).then((res: any) => {
        if (res.success) {
          authFunction(res?.data)
        }
      })
    })
      .catch((err) => console.log(err, '==Google Error'));
  };
  const handleGoogleLogin = useGoogleLogin({ onSuccess: googleResponseMessage })

  const componentClicked = (data: any) => {
  }
  const responseFacebook = (response: any) => {
    let payload = {
      email: response?.email,
      fullName: response?.name,
      image: response?.picture?.data?.url,
      role: 'customer',
    }
    if (response?.email) {
      ApiClient.post(`loginSignupSocialMedia`, payload).then((res: any) => {
        if (res.success) {
          authFunction(res?.data)
        }
      })
    }
  }
  return (
    <>
      <div className="w-full">
        <button type="button" onClick={e => handleGoogleLogin()} className="w-full rounded-xl h-11 py-2 px-4 text-base font-medium text-gray-light border border-gray-border hover:bg-white">
          <span className='flex gap-x-3 items-center justify-center'>  <FcGoogle className='text-xl' /> Sign in with Google</span>
        </button>
        <FacebookLogin
          appId={environment?.facebookAppId || ''}
          autoLoad={false}
          fields="name,email,picture"
          textButton={'Sign in with Facebook'}
          onClick={componentClicked}
          callback={responseFacebook}
          cssClass="mt-4 w-full rounded-xl flex gap-3 justify-center h-11 items-center py-2 px-4 text-base font-medium border border-gray-border text-gray-light relative hover:bg-white"
          icon={<FaFacebook color='blue' size={20} className='' />}
        />
        <button type="button" className="mt-4 w-full rounded-xl h-11 py-2 px-4 text-gray-light border text-base font-medium border-gray-border  hover:bg-white">
          <span className='flex gap-x-3 items-center justify-center'>  <FaApple className='size-6 text-gray-light' />Sign in with Apple</span>
        </button>
        <h2 className="background py-2 text-gray-light text-sm" ><span>or</span></h2>
      </div>
      
    </>
  )
}

export default AutoLogins