import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import { Link } from 'react-router-dom';
// import './style.scss';
import AuthLayout from '../../components/AuthLayout';
import { useDispatch } from 'react-redux';
import AutoLogins from '../../components/common/AutoLogins';
import { LOGIN_SUCCESS } from '../../Redux/Action/Action';
import methodModel from '../../methods/methods';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const Login = () => {
  const history = useNavigate();
  const dispatch = useDispatch()
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false, currentPassword: false });

  useEffect(() => {
    handleAutoLogin()
  }, [])

  const handleAutoLogin = () => {
    let email = methodModel.getPrams('email')
    if (email) {
      ApiClient.post(`user/auto-login`, { email: email }).then((res: any) => {
        if (res.success) {
          setLogin(res?.data)
        }
      })
    }
  }

  const setLogin = (data: any) => {
    localStorage.setItem('token', data.access_token)
    dispatch(LOGIN_SUCCESS(data))
    const url = localStorage.getItem('redirectURL')
    if(url){
      localStorage.removeItem('redirectURL')
      history(url)
    }else{
      if (data?.role == 'sub_agent') {
        history(`/customer`);
      } else if (data?.role == 'customer') {
        history(`/campervanlist`);
      } else {
        history(`/dashboard`);
      }
    }
  }

  const FormSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email address *')
      .required('Email is required *'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 cahracters *')
      .required('Password  is required *')
      .matches(/[0-9]/, 'Password requires a number *')
      .matches(/[a-z]/, 'Password requires a lowercase letter *')
      .matches(/[A-Z]/, 'Password requires an uppercase letter *')
      .matches(/[^\w]/, 'Password requires a symbol *')
      .max(16, 'Password must be less than 16 characters *'),
  })

  const handleSubmit = (value: any) => {
    let payload: any = {
      email: value.email,
      password: value.password,
    };
    loader(true)
    ApiClient.post(`user/signin`, payload).then(res => {
      if (res.success) {
        setLogin(res?.data)
      }
      loader(false)
    })
  };

  return (
    <>
      <AuthLayout>
        <div className="w-full max-w-[400px] px-1 py-6 md:pt-10 md:pb-[92px] mx-auto overflow-hidden ">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={FormSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className='text-center mb-3 lg:mb-6'>
                <h6 className='text-secondary text-lg lg:text-3xl 2xl:text-4xl font-semi-bold mb-2'>Welcome Back !</h6>
                <h4 className="text-gray-light text-base font-normal">Sign in to access your dashboard, settings and project.</h4>
              </div>
              <AutoLogins authFunction={setLogin} />
              <div className=" w-full mx-auto">
                <label className='text-sm text-secondary font-medium mb-1 lg:mb-2 block'>Email</label>
                <Field type="email" name='email' placeholder="Enter your email"
                  className=" bg-white w-full text-sm placeholder:text-gray-light rounded-xl h-10 flex items-center gap-2 overflow-hidden px-4  focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none !border-0  appearance-none"
                />
                <div className="invalid-feedback text-red-500 mt-0.5"><ErrorMessage name='email' /></div>
                <div className="relative mt-4 mb-2">
                  <label className='text-sm text-secondary font-medium mb-1 lg:mb-2 block'>Password</label>
                  <Field type={eyes.password ? "text" : "password"} name='password' placeholder="Password"
                    className=" bg-white w-full relative text-sm placeholder:text-gray-light  h-10 flex items-center gap-2 overflow-hidden px-4 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none !border-0 rounded-xl appearance-none"
                  />
                  {eyes.password ? (
                    <FaEye className="size-4 lg:top-10 top-9 right-4 absolute text-gray-light cursor-pointer" onClick={() => setEyes({ ...eyes, password: !eyes.password })} />
                  ) : (
                    <FaEyeSlash className="size-4 lg:top-10 top-9 right-4 absolute text-gray-light cursor-pointer" onClick={() => setEyes({ ...eyes, password: !eyes.password })} />
                  )}
                  <div className="invalid-feedback text-red-500 mt-0.5"><ErrorMessage name='password' /></div>
                </div>
                <div className='mb-6'>
                  <Link className="sign_up text-xs !font-normal text-gray-light hover:underline underline-offset-2 transition-all" to="/forgotpassword">Forgot password?</Link>
                </div>
                <button type="submit" className="!px-4 w-full text-base font-medium text-white h-11 flex items-center justify-center rounded-xl py-2 gap-2 bg-primary shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-gray-border disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                  Sign in
                </button>
                <p className='text-base font-normal text-gray-light mt-4 flex items-center gap-2 justify-center'>Don't have an account? <Link to="/signup" className='!text-secondary !text-lg !font-medium hover:!underline underline-offset-2 transition-all'>Sign Up</Link></p>
              </div>
            </Form>
          </Formik>
        </div>
      </AuthLayout>
    </>
  );
};
export default Login;