import ApiClient from "../../methods/api/apiClient"
import moment from "moment"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Card from "antd/es/card/Card"
import { RiArrowGoBackFill } from "react-icons/ri"
import { HiOutlineMail } from "react-icons/hi"
import Layout from "../../components/global/layout";

const BookingDetail = () => {
    const { id } = useParams()
    const [detail, setdetail] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        ApiClient.get(`Bookings?id=${id}`).then((res) => {
            if (res.success) {
                setdetail(res?.data)
            }
        })
    }

    return (
        <>
            <Layout>
                <>
                    <Card clickable className="mt-6 " >
                        <div className="flex justify-between items-center border-b border-gray-border pb-3 lg:pb-6 mb-2.5 lg:mb-5">
                            <h2 className="text-base lg:text-xl xl:text-2xl text-secondary font-semibold">Booking Details</h2>
                            <button className="w-[100px] h-10 xl:h-12 2xl:h-[54px] flex justify-center items-center gap-2 hover:text-[#F6F7FB] hover:bg-primary text-primary border border-primary rounded-xl" onClick={e => { navigate(-1) }}><RiArrowGoBackFill /> Back</button>
                        </div>
                        <div className="grid grid-cols-12 gap-2 items-center">
                            <div className="col-span-12">
                                <p className="font-semibold text-secondary text-base lg:text-lg  xl:text-xl mb-3 lg:mb-6"> Customer Details : </p>
                                <div className="grid sm:grid-cols-2 gap-6 border-b border-gray-border pb-3 lg:pb-6">
                                    <div className="flex flex-col items-start justify-start gap-2">
                                        <span className="font-normal text-lg text-secondary" >Customer Name</span>
                                        <p className="text-gray-light font-normal text-base capitalize">{detail?.firstName} {detail?.lastName}</p>
                                    </div>
                                    <div className="flex flex-col items-start justify-start gap-2">
                                        <span className="font-normal text-lg text-secondary">Customer Email</span>
                                        <p className="  text-gray-light font-normal text-base ">{detail?.email}</p>
                                    </div>
                                    {detail?.role == 'agent' || detail?.role == 'sub_agent' ?
                                        <>
                                            <div className="flex flex-col items-start justify-start gap-2">
                                                <span>Agent Name</span>
                                                <p className="text-gray-light  font-semibold capitalize">{detail?.agent_id?.firstName} {detail?.agent_id?.lastName}</p>
                                            </div>
                                            <div className="flex items-start justify-start gap-2">
                                                {/* <div className="lg:mr-2">
                                                    <HiOutlineMail className="text-2xl" />
                                                </div> */}
                                                <div>
                                                    <span>Agent Email</span>
                                                    <p className="text-gray-light  font-semibold">{detail?.agent_id?.email}</p>
                                                </div>
                                            </div>
                                        </>
                                        : null}
                                    <div className="flex flex-col items-start justify-start gap-2">
                                        <span className="font-normal text-lg text-secondary">Campervan Name</span>
                                        <p className=" capitalize text-gray-light font-normal text-base  ">{detail?.campervan_id?.campervan_name}</p>
                                    </div>
                                    <div className="flex flex-col items-start justify-start gap-2">
                                        <span className="font-normal text-lg text-secondary">Brand Name</span>
                                        <p className=" capitalize text-gray-light font-normal text-base ">{detail?.campervan_id?.campervan_type == 'admin' ? 'Peer To Peer' : detail?.campervan_id?.campervan_type}</p>
                                    </div>
                                    {detail?.campervan_type ?
                                        <div className="flex flex-col items-start justify-start gap-2">
                                            <span className="font-normal text-lg text-secondary">Campervan Type</span>
                                            <p className=" capitalize text-gray-light font-normal text-base ">{detail?.campervan_type}</p>
                                        </div>
                                        : null}
                                </div>
                                <p className="my-3 lg:my-6 text-base lg:text-lg  xl:text-xl text-secondary font-semibold ">Booking Details :</p>
                                <div className="grid sm:grid-cols-2 gap-6 border-b border-gray-border pb-3 lg:pb-6">
                                    <div className="flex flex-col items-start justify-start gap-2">
                                        <span className="font-normal text-lg text-secondary">PickUp Date</span>
                                        <p className="text-gray-light font-normal text-base ">{moment(detail?.pickup_date).format('DD MMM, YYYY')}</p>
                                    </div>
                                    <div className="flex flex-col items-start justify-start gap-2">
                                        <span className="font-normal text-lg text-secondary">DropOff Date</span>
                                        <p className="text-gray-light font-normal text-base ">{moment(detail?.drop_off_date).format('DD MMM, YYYY')}</p>
                                    </div>
                                    <div className="flex flex-col items-start justify-start gap-2">
                                        <span className="font-normal text-lg text-secondary">Booking Status</span>
                                        <p className="capitalize text-gray-light font-normal text-base">{detail?.booking_status}</p>
                                    </div>
                                    <div className="flex flex-col items-start justify-start gap-2">
                                        <span className="font-normal text-lg text-secondary">Total Days</span>
                                        <p className="text-gray-light font-normal text-base ">{detail?.total_days}</p>
                                    </div>
                                </div>
                                <p className="my-3 lg:my-6 font-semibold text-secondary text-base lg:text-lg  xl:text-xl">Princing Detail</p>
                                <div className="flex justify-between  mb-3 lg:mb-6 ">
                                    <span className="font-normal text-base text-gray-light">Campervan Price</span>
                                    <p className="text-secondary font-medium text-lg lg:text-xl  capitalize">${detail?.campervan_price}</p>
                                </div>
                                <div className="flex justify-between mb-3 lg:mb-6">
                                    <span className="font-normal text-base text-gray-light">Discount</span>
                                    <p className="text-secondary font-medium text-lg lg:text-xl">{detail?.discount}%</p>
                                </div>
                            </div>
                        </div>
                    </Card>
                </>
            </Layout>
        </>
    )
}

export default BookingDetail