import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import { Link } from 'react-router-dom';
import AuthLayout from '../../components/AuthLayout';
import { useDispatch } from 'react-redux';
import { LOGIN_SUCCESS } from '../../Redux/Action/Action';
import methodModel from '../../methods/methods';
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const Agentlogin = () => {
  const history = useNavigate();
  const dispatch = useDispatch()
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [Err, setErr] = useState(false)
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false, currentPassword: false });

  useEffect(() => {
    handleAutoLogin()
  }, [])

  // This function hit when user redirect from the email and autologin user to the panel
  const handleAutoLogin = () => {
    let email = methodModel.getPrams('email')
    if (email) {
      ApiClient.post(`user/auto-login`, { email: email }).then((res: any) => {
        if (res.success) {
          setLogin(res?.data)
        }
      })
    }
  }

  // Set the user details in redux persist and redirect user to dashboard
  const setLogin = (data: any) => {
    localStorage.setItem('token', data.access_token)
    dispatch(LOGIN_SUCCESS(data))
    history(`/dashboard`);
  }

  // Check email address with regex
  const emailValidation = (email: any) => {
    const regex = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!email || regex.test(email) === false) {
      return false
    } else {
      return true
    }
  }

  // Restrict user to enter invalid email address
  const handleEmail = (e: any) => {
    if (!emailValidation(e.target.value)) {
      setErr(true)
    } else { setErr(false) }
    setUsername(e.target.value)
  }

  const hendleSubmit = (e: any) => {
    e.preventDefault()
    if (Err) return
    let payload: any = {
      email: username,
      password,
    };
    loader(true)
    ApiClient.post(`user/signin`, payload).then(async res => {
      if (res.success) {
        setLogin(res?.data)
      }
      loader(false)
    })
  };

  return (
    <>
      <AuthLayout>
        <div className="w-full max-w-[400px] px-1 py-6 sm:py-10 mx-auto min-h-screen flex">
          <form onSubmit={hendleSubmit} autoComplete="off">
            <div className='text-center'>
              <h6 className='text-secondary text-[32px]/9 font-bold mb-6'>Agent Login</h6>
              <h4 className="text-secondary mb-6 text-base font-normal">Sign in to access your dashboard, settings and project.</h4>
            </div>
            <label className='text-base text-secondary font-medium mb-1.5 block' >Email</label>
            <input type="email" value={username} onChange={(e) => handleEmail(e)} placeholder="Enter your email" className="border bg-white w-full text-sm placeholder:text-gray-500 rounded-full h-12 flex items-center gap-2 overflow-hidden px-4 border-gray-border focus:border-primary" />
            {Err ? <span className='text-danger'>Please enter valid email</span> : <></>}
            <div className="relative my-5">
              <label className='text-base text-secondary font-medium mb-1.5 block' >Password</label>
              <input type={eyes.password ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} minLength={8} maxLength={16} placeholder="Password" required
                className="border bg-white w-full relative text-sm placeholder:text-gray-500 rounded-full h-12 flex items-center gap-2 overflow-hidden px-4 border-gray-border focus:border-primary"
              />
              {eyes.password ? (
                <FaEye className="top-11 right-[18px] absolute text-gray-light cursor-pointer" onClick={() => setEyes({ ...eyes, password: !eyes.password })} />
              ) : (
                <FaEyeSlash className="top-11 right-[18px] absolute text-gray-light cursor-pointer" onClick={() => setEyes({ ...eyes, password: !eyes.password })} />
              )}
            </div>
            <div className='mb-8'>
              <Link className="sign_up text-primary text-base font-normal hover:underline underline-offset-2 transition-all" to="/forgotpassword">Forgot Password</Link>
            </div>
            <button type="submit" className="!px-4 w-full text-base font-medium text-white h-11 flex items-center justify-center rounded-full py-2 gap-2 !bg-primary shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-gray-border disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
              Sign in
            </button>
            <p className='text-base font-normal text-secondary mt-4 text-center flex gap-2 justify-center items-center'>Don't have an account? <Link to="/signup" className='text-primary text-lg/5 font-medium hover:underline underline-offset-2 transition-all'>Sign Up</Link></p>
          </form>
        </div>
      </AuthLayout>
    </>
  );
};
export default Agentlogin;