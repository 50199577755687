import React, { useContext, useEffect, useState } from "react"
import PageLayout from "../../components/global/PageLayout";
import { IoIosSearch, IoMdTime } from "react-icons/io";
import { CiCalendarDate } from "react-icons/ci";
import { IoSpeedometerOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { SlCalender } from "react-icons/sl";
import { useSelector } from "react-redux";
import NewsLetter from "../../components/common/NewsLetter";
import Breadcrumb from "../../components/common/Breadcrumb";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { colourStyles } from "../../utils/selectStyle";
import DatePicker from "../../components/common/SearchBanner/DatePicker";
import { useDispatch } from "react-redux";
import { SEARCH_STATE } from "../../Redux/Action/Action";
import { HomeData } from "../../Pages/Home";

interface DayDetailCardProps {
  item: any;
}

interface ItinerariesCardProps {
  item: any;
}

const Itineraries = () => {
  const { id } = useParams();
  const search = useSelector((state: any) => state.Reducer.search);
  const siteSettings = useSelector((state: any) => state.Reducer.siteSettings);
  const [detail, setDetail]: any = useState();
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filters, setFilters]: any = useState({
    pickUpLocation: "",
    dropOffLocation: "",
    start_date: null,
    end_date: null,
    isReturn: true,
    isDriverAge: false,
  });
  const [jucyLocations, setJucyLocations] = useState();

  useEffect(() => {
    getJucyLocations();
    getAllItineraries();
    if (id) getItinerariesDetail(id);
  }, [id]);

  const getJucyLocations = () => {
    ApiClient.thirdPartyGet(
      `https://lanier.test.jucy.cloud/api/Sites/1.0/GetSites?country=all`
    ).then((res) => {
      if (res.ResponseType == "Success") {
        setJucyLocations(
          res?.Data?.map((item: any) => {
            return { value: item?.SiteCode, label: item?.Name };
          })
        );
      }
    });
  };

  const handleSearchCampervans = () => {
    dispatch(SEARCH_STATE({ ...search, ...filters }));
    navigate("/campervans");
  };

  const getAllItineraries = () => {
    loader(true);
    ApiClient.get(`Itinerariers/all?status=active`).then((res: any) => {
      if (res.success) {
        if (!id) {
          let featured = [];
          featured = res?.data?.filter((item: any) => item?.isFeatured);
          if (featured?.length != 0)
            getItinerariesDetail(featured?.[0]?._id || featured?.[0]?.id);
          else getItinerariesDetail(res?.data?.[0]?._id || res?.data?.[0]?.id);
        }
        let suggestList: any = [];
        if (id) {
          suggestList?.filter((itm: any) => itm?._id != id && itm?.isSuggested);
        } else {
          suggestList = res?.data?.filter((item: any) => item?.isSuggested);
        }
        setSuggestions(suggestList);
      }
      loader(false);
    });
  };

  const getItinerariesDetail = (id: string) => {
    ApiClient.get(`Itinerariers?id=${id}`).then((res: any) => {
      if (res.success) {
        setDetail({
          ...res?.data,
          thingstodo_details: res?.data?.get_thingstodo,
        });
      }
    });
  };

  const homedata: any = useContext(HomeData)
  const [driverAges, setDriverAges] = useState([])

  const DayDetailCard: React.FC<DayDetailCardProps> = ({ item }) => {
    return (
      <div className="w-full border border-gray-border rounded-2xl p-3 lg:p-4">
        <h2 className="text-lg lg:text-2xl/7 font-semibold text-secondary mb-6">
          Day {item?.day || "--"}
        </h2>
        {item?.image ? (
          <div className="mb-5">
            <img
              alt="city image"
              src={methodModel.noImg(item?.image)}
              className="w-full object-cover rounded-xl lg:h-[278px]"
            />
          </div>
        ) : null}
        <div className="flex flex-wrap items-center gap-3 lg:gap-5 mb-3 ">
          <div className="flex items-center gap-2">
            <CiLocationOn className="size-5 text-gray-light" />
            <p className="text-sm lg:text-base/5 font-normal text-gray-light">
              Distance : {item?.kms || "--"} km
            </p>
          </div>
          <div className="flex items-center gap-2">
            <IoMdTime className="size-5 text-gray-light" />
            <p className="text-sm lg:text-base/5 font-normal text-gray-light">
              Travel Time : {calculateDuration(item?.travel_time)}
            </p>
          </div>
        </div>
        <h3 className="text-base xl:text-xl/7 font-medium text-secondary mb-2">
          {item?.cities?.map((itm: any, index: number) => {
            return (
              <span>
                {" "}
                {itm} {item?.cities?.length == index + 1 ? null : "|"}
              </span>
            );
          })}
        </h3>
        <p
          className="text-sm font-normal text-gray-light mb-3 lg:mb-5"
          dangerouslySetInnerHTML={{ __html: item?.description }}
        ></p>
        <h2 className="text-base lg:text-xl font-medium text-secondary mb-3">
          Things to do :
        </h2>
        <div className="flex max-xl:flex-wrap justify-center gap-2 xl:gap-4">
          {item?.images?.map((itm: any, index: number) => {
            return (
              <div className="border border-gray-border rounded-2xl p-1 w-full lg:w-auto ">
                <img
                  alt="city image"
                  src={methodModel.noImg(itm?.images)}
                  className="w-full h-[200px] object-cover rounded-xl"
                />
                <h4 className="text-base lg:text-xl/6 font-medium text-secondary text-center pt-2 pb-1 px-4">
                  {itm?.cities}
                </h4>
              </div>
            );
          })}
        </div>
        <div
          className="pl-4 lg:pl-5 mt-4 text-sm lg:text-base font-normal text-gray-light [&>ul]:pl-2.5 [&>ul]:list-disc"
          dangerouslySetInnerHTML={{ __html: item?.things_to_do }}
        ></div>
      </div>
    );
  };
  const ItinerariesCard: React.FC<ItinerariesCardProps> = ({ item }) => {
    return (
      <div className="w-full border border-gray-border rounded-2xl p-2 pb-4 ">
        <img
          alt={item?.image_alt}
          src={methodModel.noImg(item?.image)}
          className="w-full object-cover rounded-xl lg:h-[313px]"
        />
        <div className="flex flex-wrap items-center gap-3 lg:gap-5 my-4">
          <div className="flex items-center gap-2">
            {/* <SlCalender className="size-6 text-gray-light" /> */}
            <img
              src="/assets/img/calendar.svg"
              className="size-4 lg:size-5"
              alt="calendar-icon"
            />
            <p className="text-sm lg:text-base/5 font-normal text-gray-light">
              {item?.start_day || "--"} to {item?.end_day || "--"} days
            </p>
          </div>
          <div className="flex items-center gap-2">
            <CiLocationOn className="size-5 text-gray-light" />
            <p className="text-sm lg:text-base/5 font-normal text-gray-light">
              Distance : {item?.kms} km
            </p>
          </div>
        </div>
        <h3 className="text-base lg:text-lg font-medium text-secondary mb-2">
          {item?.cities?.map((itm: any, index: number) => {
            return (
              <span>
                {" "}
                {itm} {item?.cities?.length == index + 1 ? null : "|"}
              </span>
            );
          })}
        </h3>
        <p
          className="text-sm lg:text-base font-normal text-gray-light mb-4"
          dangerouslySetInnerHTML={{
            __html: item?.thingstodo_details?.[0]?.description,
          }}
        ></p>
        <button
          type="button"
          onClick={(e) => {
            navigate(`/itineraries/${item?._id || item?.id}`);
          }}
          className="w-full h-[40px] lg:h-[46px] px-[30px] rounded-xl bg-white hover:bg-primary justify-center items-center text-base lg:text-lg/5 font-medium border border-primary text-primary hover:text-white transition-all "
        >
          View Itinerary
        </button>
      </div>
    );
  };

  const travelTime = (time: any) => {
    if (time) {
      const [hours, minutes] = time.split(":");
      if (minutes == "00") {
        return `${hours} hr drive`;
      } else {
        return `${hours} hr ${minutes} minutes drive`;
      }
    } else {
      return "--";
    }
  };

  const calculateDuration = (dateString: any) => {
    const date = new Date(dateString);
    const hours = moment(date).format("h");
    const minutes = moment(date).format("mm");
    if (minutes == "00") {
      return `${hours} h`;
    } else {
      return `${hours} h ${minutes} min`;
    }
  };

  const findDestination = (type: any) => {
    let destination = "";
    if (type == "start") {
      destination = detail?.thingstodo_details?.[0]?.cities?.[0];
      return destination;
    } else if (type == "end") {
      const citiesLength = detail?.thingstodo_details?.[0]?.cities?.length;
      destination = detail?.thingstodo_details?.[0]?.cities?.[citiesLength - 1];
      return destination;
    } else {
      let waypoints: any = [];
      detail?.thingstodo_details?.map((item: any, index: number) => {
        item?.cities?.map((itm: any, i: number) => {
          waypoints.push(itm);
        });
      });
      const uniqueSet: any = new Set(waypoints);
      let uniqueWaypoints: any = [...uniqueSet];
      uniqueWaypoints = String(uniqueWaypoints).replaceAll(",", "|");
      return uniqueWaypoints;
    }
  };

  return (
    <PageLayout>
      <div className={`max-w-[1504px] w-full mx-auto px-4 lg:px-8`}>
        <div className="mt-4 lg:mt-6">
          <Breadcrumb
            links={[
              { link: "/", name: "Home" },
              { link: "/itineraries", name: "Brisbane" },
            ]}
            currentPage={detail?.title}
          />
        </div>
        <h1 className="text-xl lg:text-4xl font-semibold text-secondary my-4  lg:my-8">
          {detail?.title}
        </h1>
        <img
          alt={detail?.image_alt}
          src={methodModel.noImg(detail?.image)}
          className="w-full object-cover rounded-[18px]"
        />
        <div className="flex flex-col xl:flex-row max-xl:items-center justify-between gap-4 mt-6 lg:mt-12">
          <div className="w-full xl:max-w-[834px]">
            <h2 className="text-lg lg:text-4xl font-semibold text-secondary mb-3">
              {detail?.title}
            </h2>
            <div className="flex items-center gap-3 lg:gap-5 mb-3 lg:mb-5">
              <div className="flex items-center gap-2">
                <img
                  src="/assets/img/speedometer.svg"
                  className="size-4 lg:size-6"
                  alt="speedometer-icon"
                />
                <h6 className="whitespace-nowrap text-sm lg:text-lg/5 font-medium text-[#686868]">
                  {detail?.kms || "--"} km
                </h6>
              </div>
              <div className="flex items-center gap-2">
                <IoMdTime className="size-4 lg:size-6 text-[#686868]" />
                <h6 className="whitespace-nowrap text-sm lg:text-lg/5 font-medium text-[#686868]">
                  {travelTime(detail?.travel_time)}
                </h6>
              </div>
              <div className="flex items-center gap-2">
                <img
                  src="/assets/img/calendar.svg"
                  className="size-4 lg:size-6"
                  alt="calendar-icon"
                />
                <h6 className="whitespace-nowrap text-sm lg:text-lg/5 font-medium text-[#686868]">
                  {detail?.start_day || "--"} - {detail?.end_day || "--"} days
                </h6>
              </div>
            </div>
            <p
              className="text-sm lg:text-base font-normal text-gray-light"
              dangerouslySetInnerHTML={{ __html: detail?.description }}
            ></p>
          </div>
          <div className="border border-primary bg-white p-3 xl:p-6 rounded-2xl max-w-[558px] w-full h-fit">
            <div className="grid grid-cols-12 ">
              <div className="col-span-12 lg:col-span-12">
                <div className="flex flex-col gap-3">
                  <div className="flex-grow w-full">
                    <label
                      htmlFor=""
                      className="text-sm/5 font-medium text-secondary inline-block mb-2"
                    >
                      Pickup Location
                    </label>
                    <div className="w-full ItinerariesSelect">
                      <Select
                        styles={colourStyles}
                        placeholder="Enter City, Airport or other location"
                        className="capitalize w-full cursor-pointer"
                        value={filters?.pickUpLocation}
                        onChange={(e) =>
                          setFilters((prev: any) => ({
                            ...prev,
                            pickUpLocation: e,
                          }))
                        }
                        options={jucyLocations}
                        isClearable
                        classNamePrefix="react"
                      />
                    </div>
                  </div>
                  {filters?.isReturn ? (
                    <></>
                  ) : (
                    <>
                      <div className="w-full">
                        <label
                          htmlFor=""
                          className="text-sm/5 font-medium text-secondary inline-block mb-2"
                        >
                          Dropoff Location
                        </label>
                        <div className="w-full ItinerariesSelect">
                          <Select
                            styles={colourStyles}
                            placeholder="Enter City, Airport or other location"
                            className="capitalize w-full cursor-pointer"
                            value={filters?.dropOffLocation}
                            onChange={(e) =>
                              setFilters((prev: any) => ({
                                ...prev,
                                dropOffLocation: e,
                              }))
                            }
                            options={jucyLocations}
                            isClearable
                            classNamePrefix="react"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="mt-3 flex gap-2 items-center">
                  <input
                    type="checkbox"
                    checked={filters?.isReturn}
                    onChange={(e) => {
                      setFilters((prev: any) => ({
                        ...prev,
                        isReturn: e.target.checked,
                        dropOffLocation: "",
                      }));
                    }}
                    name="sameLocation"
                    id="sameLocation"
                    className="shrink-0 text-primary size-5 border border-gray-400 rounded-[4px] cursor-pointer"
                  />
                  <label
                    htmlFor="sameLocation"
                    className="text-sm/5 font-normal text-secondary cursor-pointer"
                  >
                    Return campervan to same location
                  </label>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-12 mt-5">
                <div className="flex gap-4 justify-between flex-wrap flex-col xl:flex-row">
                  <DatePicker
                    label="Pickup Date"
                    placeholder="DD/MM/YYYY"
                    startDate={filters?.start_date}
                    endDate={filters?.end_date}
                    onChange={(date: any) =>
                      setFilters((prev: any) => ({
                        ...prev,
                        start_date: date?.startDate,
                        end_date: date?.endDate,
                      }))
                    }
                  />
                  <DatePicker
                    label="Return Date"
                    placeholder="DD/MM/YYYY"
                    showEndDate={true}
                    startDate={filters?.start_date}
                    endDate={filters?.end_date}
                    onChange={(date: any) =>
                      setFilters((prev: any) => ({
                        ...prev,
                        start_date: date?.startDate,
                        end_date: date?.endDate,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="col-span-12 mt-5">
                {/* <input type="checkbox"
                  checked={filters?.isDriverAge}
                  onChange={e => {
                    setFilters({ ...filters, isDriverAge: e.target.checked })
                  }}
                  name="driverAge" id="driverAge" className="shrink-0 text-primary size-4 border border-gray-400 rounded-sm cursor-pointer" />
                <label htmlFor="driverAge" className="text-sm/none text-[#6B6B6B] cursor-pointer">
                  Driver Age: Minimum: <span className="underline underline-offset-4 decoration-primary"> 23 years </span>
                  Maximum: <span className="underline underline-offset-4 decoration-primary"> 70 years</span>
                </label> */}
                <label className="text-sm/5 font-medium text-secondary inline-block mb-1 lg:mb-2">{homedata?.part1_checkbox2 ? homedata?.part1_checkbox2 : "Driver Age"}</label>
                <div className="w-full locationSelect">
                  <Select
                    styles={colourStyles}
                    placeholder={homedata?.part1_checkbox2Placeholder ? homedata?.part1_checkbox2Placeholder : "Select Driver Age"}
                    className="capitalize w-full cursor-pointer"
                    value={filters?.ageid}
                    onChange={(e: any) => setFilters({ ...filters, ageid: e })}
                    options={driverAges}
                    classNamePrefix="react zplus"
                  />
                </div>
              </div>
              <div className="col-span-12 mt-6">
                <button
                  type="button"
                  onClick={() => handleSearchCampervans()}
                  className="py-3 px-[30px] w-full h-12 lg:h-[54px] rounded-xl bg-primary hover:opacity-80 transition-all flex gap-3 whitespace-nowrap items-center justify-center text-sm lg:text-lg/5 font-medium text-white"
                >
                  <IoIosSearch className="size-5 text-white" /> Search
                  Campervans
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 my-6 md:my-12">
          <div className="w-full border border-gray-border rounded-2xl p-3 lg:p-6 ">
            <h3 className="text-lg lg:text-2xl font-semibold text-secondary mb-3 lg:mb-5">
              Itineraries Details
            </h3>
            <div className="!overflow-x-auto scrollbar bg-white w-full">
              <table className="w-full">
                <thead>
                  <tr className="text-sm/5 font-medium text-secondary bg-[#F6F7FB] text-start">
                    <th className="pl-6 py-[10px] min-w-[90px] text-start">
                      Day
                    </th>
                    <th className="pl-6 py-[10px]  text-start">
                      Cities
                    </th>
                    <th className="pl-6 py-[10px] min-w-[107px] text-start">
                      Kms
                    </th>
                    <th className="px-6 py-[10px] min-w-[123px]  whitespace-nowrap text-start">
                      Travel Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detail?.thingstodo_details?.map(
                    (item: any, index: number) => (
                      <tr
                        key={index}
                        className="whitespace-nowrap text-sm lg:text-base font-normal text-gray-light border-b border-gray-border"
                      >
                        <td className="pl-6 py-[25px]">Day {item?.day}</td>
                        <td className="pl-6 py-[25px]">
                          {item?.cities?.map((itm: any, i: number) => {
                            return (
                              <span>
                                {" "}
                                {itm}{" "}
                                {item?.cities?.length == i + 1 ? null : "|"}
                              </span>
                            );
                          })}
                        </td>
                        <td className="text-center">{item?.kms} km</td>
                        <td className=" px-4 md:px-6 py-[25px]">
                          {calculateDuration(item?.travel_time)}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {detail?.thingstodo_details?.length == 0 ? null : (
            <div className="w-full">
              <h3 className="text-lg lg:text-2xl font-semibold text-secondary mb-4">
                Map View
              </h3>
              <div className="h-full w-full !rounded-2xl">
                <iframe
                  src={`https://www.google.com/maps/embed/v1/directions?key=${
                    siteSettings?.google_map_api_key
                  }&origin=${findDestination(
                    "start"
                  )}&destination=${findDestination(
                    "end"
                  )}&waypoints=${findDestination("waypoints")}&mode=driving`}
                  className="w-full"
                  height="450px"
                  style={{ borderRadius: "16px" }}
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
          {detail?.thingstodo_details?.map((item: any, index: number) => (
            <DayDetailCard key={index} item={item} />
          ))}
        </div>
        {suggestions?.length == 0 ? null : (
          <div className="my-6 lg:my-12">
            {/* <h2 className="text-xl lg:text-[32px] font-semibold text-secondary mt-6 lg:mt-[90px] mb-8">
              Our Suggested Itineraries
            </h2> */}
            <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-8">
              {suggestions.map((item: any, index: number) => (
                <ItinerariesCard key={index} item={item} />
              ))}
            </div>
          </div>
        )}
        {/* <div className="mt-6 lg:mt-28">
          <NewsLetter />
        </div> */}
      </div>
    </PageLayout>
  );
};

export default Itineraries;
