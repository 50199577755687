
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import Layout from "../../components/global/layout";
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button } from "antd"
import Card from "antd/es/card/Card"
import { RiArrowGoBackFill } from "react-icons/ri"
import { HiOutlineCurrencyDollar, HiOutlineMail, HiOutlineUserAdd } from "react-icons/hi"
import { TbSwitchVertical } from "react-icons/tb";

const SubAgentDetail = () => {
    const { id } = useParams()
    const [detail, setdetail] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        getDetail()
    }, [])

    // Get the sub agent details
    const getDetail = () => {
        ApiClient.get(`user/detail?id=${id}`).then((res) => {
            if (res.success) {
                setdetail(res?.data)
            }
        })
    }

    return (
        <Layout>
            <>
                <div className="flex justify-between items-center">
                    <h2 className="text-2xl font-bold">Sub Agent Details</h2>
                    <Button variant="solid" className="flex items-center gap-2" onClick={e => { navigate(`/subagent`) }}><RiArrowGoBackFill /> Back</Button>
                </div>
                <Card clickable className="mt-6">
                    <div className="grid grid-cols-12 gap-2 items-center">
                        <div className="col-span-12 md:col-span-4 lg:col-span-3 border-r border-gray-300 border-dashed">
                            <div className="img-users text-center">
                                <img src={methodModel.userImg(detail?.image)} className="h-40 w-40 mx-auto object-contain" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-8 lg:col-span-9 ">
                            <div className="lg:ml-8 grid grid-cols-2">
                                <div className="mb-6 d">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <HiOutlineUserAdd className="text-2xl" />
                                        </div>
                                        <div>
                                            <span>Full Name</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold">{detail?.firstName} <span className="">{detail?.lastName}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <HiOutlineMail className="text-2xl" />
                                        </div>
                                        <div>
                                            <span>Email</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold">{detail?.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <HiOutlineCurrencyDollar className="text-2xl" />
                                        </div>
                                        <div>
                                            <span>Commission</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold capitalize">{detail?.commission}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <TbSwitchVertical className="text-2xl" />
                                        </div>
                                        <div>
                                            <span>Status</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold capitalize">{detail?.status}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </>
        </Layout>
    )
}

export default SubAgentDetail