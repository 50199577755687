import Layout from '../../components/global/layout';
import './profile.scss'
import methodModel from '../../methods/methods';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HiOutlineMail, HiOutlineUserAdd } from 'react-icons/hi';
import { Card } from 'antd';
import { MdLocalPhone } from 'react-icons/md';
import { PiCity } from 'react-icons/pi';
import { FiCalendar } from "react-icons/fi";


const Profile = () => {
    const user = useSelector((state: any) => state.User)

    return (
        <Layout>
            <div className='pb-5 wrapper_section'>
                <div className='flex items-center  justify-between mb-4'>
                    <h3 className='text-2xl font-semibold text-[#111827]'>Profile</h3>
                    <Link to="/profile/edit" className="!px-4 text-sm font-normal bg-blue-700 hover:bg-blue-700 text-white h-10 flex items-center justify-center gap-2 !bg-primary rounded-full shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed">
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
                        Edit Profile
                    </Link>
                </div>
                <Card className="">
                    <div className="grid grid-cols-12 gap-2 items-center">
                        <div className="col-span-12 md:col-span-4 lg:col-span-3 border-r border-gray-300 border-dashed">
                            <div className="img-users text-center">
                                <img src={methodModel.userImg(user?.image)} className="h-40 w-40 mx-auto rounded-full object-contain" />
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-8 lg:col-span-9 ">
                            <div className="lg:ml-8 grid sm:grid-cols-2">
                                <div className="mb-3 sm:mb-6">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <HiOutlineUserAdd className="text-2xl" />
                                        </div>
                                        <div>
                                            <span>Full Name</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold capitalize">{user?.fullName || `${user?.firstName} ${user?.lastName}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 sm:mb-6">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <HiOutlineMail className="text-2xl" />
                                        </div>
                                        <div>
                                            <span>Email</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold">{user?.email || '--'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-6 sm:mb-6">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <MdLocalPhone className="text-2xl" />
                                        </div>
                                        <div>
                                            <span>Mobile No</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold capitalize">{user?.mobileNo?`+${user?.mobileNo}`:'--'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 sm:mb-6">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <PiCity className="text-2xl" />
                                        </div>
                                        <div>
                                            <span>City</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold capitalize">{user?.city || '--'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 sm:mb-6">
                                    <div className="flex items-center gap-x-4">
                                        <div className="lg:mr-2">
                                            <FiCalendar className="text-2xl" />
                                        </div>
                                        <div>
                                            <span>Date Of Birth</span>
                                            <p className="text-gray-700 dark:text-gray-200 font-semibold capitalize">{user?.dateOfBirth || '--'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </Layout>
    );
};

export default Profile;
