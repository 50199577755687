import { useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import { Link } from "react-router-dom";
import AuthLayout from "../../components/AuthLayout";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FiArrowLeft } from "react-icons/fi";

const Forgotpassword = () => {
  const [form, setForm] = useState({ email: "" });
  const [emailSent, setemailSent] = useState(false);

  const FormSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address *")
      .required("Email is required *"),
  });

  const handleSubmit = (value: any) => {
    let payload: any = {
      email: value.email,
    };
    ApiClient.post("user/forgot-password", payload).then((res) => {
      if (res.success) {
        setForm(value);
        setemailSent(true);
      } else {
        setemailSent(false);
      }
    });
  };

  return (
    <>
      <AuthLayout>
        <div className="w-full max-w-[400px] px-1 py-3 mx-auto overflow-hidden justify-center flex items-center">
          <Formik
            initialValues={form}
            validationSchema={FormSchema}
            onSubmit={handleSubmit}
          >
            <Form className="w-full">
              <div className="img_data mb-3 lg:mb-6">
                {emailSent ? (
                  <img className="mx-auto" src="/assets/img/front/mail-updated.svg" alt="email_send_pic" />
                ) : (
                  <img className="mx-auto" src="/assets/img/front/key-updated.svg" alt="default_img" />
                )}
              </div>
              <div className="text-center mb-2">
                <h3 className="font-semi-bold font-semi-bold text-lg lg:text-4xl text-secondary">
                  {emailSent ? "Check Your Email" : "Forgot Password?"}
                </h3>
              </div>
              {emailSent ? (
                <span className="text-center">
                  <p className="mb-6 text-base text-gray-light max-w-[210px] mx-auto">
                    We sent a password link to {form?.email}
                  </p>
                  <div className="w-full">
                    <button
                      type="button"
                      onClick={(e) => {
                        window.open("http://gmail.com", "_self");
                      }}
                      className="!px-4 w-full text-base font-medium text-white h-11 flex items-center justify-center rounded-xl py-2 gap-2 !bg-primary shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-[#EDEBFC] disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                    >
                      Open email app
                    </button>
                  </div>
                  <p className="mt-4 text-base text-gray-light mx-auto max-w-[200px] sm:max-w-full">
                    Didn't receive the email?
                    <b
                      onClick={handleSubmit}
                      className="cursor-pointer text-secondary text-base font-medium hover:underline underline-offset-2 transition-all"
                    >
                      {" "}
                      Click to resend
                    </b>
                  </p>
                </span>
              ) : (
                <>
                  <p className=" text-base font-normal text-gray-light text-center w-full max-w-[188px] mx-auto">
                    No worries, we'll send you reset instructions.
                  </p>
                  <div className="inputWrapper mt-3 lg:mt-6">
                    <label className="text-sm text-secondary font-medium mb-1 lg:mb-2 block">
                      Email
                    </label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className=" bg-white w-full text-sm placeholder:text-gray-light  h-10 flex items-center gap-2 overflow-hidden px-4 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none !border-0 rounded-xl appearance-none"
                    />
                    <div className="invalid-feedback text-red-500 mt-0.5">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="buttons mt-3 lg:mt-6">
                    <button
                      type="submit"
                      className="!px-4 w-full text-base font-medium text-white h-11 flex items-center justify-center rounded-xl py-2 gap-2 !bg-primary shadow-btn hover:opacity-80 transition-all focus:ring-2 ring-gray-border disabled:bg-[#D0CAF6] disabled:cursor-not-allowed"
                    >
                      Reset Password
                    </button>
                  </div>
                </>
              )}
              <Link
                className="sign_up flex items-center justify-center gap-x-2 mt-2.5 lg:mt-5 text-center !text-secondary text-base font-medium focus:text-primary"
                to="/login">
               <FiArrowLeft className="size-4 text-secondary" />
                Back to log in
              </Link>
            </Form>
          </Formik>
        </div>
      </AuthLayout>
    </>
  );
};

export default Forgotpassword;
