import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { IoIosSearch } from "react-icons/io";
import Select from 'react-select';
import PeopleCounter from "./PeopleCounter"
import DatePicker from "./DatePicker";
import { vanSleepLimit } from "../../../utils/static-data";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SEARCH_STATE } from "../../../Redux/Action/Action";
import ApiClient from "../../../methods/api/apiClient";
import { colourStyles } from "../../../utils/selectStyle";
import { Tooltip } from "antd";
import { PiArrowUDownLeftLight } from "react-icons/pi";
import { useParams } from "react-router-dom";
import environment from "../../../environment";
import CryptoJS from 'crypto-js';
import loader from "../../../methods/loader";
import { HomeData } from "../../../Pages/Home";
import { IoIosArrowRoundForward } from "react-icons/io";

export interface searchFilterIF {
  peopleCount: number
  startDate: string
  endDate: string
}

interface SearchBannerProps {
  bgImage: string
  peopleCounter?: boolean
  listingPage?: boolean
  classes?: string
  searchFilter: searchFilterIF
  filter?: any,
  setSearchFilter: Dispatch<SetStateAction<searchFilterIF>>
  handleSearchCampervans?: (e?: any) => void
  activeIdx?: number
  setActiveIdx?: Dispatch<SetStateAction<number>>
  pickUpRef: any
}

const SearchBanner: React.FC<SearchBannerProps> = ({
  bgImage,
  peopleCounter = false,
  listingPage = false,
  classes = "lg:py-12",
  handleSearchCampervans,
  searchFilter,
  setSearchFilter,
  activeIdx,
  setActiveIdx,
  filter,
  pickUpRef
}) => {
  const { city } = useParams()
  const search = useSelector((state: any) => state.Reducer.search)
  const homedata: any = useContext(HomeData) // Get data using useContext from Home.tsx
  const dispatch = useDispatch()
  const [filters, setFilters]: any = useState({
    pickUpLocation: '',
    dropOffLocation: '',
    start_date: '',
    end_date: '',
    isReturn: true,
    isDriverAge: false,
    ageid: "",
  })
  const [locations, setLocations] = useState()
  const [driverAges, setDriverAges] = useState([])

  const handleSleepCount = (idx: number, label: string) => {
    if (typeof setActiveIdx === 'function') {
      if (label == 'Show all') {
        dispatch(SEARCH_STATE({ ...search, type: '', activeIdx: 0 }))
      } else {
        dispatch(SEARCH_STATE({ ...search, type: label, activeIdx: idx }))
      }
      setActiveIdx(idx);
    }
  };

  const handleSearch = () => {
    if (typeof handleSearchCampervans === 'function') {
      handleSearchCampervans(filters);
    }
  }

  useEffect(() => {
    getLocations()
    getCategorieAndDriverAge()
  }, [])

  // It return the locations of Jucy and Spaceship
  const getLocations = () => {
    loader(true)
    ApiClient.get(`locations`).then(res => {
      if (res.success) {
        setLocations(res?.data?.locations?.map((item: any) => {
          return ({ value: item?.SiteCode || item?.location, label: item?.location, spaceship_id: item?.spaceship_id })
        }))
        if (city) {
          const cityName = city.replace("-", " ").replace(/\b\w/g, l => l.toUpperCase()); // To capitalize city name
          res?.data?.locations?.find((item: any) => {
            if (item?.location == cityName) {
              setFilters((prev: any) => ({ ...prev, pickUpLocation: { value: item?.SiteCode || item?.location, label: item?.location, spaceship_id: item?.spaceship_id } }))
            }
          })
        }
      }
      loader(false)
    })
  }

  // Api to get the spaceship campervans categories and driver age
  const getCategorieAndDriverAge = () => {
    const payload = { "method": "step1" }
    // const API_KEY = environment.AU_SPACESHIPS_API_KEY
    // const SHARED_SECRET = environment.AU_SPACESHIPS_SHARED_SECRET || ''
    // const signature = CryptoJS.HmacSHA256(JSON.stringify(payload), SHARED_SECRET).toString(CryptoJS.enc.Hex)
    ApiClient.post(`step1`, payload).then(res => {
      if (res.success) {
        setDriverAges(res?.data?.results?.driverages?.map((item: any) => {
          if (item?.driverage === 21) {
            setFilters((prev: any) => ({ ...prev, ageid: { value: item?.id, label: item?.driverage } }))
          }
          return ({ value: item?.id, label: item?.driverage })
        }))
        // Pass Spaceship id as vehiclecategorytypeid for spaceship api's as discuss with nishant & dinesh sir if not get then pass the van id 6 getting from testing data in RCM api's
        let vehicalCategoryId = ""
        res?.data?.results?.categorytypes?.filter((item: any) => {
          if (item?.vehiclecategorytype === "Spaceship") vehicalCategoryId = item?.id
        })
        dispatch(SEARCH_STATE({ ...search, vehiclecategorytypeid: vehicalCategoryId || "6" }))
      }
    })
  }

  useEffect(() => {
    setFilters({ ...filters, ...filter })
  }, [filter])

  const [hoveredIdx, setHoveredIdx] = useState<null | number>(null);

  const clearSearchFields = () => {
    const clearData = {
      pickUpLocation: '',
      dropOffLocation: '',
      start_date: '',
      end_date: '',
      isReturn: true,
      isDriverAge: false,
      ageid: "",
      campervan_type: "",
    }
    setFilters(clearData)
    handleSleepCount(0, "Show all")
    dispatch(SEARCH_STATE({ ...search, ...clearData }))
  }

  return (
    <div className={`${classes} p-3 sm:px-7 rounded-3xl relative`}>
      <img alt={"banner-bg"} src={bgImage} className="absolute z-0 inset-0 w-full h-full object-cover object-center " />
      <div className="absolute inset-0 bg-[#0A0A0B]/[73%] " />
      {/* <div className={`w-full bg-white px-6 lg:px-14 py-8 rounded-[20px] ${filters.isDriverAge ? 'max-w-[1126px]' : (filters?.start_date ? 'max-w-[1126px]') (filters.isReturn ? 'max-w-[953px]' : 'max-w-[1158px]')} mx-auto z-10 relative`}> */}
      <div className="max-w-[1504px] w-full flex gap-4 lg:gap-12 max-lg:flex-col mx-auto items-center">
        <div className={`order-2 lg:order-1 bg-white rounded-2xl w-full max-w-[600px] xl:max-w-[634px] z-10 relative`}>
          {/* <div className="absolute right-3.5 lg:right-7 top-1.5 lg:top-2.5">
            {filters?.isReturn ? <>
              {filters?.pickUpLocation?.value || filters?.dropOffLocation?.value || filters?.start_date || filters?.end_date || !filters?.isReturn || filters?.isDriverAge || activeIdx != 0 ?
                <Tooltip placement="top" title="Clear">
                  <button type="button" onClick={() => clearSearchFields()} className="px-2.5 ml-auto relative min-w-[90px] h-8 lg:h-10 rounded-full gap-2  bg-primary hover:opacity-80 flex items-center justify-center  text-white text-xs lg:text-sm/4">
                    <PiArrowUDownLeftLight className="size-3 lg:size-4 lg:text-lg " />  Clear Search
                  </button>
                </Tooltip>
                : null}
            </> :
              <div className="ml-auto">
                {filters?.pickUpLocation?.value || filters?.dropOffLocation?.value || filters?.start_date || filters?.end_date || !filters?.isReturn || filters?.isDriverAge ?
                  <Tooltip placement="top" title="Clear">
                    <button type="button" onClick={() => clearSearchFields()} className="px-2.5 relative min-w-[90px] h-8 lg:h-10 rounded-full gap-2  bg-primary hover:opacity-80 flex items-center justify-center  text-white text-xs lg:text-sm/4">
                      <PiArrowUDownLeftLight className="size-3 lg:size-4 lg:text-lg " />  Clear Search
                    </button>
                  </Tooltip>
                  : null}
              </div>
            }
          </div> */}
          {listingPage &&
            <div className="justify-center flex gap-3 border-b border-[#E3E5ED] p-3 xl:p-6 z-10 relative flex-wrap w-full">
              {vanSleepLimit.map((itm, idx) => (
                <div
                  key={idx}
                  role="button"
                  onClick={() => handleSleepCount(idx, itm?.label)}
                  className={`shrink-0 h-20 w-[86px] ${activeIdx === idx ? "bg-primary text-white" : "bg-white text-secondary"}  flex flex-col items-center justify-center rounded-xl border border-[#EBEBEB] ${hoveredIdx === idx ? "hover:bg-primary hover:text-white" : ""}`}
                  onMouseEnter={() => setHoveredIdx(idx)}
                  onMouseLeave={() => setHoveredIdx(null)}>
                  <img
                    alt={itm.label}
                    src={itm.icon}
                    width={36}
                    height={17}
                    className={`h-4 ${
                      activeIdx === idx || hoveredIdx === idx ? "filter invert" : ""
                    }`}
                  />
                  <p className="text-sm font-normal mt-1.5">{itm.label}</p>
                </div>
              ))}
            </div>
          }
          <div className="w-full flex flex-col p-3 xl:p-6">
            <div className="flex flex-col flex-1 gap-2.5 lg:gap-5 lg:flex-none w-full">
              <div className={`w-full flex-auto`}>
                <label id="pickUpField" className="text-sm/4 font-medium text-secondary inline-block mb-1 lg:mb-2">{homedata?.part1_value1 ? homedata?.part1_value1 : "Pickup Location"}</label>
                <div className="w-full locationSelect">
                  <Select
                    styles={colourStyles}
                    placeholder={homedata?.part1_placeholder1 ? homedata?.part1_placeholder1 : "Enter City, Airport or other location"}
                    ref={pickUpRef}
                    className="capitalize w-full cursor-pointer sm:min-w-full lg:max-w-[313px]"
                    value={filters?.pickUpLocation}
                    onChange={(e: any) => setFilters({ ...filters, pickUpLocation: e })}
                    options={locations}
                    isClearable
                    classNamePrefix="react zplus"
                  />
                </div>
              </div>
              {filters?.isReturn ? <></> : <>
                <div className="w-full flex-auto">
                  <label htmlFor="" className="text-sm/4 font-medium text-secondary inline-block mb-1 lg:mb-2">{homedata?.part1_value2 ? homedata?.part1_value2 : "Dropoff Location"}</label>
                  <div className="w-full locationSelect">
                    <Select
                      styles={colourStyles}
                      placeholder={homedata?.part1_placeholder2 ? homedata?.part1_placeholder2 : "Enter City, Airport or other location"}
                      className="capitalize w-full cursor-pointer sm:min-w-full lg:max-w-[313px]"
                      value={filters?.dropOffLocation}
                      onChange={(e: any) => setFilters({ ...filters, dropOffLocation: e })}
                      options={locations}
                      isClearable
                      classNamePrefix="react zplus"
                    />
                  </div>
                </div>
              </>}
            </div>
            <div className="inline-flex items-center gap-2 w-full mt-3 lg:mb-5">
              <input type="checkbox"
                checked={filters?.isReturn}
                onChange={e => {
                  setFilters((prev: any) => ({ ...prev, isReturn: e.target.checked, dropOffLocation: '' }))
                }}
                name="sameLocation" id="sameLocation" className="shrink-0 text-primary rounded size-5 border border-gray-400 cursor-pointer appearance-none" />
              <label htmlFor="sameLocation" className="text-sm font-normal text-secondary cursor-pointer">{homedata?.part1_checkbox1 ? homedata?.part1_checkbox1 : "Return campervan to same location"}</label>
            </div>
            <div className="w-full flex flex-col">
              <div className="w-full flex gap-3 md:gap-5 flex-col sm:flex-row mb-2.5 lg:mb-5">
                <DatePicker
                  containerClassName="relative mt-8 "
                  label={homedata?.part1_value3 ? homedata?.part1_value3 : "Pickup Date"}
                  startDate={filters?.start_date}
                  endDate={filters?.end_date}
                  onChange={(e: any) => {
                    setFilters({ ...filters, start_date: e.startDate, end_date: e.endDate })
                  }}
                  placeholder={homedata?.part1_placeholder3 ? homedata?.part1_placeholder3 : "MM/DD/YYYY"}
                />
                <DatePicker
                  label={homedata?.part1_value4 ? homedata?.part1_value4 : "Return Date"}
                  showEndDate={true}
                  startDate={filters?.start_date}
                  endDate={filters?.end_date}
                  onChange={(e: any) => {
                    setFilters({ ...filters, start_date: e.startDate, end_date: e.endDate })
                  }}
                  placeholder={homedata?.part1_placeholder4 ? homedata?.part1_placeholder4 : "MM/DD/YYYY"}
                />
              </div>
              <div className={`w-full flex-auto mb-3 lg:mb-6`}>
                {/* Add dropdown of driver age and hide the checkbox to pass the ageid in spaceship api's discuss with nishant sir */}
                <label className="text-sm/4 font-medium text-secondary inline-block mb-1 lg:mb-2">{homedata?.part1_checkbox2 ? homedata?.part1_checkbox2 : "Driver Age"}</label>
                <div className="w-full locationSelect">
                  <Select
                    styles={colourStyles}
                    placeholder={homedata?.part1_checkbox2Placeholder ? homedata?.part1_checkbox2Placeholder : "Select Driver Age"}
                    className="capitalize w-full cursor-pointer sm:min-w-full lg:max-w-[313px]"
                    value={filters?.ageid}
                    onChange={(e: any) => setFilters({ ...filters, ageid: e })}
                    options={driverAges}
                    classNamePrefix="react zplus"
                  />
                </div>
                {/* <input type="checkbox"
                checked={filters?.isDriverAge}
                onChange={e => {
                  setFilters({ ...filters, isDriverAge: e.target.checked })
                }}
                name="driverAge" id="driverAge" className="shrink-0 text-primary size-4 border border-gray-400 rounded-sm cursor-pointer appearance-none" />
              <label htmlFor="driverAge" className="text-sm/none text-[#6B6B6B] cursor-pointer">
                Driver Age: Minimum: <span className="underline underline-offset-4 decoration-primary"> 23 years </span>
                Maximum: <span className="underline underline-offset-4 decoration-primary"> 70 years</span>
              </label> */}
              </div>
              <Tooltip placement="top" title="Search">
                <button type="button" onClick={() => handleSearch()} className="h-[54px] py-3 px-[30px] rounded-xl bg-primary flex gap-1 whitespace-nowrap items-center justify-center text-sm lg:text-base 2xl:text-lg text-white hover:opacity-80">
                  <IoIosSearch className="text-lg text-white size-4" />
                  Search Campervans
                </button>
              </Tooltip>
            </div>
          </div>
          <div className={peopleCounter ? "mt-2 lg:mt-4" : "mt-0"}>
            {peopleCounter &&
              <PeopleCounter
                peopleCount={searchFilter?.peopleCount}
                setSearchFilter={setSearchFilter}
              />
            }
          </div>
        </div>
        <div className=" order-1 lg:order-2 flex-1 relative text-white grid items-center">
          <p className="text-center lg:text-left  text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-[56px]/[72px] font-bold 2xl:font-bold max-w-[500px] max-lg:mx-auto mb-2 lg:mb-4" >Discover your ideal RV adventure</p>
          <p className="text-[#EAEAEA]/80 text-sm lg:text-base font-normal max-w-[750px] max-lg:mx-auto" >Experience our all-inclusive package for a seamless journey from start to finish. We take care of everything you need in a closed-loop service, ensuring a hassle-free and enjoyable experience from booking to return.  Sit back, relax, and let us handle every detail for you.</p>
          <button type="button" className="bg-primary rounded-xl text-sm lg:text-base 2xl:text-lg h-10 xl:h-12 2xl:h-[54px] max-lg:mx-auto w-[180px] text-[#F6F7FB] mt-4 lg:mt-8 p-2 flex items-center gap-2 justify-center hover:opacity-80">Discover More
            <IoIosArrowRoundForward className="size-5 text-white" />
          </button>
          {/* <div className="flex justify-between gap-4 items-center mb-3 md:mb-6">
              <div className="flex gap-2 items-center">
                  <img
                  alt="ImageIcon"
                  src="/assets/img/distance.svg"
                  width={28}
                  height={28}
                  className="shrink-0 shadow-xl brightness-0 invert-[3]"
                />             
              <h4 className="text-base md:text-xl lg:text-2xl font-semibold text-white">australia</h4>
              </div>
              <h5 className="text-sm md:text-base lg:text-xl font-medium text-white">*T&C's Apply</h5>
            </div> */}
          {/* <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold text-white flex items-center max-sm:flex-wrap gap-1.5 lg:gap-2 mb-3 md:mb-6">the <p className="text-sm md:text-base lg:text-xl/5 font-medium text-white max-w-[160px] md:max-w-[212px]">I refuse to let this year go by without a holiday</p> sale</h1>
            <p className="text-sm md:text-base lg:text-xl/5 font-medium text-white items-center gap-1 inline">Up to <h2 className="inline text-xl md:text-3xl lg:text-[42px] font-bold text-white">35% off*</h2> <br/>36 destinations</p> */}
        </div>
      </div>
    </div>
  )
}

export default SearchBanner