import PageLayout from "../../components/global/PageLayout";
import AboutUs from "../../components/common/AboutUs";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { IoIosArrowRoundForward } from "react-icons/io";

const About = () => {
  const [aboutUsDetail, setAboutUsDetail] = useState()

  useEffect(()=>{
    getAboutUsDetails()
  },[])
  
  const getAboutUsDetails=()=>{
    loader(true)
    ApiClient.get(`content?title=about us`).then(res=>{
      if(res.success){
        setAboutUsDetail(res?.data)
      }
      loader(false)
    })
  }

  const details = {
    campervan_name: "About Us",
  };

  return (
    <>
      <PageLayout>
      <div className={`max-w-[1504px] w-full mx-auto px-4 lg:px-8`}>
        <div className="mt-4 lg:mt-6">
          <Breadcrumb
            links={[
              { link: "/", name: "Home" },
            ]}
            currentPage={details?.campervan_name}
          />
        </div>
          <div className="flex items-center justify-center gap-4 lg:gap-12 flex-col lg:flex-row mt-4 lg:mt-8">
            <div className="flex flex-col lg:max-w-[678px] w-full">
              <button
                type="button"
                className="mb-5 uppercase w-fit shrink-0 py-2 px-2.5 rounded text-sm md:text-base/5 font-medium text-primary hover:opacity-80 bg-[linear-gradient(90deg,_rgba(23,68,210,0.2)_0%,_rgba(12,35,108,0)_100%)]">
                About Us
              </button>
              <p className="[&>h1]:text-base [&>h1]:md:text-xl [&>h1]:lg:text-3xl [&>h1]:xl:text-4xl [&>h1]:font-semibold [&>h1]:text-secondary [&>h1]:mb-4 [&>p]:text-sm  [&>p]:lg:text-base [&>p]:font-normal [&>p]:text-gray-light" dangerouslySetInnerHTML={{ __html: aboutUsDetail?.description }}></p>
              {/* <h1 className="text-2xl lg:text-5xl font-semibold text-secondary mb-6">
                No 1 road trip provider in North America, Oceania and Europe
              </h1>
              <p className="text-base font-normal text-secondary/70 mb-4 sm:mb-8">
                Alberta, located in western Canada, is a province renowned for
                its stunning natural beauty and diverse landscapes. It's home to
                the majestic Rocky Mountains, vast prairies, and pristine lakes,
                making it a paradise for outdoor enthusiasts and nature lovers
                alike.
              </p> */}
                <button
                  type="button"
                  className="mt-4 lg:mt-8 flex items-center gap-2 w-fit shrink-0 px-4 py-2 lg:px-8 h-10 xl:h-12 2xl:h-[54px] rounded-xl bg-primary text-base lg:text-lg/5 font-medium text-white hover:opacity-80">
                  Read More
                  <IoIosArrowRoundForward className="size-6 text-white" />
                </button>
            </div>
            <div className="lg:max-w-[714px] w-full">
              <img
                alt="campVan1"
                src={methodModel.noImg(aboutUsDetail?.image)}
                className="w-full max-lg:min-h-[150px] lg:h-[475px] object-cover rounded-[20px]"
              />
            </div>
          </div>
      </div>
        <div className="bg-[#F6F7FB] py-4 lg:py-12 my-6 lg:my-12">
          <div className="max-w-[1504px] w-full mx-auto px-4 lg:px-8 flex flex-col-reverse lg:flex-row items-center justify-between gap-12">
            <div className="lg:max-w-[714px] w-full">
              <img
                alt="campVan1"
                src={methodModel.noImg(aboutUsDetail?.image2)}
                className="w-full object-cover rounded-[20px] h-[400px] lg:h-[478px]"
              />
            </div>
            <div className="lg:max-w-[678px] w-full">
              <div className="[&>h1]:text-center [&>h1]:lg:text-left [&>h1]:text-base [&>h1]:md:text-xl [&>h1]:lg:text-3xl [&>h1]:xl:text-4xl [&>h1]:font-semibold [&>h1]:mb-4 [&>h1]:text-secondary aboutmissionsection  [&>h2]:text-gray-light " dangerouslySetInnerHTML={{ __html: aboutUsDetail?.description2 }}></div>
              {/* <div className="min-w-12 h-12 rounded-full bg-primary flex items-center justify-center">
                  <img
                    src="assets/img/camper/crisis-alert.svg"
                    alt="crisis"
                    width={24}
                    height={24}
                    className="size-6"
                  />
                </div> */}


              {/* <h1 className="text-2xl lg:text-5xl font-semibold text-secondary mb-4">
                Who We Are
              </h1>
              <p className="text-base font-normal text-secondary/70 mb-12 max-w-[730px]">
                Alberta, located in western Canada, is a province renowned for
                its stunning natural beauty and diverse landscapes. It's home to
                the majestic Rocky Mountains, vast prairies, and pristine lakes,
                making it a paradise for outdoor enthusiasts and nature lovers
                alike.
              </p>
              <div className="flex gap-4 mb-8">
                <div className="min-w-12 h-12 rounded-full bg-primary flex items-center justify-center">
                  <img
                    src="assets/img/camper/crisis-alert.svg"
                    alt="crisis"
                    width={24}
                    height={24}
                    className="size-6"
                  />
                </div>
                <div className="">
                  <h2 className="text-xl sm:text-[32px] font-semibold text-secondary mb-4">
                    Our Mission
                  </h2>
                  <p className="text-base font-normal text-secondary/70">
                    Lorem ipsum dolor sit amet consectetur. Eu dignissim dolor
                    massa nunc eget magna sed. Nec proin nascetur libero fames
                    non consectetur cursus mi. Velit morbi parturient vivamus
                    faucibus et lobortis lacus mi. Sit at in cum augue tempor.
                    Pellentesque viverra tincidunt molestie amet eleifend.
                  </p>
                </div>
              </div>
              <div className="flex gap-4">
                <div className="min-w-12 h-12 rounded-full bg-primary flex items-center justify-center">
                  <img
                  src="assets/img/camper/eye-track.svg"
                  alt="eye"
                  width={24}
                  height={24}
                  className="size-6"
                />
                </div>
                <div className="">
                  <h2 className="text-xl sm:text-[32px] font-semibold text-secondary mb-4">
                    Our Vission
                  </h2>
                  <p className="text-base font-normal text-secondary/70">
                    Lorem ipsum dolor sit amet consectetur. Eu dignissim dolor
                    massa nunc eget magna sed. Nec proin nascetur libero fames
                    non consectetur cursus mi. Velit morbi parturient vivamus
                    faucibus et lobortis lacus mi. Sit at in cum augue tempor.
                    Pellentesque viverra tincidunt molestie amet eleifend.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>  
        <div className={`max-w-[1504px] w-full mx-auto px-4 lg:px-8`}>
          <div className="flex items-center flex-col lg:flex-row gap-4 lg:gap-12 mb-4 lg:mb-12">
            <div className="lg:max-w-[822px] w-full">
              <div className="[&>h1]:text-center [&>h1]:lg:text-left [&>h1]:text-base [&>h1]:md:text-xl [&>h1]:lg:text-3xl [&>h1]:xl:text-4xl [&>h1]:font-semibold [&>h1]:mb-2 [&>h1]:lg:mb-4 [&>h1]:text-secondary [&>p]:text-sm/6 [&>p]:lg:text-base [&>p]:font-normal [&>p]:text-gray-light" dangerouslySetInnerHTML={{ __html: aboutUsDetail?.description3 }}></div>
              {/* <h1 className="text-2xl lg:text-5xl font-semibold text-secondary mb-6">
                RV Renter Center in Australia
              </h1>
              <p className="text-base font-normal text-secondary/70 mb-4 sm:mb-8">
                Lorem ipsum dolor sit amet consectetur. Ullamcorper morbi enim
                nibh facilisis venenatis pulvinar malesuada. Arcu sed odio sit
                amet ornare nibh condimentum. Nibh proin praesent dui ac.
                Fringilla sollicitudin quam tristique feugiat mattis ante morbi
                quis. Sit dignissim felis viverra amet elit faucibus. Sem
                tincidunt eleifend sollicitudin fringilla. Imperdiet platea
                lectus senectus laoreet augue. Congue molestie eget ornare duis.
                Consequat a dignissim pellentesque et sed. Odio id eleifend
                auctor faucibus donec phasellus porttitor ullamcorper hendrerit.
                Tincidunt dolor felis in dui ultrices ipsum volutpat. Natoque
                convallis turpis vestibulum proin sed lacinia porta sagittis.
                Pharetra orci sit turpis neque praesent non gravida eu mattis.
                Dictum ac ornare tellus arcu dui viverra orci leo varius.
                Gravida in aliquam habitant semper fusce eleifend ut. Diam amet
                in sit egestas volutpat nec. Fermentum at sit et lorem massa.
                Purus quam leo suspendisse dignissim. Egestas lorem enim aliquam
                egestas euismod sed laoreet in dictum. Sed at auctor vitae
                scelerisque quam non. Cras tellus dui ut tempus lacinia in eu.
                In nullam consequat elit sed ultricies at id. Blandit pulvinar
                eget lacus dignissim donec aliquam vehicula sollicitudin.
                Iaculis arcu amet integer egestas ac venenatis in vel id. Enim
                sem sem morbi nec risus etiam tellus. Eleifend malesuada
                vestibulum vulputate elementum sit arcu dui ultrices. Eget
                gravida leo diam gravida et sollicitudin.
              </p> */}
            </div>
            <div className="lg:max-w-[570px] w-full">
              <img
                alt="campVan1"
                src={methodModel.noImg(aboutUsDetail?.image3)}
                className="w-full object-cover rounded-[20px] md:h-[425px]"
              />
            </div>
          </div>
          <AboutUs/>
          <div style={{ backgroundImage: `url("assets/img/front/bg_cover.png")` }} className="bg_bag !bg-right h-[250px] sm:h-[340px] lg:h-[410px] xl:h-[490px] rounded-3xl overflow-auto px-4 md:px-10 lg:px-[100px] flex items-center my-6 lg:my-12">
            <div className="max-w-[794px] relative z-10 w-full">
              <h2 className="text-sm sm:text-base lg:text-3xl 2xl:text-[40px]/[56px] font-semibold text-[#F6F7FB] mb-4 lg:mb-10 max-w-[674px]">
                Do you want to list your campervan? List your RV for free today and <br />earn up to $1,800 /week.
              </h2>
              <button type="button" className="px-5 xl:px-[30px] py-2 lg:py-[9px] xl:h-12 2xl:h-[54px] border border-white rounded-xl text-sm lg:text-base 2xl:text-lg text-white hover:bg-primary transition-all hover:border-transparent">
                List Your RV
              </button>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default About;
