import React, { Dispatch, SetStateAction } from 'react'
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";

import { searchFilterIF } from '.';

interface DatePickerProps {
  label: string
  showEndDate?: boolean
  startDate: any
  endDate: any
  setSearchFilter?: Dispatch<SetStateAction<searchFilterIF>>
  onChange?: any
  placeholder?:any
  containerClassName?:any
  classNamePrefix?:any
  classes?:string
}

const DatePicker: React.FC<DatePickerProps> = ({ label, startDate, endDate, setSearchFilter = (e) => { }, showEndDate = false, onChange = (e: any) => { }, placeholder = "" , classes = " " }) => {

  const handleValueChange = (newValue: DateValueType | null) => {
    onChange(newValue)
    if (newValue) {
      const startDate = newValue.startDate ? newValue.startDate.toString() : '';
      const endDate = newValue.endDate ? newValue.endDate.toString() : '';

      setSearchFilter((prev) => ({
        ...prev,
        startDate,
        endDate,
      }));
    }
  };

  return (
    <div className='flex-1'>
      <label htmlFor="" className="text-sm/4 font-medium text-secondary inline-block mb-1 lg:mb-2">{label}</label>
      <div className={`px-3 h-10 rounded-xl border border-[#E3E5ED] flex gap-2 items-center justify-start relative w-full ${classes}`}  role="button">
        
        <span className={`text-sm/none ${showEndDate ? (endDate ? 'text-black whitespace-nowrap' : 'text-[#8D8D8D] whitespace-nowrap') : (startDate ? 'text-black whitespace-nowrap' : ' whitespace-nowrap text-[#8D8D8D]')}`}>{showEndDate ? (endDate || placeholder) : (startDate || placeholder)}</span>
        <Datepicker
          useRange={true}
          value={{ startDate: startDate, endDate: endDate }}
          onChange={handleValueChange}
          inputClassName="opacity-0 absolute inset-0 cursor-pointer"
          containerClassName="inset-0 customcalenderstyling left-0"
          popoverDirection="down"
          toggleClassName="opacity-0"
          minDate={new Date()}
          displayFormat={"DD/MM/YYYY"}
        />
        <img src="/assets/img/front/newcalendar.svg" alt="CalenderIcon" className="size-4 absolute top-[50%] translate-y-[-50%] right-3" width={16} height={16} />
      </div>

    </div>
  )
}

export default DatePicker