import { useContext } from "react"
import methodModel from "../../methods/methods"
import { HomeData } from "../../Pages/Home"
import { ourServices } from "../../utils/static-data"

const  OurServices = ({ detail }) => {
  const homedata = useContext(HomeData) // Get data using useContext from Home.tsx

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4 lg:gap-5 xl:gap-8">
      {detail?.length > 0 ?
        <>
          {detail.map((itm, idx) => (
            <div key={idx} className="flex flex-col items-start flex-wrap gap-y-3 lg:gap-y-6 p-4 lg:p-8 rounded-[20px] hover:shadow-[0px_24px_64px_0px_#0A0A0B1A]">
              <img src={methodModel.noImg(itm?.image)} alt='sec' className=" mx-auto" />
              <div className="gap-y-4 flex flex-col">
                <p className="[&>h3]:text-secondary [&>h3]:text-base [&>h3]:lg:text-xl [&>h3]:xl:text-2xl [&>h3]:font-semibold [&>h3]:mb-3 [&>h3]:text-center [&>p]:text-base [&>p]:text-gray-light [&>p]:font-normal [&>p]:text-center" dangerouslySetInnerHTML={{ __html: itm?.description }}></p>
              </div>
            </div>
          ))}
        </>
        :
        <>
          {/* {ourServices.map((itm, idx) => (
            <div key={idx} className="flex flex-col items-center flex-wrap  gap-y-2 xl:gap-6 lg:py-7 lg:px-11 py-4 px-4 shadow-[14px_16px_87px_0px_#2B2B2B0F] rounded-2xl border-[1px] border-[#E7E7E7]">
              <img src={itm.image} alt='sec' className="h-8 w-8 md:w-12 md:h-12" />
              <div className="gap-2 lg:gap-4 flex flex-col">
                <h4 className="text-sm lg:text-[18px] text-secondary font-semibold leading-6 w-full text-center">{itm.title}</h4>
                <p className="text-sm lg:text-sm font-normal line-clamp-4 text-secondary/70 text-center">{itm.description}</p>
              </div>
            </div>
          ))} */}
            <div className="flex flex-col items-center flex-wrap  gap-y-2 xl:gap-6 p-2 lg:p-4 xl:p-8  rounded-[20px] hover:shadow-[0px_24px_64px_0px_#0A0A0B1A]">
            <div className="bg-[#FFF6D8] rounded-full w-14 h-14  md:h-16 md:w-16 lg:h-[96px] lg:w-[96px] flex items-center justify-center ">
              <img src={homedata?.part2_icon1?methodModel.noImg(homedata?.part2_icon1):"/assets/img/front/sec1.svg"} alt='sec' className="h-8 w-8 md:w-12 md:h-12" /> </div>
              <div className="gap-2 lg:gap-4 flex flex-col">
                <h4 className="text-sm lg:text-2xl text-secondary font-semibold leading-6 w-full text-center">{homedata?.part2_sub_heading1?homedata?.part2_sub_heading1:"Flexible cancelation"}</h4>
                <p className="text-sm lg:text-base font-normal line-clamp-4 text-gray-light text-center">{homedata?.part2_description1?homedata?.part2_description1:"Campervans and RVs with the indie fleet flexible cancelation policy can be canceled free of charge up to 31 days before pick-up."}</p>
              </div>
            </div>
            <div className="flex flex-col items-center flex-wrap  gap-y-2 xl:gap-6 p-2 lg:p-4 xl:p-8  hover:shadow-[0px_24px_64px_0px_#0A0A0B1A] rounded-[20px] ">
            <div className="bg-[#F0FDF9] rounded-full w-14 h-14  md:h-16 md:w-16 lg:h-[96px] lg:w-[96px] flex items-center justify-center ">
              <img src={homedata?.part2_icon2?methodModel.noImg(homedata?.part2_icon2):"/assets/img/front/sec2.svg"} alt='sec' className="h-8 w-8 md:w-12 md:h-12" /> </div>
              <div className="gap-2 lg:gap-4 flex flex-col">
                <h4 className="text-sm lg:text-2xl text-secondary font-semibold leading-6 w-full text-center">{homedata?.part2_sub_heading2?homedata?.part2_sub_heading2:"One-way trips are available"}</h4>
                <p className="text-sm lg:text-base font-normal line-clamp-4 text-gray-light text-center">{homedata?.part2_description2?homedata?.part2_description2:"Pick up and drop off your campervan at +70 locations across North America, Europe and Oceania."}</p>
              </div>
            </div>
            <div className="flex flex-col items-center flex-wrap  gap-y-2 xl:gap-6 p-2 lg:p-4 xl:p-8 col-span-1 max-lg:max-w-[400px] mx-auto w-full sm:col-span-2 lg:col-span-1 rounded-[20px] hover:shadow-[0px_24px_64px_0px_#0A0A0B1A]">
              <div className="bg-[#FFE1B8] rounded-full w-14 h-14  md:h-16 md:w-16 lg:h-[96px] lg:w-[96px] flex items-center justify-center "> 
              <img src={homedata?.part2_icon3?methodModel.noImg(homedata?.part2_icon3):"/assets/img/front/sec4.svg"} alt='sec' className="h-8 w-8 md:w-12 md:h-12 " />
              </div>
              <div className="gap-2 lg:gap-4 flex flex-col">
                <h4 className="text-sm lg:text-2xl text-secondary font-semibold leading-6 w-full text-center">{homedata?.part2_sub_heading3?homedata?.part2_sub_heading3:"Book now, pay later!"}</h4>
                <p className="text-sm lg:text-base font-normal line-clamp-4 text-gray-light text-center">{homedata?.part2_description3?homedata?.part2_description3:"Flexible cancelation policy, you only pay the full amount of your booking 30 days before the pick-up date."}</p>
              </div>
            </div>
        </>
      }
    </div>
  )
}

export default OurServices