import React from "react";
import { FaCheck } from "react-icons/fa";

interface ReviewPreviewProps {
  tab: number;
  settab: React.Dispatch<React.SetStateAction<number>>;
}

const ReviewPreview: React.FC<ReviewPreviewProps> = ({ settab }) => {
  return (
    <div
      className={`max-w-[1504px] w-full mx-auto px-4 lg:px-8 py-9 sm:py-12 xl:h-[678px] flex items-center justify-center`}
    >
      <div>
        <div className="mb-10 sm:mb-16 mx-auto size-[80px] md:size-[140px] lg:size-[160px] rounded-full flex items-center justify-center border-4 border-primary ">
        <FaCheck className="text-primary  w-[54px] h-10 font-normal " />
        </div>
        <button
          type="button" 
          onClick={() => settab(7)}
          className="inline-block h-10 sm:h-11 w-[298px] text-white text-sm lg:text-base 2xl:text-lg/5 font-medium bg-primary rounded-xl hover:opacity-80"
        >
          Submit listing
        </button>
      </div>
    </div>
  );
};

export default ReviewPreview;
