import { Disclosure, Transition } from "@headlessui/react";
import styles from "./index.module.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { RiHome6Line, RiUserSettingsLine } from "react-icons/ri";
import { TiArrowSortedDown } from "react-icons/ti";
import { useSelector } from "react-redux";
import { SlCalender } from "react-icons/sl";
import { TbCamper } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";
import methodModel from "../../../methods/methods";
import { GoMail } from "react-icons/go";
import { IoMdHeartEmpty } from "react-icons/io";
import { LuUser2 } from "react-icons/lu";
import { useDispatch } from 'react-redux';
import {LOGOUT_SUCCESS} from '../../../Redux/Action/Action';
import { IoLogOutOutline } from "react-icons/io5";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Html = ({ ListItemLink, tabclass, isAllow, route, isOpen }) => {
  const dispatch = useDispatch()
  const location = useLocation();
  const user = useSelector((state) => state.User)
  const navigate = useNavigate()
  const menus = [
    // {
    //   name: 'Main Menu',
    // },
    {
      name: 'Dashboard',
      icon: <RiHome6Line className="shrink-0 text-lg" />,
      url: '/dashboard',
      key: 'readDashboard',
    },
    {
      name: 'Sub Agents',
      icon: <RiUserSettingsLine className="shrink-0 text-lg" />,
      url: '/subagent',
      key: 'readAgent',
    },
    {
      name: 'Customers',
      icon: <FiUsers className="shrink-0 text-lg" />,
      url: '/customer',
      key: 'readcustomer',
    },
    {
      name: 'Campervan',
      icon: <TbCamper className="shrink-0 text-lg" />,
      url: '/campervanlist',
      key: 'readCampervan',
    },
    {
      name: 'Bookings',
      icon: <SlCalender className="shrink-0 text-lg" />,
      url: '/bookings',
      key: 'readBookingdetail',
    },
    {
      name: 'My Rewards',
      icon:<img src="/assets/img/front/receipt-item.svg" alt="CalenderIcon"  width="16" height="16"></img>,
      //  <GoGift className="shrink-0 text-lg" />
      url: '/rewards',
      key: 'myreward',
    },
    {
      name: 'My Messages',
      icon: <GoMail className="shrink-0 text-lg" />,
      url: '/my-message',
      key: 'mymessage',
    },
    {
      name: 'My Wishlist',
      icon: <IoMdHeartEmpty className="shrink-0 text-lg" />,
      url: '/my-wishlist',
      key: 'mywishlist',
    },
  ]

  const handleSidebarModules = (key) => {
    if (user?.role == 'customer') {
      if (key == 'readDashboard' || key == 'readcustomer' || key == 'readAgent') {
        return false
      } else {
        return true
      }
    } else if (user?.role == 'sub_agent') {
      if (key == 'readDashboard' || key == 'readAgent') {
        return false
      } else {
        return true
      }
    }
    else {
      return true
    }
  }
  const Logout = () => {
    dispatch(LOGOUT_SUCCESS())
    localStorage.removeItem("persist:root")
    localStorage.removeItem("token")
    navigate('/login');
  };

  return (
    <>
      <div className={`flex flex-col border rounded-2xl border-gray-border xl:max-w-[320px] w-full p-4 lg:p-6 items-start  ${isOpen && styles.sm_sidebar}`} component="siderbar">
        <div className="flex flex-wrap gap-3 border-b border-gray-border pb-5 w-full">
        <img
          alt={"AccountUser"}
          src={methodModel.userImg(user?.image)}
          className="w-12 h-12"
        />
        <div className="flex flex-col">
        <h3 className="text-xl font-semibold text-secondary whitespace-nowrap mb-1 capitalize">{user?.fullName || user?.firstName}</h3>
        <p className="text-sm font-normal text-gray-light"> {user?.email || '--'}</p>
        </div>
        </div>
        <Tooltip placement="right" title="My profile">
        <button
          type="button"
          onClick={e => { navigate('/profile/edit') }}
          className="w-full py-4 px-5 rounded-xl flex items-center gap-3 text-base font-normal text-gray-light border border-transparent hover:border-primary group hover:text-primary transition-all mt-5">
          <LuUser2 className=" group-hover:text-primary" />
          {/* <img
            alt={"eidt-btn"}
            src={"/assets/img/camper/edit.svg"}
            className="w-5 h-5 ml-2"
          /> */}
          My Profile
        </button>
        </Tooltip>
        <ul className="space-y-2 px-0 mb-4 w-full">
          {menus.map(itm => {
            return <>
              {itm.icon ? <>
                <li>
                  {itm.menu ? <>
                    <Disclosure as="div" defaultOpen={tabclass(itm.tab)}>
                      {({ open }) => (
                        <>
                          <Tooltip placement="right" title={itm.name}>
                            <Disclosure.Button className="w-full py-4 px-5 rounded-xl flex items-center justify-between text-gray-light hover:border  hover:!text-primary gap-[12px] hover:border-primary transition-all duration-300">
                              <span className="text-md font-normal text-inherit flex items-center gap-[12px] crm">
                                {itm.icon}
                                <span className=" text-inherit leading-none sidebar_text whitespace-nowrap"> {itm.name}</span>
                              </span>
                              <TiArrowSortedDown
                                className={`${open ? "" : "-rotate-90 transform"
                                  } h-4 w-4 transition-all duration-500  text-gray-light`}
                              />
                            </Disclosure.Button>
                          </Tooltip>
                          <Transition
                            enter="transition duration-300 ease-in-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-300 opacity-300"
                            leave="transition duration-300 ease-in-out"
                            leaveFrom="transform scale-300 opacity-300"
                            leaveTo="transform scale-95 opacity-0">
                            <Disclosure.Panel className="pl-[30px] mt-[4px] ">
                              <ul className="space-y-2">
                                {itm.menu.map(sitm => {
                                  return <>
                                    {isAllow(sitm.key) ? <li> <NavLink className={(isActive) =>
                                      "py-4 px-5 rounded-xl block text-lg font-normal text-gray-light cursor-pointer hover:!text-white hover:border-primary hover:border transition-all " +
                                      (location?.pathname == sitm.url &&
                                        " !text-white bg-primary !font-medium")
                                    } to={sitm.url}>
                                      <span className="text-inherit leading-none sidebar_text whitespace-nowrap" title={sitm.name}> {sitm.name}</span>
                                    </NavLink></li> : null}
                                  </>
                                })}
                              </ul>
                            </Disclosure.Panel>
                          </Transition>
                        </>
                      )}
                    </Disclosure>
                  </> : <>
                    {handleSidebarModules(itm?.key) ? <>
                      <Tooltip placement="right" title={itm.name}>
                        <NavLink
                          to={itm.url}
                          className={(isActive) =>
                            "py-4 px-5 rounded-xl flex items-center gap-3 text-base font-normal text-gray-light  border border-transparent hover:border-primary group hover:text-primary transition-all " +
                            (location?.pathname == itm.url && " !text-primary border !border-primary !font-medium")
                          }>
                          {itm.icon}
                          <span className="text-inherit leading-none sidebar_text whitespace-nowrap group-hover:text-primary">{itm.name}</span>
                        </NavLink>
                      </Tooltip>
                    </> : <></>}
                  </>}
                </li>
              </> : <>
                <li>
                  <h6
                    className={`${isOpen ? "py-[12px] text-center" : "p-[12px]"
                      } text-xs font-medium text-gray-light mt-[12px]`}>
                    <span className=" sidebar_text"> {itm.name} </span>
                  </h6>
                </li>
              </>}
            </>
          })}
         
        </ul>
        <div className="border-t border-gray-border w-full">
        <button type="button" onClick={() => Logout()} className="mt-4 rounded-xl border border-transparent  flex gap-3 items-center text-gray-light cursor-pointer w-full py-4 px-5 text-left text-base ancortag group hover:border-primary hover:text-primary">
            <IoLogOutOutline className="size-5 mr-2 inline group-hover:text-primary" />  Log Out
          </button>
          </div>
      </div>
    </>
  );
}

export default Html