import React, { useState } from "react";
import Layout from "../../components/global/layout";
// import { Link } from "react-router-dom";
// import { MdOutlineImage } from "react-icons/md";
// import { FiLink } from "react-icons/fi";
// import { BsEmojiSmile } from "react-icons/bs";
// import { VscSend } from "react-icons/vsc";
import { LuSend } from "react-icons/lu";
import { Tab } from "@headlessui/react";
import { BiArchiveIn } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";

interface Message {
  id: number;
  name: string;
  time: string;
  message: string;
  imageSrc: string;
  tickIcon?: string | null;
  unreadCount: number;
  typing: boolean;
}

const messageData: Message[] = [
  {
    id: 1,
    name: "Regulon",
    time: "11:34 AM",
    message: "Great performance lad",
    imageSrc: "/assets/img/user-2.png",
    tickIcon: "/assets/img/tick.svg",
    unreadCount: 0,
    typing: false,
  },
  {
    id: 2,
    name: "Carbachol",
    time: "11:34 AM",
    message: "Great performance lad",
    imageSrc: "/assets/img/user-3.png",
    tickIcon: null,
    unreadCount: 3,
    typing: false,
  },
  {
    id: 3,
    name: "Andre Onana",
    time: "06:34 AM",
    message: "typing...",
    imageSrc: "/assets/img/user-2.png",
    tickIcon: null,
    unreadCount: 0,
    typing: true,
  },
  {
    id: 4,
    name: "Andre Onana",
    time: "06:34 AM",
    message: "Get more fal",
    imageSrc: "/assets/img/user-3.png",
    tickIcon: "/assets/img/tick.svg",
    unreadCount: 0,
    typing: false,
  },
];

const MyMessages: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedMessage, setSelectedMessage] = React.useState<number | null>(
    null
  );

  const handleSelect = (id: number) => {
    setSelectedMessage(id);
  };

  const handleTabChange = (index: number) => {
    setCurrentTab(index);
  };

  return (
    // <Layout>
    //   <div className="w-full">
    //     <h2 className="text-base lg:text-xl xl:text-2xl font-semibold mb-6 text-secondary">My Messages</h2>
    //     <div className="border border-gray-border p-3 sm:p-6 sm:pb-12 rounded-2xl">
    //       <div className="scrollbar overflow-y-auto h-[300px] pr-2">
    //         <h4 className="text-lg font-medium text-secondary mb-4">Chat with Rodger</h4>
    //         <p className="text-base font-normal text-gray-light ">Always pay and communicate through Rv renter  to ensure you’re protected under our
    //           <Link to="" className='text-secondary/70 underline-offset-2 underline hover:text-primary transition-all'> terms of service, privacy, cancellation policy </Link>
    //           and other fraudulent safeguards.
    //         </p>
    //         <div className="flex justify-end my-6 gap-3">
    //           <div className="bg-primary p-3 rounded-br-xl rounded-l-xl">
    //             <h6 className="text-lg font-medium text-white mb-1 whitespace-nowrap">Warraant</h6>
    //             <p className="text-sm font-normal text-white/70 whitespace-nowrap">Mar 3, 2024 6:50 PM</p>
    //           </div>
    //           <img src="/assets/img/user-2.png" alt="user" className='size-12 block' />
    //         </div>
    //         <h4 className="text-lg font-medium text-secondary mb-4">Chat with Rodger</h4>
    //         <p className="text-base font-normal text-gray-light ">Always pay and communicate through Rv renter  to ensure you’re protected under our
    //           <Link to="" className='text-secondary underline-offset-2 underline hover:text-primary transition-all'> terms of service, privacy, cancellation policy </Link> and other fraudulent
    //           safeguards.</p>
    //       </div>
    //       <div className="flex gap-4 items-center mt-11 justify-center flex-wrap sm:flex-nowrap">
    //         <div className="flex gap-1.5 items-center ">
    //           <Link to="#" className=""><MdOutlineImage className='size-5 text-secondary hover:text-primary' /></Link>
    //           <Link to="#" className=""><FiLink className='size-5 text-secondary hover:text-primary' /></Link>
    //           <Link to="#" className=""><BsEmojiSmile className='size-5 text-secondary hover:text-primary' /></Link>
    //         </div>
    //         <div className="w-full flex sm:gap-4 gap-2">
    //           <input type="text" name="message" id="" className='text-base font-normal text-gray-light block flex-1 border-0 rounded-xl p-3 h-10 focus:ring-offset-[1.2px] ring-1 ring-gray-border focus:ring-primary outline-none appearance-none' placeholder='Write a message...' />
    //           <button type='button' className="bg-[#F2F5F7] flex items-center justify-center min-h-10 min-w-10 rounded-full hover:bg-primary hover:text-white transition-all">
    //             <VscSend className='size-6 ' />
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </Layout>
    <Layout>
      <div className="w-full">
        <div className="border border-gray-border rounded-2xl flex max-sm:flex-col overflow-auto scrollbar">
          <div className="sm:max-w-[367px] w-full max-sm:border-b sm:border-r border-gray-border">
            <div className="py-3 sm:py-5 px-3 lg:px-6 border-b border-gray-border">
              <h3 className="text-base sm:text-2xl font-medium text-secondary">
                Inbox
              </h3>
            </div>
            <Tab.Group selectedIndex={currentTab} onChange={handleTabChange}>
              <Tab.List className="flex px-3 lg:px-6 pt-4 items-center border-b border-gray-border overflow-auto scrollbar">
                <Tab
                  className={({ selected }) =>
                    `whitespace-nowrap text-xs font-normal py-2 px-2.5 sm:px-4 outline-none ${
                      selected
                        ? "border-b border-primary text-primary !font-medium"
                        : "text-gray-light"
                    }`
                  }
                >
                  All
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `whitespace-nowrap text-xs font-normal py-2 px-2.5 sm:px-4 outline-none ${
                      selected
                        ? "border-b border-primary text-primary !font-medium"
                        : "text-gray-light"
                    }`
                  }
                >
                  Enquiries
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `whitespace-nowrap text-xs font-normal py-2 px-2.5 sm:px-4 outline-none ${
                      selected
                        ? "border-b border-primary text-primary !font-medium"
                        : "text-gray-light"
                    }`
                  }
                >
                  Requests
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `whitespace-nowrap text-xs font-normal py-2 px-2.5 sm:px-4 outline-none ${
                      selected
                        ? "border-b border-primary text-primary !font-medium"
                        : "text-gray-light"
                    }`
                  }
                >
                  Archives
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <div className="p-3 lg:pt-5 lg:px-6  text-base/5 font-medium text-gray-light">
                    Messages
                  </div>
                  <div>
                    {messageData.map((msg) => (
                      <div
                        key={msg.id}
                        onClick={() => handleSelect(msg.id)}
                        className={`px-3 lg:px-6 py-2 sm:py-3 cursor-pointer w-full ${
                          selectedMessage === msg.id
                            ? "bg-[#F6F7FB]"
                            : "hover:bg-[#F6F7FB]"
                        }`}
                      >
                        <div className="flex gap-3 items-center w-full">
                          <div className="relative">
                            <img
                              src={msg.imageSrc}
                              alt="messageImg"
                              className="block size-8 sm:min-w-14 sm:min-h-14 rounded-full"
                            />
                            <div className="absolute bottom-0 size-2 lg:size-3 p-0.5 right-0 sm:right-1 bg-white rounded-full flex items-center justify-center">
                              <span className="bg-[#63C853] block min-w-1.5 min-h-1.5 lg:min-w-2.5 lg:min-h-2.5 rounded-full"></span>
                            </div>
                          </div>
                          <div className="w-full">
                            <div className="flex justify-between gap-2 items-center mb-0.5 lg:mb-1.5">
                              <h6 className="text-sm sm:text-base lg:text-lg/5 font-medium text-secondary">
                                {msg.name}
                              </h6>
                              <p className="text-xs lg:text-sm font-normal text-gray-light text-end whitespace-nowrap">
                                {msg.time}
                              </p>
                            </div>
                            <div className="flex justify-between gap-2 items-center">
                              <p
                                className={`text-xs lg:text-sm font-normal ${
                                  msg.typing
                                    ? "text-primary"
                                    : "text-gray-light"
                                }`}
                              >
                                {msg.message}
                              </p>
                              {msg.unreadCount > 0 ? (
                                <div className="bg-[#FF0202] size-3 lg:size-5 flex items-center justify-center rounded-full mr-3 text-[7px] lg:text-[10px] font-normal text-white">
                                  {msg.unreadCount}
                                </div>
                              ) : (
                                msg.tickIcon && (
                                  <img
                                    src={msg.tickIcon}
                                    className="size-3 lg:size-5"
                                    alt="tick-icon"
                                  />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="p-3 lg:pt-5 lg:px-6  text-base/5 font-medium text-gray-light">
                    Messages
                  </div>
                  <div>
                    {messageData.map((msg) => (
                      <div
                        key={msg.id}
                        onClick={() => handleSelect(msg.id)}
                        className={`px-3 lg:px-6 py-2 sm:py-3 cursor-pointer w-full ${
                          selectedMessage === msg.id
                            ? "bg-[#F6F7FB]"
                            : "hover:bg-[#F6F7FB]"
                        }`}
                      >
                        <div className="flex gap-3 items-center w-full">
                          <div className="relative">
                            <img
                              src={msg.imageSrc}
                              alt="messageImg"
                              className="block size-8 sm:min-w-14 sm:min-h-14 rounded-full"
                            />
                            <div className="absolute bottom-0 size-2 lg:size-3 p-0.5 right-0 sm:right-1 bg-white rounded-full flex items-center justify-center">
                              <span className="bg-[#63C853] block min-w-1.5 min-h-1.5 lg:min-w-2.5 lg:min-h-2.5 rounded-full"></span>
                            </div>
                          </div>
                          <div className="w-full">
                            <div className="flex justify-between gap-2 items-center mb-0.5 lg:mb-1.5">
                              <h6 className="text-sm sm:text-base lg:text-lg/5 font-medium text-secondary">
                                {msg.name}
                              </h6>
                              <p className="text-xs lg:text-sm font-normal text-gray-light text-end whitespace-nowrap">
                                {msg.time}
                              </p>
                            </div>
                            <div className="flex justify-between gap-2 items-center">
                              <p
                                className={`text-xs lg:text-sm font-normal ${
                                  msg.typing
                                    ? "text-primary"
                                    : "text-gray-light"
                                }`}
                              >
                                {msg.message}
                              </p>
                              {msg.unreadCount > 0 ? (
                                <div className="bg-[#FF0202] size-3 lg:size-5 flex items-center justify-center rounded-full mr-3 text-[7px] lg:text-[10px] font-normal text-white">
                                  {msg.unreadCount}
                                </div>
                              ) : (
                                msg.tickIcon && (
                                  <img
                                    src={msg.tickIcon}
                                    className="size-3 lg:size-5"
                                    alt="tick-icon"
                                  />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="p-3 lg:pt-5 lg:px-6  text-base/5 font-medium text-gray-light">
                    Messages
                  </div>
                  <div>
                    {messageData.map((msg) => (
                      <div
                        key={msg.id}
                        onClick={() => handleSelect(msg.id)}
                        className={`px-3 lg:px-6 py-2 sm:py-3 cursor-pointer w-full ${
                          selectedMessage === msg.id
                            ? "bg-[#F6F7FB]"
                            : "hover:bg-[#F6F7FB]"
                        }`}
                      >
                        <div className="flex gap-3 items-center w-full">
                          <div className="relative">
                            <img
                              src={msg.imageSrc}
                              alt="messageImg"
                              className="block size-8 sm:min-w-14 sm:min-h-14 rounded-full"
                            />
                            <div className="absolute bottom-0 size-2 lg:size-3 p-0.5 right-0 sm:right-1 bg-white rounded-full flex items-center justify-center">
                              <span className="bg-[#63C853] block min-w-1.5 min-h-1.5 lg:min-w-2.5 lg:min-h-2.5 rounded-full"></span>
                            </div>
                          </div>
                          <div className="w-full">
                            <div className="flex justify-between gap-2 items-center mb-0.5 lg:mb-1.5">
                              <h6 className="text-sm sm:text-base lg:text-lg/5 font-medium text-secondary">
                                {msg.name}
                              </h6>
                              <p className="text-xs lg:text-sm font-normal text-gray-light text-end whitespace-nowrap">
                                {msg.time}
                              </p>
                            </div>
                            <div className="flex justify-between gap-2 items-center">
                              <p
                                className={`text-xs lg:text-sm font-normal ${
                                  msg.typing
                                    ? "text-primary"
                                    : "text-gray-light"
                                }`}
                              >
                                {msg.message}
                              </p>
                              {msg.unreadCount > 0 ? (
                                <div className="bg-[#FF0202] size-3 lg:size-5 flex items-center justify-center rounded-full mr-3 text-[7px] lg:text-[10px] font-normal text-white">
                                  {msg.unreadCount}
                                </div>
                              ) : (
                                msg.tickIcon && (
                                  <img
                                    src={msg.tickIcon}
                                    className="size-3 lg:size-5"
                                    alt="tick-icon"
                                  />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <div className="p-3 lg:pt-5 lg:px-6  text-base/5 font-medium text-gray-light">
                    Messages
                  </div>
                  <div>
                    {messageData.map((msg) => (
                      <div
                        key={msg.id}
                        onClick={() => handleSelect(msg.id)}
                        className={`px-3 lg:px-6 py-2 sm:py-3 cursor-pointer w-full ${
                          selectedMessage === msg.id
                            ? "bg-[#F6F7FB]"
                            : "hover:bg-[#F6F7FB]"
                        }`}
                      >
                        <div className="flex gap-3 items-center w-full">
                          <div className="relative">
                            <img
                              src={msg.imageSrc}
                              alt="messageImg"
                              className="block size-8 sm:min-w-14 sm:min-h-14 rounded-full"
                            />
                            <div className="absolute bottom-0 size-2 lg:size-3 p-0.5 right-0 sm:right-1 bg-white rounded-full flex items-center justify-center">
                              <span className="bg-[#63C853] block min-w-1.5 min-h-1.5 lg:min-w-2.5 lg:min-h-2.5 rounded-full"></span>
                            </div>
                          </div>
                          <div className="w-full">
                            <div className="flex justify-between gap-2 items-center mb-0.5 lg:mb-1.5">
                              <h6 className="text-sm sm:text-base lg:text-lg/5 font-medium text-secondary">
                                {msg.name}
                              </h6>
                              <p className="text-xs lg:text-sm font-normal text-gray-light text-end whitespace-nowrap">
                                {msg.time}
                              </p>
                            </div>
                            <div className="flex justify-between gap-2 items-center">
                              <p
                                className={`text-xs lg:text-sm font-normal ${
                                  msg.typing
                                    ? "text-primary"
                                    : "text-gray-light"
                                }`}
                              >
                                {msg.message}
                              </p>
                              {msg.unreadCount > 0 ? (
                                <div className="bg-[#FF0202] size-3 lg:size-5 flex items-center justify-center rounded-full mr-3 text-[7px] lg:text-[10px] font-normal text-white">
                                  {msg.unreadCount}
                                </div>
                              ) : (
                                msg.tickIcon && (
                                  <img
                                    src={msg.tickIcon}
                                    className="size-3 lg:size-5"
                                    alt="tick-icon"
                                  />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
          <div className="flex-1 w-full">
            <div className="p-3 sm:py-5 sm:px-6">
              <div className="flex items-center justify-between flex-wrap gap-4">
                <div className="flex gap-3">
                  <img
                    src="/assets/img/user-2.png"
                    alt="user"
                    className="size-8 block"
                  />
                  <div>
                    <h3 className="text-base/5 font-medium text-secondary">
                      Andre Onana
                    </h3>
                    <p className="text-[10px] leading-[12px] font-normal text-primary ">
                      typing...
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <button type="button">
                    <img src="/assets/img/video.svg" alt="video-icon" />
                  </button>
                  <button type="button">
                    <img src="/assets/img/call.svg" alt="call-icon" />
                  </button>
                  <Menu as="div" className="relative ml-auto size-5 ">
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md text-sm font-semibold text-gray-900">
                      <div className="flex items-center">
                        <div className="flex items-center">
                          <img
                            src="/assets/img/three-dots.svg"
                            alt="three-dots"
                          />
                        </div>
                      </div>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute -right-16 sm:right-0 z-20 mt-2 w-[200px] origin-top-right bg-white rounded-xl">
                        <div className="py-3 bg-white rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <ul>
                            <li className="py-1 px-4 hover:bg-gray-50 cursor-pointer ">
                              All Messages
                            </li>
                            <li className="py-1 px-4 hover:bg-gray-50 cursor-pointer">
                              Archives
                            </li>
                          </ul>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <div className="scrollbar border-t border-b overflow-y-auto h-[450px]">
              <div className="py-4 px-6">
                <p className="text-center text-xs font-normal text-gray-light mb-6">
                  Today
                </p>
                <div className="max-w-[442px] w-full flex items-start gap-3 mb-6">
                  <img
                    src="/assets/img/user-2.png"
                    alt="user"
                    className="size-9 block"
                  />
                  <div>
                    <h4 className="text-sm font-medium text-secondary mb-2 sm:mb-4">
                      Andre Onana
                      <span className="text-gray-light text-[10px] font-normal mb-2 ml-2">
                        06:34 Am
                      </span>
                    </h4>
                    <div className="text-xs font-normal bg-[#F6F7FB] rounded-xl w-full max-w-[394px] py-[14px] border border-gray-border px-4 text-gray-light ">
                      <p>
                        Hey lads, tough game yesterday. Let's talk about what
                        went wrong and how we can improve.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ml-auto w-full max-w-[392px] mb-6 ">
                  <div className="ml-auto flex justify-end gap-1 items-center mb-2">
                    <div className="flex items-center gap-1 text-sm font-normal">
                      <p>You</p>
                      <div className="bg-[#D9D9D9] size-1 rounded-full "></div>
                    </div>
                    <div className="flex items-center gap-2 text-[10px] text-gray-light font-normal">
                      <p>08:34 Am</p>
                      <img
                        src="/assets/img/tick.svg"
                        className=""
                        alt="tick-icon"
                      />
                    </div>
                  </div>
                  <div className="bg-primary py-[14px] px-4 border border-gray-light rounded-2xl w-full max-w-[392px] ">
                    <p className="text-xs font-normal text-white">
                      We need to control the midfield and exploit their
                      defensive weaknesses. Bruno and Paul, I'm counting on your
                      creativity. Marcus and Jadon, stretch their defense wide.
                      Use your pace and take on their full-backs.
                    </p>
                  </div>
                </div>
                <div className="max-w-[442px] w-full flex items-start gap-3 mb-6">
                  <img
                    src="/assets/img/user-2.png"
                    alt="user"
                    className="size-9 block"
                  />
                  <div>
                    <h4 className="text-sm font-medium text-secondary mb-2 sm:mb-4">
                      Andre Onana
                      <span className="text-gray-light text-[10px] font-normal mb-2 ml-2">
                        06:34 Am
                      </span>
                    </h4>
                    <div className="text-xs font-normal bg-[#F6F7FB] rounded-xl w-full max-w-[394px] py-[14px] border border-gray-border px-4 text-gray-light ">
                      <p>
                        Hey lads, tough game yesterday. Let's talk about what
                        went wrong and how we can improve.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-3 py-4 sm:px-[26px] ">
              <div className="flex gap-4 items-center justify-between bg-[#F6F7FB] rounded-[120px] min-h-[52px] px-1 ">
                <input
                  type="text"
                  name="message"
                  id=""
                  className="text-base font-normal placeholder:text-secondary text-secondary flex-1 border-0  bg-transparent pl-6 outline-none appearance-none"
                  placeholder="text..."
                />

                <button
                  type="button"
                  className="flex items-center justify-center shrink-0 size-11 text-white bg-[#1890FF] rounded-full hover:bg-opacity-80"
                >
                  <img src="/assets/img/send-icon.svg" alt="send-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MyMessages;
