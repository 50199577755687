import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import methodModel from "../../../methods/methods";
// import { RiMessage3Line } from "react-icons/ri";
import { useContext } from "react";
import { HomeData } from "../../../Pages/Home";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";

const Footer = () => {
    const user = useSelector((state) => state.User)
    const siteSettings = useSelector((state) => state.Reducer.siteSettings)
    const navigate = useNavigate()
    const footerdata = useContext(HomeData) // Get footer content data using useContext from Home.tsx

    return (
        <>
            <footer className="bg-[#F6F7FB]  pt-4 lg:pt-12">
                <div className="items-center max-w-[1504px] w-full mx-auto px-4 lg:px-8">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 md:col-span-4 lg:col-span-4 xl:col-span-4 2xl:col-span-4">
                            <div className='text-center md:text-left flex items-start gap-3 lg:gap-6 justify-center sm:justify-between flex-wrap flex-row lg:flex-col'>
                                <Link to="/">
                                    <img className="lg:mx-auto lg:m-0 w-[78px] h-8 object-contain" src={siteSettings?.logo ? `${methodModel.campervanImage(siteSettings?.logo)}` : "/assets/img/footer-logo.png"} alt={siteSettings?.logo_name_alt || 'Campervan Logo'} />
                                </Link>
                                <p className="text-gray-light text-sm w-full max-w-[266px]">
                                    {footerdata?.footer_description ? footerdata?.footer_description : "The Rv Renter Center website is a platform for renting and booking campervans in Australia."}
                                </p>
                                <div className='flex  items-center gap-x-3'>
                                    <div className="rounded-full border-[0.87px] border-[#E3E5ED] p-2 h-7 w-7 flex items-center justify-center"> <FaFacebookF className="text-gray-light" /> </div>
                                    <div className="rounded-full border-[0.87px] border-[#E3E5ED] p-2 h-7 w-7 flex items-center justify-center"> <FaLinkedinIn className="text-gray-light" /> </div>
                            {siteSettings?.social_media_links?.map((item, index) => {
                                return <img src={methodModel.campervanImage(item?.icon)} onClick={e => { window.open(item?.url, '_blank') }} className="cursor-pointer" key={index} />
                            })}
                        </div>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-8 lg:col-span-8 xl:col-span-8 2xl:col-span-8">
                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5 gap-x-2 gap-y-6">
                                <div className="">
                                    <h2 className="text-secondary font-medium mb-2.5 lg:mb-5 text-base">{footerdata?.footer_text1 ? footerdata?.footer_text1 : "Comapny"}</h2>
                                    <ul>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/about-us"><p className="">About</p></Link></li>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><p className="" onClick={e => { navigate('/blog') }}>Blog</p></li>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><p className="">Reviews</p></li>
                                    </ul>
                                </div>
                                <div className="">
                                    <h2 className="text-secondary font-medium mb-2.5 lg:mb-5 text-base">{footerdata?.footer_text2 ? footerdata?.footer_text2 : "Get Started"}</h2>
                                    <ul>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/campervans"><p className="">Search RVs</p></Link></li>
                                        <li className="text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><p className="">List your van</p></li>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/termandcondition"><p className="">Terms & Conditions</p></Link></li>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><p className="">Partners</p></li>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/contact-us"><p className="">Contact</p></Link></li>
                                    </ul>
                                </div>
                                <div className="">
                                    <h2 className="text-secondary font-medium mb-2.5 lg:mb-5 text-base">{footerdata?.footer_text3 ? footerdata?.footer_text3 : "Terms"}</h2>
                                    <ul>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/termandcondition"><p className="">Terms & Conditions</p></Link></li>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/privacypolicy"><p className="">Privacy Policy</p></Link></li>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/cookiespolicy"><p className="">Cookies Policy</p></Link></li>
                                    </ul>
                                </div>
                                <div className="">
                                    <h2 className="text-secondary font-medium mb-2.5 lg:mb-5 text-base">{footerdata?.footer_text4 ? footerdata?.footer_text4 : "Resources"}</h2>
                                    <ul>
                                        {localStorage.getItem('token') ? null : <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/agentregister"><p className=""> Register As Agent</p></Link></li>}
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/itineraries"><p className="">Itineraries</p></Link></li>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/how-it-works"><p className="">How it works?</p></Link></li>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><Link to="/insuranceandprotection"><p className="">Insurance & Protection</p></Link></li>
                                    </ul>
                                </div>
                                <div className="">
                                    <h2 className="text-secondary font-medium  mb-2.5 lg:mb-5 text-base">{footerdata?.footer_text5 ? footerdata?.footer_text5 : "Contact"}</h2>
                                    <ul>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><p className=""><Link to={`https://www.google.com/maps/search/?api=1&query=${siteSettings?.location}`} target="_blank"> {siteSettings?.location}</Link> </p></li>
                                        <li className="text-sm lg:text-sm text-gray-light group cursor-pointer mb-4 hover:text-primary transition-all"><p className="flex items-center gap-x-2">
                                            {/* <FaPhoneAlt />  */}
                                            <Link to={`tel:${siteSettings?.contactNumber}`}> +{siteSettings?.contactNumber}</Link> </p></li>
                                        {/* <li className="relative sm:top-7 left-12 bg-primary w-[54px] h-[54px] rounded-full flex justify-center items-center text-white cursor-pointer hover:opacity-80" > <RiMessage3Line className="size-6 " /></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className='colpy-footer bg-secondary py-5'>
                <div className='items-center mx-auto max-w-[1504px] w-full px-4 lg:px-8'>
                    <div className='flex items-center flex-col md:flex-row gap-y-2 justify-between'>
                        <p className='text-[#E3E5ED] text-sm font-normal'>{footerdata?.footer_link ? footerdata?.footer_link : "© 2024 Rv Renter Center. All Right Reserved"}</p>
                        <ul className="flex flex-wrap  gap-4 lg:gap-8 items-center">
                            <li className="text-sm text-[#E3E5ED] group cursor-pointer hover:text-primary transition-all"><Link to="/privacypolicy"><p className="font-normal">Privacy Policy</p></Link></li>
                            <li className="text-sm text-[#E3E5ED] group cursor-pointer hover:text-primary transition-all"><Link to="/termandcondition"><p className="font-normal">Terms & Conditions</p></Link></li>
                        </ul>
                        {/* <div className='flex  items-center gap-x-3'>
                            {siteSettings?.social_media_links?.map((item, index) => {
                                return <img src={methodModel.campervanImage(item?.icon)} onClick={e => { window.open(item?.url, '_blank') }} className="cursor-pointer" key={index} />
                            })}
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer