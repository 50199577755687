import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect, useRef } from "react";
import PageLayout from "../../components/global/PageLayout";
import ApiClient from '../../methods/api/apiClient'
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import SearchBanner from "../../components/common/SearchBanner";
import SortSelect from "../../components/common/SortSelect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SEARCH_STATE } from "../../Redux/Action/Action";
import environment from "../../environment";
import Breadcrumb from "../../components/common/Breadcrumb";
import moment from "moment";
import WhyBookWithUs from "../../components/common/WhyBookWithUs";
import CryptoJS from 'crypto-js';
import ListingLocation from "../../components/common/SearchBanner/ListingLocation";
import { GoLocation } from "react-icons/go";
import { Campervans } from "./Components/Campervans";

const sortOptions = [
  { value: "", name: "Recommended" },
  { value: "defaultRate asc", name: "Lowest price" },
  { value: "defaultRate desc", name: "Highest Price" },
  { value: "age asc", name: "Vehicle Age" },
  // { value: "", name: "Savings Amount" },
  // { value: "", name: "Savings Percentage" },
];

const CampervanListing = () => {
  const urlPath = window.location.pathname
  const search = useSelector((state) => state.Reducer.search)
  const dispatch = useDispatch()
  const [range, setrange] = useState({ start: 0, end: 30000 })
  const [searchFilter, setSearchFilter] = useState({
    peopleCount: 0,
    startDate: new Date().toISOString().substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10)
  })
  const [active, setActive] = useState(0)
  const [filter, setfilter] = useState({ page: 1, count: 10, sortBy: sortOptions[1] })
  const [campervans, setcampervans] = useState([])
  const [totalCampervans, settotalCampervans] = useState(0)
  const [filters, setFilters] = useState({
    transmission: [],
    fuelType: [],
    vehicleAge: [],
    campervan_type: [],
    amenitieis: [],
    priceStart: '',
    priceEnd: '',
  })
  const [amenities, setAmenities] = useState([])
  const [deals, setDeals] = useState([])
  const [vehicalSpecifications, setVehicalSpecifications] = useState({ transmission: {}, fuelType: {} })
  const [brand, setbrand] = useState([])
  const [jucyCampervans, setJucyCampervans] = useState([])
  const pickUpRef = useRef()
  const currentCampervanLowestPrice = useRef()
  const [futureDateCampervans, setFutureDateCampervans] = useState([])
  const [pastDateCampervans, setPastDateCampervans] = useState([])
  const API_KEY = environment.AU_SPACESHIPS_API_KEY
  const SHARED_SECRET = environment.AU_SPACESHIPS_SHARED_SECRET || ''
  const [alternativeLocationsCampervans, setAlternativeLocationsCampervans] = useState([])

  useEffect(() => {
    scrollToDetail()
    getAmenities()
    getDeals()
    getBrands()
    getVehicalSpecifications()
    setFilters({
      transmission: search?.transmission || [],
      fuelType: search?.fuelType || [],
      vehicleAge: search?.vehicleAge || [],
      campervan_type: search?.campervan_type || [],
      amenitieis: search?.amenitieis || [],
      priceStart: search?.priceStart || '',
      priceEnd: search?.priceEnd || '',
    })
  }, [])

  useEffect(() => {
    setfilter((prev) => ({ ...prev, ...search }))
    getcampervan(search)
  }, [search])

  useEffect(() => {
    dispatch(SEARCH_STATE({
      ...search, ...filters,
      transmission: String(filters?.transmission),
      fuelType: String(filters?.fuelType),
      campervan_type: String(filters?.campervan_type),
      vehicleAge: String(filters?.vehicleAge),
      amenitieis: String(filters?.amenitieis),
    }))
  }, [filters])

  const getBrands = () => {
    ApiClient.get(`brand/all?status=active`).then(res => {
      if (res.success) {
        setbrand(res?.data?.map((item) => {
          const brandName = item?.brand_name == "admin" ? "Peer to Peer" : item?.brand_name
          const brandValue = item?.brand_name.toLowerCase()
          return ({ value: brandValue, label: brandName })
        }))
      }
    })
  }

  const scrollToDetail = () => {
    const data = document.getElementById('locationDiv').offsetTop
    window.scrollTo({
      top: data - 50,
      behavior: "smooth",
    })
  }

  const getAmenities = () => {
    ApiClient.get(`category/all?status=active&category_type=amenities`).then(res => {
      if (res.success) {
        setAmenities(res.data)
      }
    })
  }

  const getDeals = () => {
    ApiClient.get(`all/deals?accountId=${environment?.agentAccountKey}&region&code&type`).then(res => {
      if (res.success) {
        setDeals(res.data)
      }
    })
  }

  const getVehicalSpecifications = () => {
    ApiClient.get(`all/CampervanSpecifications/options?status=active&dataFormat=&slug=vehicle`).then(res => {
      if (res.success) {
        res?.data?.map(item => {
          if (item?._id == "65e710fdc1fb9fabb9bb8abc") {
            setVehicalSpecifications((prev) => ({ ...prev, transmission: item }))
          } else if (item?._id == "65e71eadc1fb9fabb9bb8ad1") {
            setVehicalSpecifications((prev) => ({ ...prev, fuelType: item }))
          } else {
            return
          }
        })
      }
    })
  }

  const getcampervan = (p = {}) => {
    let payload = { ...filter, sortBy: filter?.sortBy?.value, ...p }
    delete payload?.isReturn //No Need for this key in payload

    // It check the pickUpLocation and dropOffLocation location siteCode is valid or not
    if (payload?.pickUpLocation?.label === payload?.pickUpLocation?.value) delete payload?.pickUpLocation?.value
    if (payload?.dropOffLocation?.label === payload?.dropOffLocation?.value) delete payload?.dropOffLocation?.value

    //Location and Date filters
    const pickUpLocationSiteCode = payload?.pickUpLocation?.value || ''
    const dropOffLocationSiteCode = payload?.dropOffLocation?.value || payload?.pickUpLocation?.value || ''
    const pickUpDate = payload?.start_date || ''
    const dropOfDate = payload?.end_date || ''

    if (pickUpLocationSiteCode || dropOffLocationSiteCode || pickUpDate || dropOfDate) {
      let startDateAddTwoDays = new Date(pickUpDate)
      let endDateAddTwoDays = new Date(dropOfDate)
      let startDateSubtractTwoDays = new Date(pickUpDate)
      let endDateSubtractTwoDays = new Date(dropOfDate)

      // Add 2 days from the chosen date
      startDateAddTwoDays.setDate(startDateAddTwoDays.getDate() + 2)
      endDateAddTwoDays.setDate(endDateAddTwoDays.getDate() + 2)

      // Subtract 2 days from the chosen date
      startDateSubtractTwoDays.setDate(startDateSubtractTwoDays.getDate() - 2)
      endDateSubtractTwoDays.setDate(endDateSubtractTwoDays.getDate() - 2)

      // Spaceship api payload, api key and signature
      let spaceShipPayload = {
        method: "step2",
        vehiclecategorytypeid: payload?.vehiclecategorytypeid || "6", // Pass van id as vehiclecategorytypeid for spaceship api's as discuss with nishant & dinesh sir
        pickuplocationid: payload?.pickUpLocation?.spaceship_id,
        pickupdate: moment(payload?.start_date).format('DD/MM/YYYY'), // Change the date format
        pickuptime: "10:00", // Pass static time because user choose only date as dinesh sir said
        dropofflocationid: payload?.dropOffLocation?.spaceship_id || payload?.pickUpLocation?.spaceship_id,
        dropoffdate: moment(payload?.end_date).format('DD/MM/YYYY'), // Change the date format
        dropofftime: "10:00", // Pass static time because user choose only date as dinesh sir said
        ageid: payload?.ageid?.value || 9,
      }
      const signature = CryptoJS.HmacSHA256(JSON.stringify(spaceShipPayload), SHARED_SECRET).toString(CryptoJS.enc.Hex)

      // Hit functions to get the future and past campervan prices
      getFutureDatesCampervans(startDateAddTwoDays, endDateAddTwoDays, payload, spaceShipPayload)
      getPastDatesCampervans(startDateSubtractTwoDays, endDateSubtractTwoDays, payload, spaceShipPayload)

      // Check the locations if pickup or dropoff locations is differnet then the function call with reverse locations like pickup location change into dropoff and dropoff location change into pickup location
      if (payload?.pickUpLocation?.value != payload?.dropOffLocation?.value && payload?.dropOffLocation?.value) {
        const pickupLocation = payload?.pickUpLocation
        const alternativeDestinationsPayload = {
          ...payload,
          pickUpLocation: payload?.dropOffLocation,
          dropOffLocation: pickupLocation
        }
        spaceShipPayload = {
          ...spaceShipPayload,
          pickuplocationid: alternativeDestinationsPayload?.pickUpLocation?.spaceship_id,
          dropofflocationid: alternativeDestinationsPayload?.dropOffLocation?.spaceship_id,
        }
        getAlternativeDestinations(alternativeDestinationsPayload, spaceShipPayload)
      } else {
        setAlternativeLocationsCampervans([])
      }
      loader(true)
      // Pass campervan static id as typeId for jucy api's
      ApiClient.post(`availabilityAndPrice?page=${payload?.page}&count=${payload?.count}&typeId=${'7d3dece7-ab86-e611-80e8-c4346bc5977c'}&pickUpLocation=${pickUpLocationSiteCode}&dropOffLocation=${dropOffLocationSiteCode}&pickUpDateTime=${pickUpDate}&dropOffDateTime=${dropOfDate}&accountKey=${environment?.agentAccountKey}&driverAge=${payload?.ageid?.label || 23}&mergeSurchargesFees=${false}&transmission=${payload?.transmission || ''}&fuelType=${payload?.fuelType || ''}&vehicleAge=${payload?.vehicleAge || ''}&amenitieis=${payload?.amenitieis || ''}&sortBy=${payload?.sortBy}&status=${'active'}&apikey=${API_KEY}&campervan_type=${payload?.campervan_type}`, spaceShipPayload).then(res => {
        if (res?.data?.ResponseType == 'Success') {
          const campervanIds = []
          const response = res?.data?.Data?.[1]?.Categories?.filter((item, index) => item?.campervan_type == 'spaceship' && item?.numberofdays != 0 || item?.Availability != 'StopSell' && item?.campervan_type == 'jucy')
          currentCampervanLowestPrice.current = response?.[0]?.Total?.Value || response?.[0]?.defaultRate // Set the first campervan price
          setcampervans(response)
          settotalCampervans(0)
          res?.data?.Data?.[1]?.Categories?.map((item) => {
            if (item?.Availability != 'StopSell' && item?.campervan_type == 'jucy') {
              campervanIds.push(item?.Id)
            }
          })
          if (campervanIds?.length > 0) {
            ApiClient.get(`all/Campervan`, { jucy_campervan_id: String(campervanIds) }).then(res => {
              if (res.success) {
                setJucyCampervans(res?.data)
              }
            })
          }
        } else {
          setcampervans([])
        }
        loader(false)
      })
    } else {
      loader(true)
      ApiClient.get(`all/Campervan`, { ...payload, status: 'active' }).then((res) => {
        if (res?.success) {
          setcampervans(res?.data)
          settotalCampervans(res?.total)
          currentCampervanLowestPrice.current = 0
        }
        loader(false)
      })
    }
  }

  const showJucyImages = (item, index) => {
    let sliderImages = item
    sliderImages = item?.filter((itm) => {
      if (itm?.type == "Image") return itm?.original
    })
    sliderImages = sliderImages?.slice(0, 5)?.map((value) => value?.original)
    return sliderImages
  }
  const handleSearchCampervans = (f) => {
    setfilter({ ...filter, ...f })
    delete f?.sortBy
    dispatch(SEARCH_STATE({ ...search, ...f }))
    scrollToDetail()
  }

  // To get the future dates campervan pricing
  const getFutureDatesCampervans = (startDate, endDate, f, spaceShipPayload) => {
    startDate = moment(startDate).format("YYYY-M-DD")
    endDate = moment(endDate).format("YYYY-M-DD")
    spaceShipPayload = {
      ...spaceShipPayload,
      pickupdate: moment(startDate).format('DD/MM/YYYY'),
      dropoffdate: moment(endDate).format('DD/MM/YYYY'),
    }
    ApiClient.post(`availabilityAndPrice?page=${f?.page}&count=${f?.count}&typeId=${'7d3dece7-ab86-e611-80e8-c4346bc5977c'}&pickUpLocation=${f?.pickUpLocation?.value || ''}&dropOffLocation=${f?.dropOffLocation?.value || f?.pickUpLocation?.value || ''}&pickUpDateTime=${startDate}&dropOffDateTime=${endDate}&accountKey=${environment?.agentAccountKey}&driverAge=${f?.ageid?.label || 23}&mergeSurchargesFees=${false}&transmission=${f?.transmission || ''}&fuelType=${f?.fuelType || ''}&vehicleAge=${f?.vehicleAge || ''}&amenitieis=${f?.amenitieis || ''}&sortBy=${f?.sortBy}&status=${'active'}&apikey=${API_KEY}&campervan_type=${f?.campervan_type}`, spaceShipPayload, '', true).then(res => {
      if (res?.data?.ResponseType == 'Success') {
        const response = res?.data?.Data?.[1]?.Categories?.filter((item, index) => item?.campervan_type == 'spaceship' && item?.numberofdays != 0 || item?.Availability != 'StopSell' && item?.campervan_type == 'jucy')
        setFutureDateCampervans(response)
      } else {
        setFutureDateCampervans([])
      }
    })
  }
  // To get the past dates campervan pricing
  const getPastDatesCampervans = (startDate, endDate, f, spaceShipPayload) => {
    if (startDate > new Date()) {
      startDate = moment(startDate).format("YYYY-M-DD")
      endDate = moment(endDate).format("YYYY-M-DD")
      spaceShipPayload = {
        ...spaceShipPayload,
        pickupdate: moment(startDate).format('DD/MM/YYYY'),
        dropoffdate: moment(endDate).format('DD/MM/YYYY'),
      }
      ApiClient.post(`availabilityAndPrice?page=${f?.page}&count=${f?.count}&typeId=${'7d3dece7-ab86-e611-80e8-c4346bc5977c'}&pickUpLocation=${f?.pickUpLocation?.value || ''}&dropOffLocation=${f?.dropOffLocation?.value || f?.pickUpLocation?.value || ''}&pickUpDateTime=${startDate}&dropOffDateTime=${endDate}&accountKey=${environment?.agentAccountKey}&driverAge=${f?.ageid?.label || 23}&mergeSurchargesFees=${false}&transmission=${f?.transmission || ''}&fuelType=${f?.fuelType || ''}&vehicleAge=${f?.vehicleAge || ''}&amenitieis=${f?.amenitieis || ''}&sortBy=${f?.sortBy}&status=${'active'}&apikey=${API_KEY}&campervan_type=${f?.campervan_type}`, spaceShipPayload, '', true).then(res => {
        if (res?.data?.ResponseType == 'Success') {
          const response = res?.data?.Data?.[1]?.Categories?.filter((item, index) => item?.campervan_type == 'spaceship' && item?.numberofdays != 0 || item?.Availability != 'StopSell' && item?.campervan_type == 'jucy')
          setPastDateCampervans(response)
        } else {
          setPastDateCampervans([])
        }
      })
    }
  }
  // To get the Alternative Destinations pricing
  const getAlternativeDestinations = (payload, spaceShipPayload) => {
    ApiClient.post(`availabilityAndPrice?page=${payload?.page}&count=${payload?.count}&typeId=${'7d3dece7-ab86-e611-80e8-c4346bc5977c'}&pickUpLocation=${payload?.pickUpLocation?.value || ''}&dropOffLocation=${payload?.dropOffLocation?.value || ''}&pickUpDateTime=${payload?.start_date}&dropOffDateTime=${payload?.end_date}&accountKey=${environment?.agentAccountKey}&driverAge=${payload?.ageid?.label || 23}&mergeSurchargesFees=${false}&transmission=${payload?.transmission || ''}&fuelType=${payload?.fuelType || ''}&vehicleAge=${payload?.vehicleAge || ''}&amenitieis=${payload?.amenitieis || ''}&sortBy=${payload?.sortBy}&status=${'active'}&apikey=${API_KEY}&campervan_type=${payload?.campervan_type}`, spaceShipPayload, '', true).then(res => {
      if (res?.data?.ResponseType == 'Success') {
        const response = res?.data?.Data?.[1]?.Categories?.filter((item, index) => item?.campervan_type == 'spaceship' && item?.numberofdays != 0 || item?.Availability != 'StopSell' && item?.campervan_type == 'jucy')
        setAlternativeLocationsCampervans(response)
      } else {
        setAlternativeLocationsCampervans([])
      }
    })
  }

  const handleCheckboxChange = (name, value) => {
    if (name === "transmission") { // Transmission can be selected only one
      setFilters((prev) => ({ ...prev, [name]: value }))
    } else {
      setFilters((prevState) => ({ ...prevState, [name]: prevState?.[name]?.includes(value) ? prevState?.[name]?.filter((item) => item !== value) : [...prevState?.[name], value] }));
    }
  }

  const handleClearAll = () => {
    let fields = {
      type: '', region: '', transmission: '', fuelType: '', vehicleAge: '', campervan_type: '', amenitieis: [], priceStart: '', priceEnd: '',
    }
    dispatch(SEARCH_STATE({ ...search, ...fields }))
    setFilters({
      transmission: '',
      fuelType: '',
      vehicleAge: '',
      campervan_type: '',
      amenitieis: [],
      priceStart: '',
      priceEnd: '',
    });
    setfilter({ page: 1, count: 10, sortBy: sortOptions[1] })
  }

  const showJucyCampervanDetail = (item, index, key) => {
    const data = jucyCampervans?.find((itm) => itm?.jucy_campervan_id == item?.Id)
    if (key == 'gallery') {
      return showJucyImages(data?.gallery, index)
    } else {
      return data?.[key]
    }
  }

  const handlePriceRange = () => {
    setFilters((prev) => ({ ...prev, priceStart: range?.start, priceEnd: range?.end }))
  }

  const hanldeSortBy = (e) => {
    setfilter((prev) => ({ ...prev, sortBy: e }))
    getcampervan({ sortBy: e?.value || '' })
  }

  // Function to find the future and past date campervans with cheapest price.Type is used to return the startDate, endDate and cheapest price.
  const findCheapestCampervan = (type, key, campervanPrice) => {
    let cheapestCampervan = {}
    let cheapestCampervanStartDates = search?.start_date ? new Date(search?.start_date) : new Date()
    let cheapestCampervanEndDates = search?.end_date ? new Date(search?.end_date) : new Date()
    // Get the current first campervan price
    let findCurrentCampervanLowestPrice = campervanPrice

    // Then compare the current first campervan price to the other current campervans and find the cheapest campervan
    campervans && campervans?.find((item) => {
      const total = key == 'jucy' ? item?.Total?.Value : item?.[key]
      if (total <= findCurrentCampervanLowestPrice) {
        findCurrentCampervanLowestPrice = key == 'jucy' ? item?.Total?.Value : item?.[key]
        cheapestCampervan = item
        cheapestCampervanStartDates = new Date(search?.start_date)
        cheapestCampervanEndDates = new Date(search?.end_date)
      }
    })

    // Compare the future dates campervan price to the current cheapest campervan price
    futureDateCampervans && futureDateCampervans?.find((item) => {
      const total = key == 'jucy' ? item?.Total?.Value : item?.[key]
      if (total <= findCurrentCampervanLowestPrice) {
        findCurrentCampervanLowestPrice = key == 'jucy' ? item?.Total?.Value : item?.[key]
        cheapestCampervan = item
        cheapestCampervanStartDates.setDate(cheapestCampervanStartDates.getDate() + 2)
        cheapestCampervanEndDates.setDate(cheapestCampervanEndDates.getDate() + 2)
      }
    })

    // Compare the past dates campervan price to the current cheapest campervan price
    pastDateCampervans && pastDateCampervans?.find((item) => {
      const total = key == 'jucy' ? item?.Total?.Value : item?.[key]
      if (total <= findCurrentCampervanLowestPrice) {
        findCurrentCampervanLowestPrice = key == 'jucy' ? item?.Total?.Value : item?.[key]
        cheapestCampervan = item
        cheapestCampervanStartDates.setDate(cheapestCampervanStartDates.getDate() - 2)
        cheapestCampervanEndDates.setDate(cheapestCampervanEndDates.getDate() - 2)
      }
    })

    if (type == 'startDate') return moment(cheapestCampervanStartDates || new Date()).format("DD MMM YYYY")
    else if (type == 'endDate') return moment(cheapestCampervanEndDates || new Date()).format("DD MMM YYYY")
    else return key == 'jucy' ? cheapestCampervan?.Total?.Value : cheapestCampervan?.[key] || campervanPrice
  }
  // Function to find to cheapest campervan with alternative destinations
  const findAlternativeLocationsCampervan = (type, key, campervanPrice) => {
    let cheapestCampervan = {}
    let location = search?.dropOffLocation?.label
    // Get the current first campervan price
    let findCurrentCampervanLowestPrice = campervanPrice

    // Compare the current first campervan price to the other current campervans and find the cheapest campervan
    campervans && campervans?.find((item) => {
      let total = ""
      if (key === "jucy" && item?.campervan_type == 'jucy') {
        total = item?.Total?.Value
      } else {
        if (item?.campervan_type != 'jucy') total = item?.[key]
      }
      if (total <= findCurrentCampervanLowestPrice) {
        findCurrentCampervanLowestPrice = key == 'jucy' ? item?.Total?.Value : item?.[key]
        cheapestCampervan = item
      }
    })
    // Then compare the cheapest campervan price to the alternative destination campervan price to find the cheapest campervan from current and alternative destinations
    alternativeLocationsCampervans && alternativeLocationsCampervans?.find((item) => {
      let total = ""
      if (key === 'jucy' && item?.campervan_type == 'jucy') {
        total = item?.Total?.Value
      } else {
        if (item?.campervan_type != "jucy") total = item?.[key]
      }
      if (total <= findCurrentCampervanLowestPrice) {
        findCurrentCampervanLowestPrice = key === 'jucy' ? item?.Total?.Value : item?.[key]
        cheapestCampervan = item
        // location = search?.dropOffLocation?.label
      }
    })
    if (type == "location") return location
    else if (type == "id" && key == "jucy") return jucyCampervans?.find((itm) => itm?.jucy_campervan_id === cheapestCampervan?.Id)?._id
    else if (type == "id" && key == 'defaultRate') return cheapestCampervan?.id || cheapestCampervan?._id || cheapestCampervan?.Id
    else return key == 'jucy' ? cheapestCampervan?.Total?.Value : cheapestCampervan?.[key] || campervanPrice
  }

  return (
    <>
      <PageLayout>
        {urlPath != "/campervanlist" ?
          <div className="max-w-[1504px] w-full mx-auto px-4 lg:px-8">
            {/* BreadCrums */}

            <div className="mt-5 lg:mt-10 mb-3 lg:mb-6">
              <Breadcrumb
                links={[
                  { link: "/", name: "Home" },
                ]}
                currentPage="Campervans"
              />
            </div>

            {/* <div className="mt-3 lg:mt-6"> */}
            {/* <h1 className="font-semibold text-secondary text-2xl/6 lg:text-[58px] mb-6 lg:mb-12">
              Browse and book your RV escape
            </h1> */}
            {/* <SearchBanner
              bgImage="assets/img/front/herobanner.png"
              listingPage
              searchFilter={searchFilter}
              filter={filter}
              setSearchFilter={setSearchFilter}
              classes="lg:py-[104px]"
              handleSearchCampervans={handleSearchCampervans}
              activeIdx={active}
              setActiveIdx={setActive}
              pickUpRef={pickUpRef}
            /> */}
            {/* </div> */}
          </div>
          : null}
        <ListingLocation
          searchFilter={searchFilter}
          filter={filter}
          setSearchFilter={setSearchFilter}
          handleSearchCampervans={handleSearchCampervans}
          activeIdx={active}
          setActiveIdx={setActive}
          pickUpRef={pickUpRef}
        />
        <div className="max-w-[1504px] w-full mx-auto px-4 lg:px-8">
          <div className="titled-haed flex flex-col sm:flex-row flex-wrap  gap-3 sm:gap-4 lg:gap-6 xl:gap-8 sm:items-center justify-between my-4 lg:my-8 ">
            <div className="">
              <h3 className="text-secondary text-lg lg:text-xl xl:text-2xl font-semibold mb-2">Search results based on your criteria</h3>
              <p className="text-gray-light text-sm lg:text-base">Results Displaying <span className="text-secondary font-medium"> {campervans?.length} of {totalCampervans || campervans?.length} </span></p>
            </div>
            <SortSelect
              classes={"w-full max-w-[220px] cursor-pointer border-0 max-sm:ml-auto"}
              labelBg={"bg-white"}
              options={sortOptions}
              value={filter?.sortBy}
              onChange={e => hanldeSortBy(e)}
            />
          </div>
          <div className="flex flex-col xl:flex-row flex-wrap gap-4 2xl:gap-12">
            {/* Filters */}
            {urlPath != "/campervanlist" ?
              <div className="xl:max-w-[380px] w-full mb-6 max-xl:grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-3">
                <div className="w-full" id="locationDiv">
                  {search?.pickUpLocation?.value && search?.start_date && search?.end_date ?
                    <div className="border rounded-2xl border-primary overflow-x-auto p-3 lg:p-4 xl:p-6 xl:mb-5 h-full">
                      <div className="flex justify-between">
                        <h6 className="text-lg font-semibold text-secondary mb-4">Your Search</h6>

                      </div>
                      <div className="flex flex-row">
                        <div className="w-10">
                          <span>
                            <img src="/assets/img/pickup-icon.svg" className="size-6" />
                          </span>
                          <span className="border-l-[2px] h-[35%] sm:h-[67%] xl:h-[42%] inline-block border-dashed border-primary w-fit relative left-3"></span>
                          <span>
                            {/* <img src="/assets/img/distance.svg" className="size-6" /> */}
                            <GoLocation className="size-6 text-primary" />

                          </span>
                        </div>
                        <div className="flex gap-3 gap-3 sm:gap-40 xl:gap-6 flex-col">
                          <div className="flex flex-col gap-1">
                            <h4 className="font-semibold text-base whitespace-nowrap text-secondary">Pickup Location:</h4>
                            <p className="font-normal text-base text-gray-light whitespace-nowrap">{search?.pickUpLocation?.label || '--'}</p>
                            <p className="font-normal text-base text-gray-light whitespace-nowrap">{moment(search?.start_date).format('ddd, DD MMM YYYY')}</p>
                          </div>

                          <div className="flex flex-col gap-1">
                            <h4 className="font-semibold  text-sm whitespace-nowrap">Drop Off:</h4>
                            <p className="font-normal text-base text-gray-light whitespace-nowrap ">{search?.dropOffLocation?.label || search?.pickUpLocation?.label || '--'}</p>
                            <p className="font-normal text-base text-gray-light whitespace-nowrap">{moment(search?.end_date).format('ddd, DD MMM YYYY')}</p>
                            {/* <div className="mt-4">
                                  <div onClick={e => { pickUpRef.current.focus() }} className="text-white bg-primary rounded-full py-3 text-center w-[135px] cursor-pointer text-sm font-normal hover:opacity-80 transition-all">Edit Search</div>
                                </div> */}

                          </div>

                        </div>
                      </div>
                      <button className="text-sm  text-primary cursor-pointer w-full max-w-[332px] h-10 flex justify-center items-center border border-primary rounded-xl mt-3 lg:mt-6 hover:bg-primary hover:text-white  transition-all" onClick={e => { pickUpRef.current.focus() }}>Edit Search</button>
                    </div>
                    : null}
                </div>

                {/* <div className="w-full border border-primary rounded-2xl"> */}
                <div className={`w-full border border-primary rounded-2xl ${!(search?.pickUpLocation?.value && search?.start_date && search?.end_date) ? 'col-span-2' : ''}`}>
                  <div className="flex items-center justify-between px-6 pt-[30px] pb-5">
                    <p className='text-lg text-secondary font-semibold'>Filters</p>
                    <button
                      type="button"
                      onClick={handleClearAll}
                      className="text-primary flex gap-1 items-center justify-center outline-none border-primary border rounded-xl group  w-[125px] h-10 px-6 py-3 hover:bg-primary hover:text-white group">
                      {/* <BsTrash3 size={18} className="text-primary group-hover:text-white" /> */}
                      {/* <img src="/assets/img/front/deleteicon.svg" alt="DeleteIcon" class=" text-[#1744D2] group-hover:text-white"  width="16" height="16"></img> */}
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="fill-current text-[#1744D2] group-hover:text-white"
                      >
                        <path
                          d="M3.46025 4.75329C3.33358 4.75329 3.20025 4.69996 3.10691 4.60663C2.91358 4.41329 2.91358 4.09329 3.10691 3.89996L5.52691 1.47996C5.72025 1.28663 6.04025 1.28663 6.23358 1.47996C6.42692 1.67329 6.42692 1.99329 6.23358 2.18663L3.81358 4.60663C3.71358 4.69996 3.58691 4.75329 3.46025 4.75329Z"
                          fill="currentColor"
                        />
                        <path
                          d="M12.5404 4.75329C12.4137 4.75329 12.2871 4.70663 12.1871 4.60663L9.76707 2.18663C9.57374 1.99329 9.57374 1.67329 9.76707 1.47996C9.9604 1.28663 10.2804 1.28663 10.4737 1.47996L12.8937 3.89996C13.0871 4.09329 13.0871 4.41329 12.8937 4.60663C12.8004 4.69996 12.6671 4.75329 12.5404 4.75329Z"
                          fill="currentColor"
                        />
                        <path
                          d="M13.473 7.56657C13.4263 7.56657 13.3797 7.56657 13.333 7.56657H13.1797H2.66634C2.19967 7.57324 1.66634 7.57324 1.27967 7.18657C0.973008 6.88657 0.833008 6.4199 0.833008 5.73324C0.833008 3.8999 2.17301 3.8999 2.81301 3.8999H13.1863C13.8263 3.8999 15.1663 3.8999 15.1663 5.73324C15.1663 6.42657 15.0263 6.88657 14.7197 7.18657C14.373 7.53324 13.9063 7.56657 13.473 7.56657ZM2.81301 6.56657H13.3397C13.6397 6.57324 13.9197 6.57324 14.013 6.4799C14.0597 6.43324 14.1597 6.27324 14.1597 5.73324C14.1597 4.9799 13.973 4.8999 13.1797 4.8999H2.81301C2.01967 4.8999 1.83301 4.9799 1.83301 5.73324C1.83301 6.27324 1.93967 6.43324 1.97967 6.4799C2.07301 6.56657 2.35967 6.56657 2.65301 6.56657H2.81301Z"
                          fill="currentColor"
                        />
                        <path
                          d="M6.50659 12.6999C6.23326 12.6999 6.00659 12.4733 6.00659 12.1999V9.83325C6.00659 9.55992 6.23326 9.33325 6.50659 9.33325C6.77993 9.33325 7.00659 9.55992 7.00659 9.83325V12.1999C7.00659 12.4799 6.77993 12.6999 6.50659 12.6999Z"
                          fill="currentColor"
                        />
                        <path
                          d="M9.57324 12.6999C9.29991 12.6999 9.07324 12.4733 9.07324 12.1999V9.83325C9.07324 9.55992 9.29991 9.33325 9.57324 9.33325C9.84658 9.33325 10.0732 9.55992 10.0732 9.83325V12.1999C10.0732 12.4799 9.84658 12.6999 9.57324 12.6999Z"
                          fill="currentColor"
                        />
                        <path
                          d="M9.92659 15.6667H5.90658C3.51992 15.6667 2.98658 14.2467 2.77992 13.0134L1.83992 7.24675C1.79325 6.97341 1.97992 6.72008 2.25325 6.67341C2.52658 6.62675 2.77992 6.81341 2.82658 7.08675L3.76658 12.8467C3.95992 14.0267 4.35992 14.6667 5.90658 14.6667H9.92659C11.6399 14.6667 11.8333 14.0667 12.0533 12.9067L13.1733 7.07341C13.2266 6.80008 13.4866 6.62008 13.7599 6.68008C14.0333 6.73341 14.2066 6.99341 14.1533 7.26675L13.0333 13.1001C12.7733 14.4534 12.3399 15.6667 9.92659 15.6667Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span className='text-sm font-medium  text-primary whitespace-nowrap group-hover:text-white'>Clear All</span>
                    </button>
                  </div>
                  <div className="border-t border-gray-border" />
                  <div className="px-6 pt-5 pb-6">
                    <h6 className='text-base font-semibold text-secondary mb-2 lg:mb-3 capitalize'>{vehicalSpecifications?.transmission?.name}</h6>
                    <div className="flex gap-4 items-center mb-4 lg:mb-6">
                      {vehicalSpecifications?.transmission?.values?.map((item) => (
                        <div key={item?.data} role="button" onClick={() => handleCheckboxChange('transmission', item?.data)}
                          className={`border px-5 py-1.5 rounded-lg flex items-center gap-2 h-8 min-w-25 cursor-pointer ${filters.transmission === item?.data ? 'bg-primary text-white img-white border-primary' : 'text-gray-light border-gray-border border'}`}
                        >
                          <span className='text-xs/4 capitalize'>{item?.data}</span>
                        </div>
                      ))}
                    </div>
                    <h6 className='text-base font-semibold text-secondary mb-2 lg:mb-3 capitalize'>Amenities</h6>
                    <div className="flex gap-2 flex-wrap mb-3 lg:mb-6">
                      {amenities.map((itm) => (
                        <div key={itm?._id || itm?.id} role="button" onClick={() => handleCheckboxChange('amenitieis', itm?._id || itm?.id)}
                          className={`border px-3 py-1.5 rounded-lg flex items-center gap-1 h-8 min-w-20 cursor-pointer ${filters.amenitieis.includes(itm?._id || itm?.id) ? 'bg-primary text-white img-white border-primary' : 'text-gray-light border-gray-border border-'}`}
                        >
                          <img alt={itm?.image_alt_name} src={methodModel.campervanImage(itm?.image)} className="size-4" />
                          <span className='text-xs/4 capitalize'>{itm?.name}</span>
                        </div>
                      ))}
                    </div>
                    <h6 className='text-base font-semibold text-secondary mb-2 lg:mb-3 capitalize'>Brands</h6>
                    <div className="flex gap-4 items-center mb-4 lg:mb-6 flex-wrap">
                      {brand?.map((item, idx) => (
                        <div key={idx} className="flex items-center gap-2">
                          <input
                            type="checkbox"
                            name="campervan_type"
                            id={`${idx}years`}
                            checked={filters.campervan_type.includes(item?.value)}
                            onChange={() => handleCheckboxChange('campervan_type', item?.value)}
                            className="custom-radio shrink-0 text-primary size-5 border border-gray-border rounded cursor-pointer appearance-none"
                          />
                          <label htmlFor={`${idx}years`} className="text-sm text-gray-light cursor-pointer capitalize">
                            {item?.label}
                          </label>
                        </div>
                      ))}
                    </div>
                    <h6 className="text-base font-semibold text-secondary mb-2 lg:mb-3 capitalize">Price Range</h6>
                    <div className="flex gap-3 max-sm:justify-between items-center mb-6">
                      <div className="">
                        <p>
                          <input className="flex items-center gap-x-1 gap-y-1 border border-gray-border rounded-lg px-4 py-2 h-[30px] w-[80px] justify-center text-sm text-gray-light appearance-none outline-none" placeholder="0" value={range?.start} onChange={e => setrange({ ...range, start: e.target.value })} onBlur={e => handlePriceRange()} type="text" minLength={1} maxLength={5} />
                        </p>
                      </div>
                      <span className="text-sm text-secondary">To</span>
                      <div className="">
                        <p>
                          <input className="flex items-center gap-x-1 gap-y-1 border border-gray-border rounded-lg px-4 py-2 h-[30px] w-[80px] justify-center text-sm text-gray-light appearance-none outline-none" placeholder="999" value={range?.end} onChange={e => setrange({ ...range, end: e.target.value })} onBlur={e => handlePriceRange()} type="text" minLength={1} maxLength={5} />
                        </p>
                      </div>
                    </div>
                    <div className="h-2 w-full xl:w-[330px] bg-secondary rounded-[10px] relative">
                      <RangeSlider className="w-5 h-5 !bg-[#E6EBFF]" onThumbDragEnd={e => handlePriceRange()} value={[range?.start, range?.end]} onInput={(e) => setrange({ start: e[0], end: e[1] })} min={0} max={50000} />
                    </div>
                  </div>
                  {/* <h6 className='text-base/5 font-semibold text-primary mb-4 mt-8'>Deals</h6>
                  <div className="flex gap-4 items-center mb-8 flex-wrap">
                    {deals?.map((item, idx) => (
                      <div key={idx} className="relative items-center gap-2">
                        <div className="relative bg-primary px-6 py-2 rounded-lg  flex items-center gap-2 min-w-28 cursor-pointer text-white   transition duration-300 overflow-hidden">
                          <span className="font-bold text-lg">{item?.title}</span>
                          <div className="absolute w-full h-full top-0 left-0 border-4 border-dotted">
                            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-1/2 w-6 h-6 bg-white rounded-full z-10 "></div>
                            <div className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2 w-6 h-6 bg-white rounded-full z-10 "></div>
                          </div>
                          <div className="shine-wrapper">
                            <div className="shine"></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div> */}
                </div>
                <div class="sm:col-span-2">
                  <WhyBookWithUs />
                </div>
              </div>
              : null}
            <Campervans
              campervans={campervans}
              findAlternativeLocationsCampervan={findAlternativeLocationsCampervan}
              findCheapestCampervan={findCheapestCampervan}
              showJucyImages={showJucyImages}
              showJucyCampervanDetail={showJucyCampervanDetail}
              totalCampervans={totalCampervans}
              filter={filter}
              setfilter={setfilter}
              getcampervan={getcampervan}
              currentCampervanLowestPrice={currentCampervanLowestPrice}
            />
          </div>
        </div>
      </PageLayout>
      {/* <ReactTooltip
        id="my-tooltip-1"
        classNameArrow="border-b border-r "
        className="!z-[999] !bg-white !text-secondary !border"
        place="top"
        variant="info"
        content="I'm a info tooltip"
      /> */}
    </>
  )
}

export default CampervanListing